
















import { Vue, Component, Prop } from 'vue-property-decorator';

const moment = require('moment');

@Component
export default class NoOrders extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  readonly button!: boolean;
}
