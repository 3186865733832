var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        "content-class": "confirmSynchro",
        persistent: "",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("declineSynchro")
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "confirmSynchro-card" } },
        [
          _c("v-card-title", { staticClass: "confirmSynchro-card-title" }, [
            _c("h1", { staticClass: "confirmSynchro-card-title-text" }, [
              _vm._v(_vm._s(_vm.autoSynchroTitle))
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "confirmSynchro-card-content" },
            [_c("v-container", [_vm._v(_vm._s(_vm.autoSynchroContent))])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "confirmSynchro-card-btn",
              attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v(" Revenir sur le programme ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }