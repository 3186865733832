<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.129"
    height="30.128"
    viewBox="0 0 30.129 30.128"
  >
    <g
      id="file"
      transform="translate(0 0)"
    >
      <path
        id="Tracé_3576"
        data-name="Tracé 3576"
        d="M0,0H30.129V30.128H0Z"
        transform="translate(0 0)"
        fill="none"
      />
      <path
        id="Tracé_3577"
        data-name="Tracé 3577"
        d="M14,3V8.021a1.255,1.255,0,0,0,1.255,1.255h5.021"
        transform="translate(3.575 0.766)"
        class="st1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Tracé_3578"
        data-name="Tracé 3578"
        d="M20.064,25.6H7.511A2.511,2.511,0,0,1,5,23.085V5.511A2.511,2.511,0,0,1,7.511,3H16.3l6.277,6.277V23.085A2.511,2.511,0,0,1,20.064,25.6Z"
        transform="translate(1.277 0.766)"
        class="st1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgStandardOfferType extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.st1 {
  fill: none;
  stroke: var(--v-inactif-base);
}
</style>
