var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delete-wrapper" },
    [
      _c("PageTitle", {
        staticClass: "d-md-flex",
        attrs: { text: "Supprimer mon compte" }
      }),
      _c(
        "v-form",
        { ref: "deleteAccountForm", staticClass: "delete-content" },
        [
          _c("v-textarea", {
            attrs: {
              rules: _vm.rules.reason,
              maxLength: _vm.maxContentLength,
              label: "Pourquoi vous désinscrire ?",
              outlined: "",
              color: "homeglobal_sous_titre",
              counter: _vm.maxContentLength
            },
            model: {
              value: _vm.reason,
              callback: function($$v) {
                _vm.reason = $$v
              },
              expression: "reason"
            }
          }),
          _vm.deleteRGPDMessage && _vm.deleteRGPDMessage !== "<p></p>"
            ? _c("v-card-text", {
                staticClass: "delete-text",
                domProps: { innerHTML: _vm._s(_vm.deleteRGPDMessage) }
              })
            : _c("p", { staticClass: "delete-text" }, [
                _vm._v(
                  " Si vous supprimez votre compte, celui-ci sera automatiquement désactivé. Seule l'équipe "
                ),
                _c("span", { staticClass: "delete-text--company" }, [
                  _vm._v(_vm._s(_vm.compPlatform.name))
                ]),
                _vm._v(
                  " peut voir les comptes désactivés, avant que ces derniers ne soient supprimés de manière permanente. Cette suppression intervient dans un délai indiqué dans les politiques de confidentialité. "
                )
              ]),
          _c("v-btn", {
            staticClass: "submit",
            attrs: { rounded: "", color: "homeglobal_fond_bouton", large: "" },
            domProps: { textContent: _vm._s("Supprimer mon compte") },
            on: { click: _vm.handleDeleteAccount }
          })
        ],
        1
      ),
      _vm.showConfirmation
        ? _c("ConfirmDeleteAccount", {
            on: { onClose: _vm.closeConfirm, confirmDelete: _vm.doDelete }
          })
        : _vm._e(),
      _vm.showGoodbye
        ? _c("Goodbye", { on: { onClose: _vm.closeGoodbye } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }