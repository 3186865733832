














































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Component } from 'vue-property-decorator';
import { formRules } from '@/services/userSpace/UserService';
import { signInPasswordFlow } from '@/api/resources/ssoApi';
import { isConnected } from '@/services/common/common';
import SSOPartnerErrorDialog from '@/components/Dialogs/SSOPartnerErrorDialog.vue';
import { HttpStatus } from '@/services/userSpace/types';
import { loginAndRedirectToLanding } from '@/services/authSpace/sso.service';

const STAR_MISSING_VALUES = 'STARMissingValuesException';

@Component({
  components: { SSOPartnerErrorDialog },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (isConnected()) {
        const { platform } = vm as any;
        next(platform.config.landingPath);
      } else {
        next();
      }
    });
  },

  computed: {
    ...mapGetters('platform', {
      platform: 'getPlatform',
    }),
  },
  methods: {
    ...mapActions('notifications', ['addDanger']),
  },
})
export default class SSOSignInForm extends Vue {
  addDanger!: Function;

  platform!: any;

  formRules!: typeof formRules;

  // ===== data =======
  showPassword: boolean = false;

  submitting: boolean = false;

  form = {
    externalEmail: '',
    externalPassword: '',
  };

  showDialogError: boolean = false;

  error: string = '';

  // ===== computed =======
  get ssoOptions() {
    return this.$store.getters['platform/ssoOptions'];
  }

  get signupRGPDMessage() {
    return this.$store.getters['platform/getBannerInfos'].rgpdMessages.signupRGPDMessage;
  }

  // ===== lifecycle =======
  created() {
    this.formRules = formRules;
  }

  // ===== methods =====
  submit() {
    if (!(this.$refs.form as any).validate()) {
      return Promise.resolve();
    }

    this.submitting = true;
    return this.submitPasswordFlow().finally(() => {
      this.submitting = false;
    });
  }

  async submitPasswordFlow() {
    this.error = '';

    try {
      const { tokens, userInfos, externalAccountId } = await signInPasswordFlow({
        platformUUID: this.platform.uuid,
        email: this.form.externalEmail,
        password: this.form.externalPassword,
      });

      if (!tokens) {
        return this.$router.push({
          name: 'sso:pairing',
          query: {
            userInfos,
            externalAccountId,
          },
        });
      }

      return loginAndRedirectToLanding(tokens);
    } catch (e) {
      const statusCode = (e as any).response.status;

      switch (statusCode) {
        case HttpStatus.NOT_FOUND:
          this.error = 'Email ou mot de passe incorrect.';
          break;
        case HttpStatus.BAD_GATEWAY:
          this.showDialogError = true;
          break;
        case HttpStatus.BAD_REQUEST:
          if (e.response.data.name === STAR_MISSING_VALUES) {
            this.addDanger({ message: "Votre compte  STAR n'est pas encore activé. Veuillez vérifier vos emails pour activer votre compte." });
          } else {
            this.showDialogError = true;
          }
          break;
        default:
          this.addDanger('Une erreur inconnue est survenue. Veuillez contactez le support.');
          throw e;
      }
    }

    return Promise.resolve();
  }
}
