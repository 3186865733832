






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class OfferDescription extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'description du produit',
  })
  readonly description!: string;
}
