var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup" },
    [
      _c("div", { staticClass: "signup-title" }, [
        _vm.showStickers
          ? _c(
              "div",
              { staticClass: "signup-title-picto" },
              [_c("SvgSignup")],
              1
            )
          : _vm._e(),
        _c("h1", { staticClass: "signup-title-text" }, [_vm._v("Inscription")])
      ]),
      _c(
        "v-form",
        {
          ref: "stepForm",
          staticClass: "signup-form",
          attrs: { "data-cy": "signup-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validate($event)
            }
          },
          model: {
            value: _vm.formValid,
            callback: function($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "signup-form-row" },
            [
              _c("v-text-field", {
                ref: "lastname",
                staticClass: "signup-form-field-label",
                attrs: {
                  label: _vm.clientPersonalInformations.requiredName
                    ? "Nom *"
                    : "Nom",
                  rules: _vm.clientPersonalInformations.requiredName
                    ? _vm.formRules.name
                    : [],
                  required: _vm.clientPersonalInformations.requiredName,
                  name: "lastname",
                  "data-cy": "signup-input-lastname",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.formValues.lastname,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "lastname", $$v)
                  },
                  expression: "formValues.lastname"
                }
              }),
              _c("v-text-field", {
                ref: "firstname",
                staticClass: "signup-form-field-label",
                attrs: {
                  label: _vm.clientPersonalInformations.requiredFirstName
                    ? "Prénom *"
                    : "Prénom",
                  rules: _vm.clientPersonalInformations.requiredFirstName
                    ? _vm.formRules.firstname
                    : [],
                  required: _vm.clientPersonalInformations.requiredFirstName,
                  name: "firstname",
                  "data-cy": "signup-input-firstname",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.formValues.firstname,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "firstname", $$v)
                  },
                  expression: "formValues.firstname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "signup-form-row with-tooltip" },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "##/##/####",
                    expression: "'##/##/####'"
                  }
                ],
                ref: "dateOfBirth",
                staticClass: "signup-form-field-label",
                attrs: {
                  name: "dateOfBirth",
                  label: _vm.clientPersonalInformations.requiredDateOfBirth
                    ? "Date de naissance *"
                    : "Date de naissance",
                  required: _vm.clientPersonalInformations.requiredDateOfBirth,
                  rules: _vm.clientPersonalInformations.requiredDateOfBirth
                    ? _vm.formRules.dateOfBirth
                    : _vm.formRules.optionalDateOfBirth,
                  "data-cy": "signup-input-dateOfBirth",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.formValues.dateOfBirth,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "dateOfBirth", $$v)
                  },
                  expression: "formValues.dateOfBirth"
                }
              }),
              _vm.sponsorshipIsActive
                ? _c("v-text-field", {
                    ref: "referrerCode",
                    staticClass: "signup-form-field-label",
                    attrs: {
                      rules: _vm.formRules.referrerCode,
                      name: "referrerCode",
                      "data-cy": "signup-input-referrerCode",
                      label: "Code de parrainage (optionnel)",
                      "validate-on-blur": ""
                    },
                    model: {
                      value: _vm.formValues.referrerCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formValues, "referrerCode", $$v)
                      },
                      expression: "formValues.referrerCode"
                    }
                  })
                : _c("div", { staticStyle: { "flex-basis": "50%" } })
            ],
            1
          ),
          _vm.hasManyServiceForSignUp
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "multi-service-choice-label" }, [
                    _vm._v("Quel service utilisez-vous ?")
                  ]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "multi-service-choice",
                      attrs: { mandatory: "", row: "" },
                      model: {
                        value: _vm.selectedServiceIndex,
                        callback: function($$v) {
                          _vm.selectedServiceIndex = $$v
                        },
                        expression: "selectedServiceIndex"
                      }
                    },
                    _vm._l(_vm.formValues.services, function(service, index) {
                      return _c("v-radio", {
                        key: service.id,
                        attrs: {
                          label: service.name,
                          value: index,
                          color: "homeglobal_fond_bouton"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        color:
                                          "var(--v-homeglobal_texte_fond-base)"
                                      }
                                    },
                                    [_vm._v(_vm._s(service.name))]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.emailAsExternal
            ? _c(
                "div",
                { staticClass: "with-tooltip" },
                [
                  _c("v-text-field", {
                    ref: "externalAccountId",
                    staticClass: "signup-form-field-label",
                    attrs: {
                      name: "externalAccountId",
                      label: _vm.clientId.required
                        ? _vm.clientId.label + " *"
                        : _vm.clientId.label,
                      required: _vm.clientId.required,
                      rules: _vm.clientId.required
                        ? _vm.externalAccountIdRule
                        : [],
                      "data-cy": "signup-input-externalAccountId",
                      "validate-on-blur": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _vm.cardTooltip.mainDesc
                                ? _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "click-outside",
                                          rawName: "v-click-outside",
                                          value: (_vm.showCardTooltip = false),
                                          expression:
                                            "(showCardTooltip = false)"
                                        }
                                      ],
                                      attrs: { color: "homeglobal_picto" },
                                      on: {
                                        click: function($event) {
                                          _vm.showCardTooltip = !_vm.showCardTooltip
                                        }
                                      }
                                    },
                                    [_vm._v(" fa-info-circle ")]
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1181757251
                    ),
                    model: {
                      value: _vm.externalAccountValue,
                      callback: function($$v) {
                        _vm.externalAccountValue = $$v
                      },
                      expression: "externalAccountValue"
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCardTooltip,
                          expression: "showCardTooltip"
                        }
                      ],
                      staticClass: "tooltip"
                    },
                    [
                      _c("div", { staticClass: "tooltip-arrow-up" }),
                      _c("v-card", { staticClass: "tooltip-card" }, [
                        _vm._v(" " + _vm._s(_vm.cardTooltip.mainDesc) + " "),
                        _vm.cardTooltipImageSrc
                          ? _c("img", {
                              attrs: { src: _vm.cardTooltipImageSrc }
                            })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.cardTooltip.optionalDesc) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "with-tooltip" },
            [
              _c("v-text-field", {
                ref: "email",
                staticClass: "signup-form-field-label",
                staticStyle: { "margin-right": "0 !important" },
                attrs: {
                  name: "email",
                  rules: _vm.formRules.email,
                  "data-cy": "signup-input-email",
                  label: "Email *",
                  required: "",
                  "validate-on-blur": ""
                },
                on: {
                  input: function($event) {
                    return _vm.maybeUpdateExternal(_vm.formValues.email)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _vm.mailTooltip.mainDesc
                          ? _c(
                              "v-icon",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: (_vm.showMailTooltip = false),
                                    expression: "(showMailTooltip = false)"
                                  }
                                ],
                                attrs: { color: "homeglobal_picto" },
                                on: {
                                  click: function($event) {
                                    _vm.showMailTooltip = !_vm.showMailTooltip
                                  }
                                }
                              },
                              [_vm._v(" fa-info-circle ")]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.formValues.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "email", $$v)
                  },
                  expression: "formValues.email"
                }
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMailTooltip,
                      expression: "showMailTooltip"
                    }
                  ],
                  staticClass: "tooltip"
                },
                [
                  _c("div", { staticClass: "tooltip-arrow-up" }),
                  _c("v-card", { staticClass: "tooltip-card" }, [
                    _vm._v(" " + _vm._s(_vm.mailTooltip.mainDesc) + " "),
                    _vm.getEmailTooltipImage
                      ? _c("img", { attrs: { src: _vm.getEmailTooltipImage } })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.mailTooltip.optionalDesc) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-text-field", {
            ref: "password",
            staticClass: "signup-form-field-label",
            attrs: {
              rules: _vm.formRules.password,
              type: _vm.passwordShow ? "text" : "password",
              "data-cy": "signup-input-password",
              hint:
                "Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.",
              label: "Mot de passe *",
              required: "",
              "validate-on-change": ""
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            _vm.passwordShow = !_vm.passwordShow
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.passwordShow
                                ? "fas fa-eye"
                                : "fas fa-eye-slash"
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.formValues.password,
              callback: function($$v) {
                _vm.$set(_vm.formValues, "password", $$v)
              },
              expression: "formValues.password"
            }
          }),
          _c("v-text-field", {
            ref: "confirmPassword",
            staticClass: "signup-form-field-label",
            attrs: {
              rules: _vm.formRules.confirmPassword.concat([
                _vm.matchingPassword
              ]),
              type: _vm.confirmPasswordShow ? "text" : "password",
              "data-cy": "signup-input-confirmPassword",
              label: "Confirmer mot de passe *",
              required: "",
              "validate-on-change": ""
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            _vm.confirmPasswordShow = !_vm.confirmPasswordShow
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.confirmPasswordShow
                                ? "fas fa-eye"
                                : "fas fa-eye-slash"
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.confirmPassword,
              callback: function($$v) {
                _vm.confirmPassword = $$v
              },
              expression: "confirmPassword"
            }
          }),
          _c("p", { staticClass: "signup-form-required-text" }, [
            _vm._v("* champs obligatoires")
          ]),
          _c(
            "div",
            { staticClass: "signup-form-switch-wrapper" },
            [
              _c("v-checkbox", {
                attrs: {
                  rules: _vm.formRules.gcuAndConfidentiality,
                  "hide-details": "",
                  dense: "",
                  color: "actif",
                  "data-cy": "signup-checkbox-gcu"
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("span", [
                          _vm._v(" Veuillez accepter les "),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.GCURouter, target: "_blank" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(" CGU ")]
                          ),
                          _vm._v(" , la gestion des "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.privacyPolicyRoute,
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(" Données personnelles - RGPD ")]
                          ),
                          _vm._v(" et les "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.gamesRegulationRoute,
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(" Parcours utilisateurs ")]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.formValues.gcuAndConfidentiality,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "gcuAndConfidentiality", $$v)
                  },
                  expression: "formValues.gcuAndConfidentiality"
                }
              }),
              _c("v-checkbox", {
                attrs: {
                  rules: _vm.formRules.newsletter,
                  "hide-details": "",
                  dense: "",
                  color: "actif",
                  "data-cy": "signup-checkbox-newsletter"
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _vm._v(
                          " J’accepte de recevoir les newsletters pour être informé(e) des nouvelles offres, événements et jeux concours sur le programme "
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.formValues.newsletter,
                  callback: function($$v) {
                    _vm.$set(_vm.formValues, "newsletter", $$v)
                  },
                  expression: "formValues.newsletter"
                }
              })
            ],
            1
          ),
          _vm.error !== ""
            ? _c(
                "div",
                {
                  staticClass:
                    "signup-form-custom-error v-messages theme--light error--text",
                  attrs: { "data-cy": "signup-form-globalerror", role: "alert" }
                },
                [
                  _c("div", { staticClass: "v-messages__wrapper" }, [
                    _c("div", { staticClass: "v-messages__message" }, [
                      _vm._v(_vm._s(_vm.error))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm.signupActivated
            ? _c(
                "v-btn",
                {
                  staticClass: "signup-form-button",
                  attrs: {
                    disabled: !_vm.formValid,
                    loading: _vm.innerLoading,
                    color: "homeglobal_fond_bouton",
                    "data-cy": "signup-button-validate",
                    large: "",
                    rounded: "",
                    type: "submit"
                  }
                },
                [_vm._v(" Valider ")]
              )
            : _c(
                "v-btn",
                {
                  staticClass: "signup-form-button",
                  attrs: {
                    color: "homeglobal_fond_bouton",
                    "data-cy": "signup-button-validate",
                    disabled: "",
                    large: "",
                    rounded: ""
                  }
                },
                [_vm._v(" Inscription temporairement indisponible ")]
              ),
          _c(
            "a",
            { staticClass: "signup-form-link", on: { click: _vm.showSignin } },
            [_vm._v(" Déjà inscrit ? ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "700px",
            "content-class": "consent-dialog"
          },
          model: {
            value: _vm.showParentalAndMinorConsent,
            callback: function($$v) {
              _vm.showParentalAndMinorConsent = $$v
            },
            expression: "showParentalAndMinorConsent"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "consent-dialog-card",
              attrs: { "data-cy": "consent-dialog" }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "consent-dialog-card-picto",
                  on: {
                    click: function($event) {
                      return _vm.closeDialog("consent")
                    }
                  }
                },
                [_vm._v(" fas fa-times ")]
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "consent-dialog-card-title",
                  attrs: { "primary-title": "" }
                },
                [_vm._v(" Consentements pour les mineurs ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { staticClass: "consent-dialog-card-text" },
                    [
                      _c("v-checkbox", {
                        staticClass: "consent-dialog-card-checkbox",
                        attrs: {
                          rules: _vm.formRules.parentalAgreement,
                          "hide-details": "",
                          dense: "",
                          color: "actif",
                          "data-cy": "consent-checkbox-parental"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " En tant parent ou tuteur du mineur de moins de 15 ans, je donne mon consentement pour que celui-ci s’inscrive au service "
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.consents.parental,
                          callback: function($$v) {
                            _vm.$set(_vm.consents, "parental", $$v)
                          },
                          expression: "consents.parental"
                        }
                      }),
                      _c("v-checkbox", {
                        staticClass: "consent-dialog-card-checkbox",
                        attrs: {
                          rules: _vm.formRules.minorConsent,
                          "hide-details": "",
                          dense: "",
                          color: "actif",
                          "data-cy": "consent-checkbox-minor"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " En tant que mineur de moins de 15 ans, je donne mon consentement pour m’inscrire au service "
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.consents.minor,
                          callback: function($$v) {
                            _vm.$set(_vm.consents, "minor", $$v)
                          },
                          expression: "consents.minor"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "consent-dialog-card-button",
                          attrs: {
                            color: "popup_fond_bouton",
                            "data-cy": "consent-dialog-button-validate",
                            disabled: !_vm.consentValidated,
                            large: "",
                            rounded: ""
                          },
                          on: { click: _vm.validateWithConsents }
                        },
                        [_vm._v(" Valider les consentements ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": _vm.$vuetify.breakpoint.xsOnly ? "350px" : "700px",
            "content-class": "consent-dialog"
          },
          model: {
            value: _vm.showRefusedMinor,
            callback: function($$v) {
              _vm.showRefusedMinor = $$v
            },
            expression: "showRefusedMinor"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "consent-dialog-card",
              attrs: { "data-cy": "refused-dialog" }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "consent-dialog-card-picto",
                  on: {
                    click: function($event) {
                      return _vm.closeDialog("refused")
                    }
                  }
                },
                [_vm._v(" fas fa-times ")]
              ),
              _c("v-card-text", { staticClass: "consent-dialog-card-text" }, [
                _c("span", [_vm._v("Inscription impossible")]),
                _c("span", [
                  _vm._v(
                    "Les mineurs de moins de 15 ans ne peuvent pas souscrire au programme de fidélité"
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }