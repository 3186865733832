export default {
  save(key, value: object) {
    return localStorage.setItem(key, JSON.stringify(value));
  },

  get(key) {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  },
};
