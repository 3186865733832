var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "cashback",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 82.1 82.3"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M20.9,35.3v1.3a4.3,4.3,0,0,1-3.3-1.3,4.2,4.2,0,0,1,.1-6.1,4.2,4.2,0,0,1,6.1-.1,4.4,4.4,0,0,1,1.3,3.1H23.7a3,3,0,0,0-.9-2.2,3,3,0,0,0-4.2,4.2A2.6,2.6,0,0,0,20.9,35.3Z",
            transform: "translate(0 0.1)"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M27,39.4,24.4,42l-1-1,2.3-2.3a1,1,0,0,0,0-1.6,1.5,1.5,0,0,0-1.3-.3l-2.6,2.6-1-1L27.2,32l1,1-2.9,2.9a1.9,1.9,0,0,1,1.7.6A1.9,1.9,0,0,1,27,39.4Z",
            transform: "translate(0 0.1)"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M31.6,43.9,29,46.6l-1-1,.5-.5a2.2,2.2,0,0,1-1.9-.7,1.9,1.9,0,0,1-.1-2.8,2.1,2.1,0,0,1,2.9.2,2.7,2.7,0,0,1,.8,1.4l.2-.2a1.1,1.1,0,0,0-.2-1.6,4.4,4.4,0,0,0-1.5-.9l.5-1.2a7,7,0,0,1,2,1.2C32.4,41.8,32.5,43,31.6,43.9Zm-2.4.4.4-.4a3.2,3.2,0,0,0-.7-1.1c-.3-.3-.9-.6-1.3-.2s-.1.9.2,1.2A1.5,1.5,0,0,0,29.2,44.3Z",
            transform: "translate(0 0.1)"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M36.2,48.6l-2.6,2.6-1-1,2.3-2.3a1,1,0,0,0,0-1.6,1.5,1.5,0,0,0-1.3-.3L31,48.6l-1-1,4-4,.9.9-.6.6a2.1,2.1,0,0,1,1.9.5A2,2,0,0,1,36.2,48.6Z",
            transform: "translate(0 0.1)"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M38.9,53.8v1.3a3,3,0,0,1-2.4-.9,3.1,3.1,0,0,1-.1-4.3,2.9,2.9,0,0,1,4.3.1,3,3,0,0,1,.9,2.4H40.3a1.8,1.8,0,0,0-.5-1.4,1.6,1.6,0,1,0-2.3,2.3A1.4,1.4,0,0,0,38.9,53.8Z",
            transform: "translate(0 0.1)"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M44.7,58.2l-.4.4-3-3a1.6,1.6,0,0,0,.3,1.9,2.3,2.3,0,0,0,1.5.7l-.3,1.3a4.1,4.1,0,0,1-2.2-1,3.1,3.1,0,0,1-.1-4.3,2.9,2.9,0,0,1,4.2,4ZM44,56.8a1.4,1.4,0,0,0-2-2Z",
            transform: "translate(0 0.1)"
          }
        })
      ]),
      _c(
        "g",
        { attrs: { id: "Composant_102_1", "data-name": "Composant 102 1" } },
        [
          _c(
            "g",
            {
              attrs: { id: "Composant_101_3", "data-name": "Composant 101 3" }
            },
            [
              _c(
                "g",
                {
                  attrs: { id: "Composant_49_1", "data-name": "Composant 49 1" }
                },
                [
                  _c("path", {
                    staticClass: "st1",
                    attrs: {
                      id: "accent",
                      d: "M0-.1,82.1,82.2H51.4L0,30.6Z",
                      transform: "translate(0 0.1)"
                    }
                  }),
                  _c("g", { staticStyle: { isolation: "isolate" } }, [
                    _c("g", [
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M12.5,30.6v1.8A5.9,5.9,0,0,1,8,30.7a5.8,5.8,0,0,1,.1-8.4,5.7,5.7,0,0,1,8.4-.1,5.9,5.9,0,0,1,1.7,4.3H16.3a3.9,3.9,0,0,0-1.1-3,4,4,0,0,0-5.7.2,3.9,3.9,0,0,0-.2,5.6A4.2,4.2,0,0,0,12.5,30.6Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M20.7,35.8l-3.6,3.7-1.3-1.3.7-.8a3.2,3.2,0,0,1-2.7-.9,2.7,2.7,0,0,1-.1-3.9c1-1.1,2.8-.9,4,.3a4,4,0,0,1,1.1,2l.4-.3a1.6,1.6,0,0,0-.3-2.2,5.9,5.9,0,0,0-2.1-1.2l.7-1.7a8.7,8.7,0,0,1,2.7,1.7C21.8,32.8,22,34.6,20.7,35.8Zm-3.4.6.6-.6a3.3,3.3,0,0,0-1-1.5,1.2,1.2,0,0,0-1.8-.3c-.6.6-.1,1.3.3,1.7A2.2,2.2,0,0,0,17.3,36.4Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M20.8,39.4a3.5,3.5,0,0,0,.9,2.5c.6.5,1.2.9,1.5.5s.2-.7-.5-1.7-1.3-2.7-.3-3.8,2.6-.9,4.2.7A5.2,5.2,0,0,1,28,40.3l-1.8.3a3,3,0,0,0-.9-1.8c-.5-.5-1.2-.8-1.5-.5s0,.8.7,1.9,1.3,2.5.1,3.7-2.7.7-4.1-.7A5.3,5.3,0,0,1,19,39.6Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M32.9,48.1l-3.6,3.6-1.4-1.4L31.2,47a1.4,1.4,0,0,0,0-2.2,2,2,0,0,0-1.8-.4l-3.7,3.7-1.4-1.4,8.9-8.9,1.4,1.4-4,4A2.7,2.7,0,0,1,33,44,2.9,2.9,0,0,1,32.9,48.1Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M39.7,44.4l1.4,1.4-3.8,3.9a2.9,2.9,0,0,1,2.3.8,4,4,0,0,1-.3,5.7,4,4,0,0,1-5.6.2,2.8,2.8,0,0,1-.8-2.6l-.7.8-1.3-1.3Zm-5.5,8.3a2.4,2.4,0,0,0,.6,2.1,2,2,0,0,0,3.1-.1,2.1,2.1,0,0,0,.1-3.1,2.2,2.2,0,0,0-2.1-.5Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M46.1,61.2l-3.6,3.6-1.3-1.3.7-.7a3.2,3.2,0,0,1-2.7-.9,2.7,2.7,0,0,1-.1-3.9c1-1.1,2.8-.9,4,.3a3.6,3.6,0,0,1,1.1,2l.4-.3a1.6,1.6,0,0,0-.3-2.2,7,7,0,0,0-2.1-1.3l.7-1.6a8.7,8.7,0,0,1,2.7,1.7C47.2,58.2,47.4,60,46.1,61.2Zm-3.4.6.6-.6a3.8,3.8,0,0,0-1-1.6,1.3,1.3,0,0,0-1.8-.2c-.6.6-.1,1.3.3,1.7A2.2,2.2,0,0,0,42.7,61.8Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M49.7,68.4v1.8A4,4,0,0,1,46.4,69a4.1,4.1,0,1,1,5.8-5.8,4,4,0,0,1,1.2,3.3H51.6a2.6,2.6,0,0,0-.7-1.9,2.2,2.2,0,0,0-3.2,0,2.2,2.2,0,0,0,0,3.2A2.4,2.4,0,0,0,49.7,68.4Z",
                          transform: "translate(0 0.1)"
                        }
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          d:
                            "M54.6,70.3l4.2-.2,1.8,1.8-5,.2-.4,5.5-1.8-1.9.3-4.5-2.4,2.4-1.4-1.4,8.8-8.8,1.4,1.4Z",
                          transform: "translate(0 0.1)"
                        }
                      })
                    ])
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }