var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c("Datatable", {
        staticClass: "orders-tab",
        attrs: { headers: _vm.headers },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return _vm._l(_vm.criterias, function(item, $index) {
                return _c(
                  "div",
                  {
                    key: $index,
                    staticClass: "orders-tab-list",
                    attrs: { "data-num": $index + 1 }
                  },
                  [
                    _c("div", { staticClass: "orders-tab-list-label" }, [
                      _vm._v(_vm._s(item.label))
                    ]),
                    _c("div", { staticClass: "orders-tab-list-points" }, [
                      _vm._v(" " + _vm._s(item.points) + " Points ")
                    ]),
                    _c("div", { staticClass: "orders-tab-list-days" }, [
                      _vm._v(" " + _vm._s(_vm.formatedDays(item.days)) + " ")
                    ]),
                    _c("div", { staticClass: "orders-tab-list-time" }, [
                      _vm._v(
                        " De " +
                          _vm._s(_vm.formatedHours(item.startTime)) +
                          " à " +
                          _vm._s(_vm.formatedHours(item.endTime)) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "orders-tab-list-date" }, [
                      _vm._v(
                        " Du " +
                          _vm._s(_vm.formatedDate(item.startDate)) +
                          " au " +
                          _vm._s(_vm.formatedDate(item.endDate)) +
                          " "
                      )
                    ])
                  ]
                )
              })
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }