

























import { Vue, Component, Prop } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import MiniProfileIcon from '@/components/Svg/MiniProfileIcon.vue';

@Component({ components: { Avatar, MiniProfileIcon } })
export default class MiniProfile extends Vue {
  @Prop({ type: String, default: 'John Doe' })
  readonly fullName!: string;

  @Prop({ type: String, default: 'John Doe' })
  readonly initials!: string;

  @Prop({ type: String, default: 'Mes informations personnelles' })
  readonly buttonText!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasIllustration!: boolean;

  @Prop({ type: String, default: 'update-profile' })
  readonly routeName!: string;

  navigateToUpdateProfile() {
    this.$router.push({ name: this.routeName });
  }
}
