// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n#tarteaucitron-root {\n  z-index: 202 !important;\n}\n.breadcrumb {\n  padding-left: 40px;\n}\n.container {\n  min-height: 100%;\n}\n.app-header {\n  height: 3rem;\n}\n.app.error-page {\n  overflow: hidden;\n}\n.app-content {\n  overflow-x: hidden;\n  -webkit-overflow-scrolling: touch;\n}\n.app-content.nopadding {\n  padding-top: 0 !important;\n}\n.app-content-newcgu_btn {\n  margin: 2.5rem auto;\n  width: 15%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  text-transform: uppercase;\n}\n.app-content-view {\n  overflow: hidden;\n}\n.app.theme--light.v-application {\n  background: var(--v-homeglobal_fond_page-base);\n}\n.app .v-breadcrumbs .mdi-chevron-right {\n  font-size: 1rem;\n  color: var(--v-homeglobal_texte_fond-base);\n}\n.app .v-breadcrumbs__divider {\n  padding: 0 !important;\n}\n.app .v-breadcrumbs__item {\n  color: var(--v-homeglobal_texte_fond-base);\n  font-size: 1rem;\n  font-family: Secondary, SecondaryFallback, sans-serif;\n  font-weight: bold;\n}\n.app .v-breadcrumbs__item--disabled {\n  color: var(--v-homeglobal_texte_fond-base) !important;\n  font-weight: normal;\n}\n.app .banner {\n  margin-top: 3rem;\n}", ""]);
// Exports
module.exports = exports;
