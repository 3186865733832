var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 401.337 585.609",
        preserveAspectRatio: "xMinYMin slice"
      }
    },
    [
      _c("defs"),
      _c(
        "g",
        { attrs: { id: "Composant_106_1", "data-name": "Composant 106 – 1" } },
        [
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Accent",
              d:
                "M258.52,0c20.61,20.61,29.92,45.48,29.92,76.93A113.87,113.87,0,1,1,90.62,0Z",
              transform: "translate(0.18 0.3)"
            }
          }),
          _c("circle", {
            staticClass: "cls-1",
            attrs: {
              id: "Accent-2",
              "data-name": "Accent",
              cx: "78.67",
              cy: "78.67",
              r: "78.67",
              transform: "translate(141.861 355.29)"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "accent-3",
              "data-name": "accent",
              d:
                "M87.35,578c20.1-23.57,50.55-38.38,84.9-38.38A111.84,111.84,0,0,1,256.86,578",
              transform: "translate(5.236 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "primary",
              d: "M253.22,578",
              transform: "translate(9.004 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "primary-2",
              "data-name": "primary",
              d: "M153.19,582.46H256.91",
              transform: "translate(1.567 -4.16)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_478",
              "data-name": "Ligne 478",
              x2: "0.28",
              y2: "11.49",
              transform: "translate(297.936 553.64)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_479",
              "data-name": "Ligne 479",
              x2: "0.38",
              y2: "7.43",
              transform: "translate(263.824 557.7)"
            }
          }),
          _c("line", {
            staticClass: "cls-3",
            attrs: {
              id: "primary-3",
              "data-name": "primary",
              x1: "5.95",
              y2: "4.46",
              transform: "translate(222.721 551.21)"
            }
          }),
          _c("path", {
            staticClass: "cls-4",
            attrs: {
              id: "secondary",
              d:
                "M384.76,413.72A112.78,112.78,0,1,1,332.54,201c20,0,35.93,3.66,52.21,12.8",
              transform: "translate(15.437 0.3)"
            }
          }),
          _c("circle", {
            staticClass: "cls-5",
            attrs: {
              id: "Ellipse_176",
              "data-name": "Ellipse 176",
              cx: "79.82",
              cy: "79.82",
              r: "79.82",
              transform: "translate(3.13 381.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "primary-4",
              "data-name": "primary",
              d:
                "M384.82,241.9c-33.52,0-58-27.17-58-60.69s24.52-60.68,58-60.68",
              transform: "translate(15.437 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-4",
            attrs: {
              id: "secondary-2",
              "data-name": "secondary",
              d:
                "M-.18,0V95.4s21.84,20.6,64.05,8.27c0,0-13.3-60.62,26.75-103.67Z",
              transform: "translate(0.18 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-4",
            attrs: {
              id: "secondary-3",
              "data-name": "secondary",
              d:
                "M384.82,578l-63.46.25S263,524.1,317.5,458c25.88-28.63,67.32-24.37,67.32-24.37Z",
              transform: "translate(15.437 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-4",
            attrs: {
              id: "secondary-4",
              "data-name": "secondary",
              d:
                "M-.18,521.35,0,578H87.35s17.67-17.34,24.38-21C111.73,557,75.87,488.89-.18,521.35Z",
              transform: "translate(0.18 0.3)"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Accent-4",
              "data-name": "Accent",
              d:
                "M-.16,254.22A113.87,113.87,0,0,1,113.71,368.09,113.87,113.87,0,0,1-.16,482",
              transform: "translate(0.18 0.3)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_480",
              "data-name": "Ligne 480",
              y1: "83.33",
              x2: "65.17",
              transform: "translate(179.366 222.57)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_481",
              "data-name": "Ligne 481",
              y1: "126.54",
              x2: "95.12",
              transform: "translate(182.541 215.88)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_482",
              "data-name": "Ligne 482",
              y1: "145.57",
              x2: "114.2",
              transform: "translate(191.016 219.48)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_483",
              "data-name": "Ligne 483",
              y1: "162.03",
              x2: "124.48",
              transform: "translate(204.231 227.71)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_484",
              "data-name": "Ligne 484",
              y1: "167.64",
              x2: "124.48",
              transform: "translate(221.372 234.96)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_485",
              "data-name": "Ligne 485",
              y1: "167.65",
              x2: "124.48",
              transform: "translate(241.802 245.87)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_486",
              "data-name": "Ligne 486",
              y1: "153.56",
              x2: "114.01",
              transform: "translate(265.145 269.13)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_487",
              "data-name": "Ligne 487",
              y1: "121.53",
              x2: "96.72",
              transform: "translate(293.857 297.67)"
            }
          }),
          _c("line", {
            staticClass: "cls-7",
            attrs: {
              id: "Ligne_488",
              "data-name": "Ligne 488",
              y1: "91.08",
              x2: "72.48",
              transform: "translate(320.097 326.88)"
            }
          }),
          _c("path", {
            staticClass: "cls-4",
            attrs: {
              id: "secondary-5",
              "data-name": "secondary",
              d: "M318.84-.3h66V25.8S343.66,27.8,318.84-.3Z",
              transform: "translate(15.437 0.3)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_489",
              "data-name": "Ligne 489",
              x1: "3.55",
              y2: "10.93",
              transform: "translate(309.349 142.64)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_490",
              "data-name": "Ligne 490",
              x2: "4.78",
              y2: "10.45",
              transform: "translate(313.166 103.19)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_491",
              "data-name": "Ligne 491",
              x2: "10.9",
              y2: "3.6",
              transform: "translate(342.944 103.22)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_492",
              "data-name": "Ligne 492",
              x1: "1.89",
              y2: "11.32",
              transform: "translate(355.407 67.26)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_493",
              "data-name": "Ligne 493",
              x1: "8.39",
              y2: "7.85",
              transform: "translate(320.126 53.3)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_494",
              "data-name": "Ligne 494",
              x1: "7.68",
              y1: "8.54",
              transform: "translate(308.32 19.52)"
            }
          }),
          _c("line", {
            staticClass: "cls-8",
            attrs: {
              id: "Ligne_495",
              "data-name": "Ligne 495",
              y1: "8.4",
              x2: "0.56",
              transform: "translate(341.593 13.06)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-5",
              "data-name": "primary",
              y1: "8.04",
              x2: "0.97",
              transform: "translate(244.813 64.97)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-6",
              "data-name": "primary",
              y1: "6.28",
              x2: "5.13",
              transform: "translate(277.372 29.52)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-7",
              "data-name": "primary",
              y1: "8.1",
              x2: "0.21",
              transform: "translate(293.838 73.61)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-8",
              "data-name": "primary",
              y1: "12.19",
              x2: "5.75",
              transform: "translate(247.604 99.32)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-9",
              "data-name": "primary",
              x2: "13.47",
              y2: "0.63",
              transform: "translate(266.082 128.8)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-10",
              "data-name": "primary",
              x2: "1.8",
              y2: "10.05",
              transform: "translate(238.704 9.21)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-11",
              "data-name": "primary",
              x2: "7.27",
              y2: "7.15",
              transform: "translate(200.255 2.84)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-12",
              "data-name": "primary",
              x2: "0.3",
              y2: "10.2",
              transform: "translate(173.864 17.38)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-13",
              "data-name": "primary",
              x1: "2.27",
              y2: "7.13",
              transform: "translate(211.671 45.08)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-14",
              "data-name": "primary",
              x1: "7.42",
              y1: "0.97",
              transform: "translate(169.706 60.72)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-15",
              "data-name": "primary",
              x1: "0.09",
              y1: "7.48",
              transform: "translate(155.687 84.38)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-16",
              "data-name": "primary",
              x1: "4.32",
              y1: "6.11",
              transform: "translate(199.784 90.64)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-17",
              "data-name": "primary",
              x1: "2.38",
              y1: "7.09",
              transform: "translate(177.274 115.28)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-18",
              "data-name": "primary",
              x1: "0.4",
              y1: "7.48",
              transform: "translate(217.154 125.18)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-19",
              "data-name": "primary",
              y1: "5.08",
              x2: "5.5",
              transform: "translate(215.842 160.48)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "primary-20",
              "data-name": "primary",
              x1: "1.87",
              y1: "7.25",
              transform: "translate(258.061 162.93)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_496",
              "data-name": "Ligne 496",
              x1: "11.49",
              y1: "0.29",
              transform: "translate(37.87 155.52)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_497",
              "data-name": "Ligne 497",
              x1: "9.2",
              y2: "6.89",
              transform: "translate(50.43 180.54)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_498",
              "data-name": "Ligne 498",
              x2: "1.7",
              y2: "11.37",
              transform: "translate(75.79 199.6)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_499",
              "data-name": "Ligne 499",
              y1: "1.79",
              x2: "11.35",
              transform: "translate(35.15 213.9)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_500",
              "data-name": "Ligne 500",
              x2: "9.41",
              y2: "6.6",
              transform: "translate(9.74 183.24)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_501",
              "data-name": "Ligne 501",
              y1: "10.69",
              x2: "4.24",
              transform: "translate(6.08 142.76)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_502",
              "data-name": "Ligne 502",
              x2: "11.47",
              y2: "0.65",
              transform: "translate(59.57 238.32)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_503",
              "data-name": "Ligne 503",
              x2: "6.72",
              y2: "9.32",
              transform: "translate(16.82 241.21)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_504",
              "data-name": "Ligne 504",
              x2: "11.47",
              y2: "0.6",
              transform: "translate(55.15 269.76)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_505",
              "data-name": "Ligne 505",
              y1: "8.4",
              x2: "7.84",
              transform: "translate(22 272.41)"
            }
          }),
          _c("line", {
            staticClass: "cls-2",
            attrs: {
              id: "Ligne_506",
              "data-name": "Ligne 506",
              x2: "11.12",
              y2: "2.92",
              transform: "translate(20.36 300.91)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "primary-21",
              "data-name": "primary",
              y2: "199.94",
              transform: "translate(400.257 213.38)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }