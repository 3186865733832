var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Draw_3",
        xmlns: "http://www.w3.org/2000/svg",
        width: "555.911",
        height: "341.918",
        viewBox: "0 0 126 76.317"
      }
    },
    [
      _c("title"),
      _c("ellipse", {
        staticClass: "cls-1",
        attrs: {
          id: "Ellipse_27",
          "data-name": "Ellipse 27",
          cx: "63",
          cy: "5",
          rx: "63",
          ry: "5",
          transform: "translate(0 66.317)"
        }
      }),
      _c("path", {
        staticClass: "cls-2",
        attrs: {
          id: "Tracé_1663",
          "data-name": "Tracé 1663",
          d:
            "M806.68,303.812s.4,5.334.4,5.532-2.766,13.83-2.766,13.83-3.951,7.508,0,7.31,2.766-7.113,2.766-7.113l4.149-11.459-1.778-8.1Z",
          transform: "translate(-709.761 -301.388)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_762",
          "data-name": "Rectangle 762",
          width: "78.512",
          height: "55.152",
          transform: "translate(20.22 16.081)"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: {
          id: "Rectangle_763",
          "data-name": "Rectangle 763",
          width: "19.714",
          height: "34.413",
          transform: "translate(26.83 37.124)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_764",
          "data-name": "Rectangle 764",
          width: "5.31",
          height: "4.95",
          transform: "translate(30.476 39.188)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_765",
          "data-name": "Rectangle 765",
          width: "5.31",
          height: "4.95",
          transform: "translate(37.586 39.188)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_766",
          "data-name": "Rectangle 766",
          width: "5.31",
          height: "5.13",
          transform: "translate(30.476 45.939)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_767",
          "data-name": "Rectangle 767",
          width: "5.31",
          height: "5.13",
          transform: "translate(37.586 45.939)"
        }
      }),
      _c("circle", {
        staticClass: "cls-4",
        attrs: {
          id: "Ellipse_29",
          "data-name": "Ellipse 29",
          cx: "1.253",
          cy: "1.253",
          r: "1.253",
          transform: "translate(42.783 55.394)"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: {
          id: "Rectangle_768",
          "data-name": "Rectangle 768",
          width: "38.971",
          height: "32.704",
          transform: "translate(53.152 33.857)"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          id: "Tracé_1672",
          "data-name": "Tracé 1672",
          d:
            "M187.12,203.515H92.2l6.329-13.232,1.416-2.963,1.853-3.874,1.416-2.963,1.642-3.432h74.786l.97,3.432.837,2.963,1.1,3.874.837,2.963Z",
          transform: "translate(-80.183 -177.051)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_769",
          "data-name": "Rectangle 769",
          width: "1.367",
          height: "8.432",
          transform: "translate(54.52 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_770",
          "data-name": "Rectangle 770",
          width: "1.367",
          height: "1.026",
          transform: "translate(54.52 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_771",
          "data-name": "Rectangle 771",
          width: "1.367",
          height: "1.026",
          transform: "translate(54.52 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_772",
          "data-name": "Rectangle 772",
          width: "3.305",
          height: "8.432",
          transform: "translate(56.571 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_773",
          "data-name": "Rectangle 773",
          width: "3.305",
          height: "1.026",
          transform: "translate(56.571 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_774",
          "data-name": "Rectangle 774",
          width: "3.305",
          height: "1.026",
          transform: "translate(56.571 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_775",
          "data-name": "Rectangle 775",
          width: "1.367",
          height: "8.432",
          transform: "translate(60.673 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_776",
          "data-name": "Rectangle 776",
          width: "1.367",
          height: "1.026",
          transform: "translate(60.673 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_777",
          "data-name": "Rectangle 777",
          width: "1.367",
          height: "1.026",
          transform: "translate(60.673 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_778",
          "data-name": "Rectangle 778",
          width: "1.367",
          height: "8.432",
          transform: "translate(62.724 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_779",
          "data-name": "Rectangle 779",
          width: "1.367",
          height: "1.026",
          transform: "translate(62.724 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_780",
          "data-name": "Rectangle 780",
          width: "1.367",
          height: "1.026",
          transform: "translate(62.724 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_781",
          "data-name": "Rectangle 781",
          width: "1.367",
          height: "8.432",
          transform: "translate(64.13 47.924) rotate(-20.654)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_782",
          "data-name": "Rectangle 782",
          width: "1.367",
          height: "1.026",
          transform: "translate(64.572 49.097) rotate(-20.654)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_783",
          "data-name": "Rectangle 783",
          width: "1.367",
          height: "1.026",
          transform: "translate(66.301 53.682) rotate(-20.654)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_784",
          "data-name": "Rectangle 784",
          width: "3.305",
          height: "8.432",
          transform: "translate(87.565 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_785",
          "data-name": "Rectangle 785",
          width: "3.305",
          height: "1.026",
          transform: "translate(87.565 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_786",
          "data-name": "Rectangle 786",
          width: "3.305",
          height: "1.026",
          transform: "translate(87.565 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_787",
          "data-name": "Rectangle 787",
          width: "1.367",
          height: "8.432",
          transform: "translate(85.4 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_788",
          "data-name": "Rectangle 788",
          width: "1.367",
          height: "1.026",
          transform: "translate(85.4 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_789",
          "data-name": "Rectangle 789",
          width: "1.367",
          height: "1.026",
          transform: "translate(85.4 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_790",
          "data-name": "Rectangle 790",
          width: "1.367",
          height: "8.432",
          transform: "translate(83.349 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_791",
          "data-name": "Rectangle 791",
          width: "1.367",
          height: "1.026",
          transform: "translate(83.349 48.671)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_792",
          "data-name": "Rectangle 792",
          width: "1.367",
          height: "1.026",
          transform: "translate(83.349 53.571)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_793",
          "data-name": "Rectangle 793",
          width: "8.432",
          height: "1.367",
          transform: "translate(79.058 55.333) rotate(-69.346)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_794",
          "data-name": "Rectangle 794",
          width: "1.026",
          height: "1.367",
          transform: "translate(81.228 49.575) rotate(-69.346)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_795",
          "data-name": "Rectangle 795",
          width: "1.026",
          height: "1.367",
          transform: "translate(79.5 54.16) rotate(-69.346)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_796",
          "data-name": "Rectangle 796",
          width: "38.971",
          height: "0.798",
          transform: "translate(53.152 55.85)"
        }
      }),
      _c("rect", {
        staticClass: "cls-4",
        attrs: {
          id: "Rectangle_797",
          "data-name": "Rectangle 797",
          width: "7.179",
          height: "8.432",
          transform: "translate(70.131 47.417)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_798",
          "data-name": "Rectangle 798",
          width: "3.646",
          height: "1.595",
          transform: "translate(71.897 49.24)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_799",
          "data-name": "Rectangle 799",
          width: "0.684",
          height: "7.293",
          transform: "translate(72.296 33.857)"
        }
      }),
      _c("circle", {
        staticClass: "cls-3",
        attrs: {
          id: "Ellipse_30",
          "data-name": "Ellipse 30",
          cx: "1.14",
          cy: "1.14",
          r: "1.14",
          transform: "translate(71.498 40.01)"
        }
      }),
      _c("path", {
        staticClass: "cls-4",
        attrs: {
          id: "Tracé_1673",
          "data-name": "Tracé 1673",
          d: "M234.989,202.591H155.337l1.416-2.963h77.4Z",
          transform: "translate(-133.723 -196.196)"
        }
      }),
      _c("path", {
        staticClass: "cls-4",
        attrs: {
          id: "Tracé_1674",
          "data-name": "Tracé 1674",
          d: "M218.685,247.566H133.831l1.416-2.963h82.6Z",
          transform: "translate(-115.486 -234.334)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }