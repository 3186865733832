// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-c15146e4] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-c15146e4] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-c15146e4] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.documentation-wrapper[data-v-c15146e4] {\n  max-width: 960px;\n  margin: 0 auto;\n}\n.linksList[data-v-c15146e4] {\n  padding: 0;\n  background-color: var(--v-homeglobal_fond_page-base);\n}\n@media only screen and (min-width: 960px) {\n.linksList[data-v-c15146e4] {\n    padding: 0.5rem 0.25rem;\n}\n}\n.listItemText[data-v-c15146e4] {\n  font-size: 16px;\n  font-family: Secondary, SecondaryFallback, sans-serif;\n  color: var(--v-homeglobal_sous_titre-base);\n}\n@media only screen and (min-width: 960px) {\n.listItemText[data-v-c15146e4] {\n    font-size: 1.5rem;\n}\n}\n.listItem[data-v-c15146e4] {\n  cursor: pointer;\n  background-color: var(--v-homeglobal_fond_page-base);\n  border-bottom: solid 1px #dedede;\n}\n@media only screen and (min-width: 960px) {\n.listItem[data-v-c15146e4] {\n    margin: 0.5rem 0;\n    border-radius: 2px;\n    height: 80px;\n    padding-top: 0.7rem;\n    padding-bottom: 0.7rem;\n}\n}", ""]);
// Exports
module.exports = exports;
