var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "confirm-address-card",
      attrs: { "data-cy": "confirm-address-card" }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "confirm-address-card__title",
          attrs: { "primary-title": "" }
        },
        [_vm._v(" Adresse de livraison ")]
      ),
      _c("v-card-text", { staticClass: "confirm-address-card__subtitle" }, [
        _vm._v(
          " Veuillez sélectionner l’adresse où votre produit doit être livré "
        )
      ]),
      _vm.isAddressDefined
        ? _c(
            "v-radio-group",
            {
              staticClass: "confirm-address-card__radio",
              on: { change: _vm.handleRadioChange },
              model: {
                value: _vm.selectedRadio,
                callback: function($$v) {
                  _vm.selectedRadio = $$v
                },
                expression: "selectedRadio"
              }
            },
            [
              _c("v-radio", {
                attrs: {
                  value: "0",
                  "data-cy": "confirm-address-radio__first"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _c("div", [
                            _vm._v(" Adresse enregistrée : "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.formattedDefaultAddress))
                            ])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1162002999
                )
              }),
              _c("v-radio", {
                staticClass: "mt-5",
                attrs: { value: "1", label: _vm.customAddressLabel }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.selectedRadio === "1" || !_vm.isAddressDefined
        ? _c(
            "v-form",
            {
              ref: "form",
              staticClass: "confirm-address-card__form",
              attrs: { "data-cy": "confirm-address-card__form" }
            },
            [
              _c("v-text-field", {
                staticClass: "confirm-address-card__form--input mb-3",
                attrs: {
                  "data-cy": "address-form__street",
                  "hide-details": "",
                  label: "Adresse",
                  solo: "",
                  rounded: ""
                },
                on: { input: _vm.getAddressSuggestions },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              }),
              _vm.suggestions.length > 0
                ? _c(
                    "v-list",
                    _vm._l(_vm.suggestions, function(suggestion, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: index,
                          on: {
                            click: function($event) {
                              return _vm.selectSuggestion(suggestion)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(suggestion.full_address))
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c("v-text-field", {
                staticClass: "confirm-address-card__form--input mb-3",
                attrs: {
                  "data-cy": "address-form__zipCode",
                  name: "zipCode",
                  "hide-details": "",
                  label: "Code postal",
                  solo: "",
                  rounded: ""
                },
                model: {
                  value: _vm.form.zipCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "zipCode", $$v)
                  },
                  expression: "form.zipCode"
                }
              }),
              _c("v-text-field", {
                staticClass: "confirm-address-card__form--input",
                attrs: {
                  "data-cy": "address-form__city",
                  "hide-details": "",
                  label: "Ville",
                  solo: "",
                  rounded: ""
                },
                model: {
                  value: _vm.form.city,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "confirm-address-card__button",
          attrs: {
            large: "",
            rounded: "",
            disabled: !_vm.isButtonEnabled,
            color: "popup_fond_bouton",
            "data-cy": "confirm-address-card-btn"
          },
          on: {
            click: function($event) {
              return _vm.$emit("address-confirmed", _vm.form)
            }
          }
        },
        [_vm._v(" Valider l'adresse ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }