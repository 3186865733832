import { apiAuthenticatedInstance, eoiAuthenticatedInstance, getEoiToken } from '@/services/API';
import { Order } from '@/services/merchantSpace/types';

export interface PaymentRequest {
  amount: number;
  iban: string;
  ibic: string;
  firstname: string;
  lastname: string;
}

export interface Transaction {
  idUser: String;
  entExterneId: String;
  transacId: number;
  amount: number;
  percentCashback: Number;
  unitCashback: String;
  shopId: number;
  shopName: string;
  gain: number;
  transacState: {
    code: Number;
    status: String;
    comment: String;
  };
  date: String;
}

export interface Payment {
  idUser: String;
  orderId: number;
  amount: number;
  date: Date;
  detail: String;
  comment: String;
  status: number;
}

export const sendPaymentRequestMail = async (amount: number): Promise<any> => {
  return (await apiAuthenticatedInstance().post(`/api/offers/cashback/payment`, { amount, platformUuid: process.env.VUE_APP_PLATFORM_UUID, locale: 'fr' }))
    .data;
};

export const getOrder = async (offerId: any): Promise<Order[]> => {
  return (await apiAuthenticatedInstance().get(`/api/offers/orders`, offerId)).data;
};

export const executePaymentRequest = async (userIdentifier: string, request: PaymentRequest): Promise<any> => {
  const jwt = await getEoiToken(process.env.VUE_APP_IREBY_EOI_USER, process.env.VUE_APP_IREBY_EOI_PASWORD);
  return (
    await eoiAuthenticatedInstance(jwt).post(`/api/furnishers/executePayment?furnisherName=hakamae&identifier=${userIdentifier}`, {
      Amount: request.amount,
      Iban: request.iban,
      Ibic: request.ibic,
      Firstname: request.firstname,
      Lastname: request.lastname,
    })
  ).data;
};

export const getCashbackTransactions = async (userIdentifier: string): Promise<Transaction[]> => {
  const jwt = await getEoiToken(process.env.VUE_APP_IREBY_EOI_USER, process.env.VUE_APP_IREBY_EOI_PASWORD);
  return (await eoiAuthenticatedInstance(jwt).get(`/api/furnishers/transactions?furnisherName=hakamae&identifier=${userIdentifier}`)).data;
};

export const getCashbackPayments = async (userIdentifier: string): Promise<any> => {
  const jwt = await getEoiToken(process.env.VUE_APP_IREBY_EOI_USER, process.env.VUE_APP_IREBY_EOI_PASWORD);
  return (await eoiAuthenticatedInstance(jwt).get(`/api/furnishers/payOrders?furnisherName=hakamae&identifier=${userIdentifier}`)).data;
};
