



































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as ServiceService from '@/services/merchantSpace/ServiceService';
import SvgPictoCopy from '@/components/Svg/SvgPictoCopy.vue';

@Component({
  components: { SvgPictoCopy },
})
export default class DashboardProfileCard extends Vue {
  @Prop({ type: String, required: true })
  readonly referralCode!: string;

  @Prop({ type: Array, required: true })
  readonly userServices!: [];

  snackbar: boolean = false;

  services: any = {};

  points: number = 0;

  limit: number = 0;

  displayCopy: boolean = false;

  isReferral: boolean = false;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  async mounted() {
    await this.getActionsData();
  }

  async getActionsData() {
    const defaultRankSort = 0;
    const referCategory = 3;

    const serviceIds: number[] = this.userServices.map(({ serviceId }) => serviceId);
    this.services = await ServiceService.getActions();

    let affiliateServices = this.services.filter(({ actionCreditList, serviceId }) => actionCreditList && serviceIds.includes(serviceId));

    if (!affiliateServices.length) return;

    if (affiliateServices.length > 1) {
      affiliateServices = affiliateServices.filter(({ rank }) => rank === defaultRankSort);
    }

    const referAction = affiliateServices[0].actionCreditList.find(({ isRevoked, actionCategoryId }) => !isRevoked && actionCategoryId === referCategory);

    if (referAction) {
      this.points = referAction.value;
      this.limit = referAction.limit;
    }
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.referralCode);
    this.displayCopy = true;
    setTimeout(() => {
      this.displayCopy = false;
    }, 1500);
  }
}
