import { get } from 'lodash-es';
import { ServiceKey } from '@/constants';

const config = {
  [ServiceKey.IRIGO_ATOUT]: {
    synchronizeBy: 'externalAccountId',
  },

  default: {
    synchronizeBy: 'email',
  },
};

export function getServiceConfig(serviceKey: ServiceKey, configKey: string) {
  const result = resolveConfig(serviceKey, configKey);
  return result !== undefined ? result : resolveConfig('default', configKey);
}

function resolveConfig(serviceKey: ServiceKey | 'default', configKey: string) {
  return get(config, `${serviceKey}.${configKey}`);
}
