



































































































import {Component, Vue} from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';

import * as UserService from '@/services/userSpace/UserService';
import ConfirmPassword from '@/components/Dialogs/ConfirmPassword.vue';
import PageTitle from '@/components/PageTitle.vue';
import {User} from "@/services/userSpace/types";

@Component({
  components: {
    ConfirmPassword,
    Avatar,
    PageTitle,
  },
})
export default class UpdatePassword extends Vue {
  user: User = {
    id: -1,
    email: '',
    password: '',
    externalAccountId: '',
    firstname: '',
    lastname: '',
    userServices: [],
  };

  form = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  formRules: Object = UserService.formRules;

  show = {
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  };

  showConfirm = false;

  error = '';

  get matchingPassword() {
    return this.form.newPassword === this.form.confirmPassword || 'Les mots de passe ne coïncident pas';
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  async mounted() {
    this.user = await UserService.getCurrentUser(true);
  }

  compFirstName() {
    return this.user.firstname ? this.user.firstname : '';
  }

  compLastName() {
    return this.user.lastname ? this.user.lastname : '';
  }

  compInitials() {
    const firstNameInitial = this.user.firstname ? this.user.firstname[0] : '';
    const lastNameInitial = this.user.lastname ? this.user.lastname[0] : '';
    return `${firstNameInitial}${lastNameInitial}`;
  }

  async handleUpdatePassword() {
    if ((this.$refs.updatePasswordForm as Vue & { validate: () => boolean }).validate()) {
      this.error = '';
      const res: any = await UserService.updatePassword(this.user.id, {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword
      });
      if (res.response && res.response.status === 409) {
        this.error = `l'ancien mot de passe n’est pas correct`;
      } else {
        this.showConfirm = true;
      }
    }
  }

  closeConfirm() {
    this.showConfirm = false;
  }
}
