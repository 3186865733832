// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-2ec15976] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-2ec15976] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-2ec15976] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.cls-1[data-v-2ec15976] {\n  fill: #f2f2f2;\n}\n.cls-2[data-v-2ec15976] {\n  fill: #3f3d56;\n}\n.cls-3[data-v-2ec15976] {\n  fill: #ccc;\n}\n.cls-4[data-v-2ec15976] {\n  fill: var(--v-stickers_primaire-base);\n}\n.cls-5[data-v-2ec15976] {\n  fill: var(--v-stickers_primaire-base);\n}\n.cls-6[data-v-2ec15976] {\n  fill: #e6e6e6;\n}\n.cls-7[data-v-2ec15976] {\n  fill: #fff;\n}\n.cls-8[data-v-2ec15976] {\n  fill: #2f2e41;\n}\n.cls-9[data-v-2ec15976] {\n  fill: #ffb8b8;\n}\n.cls-10[data-v-2ec15976] {\n  fill: #575a89;\n}\n.cls-11[data-v-2ec15976] {\n  opacity: 0.2;\n}\n.cls-12[data-v-2ec15976] {\n  fill: #d0cde1;\n}\n.cls-13[data-v-2ec15976] {\n  fill: #a0616a;\n}", ""]);
// Exports
module.exports = exports;
