import { render, staticRenderFns } from "./SvgHomeServiceProvidersPicto.vue?vue&type=template&id=2ec15976&scoped=true&"
import script from "./SvgHomeServiceProvidersPicto.vue?vue&type=script&lang=js&"
export * from "./SvgHomeServiceProvidersPicto.vue?vue&type=script&lang=js&"
import style0 from "./SvgHomeServiceProvidersPicto.vue?vue&type=style&index=0&id=2ec15976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec15976",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ec15976')) {
      api.createRecord('2ec15976', component.options)
    } else {
      api.reload('2ec15976', component.options)
    }
    module.hot.accept("./SvgHomeServiceProvidersPicto.vue?vue&type=template&id=2ec15976&scoped=true&", function () {
      api.rerender('2ec15976', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Home/SvgHomeServiceProvidersPicto.vue"
export default component.exports