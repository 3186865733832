var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "offer-delivery" }, [
    _c("span", { staticClass: "offer-delivery__title" }, [
      _vm._v("Détails de l'offre :")
    ]),
    !_vm.isShowcaseOffer
      ? _c(
          "div",
          { staticClass: "offer-delivery__date offer-delivery__line" },
          [
            _c("v-icon", { staticClass: "offer-delivery__icon" }, [
              _vm._v("fas fa-calendar-alt")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.compTextForDate))])
          ],
          1
        )
      : _vm._e(),
    !_vm.isShowcaseOffer & !_vm.isCollectOffer
      ? _c(
          "div",
          { staticClass: "offer-delivery__stock offer-delivery__line" },
          [
            _c("v-icon", { staticClass: "offer-delivery__icon" }, [
              _vm._v("fas fa-shopping-bag")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.compTextForStock) + " ")])
          ],
          1
        )
      : _vm._e(),
    !_vm.isShowcaseOffer & !_vm.isCollectOffer
      ? _c(
          "div",
          { staticClass: "offer-delivery__delivery offer-delivery__line" },
          [
            _c("v-icon", { staticClass: "offer-delivery__icon" }, [
              _vm._v(_vm._s(_vm.compIconForDelivery))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.compTextForDelivery))])
          ],
          1
        )
      : _vm._e(),
    _vm.compUrlWebsite
      ? _c(
          "div",
          { staticClass: "offer-delivery__link offer-delivery__line" },
          [
            _c("v-icon", { staticClass: "offer-delivery__icon" }, [
              _vm._v("fas fa-globe")
            ]),
            _c(
              "a",
              {
                staticClass: "offer-delivery__icon-text",
                attrs: { href: "//" + _vm.compUrlWebsite, target: "_blank" }
              },
              [_vm._v("Voir le site")]
            ),
            _c("v-icon", {
              staticClass: "offer-delivery__angle-right",
              attrs: { small: "" },
              domProps: { textContent: _vm._s("fas fa-chevron-right") }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }