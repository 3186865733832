




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import { sendSSOSyncEmail } from '@/services/userSpace/UserService';

@Component({
  components: {
    SvgPopup,
  },
})
export default class AskSSOSynchroDialog extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  readonly userInfos!: any;

  @Prop({ type: String, required: false }) customMessage?: string;

  @Prop({ type: String, required: false }) titleMessage?: string;

  @Prop({ type: String, required: false }) buttonText?: string;

  snackbar = false;

  snackbarMessage = '';

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  showSnackBar(message: string) {
    this.snackbarMessage = message;
    this.snackbar = true;
  }

  async handleAcceptSynchro() {
    if (!this.userInfos) {
      this.$emit('acceptSynchro');
      return;
    }
    try {
      const response = await sendSSOSyncEmail(this.userInfos);
      if (response) {
        this.showSnackBar('Email envoyé avec succès ! Vous serez redirigé sous peu.');
        setTimeout(() => {
          this.$router.push('/');
        }, 4000);
      }
      this.$emit('close');
    } catch (error) {
      console.error('Error sending email:', error);
      // Optionally handle the error, e.g., show an error message
    }
  }

  get showStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
