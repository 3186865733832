<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51.666"
    height="52.719"
    viewBox="0 0 51.666 52.719"
  >
    <g
      id="prefix__surface1"
      transform="translate(-.058 .001)"
    >
      <path
        id="prefix__Tracé_2437"
        d="M55.922 265.672H61.8v19.845h-5.878zm0 0"
        class="prefix__cls-2"
        data-name="Tracé 2437"
        transform="translate(-50.914 -242.133)"
      />
      <path
        id="prefix__Tracé_2438"
        d="M43.907 208.57v3.765a1.3 1.3 0 0 1-1.29 1.29h-7.132a1.294 1.294 0 0 1-1.29-1.29v-3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2438"
        transform="translate(-31.112 -190.091)"
      />
      <path
        id="prefix__Tracé_2439"
        d="M43.907 494.7v-3.765a1.3 1.3 0 0 0-1.29-1.29h-7.132a1.294 1.294 0 0 0-1.29 1.29v3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2439"
        transform="translate(-31.112 -446.262)"
      />
      <path
        id="prefix__Tracé_2440"
        d="M258.223 265.672h5.877v19.845h-5.881zm0 0"
        class="prefix__cls-2"
        data-name="Tracé 2440"
        transform="translate(-235.29 -242.133)"
      />
      <path
        id="prefix__Tracé_2441"
        d="M246.351 208.57v3.765a1.3 1.3 0 0 1-1.29 1.29h-7.118a1.3 1.3 0 0 1-1.29-1.29v-3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2441"
        transform="translate(-215.631 -190.091)"
      />
      <path
        id="prefix__Tracé_2442"
        d="M246.351 494.7v-3.765a1.3 1.3 0 0 0-1.29-1.29h-7.118a1.3 1.3 0 0 0-1.29 1.29v3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2442"
        transform="translate(-215.631 -446.262)"
      />
      <path
        id="prefix__Tracé_2443"
        d="M460.52 265.672h5.88v19.845h-5.88zm0 0"
        class="prefix__cls-2"
        data-name="Tracé 2443"
        transform="translate(-419.663 -242.133)"
      />
      <path
        id="prefix__Tracé_2444"
        d="M448.7 208.57v3.765a1.294 1.294 0 0 1-1.29 1.29h-7.11a1.3 1.3 0 0 1-1.29-1.29v-3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2444"
        transform="translate(-400.057 -190.091)"
      />
      <path
        id="prefix__Tracé_2445"
        d="M448.7 494.7v-3.765a1.294 1.294 0 0 0-1.29-1.29h-7.11a1.3 1.3 0 0 0-1.29 1.29v3.765zm0 0"
        class="prefix__cls-3"
        data-name="Tracé 2445"
        transform="translate(-400.057 -446.262)"
      />
      <path
        id="prefix__Tracé_2446"
        d="M58.342 21.086L33.247 7.891 8.152 21.086zm0 0"
        class="prefix__cls-4"
        data-name="Tracé 2446"
        transform="translate(-7.377 -7.192)"
      />
      <path
        id="prefix__Tracé_2447"
        d="M20.043 151.742h45.47a1.433 1.433 0 0 1 1.433 1.433v2.169a1.433 1.433 0 0 1-1.433 1.433h-45.47a1.433 1.433 0 0 1-1.433-1.433v-2.169a1.433 1.433 0 0 1 1.433-1.433zm0 0"
        class="prefix__cls-2"
        data-name="Tracé 2447"
        transform="translate(-16.907 -138.298)"
      />
      <path
        id="prefix__Tracé_2448"
        d="M66.95 550.269v-2.121a1.462 1.462 0 0 0-1.457-1.457H20.07a1.461 1.461 0 0 0-1.457 1.457v2.121zm0 0"
        class="prefix__cls-4"
        data-name="Tracé 2448"
        transform="translate(-16.911 -498.254)"
      />
      <path
        id="prefix__Tracé_2449"
        d="M1.028 14.562a2.1 2.1 0 0 0-.028.315v2.169a2.15 2.15 0 0 0 1.39 2.007v3.191a2.006 2.006 0 0 0 1.911 2v18.432a2.007 2.007 0 0 0-1.911 2v3.182A2.173 2.173 0 0 0 1 49.88V52a.717.717 0 0 0 .716.716h48.323a.717.717 0 0 0 .716-.716v-2.12a2.173 2.173 0 0 0-1.395-2.025v-3.2a2.006 2.006 0 0 0-1.911-2V24.241a2.007 2.007 0 0 0 1.911-2v-3.188a2.15 2.15 0 0 0 1.395-2.006v-2.156a2.091 2.091 0 0 0-.033-.315.713.713 0 0 0 .578-1.3L26.2.082a.717.717 0 0 0-.669 0L.441 13.258a.716.716 0 0 0 .587 1.3zm48.293.329v2.169a.717.717 0 0 1-.716.716H3.135a.717.717 0 0 1-.716-.716v-2.169a.717.717 0 0 1 .716-.716h45.47a.717.717 0 0 1 .717.716zM47.927 20.3h-8.265v-1.109h8.265zm-1.911 22.376h-4.443V24.255h4.447zm-5.881 0a2.007 2.007 0 0 0-1.911 2v3.048h-6.788v-3.051a2.007 2.007 0 0 0-1.911-2V24.241a2.007 2.007 0 0 0 1.911-2v-3.05h6.793v3.048a2.006 2.006 0 0 0 1.911 2zm7.787 5.045h-8.26v-1.094h8.265zm-26.189 0v-1.094H30v1.094zm-8.226 0v-3.048a2.006 2.006 0 0 0-1.911-2V24.245a2.007 2.007 0 0 0 1.911-2v-3.054h6.8v3.048a2.006 2.006 0 0 0 1.911 2v18.44a2.007 2.007 0 0 0-1.911 2v3.048zm-9.7 0v-1.094h8.265v1.094zM5.72 24.255h4.448v18.412H5.725zm6.359-5.064v1.094H3.813v-1.094zm17.924 0v1.094h-8.265v-1.094zm0 25.993h-8.265v-.521a.573.573 0 0 1 .573-.573h7.118a.573.573 0 0 1 .573.573zm-1.911-2.527h-4.443v-18.4H28.1zm1.338-19.835h-7.119a.573.573 0 0 1-.573-.573v-.521H30v.521a.573.573 0 0 1-.571.573zm-25.617-.574v-.521h8.265v.521a.573.573 0 0 1-.573.573H4.372a.573.573 0 0 1-.559-.578zM4.372 44.1h7.118a.573.573 0 0 1 .573.573v.521h-8.25v-.521a.574.574 0 0 1 .559-.573zm44.935 7.2H2.419v-1.4a.741.741 0 0 1 .74-.741h45.422a.741.741 0 0 1 .74.741zm-1.395-6.626v.521h-8.25v-.521a.573.573 0 0 1 .573-.573h7.133a.573.573 0 0 1 .559.573zm-.545-21.851H40.24a.573.573 0 0 1-.573-.573v-.521h8.265v.521a.573.573 0 0 1-.564.572zM25.87 1.515l21.34 11.217H4.53zm0 0"
        data-name="Tracé 2449"
        style="fill:none"
      />
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgCultureCategory extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.prefix__cls-2 {
  fill: var(--v-stickers_secondaire-base);
}

.prefix__cls-4 {
  fill: var(--v-homebloc_texte-base);
}

.prefix__cls-3 {
  fill: var(--v-sticker_primaire-base);
}
</style>
