
























































































































































































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ClickOutside } from 'vuetify/lib/directives';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import SvgSignup from '@/components/Svg/SvgSignup.vue';
import * as UserService from '@/services/userSpace/UserService';
import { SignupStepValues } from '@/services/userSpace/types';
import { AgeRestriction, getUIConfig } from '@/configs';

dayjs.extend(isBetween);

interface Tooltip {
  mainDesc: string;
  imageSrc: string;
  optionalDesc: string;
}

interface ClientPersonalInformations {
  requiredName: boolean;
  requiredFirstName: boolean;
  requiredDateOfBirth: boolean;
}

interface ClientId {
  label: string;
  required: boolean;
}

@Component({
  components: {
    SvgSignup,
  },
  directives: {
    ClickOutside,
  },
})
export default class SignupStep extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  error!: string;

  @Prop({
    type: Object,
    required: false,
    default: {},
  })
  formValues!: SignupStepValues;

  cardTooltip: Tooltip = {
    mainDesc: '',
    imageSrc: '',
    optionalDesc: '',
  };

  mailTooltip: Tooltip = {
    mainDesc: '',
    imageSrc: '',
    optionalDesc: '',
  };

  clientPersonalInformations: ClientPersonalInformations = {
    requiredName: false,
    requiredFirstName: false,
    requiredDateOfBirth: false,
  };

  clientId: ClientId = {
    label: '',
    required: false,
  };

  consents = {
    minor: false,
    parental: false,
  };

  showParentalAndMinorConsent: boolean = false;

  showRefusedMinor: boolean = false;

  externalAccountValue: string = '';

  formValid: boolean = false;

  innerLoading: boolean = false;

  formRules: any = UserService.formRules;

  passwordShow: boolean = false;

  confirmPassword: string = '';

  confirmPasswordShow: boolean = false;

  showCardTooltip: boolean = false;

  showMailTooltip: boolean = false;

  externalAccountIdRegex: string | null = null;

  selectedServiceIndex = 0;

  formatDateOfBirth(dob) {
    const string = dob.toString();
    const split = string.split('/');
    return `${split[2]}-${split[1]}-${split[0]}`;
  }

  get externalAccountIdRule() {
    return UserService.createExternalAccountIdRule(this.platform.config.externalAccountIdRegex)
  }

  get isAdult() {
    return dayjs(this.formatDateOfBirth(this.formValues.dateOfBirth)).isBefore(dayjs().subtract(15, 'year'));
  }

  get ageRestrictionConfig() {
    return getUIConfig(this.platform.key, 'signup.ageRestriction');
  }

  get needConsent() {
    if (this.ageRestrictionConfig === AgeRestriction.MINOR_NEED_CONSENT) {
      return dayjs(this.formatDateOfBirth(this.formValues.dateOfBirth)).isBetween(dayjs().subtract(8, 'year'), dayjs().subtract(15, 'year').add(1, 'day'));
    }

    return false;
  }

  get consentValidated() {
    return this.consents.minor && this.consents.parental;
  }

  get showStickers() {
    return !this.platform.style.hideStickers;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get emailAsExternal() {
    return this.platform.config.emailAsExternal;
  }

  get GCURouter() {
    const route = this.$router.resolve({ name: 'gcu-legal-notice' });
    return route.href;
  }

  get privacyPolicyRoute() {
    const route = this.$router.resolve({ name: 'gcu-rgpd' });
    return route.href;
  }

  get gamesRegulationRoute() {
    const route = this.$router.resolve({ name: 'gcu-user-route' });
    return route.href;
  }

  get signupActivated() {
    return this.signUpServices.length >= 1;
  }

  get hasManyServiceForSignUp() {
    return this.signUpServices.length > 1;
  }

  get signUpServices() {
    const platformServices = this.servicesAssociated;
    return (
      platformServices
        // on a un script de signup activé
        .filter((ps) => ps.service.serviceScript.signupScriptActivated)
        // peut-être utilisé pour l'inscription
        .filter((ps) => ps.forSignup)
        // Transforme en obj
        .map((ps) => {
          return { id: ps.serviceId, value: null, name: ps.service.name };
        })
    );
  }

  get servicesAssociated() {
    return this.platform.platformService;
  }

  get matchingPassword() {
    return this.formValues.password === this.confirmPassword || 'Les mots de passe ne coïncident pas';
  }

  get cardTooltipImageSrc() {
    return this.cardTooltip.imageSrc ? `${process.env.VUE_APP_API_URL}/api${this.cardTooltip.imageSrc}` : null;
  }

  get getEmailTooltipImage() {
    return this.mailTooltip.imageSrc ? `${process.env.VUE_APP_API_URL}/api${this.mailTooltip.imageSrc}` : null;
  }

  get sponsorshipIsActive() {
    return this.$store.getters['platform/sponsorshipIsActive'];
  }

  async created() {
    this.innerLoading = this.loading;

    const platform = this.$store.getters['platform/getPlatform'];
    this.cardTooltip.mainDesc = platform.style.signup.cardTooltipMainDesc;
    this.cardTooltip.optionalDesc = platform.style.signup.cardTooltipOptionalDesc;
    this.cardTooltip.imageSrc = platform.style.signup.cardTooltipImageUrl;
    this.mailTooltip.mainDesc = platform.style.signup.mailTooltipMainDesc;
    this.mailTooltip.optionalDesc = platform.style.signup.mailTooltipOptionalDesc;
    this.mailTooltip.imageSrc = platform.style.signup.mailTooltipImageUrl;
    this.clientPersonalInformations.requiredName = platform.style.signup.clientNameRequired;
    this.clientPersonalInformations.requiredFirstName = platform.style.signup.clientFirstNameRequired;
    this.clientPersonalInformations.requiredDateOfBirth = platform.style.signup.clientDateOfBirthRequired;
    this.clientId.label = platform.style.signup.clientIdLabel;
    this.clientId.required = platform.style.signup.clientIdRequired;
    this.formValues.services = [...this.signUpServices];
    this.formValues.services[this.selectedServiceIndex].value = '';
  }

  @Watch('loading')
  onLoadingChange(): void {
    this.innerLoading = this.loading;
  }

  validate(): void {
    if ((this.$refs.stepForm as any).validate()) {
      if (this.isAdult) {
        this.innerLoading = true;
        this.onValidate((this.$refs.stepForm as any).validate(), this.formValues);
      } else if (!this.isAdult && this.needConsent) {
        this.showParentalAndMinorConsent = true;
      } else {
        this.showRefusedMinor = true;
      }
    }
  }

  validateWithConsents(): void {
    this.innerLoading = true;
    this.onValidate((this.$refs.stepForm as any).validate(), this.formValues);
  }

  @Watch('selectedServiceIndex')
  indexChange(newValue, oldValue) {
    const { value } = this.formValues.services[oldValue];
    this.formValues.services.forEach((s) => {
      s.value = null;
    });
    this.formValues.services[newValue].value = value || '';
  }

  @Watch('externalAccountValue')
  externalAccountValueChange(newValue) {
    this.formValues.services[this.selectedServiceIndex].value = newValue;
  }

  maybeUpdateExternal(value) {
    if (!this.emailAsExternal) {
      return;
    }
    this.formValues.services[this.selectedServiceIndex].value = value;
  }

  toDashboard(): void {
    this.$router.push('/dashboard');
  }

  closeDialog(dialog: string) {
    if (dialog === 'consent') {
      this.showParentalAndMinorConsent = false;
    }
    if (dialog === 'refused') {
      this.showRefusedMinor = false;
    }
  }

  @Emit('onValidate')
  onValidate(valid: boolean, formValues: SignupStepValues): void {}

  showSignin() {
    this.$store.dispatch('showLogin', true);
  }
}
