var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.synced
    ? _c(
        "div",
        [
          _c("PageTitle", {
            staticClass: "d-md-flex",
            attrs: { text: "Vos applications synchronisées" }
          }),
          _c("p", {
            staticClass: "unsync__text",
            domProps: { innerHTML: _vm._s(_vm.appText.intro) }
          }),
          _c(
            "a",
            {
              staticClass: "unsync__link",
              attrs: { href: "/gcu/legalNotice", target: "_blank" }
            },
            [_vm._v(" Mentions légales et conditions générales ")]
          ),
          _c(
            "v-col",
            {
              attrs: {
                "offset-xl": "2",
                "offset-lg": "2",
                "offset-md": "2",
                "offset-sm": "1",
                xl: "8",
                lg: "8",
                md: "8",
                sm: "10",
                xs: "8"
              }
            },
            [
              _c("p", {
                staticClass: "d-md-flex applications-subtitle",
                domProps: { innerHTML: _vm._s(_vm.appText.connect) }
              }),
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.synchronize($event)
                    }
                  }
                },
                [
                  _vm.synchronizeBy === "email"
                    ? _c("v-text-field", {
                        attrs: {
                          label: "EMAIL",
                          "validate-on-blur": "",
                          required: "",
                          dense: "",
                          error: !!_vm.error,
                          "error-messages": _vm.error,
                          rules: _vm.formRules.email
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    : _vm.synchronizeBy === "externalAccountId"
                    ? _c("v-text-field", {
                        attrs: {
                          label: "Numéro de carte",
                          "validate-on-blur": "",
                          required: "",
                          dense: "",
                          error: !!_vm.error,
                          "error-messages": _vm.error,
                          rules: _vm.formRules.externalAccountId
                        },
                        model: {
                          value: _vm.form.externalAccountId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "externalAccountId", $$v)
                          },
                          expression: "form.externalAccountId"
                        }
                      })
                    : _c("p", [
                        _vm._v(_vm._s(_vm.synchronizeBy) + " not implemented")
                      ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-5 applications-btn",
                          attrs: {
                            loading: _vm.loading,
                            type: "submit",
                            color: "homeglobal_fond_bouton",
                            rounded: ""
                          }
                        },
                        [_vm._v(" Synchroniser ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        [
          _c("PageTitle", {
            staticClass: "d-md-flex",
            attrs: { text: _vm.appText.name }
          }),
          _c(
            "div",
            { staticClass: "applications-wrapper" },
            [
              _c(
                "ApplicationCard",
                {
                  attrs: {
                    name: _vm.service.name,
                    logo: _vm.appLogo,
                    synchronized: _vm.product.synchronized,
                    waitingValidation: !_vm.product.synchronized,
                    solo: ""
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "text" },
                    [
                      _vm.product.synchronized
                        ? [
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v("Synchronisé depuis")
                            ]),
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v(
                                "le " + _vm._s(_vm.formattedProductCreatedAt)
                              )
                            ])
                          ]
                        : [
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v("Email de synchronisation envoyé")
                            ]),
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v(
                                "le " + _vm._s(_vm.formattedProductCreatedAt)
                              )
                            ])
                          ]
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.product.productCode))
          ]),
          _c(
            "v-btn",
            {
              staticClass: "applications-disconnection",
              attrs: { color: "homeglobal_fond_bouton", rounded: "" },
              on: { click: _vm.unsynchronize }
            },
            [_vm._v(" Se déconnecter ")]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }