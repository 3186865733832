// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-5de2f940] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-5de2f940] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-5de2f940] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n[data-v-5de2f940] .v-card__text {\n  background: var(--v-popup_fond-base) !important;\n}\n.card-points[data-v-5de2f940] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  text-align: center;\n  line-height: 1.2;\n}\n.card-points-title[data-v-5de2f940] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n  font-size: 1.5rem;\n  letter-spacing: -0.4px;\n  color: var(--v-popup_sous_titre-base) !important;\n}\n.card-points-points[data-v-5de2f940] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n  font-size: 1.9rem;\n  letter-spacing: -0.6px;\n  font-weight: bold;\n  color: var(--v-popup_texte-base) !important;\n}", ""]);
// Exports
module.exports = exports;
