import { apiPublicInstance } from '../API';

export interface Rule {
  category: number;
  points: number;
  pointType: number;
  threshold: number;
  frequency: number;
  label: string;
}

export const getActions = async () => {
  return (await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}/servicesDetails`)).data;
};
