var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c("PageTitle", { attrs: { text: "Commandes" } }),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c("div", [_c("OrdersMobile")], 1)
        : _c("div", [_c("OrdersDatatable")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }