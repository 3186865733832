var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password" },
    [
      _vm.showDialog
        ? _c("PasswordResetSuccess", {
            model: {
              value: _vm.showDialog,
              callback: function($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog"
            }
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { "multi-line": true },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { color: "red", text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "reset-password__title" }, [
        _vm.compShowStickers
          ? _c(
              "div",
              { staticClass: "reset-password__title-icon" },
              [_c("SvgPopup", { attrs: { width: "80", height: "80" } })],
              1
            )
          : _vm._e(),
        _c("h2", { staticClass: "reset-password__title-text" }, [
          _vm._v(
            " " +
              _vm._s(_vm.migrated ? "Mot de passe" : "Mot de passe oublié") +
              " "
          )
        ])
      ]),
      _vm.migrated
        ? _c("v-card-text", { staticClass: "reset-password-subtitle last" }, [
            _vm._v(
              " Suite à l’évolution du programme de fidélité, et aux nouvelles règles de sécurité nous vous demandons de bien vouloir modifier votre mot de passe et d’en créer un nouveau. "
            )
          ])
        : _vm._e(),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "reset-password__form",
          attrs: { autocomplete: "new-password" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "reset-password__form-fields" },
            [
              _c("v-text-field", {
                staticClass: "reset-password__form-field mb-5",
                attrs: {
                  autocomplete: "off",
                  rules: _vm.rules.password,
                  type: _vm.show.password ? "text" : "password",
                  label: "NOUVEAU MOT DE PASSE",
                  hint:
                    "Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.",
                  "validate-on-blur": "",
                  required: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.show.password = !_vm.show.password
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.show.password
                                    ? "fas fa-eye"
                                    : "fas fa-eye-slash"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _c("v-text-field", {
                staticClass: "reset-password__form-field",
                attrs: {
                  autocomplete: "new-password",
                  type: _vm.show.confirmPassword ? "text" : "password",
                  rules: _vm.rules.confirmPassword.concat([
                    _vm.matchingPassword
                  ]),
                  label: "VALIDER NOUVEAU MOT DE PASSE",
                  "validate-on-blur": "",
                  required: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.show.confirmPassword = !_vm.show
                                  .confirmPassword
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.show.confirmPassword
                                    ? "fas fa-eye"
                                    : "fas fa-eye-slash"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.confirmPassword,
                  callback: function($$v) {
                    _vm.confirmPassword = $$v
                  },
                  expression: "confirmPassword"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "reset-password__form-submit-button" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "reset-password__form-submit-button_btn",
                  attrs: {
                    color: "popup_fond_bouton",
                    large: "",
                    type: "submit",
                    rounded: "",
                    block: "",
                    loading: _vm.isLoading
                  }
                },
                [_vm._v(" Valider ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }