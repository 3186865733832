var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compShowStickers
    ? _c("div", { staticClass: "program" }, [
        _c("div", [
          _c("h1", {
            class: {
              program__title: true,
              "title-center": _vm.centerTitleAccent,
              program__divider: _vm.titleDivider
            },
            style: "max-width: " + _vm.titleMaxWidth + ";",
            domProps: { innerHTML: _vm._s(_vm.title) }
          }),
          _vm.titleDivider
            ? _c("div", { staticClass: "program__divider" })
            : _vm._e()
        ]),
        _vm.showPageSvg
          ? _c(
              "div",
              { staticClass: "program__svg" },
              [
                _vm.$vuetify.breakpoint.xsOnly
                  ? _c("SvgProgramIntro")
                  : _vm._e(),
                _vm.$vuetify.breakpoint.smAndUp
                  ? _c("SvgProgramIntroWeb")
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    : _c("div", { staticClass: "program" }, [
        _c("h1", {
          staticClass: "program__title",
          class: {
            program__title: true,
            "title-center": _vm.centerTitleAccent,
            program__divider: _vm.titleDivider,
            program__title__highlight: _vm.highlightTitle
          },
          style: "max-width: " + _vm.titleMaxWidth + ";",
          domProps: { innerHTML: _vm._s(_vm.title) }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }