






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CarouselCard extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly pictures!: any[];

  @Prop({
    type: Boolean,
    required:false,
    default: false,
  })
  readonly big!: boolean;

  get compShowArrow(): boolean {
    return this.pictures.length > 1;
  }

  get compSize(): number{
    return this.big ? 650 : 312;
  }
}
