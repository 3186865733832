










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ExpirableBalance extends Vue {
  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  expirableBalance!: number;
}
