var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expired-token px-6" },
    [
      _vm.showDialog
        ? _c("ForgotPassword", {
            model: {
              value: _vm.showDialog,
              callback: function($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "expired-token__title" }, [
        _vm.compShowStickers
          ? _c(
              "div",
              { staticClass: "expired-token__title-icon" },
              [_c("SvgPopup")],
              1
            )
          : _vm._e(),
        _c("h2", { staticClass: "expired-token__title-text" }, [
          _vm._v("Oups !")
        ])
      ]),
      _c("div", { staticClass: "form" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "expired-token__action" },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  color: "popup_fond_bouton",
                  large: "",
                  rounded: "",
                  block: ""
                },
                on: {
                  click: function($event) {
                    _vm.showDialog = true
                  }
                }
              },
              [_vm._v(" Mot de passe oublié ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "expired-token__message" }, [
      _c("span", [
        _vm._v(
          "Le lien a expiré, veuillez renouveler votre demande de réinitialisation de mot de passe."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }