












import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class BalancePointsCard extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Number, required: true })
  readonly points!: number;
}
