var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c("Datatable", {
        attrs: { headers: _vm.headers },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm._l(_vm.list, function(item, $index) {
                  return _c(
                    "div",
                    {
                      key: $index,
                      staticClass: "orders-list-item",
                      attrs: { "data-num": $index + 1 }
                    },
                    [
                      _c("OrderLineWeb", {
                        attrs: {
                          number: item.id,
                          date: item.createdAt,
                          companyName: item.companyName,
                          companyAvatar: item.companyAvatar || "",
                          points: item.offerPoints,
                          title: item.offerTitle,
                          status: item.status,
                          uuid: item.uuid
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm.infinite
                  ? _c(
                      "infinite-loading",
                      { on: { infinite: _vm.infiniteHandler } },
                      [
                        _c("div", {
                          attrs: { slot: "no-more" },
                          slot: "no-more"
                        }),
                        _c("div", {
                          attrs: { slot: "no-results" },
                          slot: "no-results"
                        }),
                        _c("div", { attrs: { slot: "error" }, slot: "error" })
                      ]
                    )
                  : _vm._e(),
                !_vm.isLoading && !_vm.list.length
                  ? _c(
                      "div",
                      [_c("NoOrders", { attrs: { button: _vm.infinite } })],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }