





























import { Vue, Component, Prop } from 'vue-property-decorator';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';
import * as OrderService from '@/services/merchantSpace/OrderService';

const moment = require('moment');

@Component({
  components: {
    OfferAvatar,
  },
})
export default class CashbackTransactionLine extends Vue {
  @Prop({
    type: String,
    required: true,
    default: '22/02/2019',
  })
  readonly date!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly name!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly status!: string;

  @Prop({
    type: Number,
    required: false,
    default: '',
  })
  readonly gain!: number;

  @Prop({
    type: Number,
    required: false,
    default: '',
  })
  readonly amountPayed!: number;

  @Prop({
    type: Number,
    required: false,
    default: '',
  })
  readonly reduction!: number;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly unitReduction!: string;

  get formatedDate(): string {
    return moment(this.date).format('DD/MM/YYYY');
  }

  get companyInitials() {
    return this.name.charAt(0).toUpperCase();
  }
}
