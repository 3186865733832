var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card" },
    [
      _c("v-img", {
        staticClass: "card-image",
        attrs: {
          src: _vm.compImage,
          gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)",
          alt: ""
        }
      }),
      _vm.isLuck
        ? _c("Countdown", {
            staticClass: "card-countdown",
            attrs: { drawAt: _vm.drawAt }
          })
        : _vm._e(),
      _vm.isLuck
        ? _c("OrdersNumber", {
            staticClass: "card-orders-number",
            attrs: { offerId: _vm.offerId, platformId: _vm.platformId }
          })
        : _vm._e(),
      _c("div", { staticClass: "card-description" }, [
        _c("span", { staticClass: "card-description-company-name" }, [
          _vm._v(" " + _vm._s(_vm.companyName) + " ")
        ]),
        _c("span", { staticClass: "card-description-offer-title mb-3" }, [
          _vm._v(" " + _vm._s(_vm.offerTitle) + " ")
        ]),
        _c("div", { staticClass: "card-description-offer-points" }, [
          !_vm.isCollect && !_vm.isShowcase
            ? _c(
                "span",
                { staticClass: "card-description-offer-points-text" },
                [_vm._v(_vm._s(_vm.points) + " points")]
              )
            : _vm._e(),
          _vm.isCollect
            ? _c(
                "div",
                { staticClass: "card-description-offer-progress" },
                [
                  _c("v-progress-linear", {
                    staticClass: "card-description-offer-progress-bar",
                    attrs: {
                      "background-color": "inactif",
                      color: "actif",
                      value: _vm.progressBarValue
                    }
                  }),
                  _c(
                    "span",
                    { staticClass: "card-description-offer-progress-text" },
                    [
                      _vm._v(
                        "Déjà récolté : " +
                          _vm._s(_vm.filledPoints) +
                          " " +
                          _vm._s(_vm.textPt)
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "card-offer-type" }, [
        _vm.compTargetAudience
          ? _c(
              "div",
              { staticClass: "card-offer-type__svg" },
              [_c("SvgPictoPro")],
              1
            )
          : _vm._e(),
        _vm.isLuck
          ? _c(
              "div",
              { staticClass: "card-offer-type__svg" },
              [_c("SvgPictoLuck")],
              1
            )
          : _vm._e(),
        _vm.isShowcase
          ? _c(
              "div",
              { staticClass: "card-offer-type__svg" },
              [_c("SvgPictoShowcase")],
              1
            )
          : _vm._e(),
        _vm.isCollect
          ? _c(
              "div",
              { staticClass: "card-offer-type__svg" },
              [_c("SvgPictoCollect")],
              1
            )
          : _vm._e(),
        _vm.isCashback
          ? _c(
              "div",
              { staticClass: "card-offer-type__svg" },
              [_c("SvgPictoCashback")],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }