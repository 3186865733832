import { apiAuthenticatedInstance, apiPublicInstance } from '@/services/API';
import { Offer, OrderAddress } from '@/services/merchantSpace/types';

export interface ContributionChecked {
  exceeded: boolean;
  remainingPoints: number;
}

const offerTransformer = (offer) => {
  return {
    ...offer,
    archivedAt: offer.archivedAt ? new Date(offer.archivedAt) : undefined,
    drawAt: offer.drawAt ? new Date(offer.drawAt) : undefined,
    startedAt: offer.startedAt ? new Date(offer.startedAt) : undefined,
    createdAt: offer.createdAt ? new Date(offer.createdAt) : undefined,
    expiredAt: offer.expiredAt ? new Date(offer.expiredAt) : undefined,
    collectionDateMin: offer.collectionDateMin ? new Date(offer.collectionDateMin) : undefined,
    collectionDateMax: offer.collectionDateMax ? new Date(offer.collectionDateMax) : undefined,
    collectionDateUnique: offer.collectionDateUnique ? new Date(offer.collectionDateUnique) : undefined,
  };
};

const offersTransformer = (offers) => ({
  ...offers,
  items: offers.items.map(offerTransformer),
});

export async function getAllOffers(params = {}) {
  const offers = await apiAuthenticatedInstance()
    .get(`/api/offers/all`, {
      params: {
        ...params,
        platformUuid: process.env.VUE_APP_PLATFORM_UUID,
      },
    })
    .then((res) => res.data);
  return offersTransformer(offers);
}

export async function getPublicOffers(params = {}) {
  const offers = await apiPublicInstance()
    .get(`/api/offers/public`, {
      params: {
        ...params,
        platformUuid: process.env.VUE_APP_PLATFORM_UUID,
      },
    })
    .then((res) => res.data);
  return offersTransformer(offers);
}

export async function getOfferById(offerId: number) {
  const offer = await apiPublicInstance().get(`/api/offers/public/${offerId}?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}`);
  return offerTransformer(offer.data);
}

export const isAlreadyOrder = async (offerId: number): Promise<boolean> => {
  return (await apiAuthenticatedInstance().get(`/api/offers/alreadyOrder?id=${offerId}`)).data;
};

export const isOrderable = async (offerId: number): Promise<boolean> => {
  return (await apiAuthenticatedInstance().get(`/api/offers/orderable?id=${offerId}`)).data;
};

export const consumeOffer = async (id: number, orderAddress?: OrderAddress): Promise<Offer> => {
  return (
    await apiAuthenticatedInstance().post(`/api/offers/${id}/consume`, {
      platformUuid: process.env.VUE_APP_PLATFORM_UUID,
      locale: 'fr',
      orderAddress: orderAddress || {},
    })
  ).data;
};

export const getFilterInfos = async (): Promise<any> => {
  return (await apiPublicInstance().get(`/api/offers/filterInfos?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}`)).data;
};

export const contributeFundingOffer = async (id: number, points: number): Promise<Offer> => {
  return (
    await apiAuthenticatedInstance().post(`/api/offers/${id}/contribute`, {
      points,
      platformUuid: process.env.VUE_APP_PLATFORM_UUID,
      locale: 'fr',
    })
  ).data;
};

export const contributionCheck = async (id: number, points: number): Promise<ContributionChecked> => {
  return (await apiAuthenticatedInstance().get(`/api/offers/${id}/checkContribution?points=${points}`)).data;
};
