




























import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import { getTranslation } from '../../i18n';

@Component({
  components: {
    SvgPopup,
  },
})
export default class ConfirmSSOSynchroDialog extends Vue {
  dialog: boolean = true;

  @Prop({
    type: Object,
    required: false,
  })
  
  @Prop({ type: String, required: false }) platformKey?: string;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  closeDialog () {
    this.dialog = false;
    this.$store.dispatch('showConfirmSSOSynchroMessage', false);
  }

  get autoSynchroError() {
    return this.$store.getters.autoSynchroError;
  }

  get autoSynchroContent() {
    if(!this.autoSynchroError) {
      const serviceName = getTranslation(`${this.platformKey}.signInForm.connectWith`) || getTranslation(`signInForm.connectWith`);
      return `Votre compte ${serviceName} a bien été associé et synchronisé.`;
    } 
    return "Il semblerait que le compte soit déjà rattaché."
  }

  get autoSynchroTitle() {
    return !this.autoSynchroError ? "Synchronisation réussie !" : "Oups !"
  }

}
