import store from '@/store';
import { isConnected } from '@/services/common/common';
import { refreshUser, refreshUserPoints } from '@/services/userSpace/UserService';
import { executeSSOFlow } from './authSpace/sso.service';
import { hasToConsentForCookies, loadPlatformByUUID } from './merchantSpace/PlatformService';
import { loadCookiesConsentment } from '@/libs/cookies-consentment';
import * as externalSessionService from '@/services/authSpace/externalSession.service';

declare let FontFace: any;

interface FontObject {
  filename: string;
  id: number;
  uuid: string;
}

interface Fonts {
  Primary: FontObject;
  Secondary: FontObject;
  Tertiary: FontObject;
}

const isSSOEnabled = () => store.getters['platform/isSSOEnabled'];
const getExternalSessionName = () => store.getters['platform/externalSessionName'];

export async function loadApp({ platformUUID }: { platformUUID: string }): Promise<{
  platform: any;
}> {
  const platform = await loadPlatformByUUID(platformUUID);

  if (isConnected()) {
    // Il est nécessaire de raffraichir les données utilisateurs
    // pour gérer les maj asynchrones
    await Promise.all([refreshUser(), refreshUserPoints()]).catch((e) => console.error(e));
  }

  if (!isConnected() && isSSOEnabled()) {
    await executeSSOFlow();
  }

  if (hasToConsentForCookies(platform)) {
    loadCookiesConsentment();
  }

  await loadFonts(platform.style.fonts);

  const externalSessionName = getExternalSessionName();
  if (externalSessionName) {
    await externalSessionService
      .signIn({
        platformUUID,
        sessionName: externalSessionName,
      })
      .catch((e) => console.error('Cannot sign in with external session', e));
  }

  return {
    platform,
  };
}

async function loadFonts(fonts: Fonts): Promise<any> {
  const primaryFont = new FontFace('Primary', `url('${process.env.VUE_APP_API_URL}/api/files/fonts/${fonts.Primary.filename}')`);
  const secondaryFont = new FontFace('Secondary', `url('${process.env.VUE_APP_API_URL}/api/files/fonts/${fonts.Secondary.filename}')`);
  const tertiaryFont = new FontFace('Tertiary', `url('${process.env.VUE_APP_API_URL}/api/files/fonts/${fonts.Tertiary.filename}')`);
  const fontsToLoad = [primaryFont, secondaryFont, tertiaryFont];
  const fontPromises = fontsToLoad.map((font) =>
    font
      .load()
      .then((loadedFont) => {
        (document as any).fonts.add(loadedFont);
      })
      .catch((error) => {
        console.error('issue while loading font', error);
      }),
  );

  await Promise.all(fontPromises);
}
