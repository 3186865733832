















import { Vue, Component, Prop } from 'vue-property-decorator';

import PageTitle from '@/components/PageTitle.vue';
import PointsHistory from '@/components/Account/PointsHistory.vue';

@Component({
  components: {
    PageTitle,
    PointsHistory,
  },
})
export default class PointsHistoryContainer extends Vue {
  @Prop({ type: Number, default: 12 })
  readonly pageLimit!: number;
}
