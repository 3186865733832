



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgHomePointsPicto from '@/components/Home/SvgHomePointsPicto.vue';
import SvgHomePointsPicto2 from '@/components/Home/SvgHomePointsPicto2.vue';
import SvgHomeBackground from '@/components/Home/SvgHomeBackground.vue';
import { isConnected } from '@/services/common/common';

@Component({
  components: {
    SvgHomePointsPicto,
    SvgHomePointsPicto2,
    SvgHomeBackground,
  },
})
export default class HomePoints extends Vue {
  @Prop({ type: String, required: true })
  readonly pointsTitle!: string;

  @Prop({ type: String, required: true })
  readonly pointsSubtitle!: string;

  @Prop({ type: String, required: true })
  readonly pointsDescription!: string;

  @Prop({ type: Boolean, default: false })
  readonly noBtnBarem!: boolean;

  @Prop({ type: String, required: false, default: 'Barème de points' })
  readonly btnBaremText!: string;

  @Prop({ type: String, default: null })
  readonly srcImg!: string;

  @Prop({ type: Boolean, default: false })
  readonly bigLine!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly squareImage!: boolean;

  @Prop({ type: Boolean, default: null })
  readonly applyRadius!: boolean;

  get navigateToPointsScale() {
    if (isConnected()) {
      return { name: 'scalePoints' };
    }
    return { name: 'scale-points-public' };
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get secondBlockImage() {
    return this.$store.getters['platform/getPlatform'].style.secondBlockImage;
  }

  get compSecondBlockImage() {
    return `${process.env.VUE_APP_API_URL}/api${this.secondBlockImage}`;
  }

  get titleClass() {
    return this.bigLine ? 'points__text--title points__text--title--bigline' : 'points__text--title';
  }

  get radiusClass() {
    return this.applyRadius ? 'points__second--img points__second--img--radius' : 'points__second--img';
  }

  get secondClass() {
    return this.squareImage ? 'points__squareimage' : 'points__second';
  }

  get buttonClass() {
    return this.squareImage ? 'points__text--btn points__text--btn--nomargin' : 'points__text--btn';
  }
}
