var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "line" },
    [
      _c("div", { staticClass: "line__content align-center px-2 px-md-6" }, [
        _c(
          "div",
          { staticClass: "line__content-content" },
          [
            _c("OfferAvatar", {
              attrs: { size: "52px", initial: _vm.companyInitials }
            })
          ],
          1
        ),
        _c("div", { staticClass: "line__content-desc" }, [
          _c("span", [_vm._v(_vm._s(_vm.formatedDate))]),
          _c("br"),
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _c("br", { staticClass: "line__content-info-br" }),
          _c("span", { staticClass: "line__content-desc-special" }, [
            _vm._v("Gain de " + _vm._s(_vm.gain) + "€")
          ])
        ]),
        _c("div", { staticClass: "line__content-info" }, [
          _c("span", [
            _vm._v("Montant dépensé : " + _vm._s(_vm.amountPayed) + "€")
          ]),
          _c("br"),
          _c("span", [
            _vm._v(
              "Réduction: " + _vm._s(_vm.reduction) + _vm._s(_vm.unitReduction)
            )
          ]),
          _c("br", { staticClass: "line__content-info-br" }),
          _c("span", { staticClass: "line__content-info-special" }, [
            _vm._v(_vm._s(_vm.status))
          ])
        ])
      ]),
      _c("v-divider", { staticClass: "line__divider" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }