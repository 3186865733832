var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-hint-offer",
      class: { "is-mobile": _vm.isMobile }
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "dashboard-hint-offer__content" },
        [_c("OfferSlider")],
        1
      ),
      _c(
        "div",
        { staticClass: "dashboard-hint-offer__actions" },
        [
          _c(
            "v-btn",
            {
              staticClass: "dashboard-hint-offer__actions-btn",
              attrs: {
                large: "",
                rounded: "",
                color: "homeglobal_fond_bouton",
                to: "/shop"
              }
            },
            [_vm._v(" Accéder à la boutique ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-hint-offer__header" }, [
      _c("h3", { staticClass: "dashboard-hint-offer__header-title" }, [
        _vm._v("Nos suggestions")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }