<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53.905"
    height="50.127"
    viewBox="0 0 53.905 50.127"
  >
    <g
      id="prefix__Composant_105_1"
      data-name="Composant 105 – 1"
      style="opacity:1"
    >
      <g
        id="prefix__surface1"
        transform="translate(0 22)"
      >
        <path
          id="prefix__Tracé_2450"
          d="M199.961 417.509a12.966 12.966 0 0 0 2.529 3.309.824.824 0 0 0 1.1 0 12.97 12.97 0 0 0 2.529-3.309 13.276 13.276 0 0 1-3.079-1.766 13.278 13.278 0 0 1-3.079 1.766zm0 0"
          class="prefix__cls-2"
          data-name="Tracé 2450"
          transform="translate(-188.975 -392.901)"
        />
        <path
          id="prefix__Tracé_2451"
          d="M296.223 317.992c-.153.174-.311.343-.473.5a13.568 13.568 0 0 1-6.388 3.441 20.08 20.08 0 0 1-3.651.563 9.158 9.158 0 0 0 4.987 1.689c3.978 0 7.1-3.438 7.234-3.584a.824.824 0 0 0 0-1.1 12.381 12.381 0 0 0-1.709-1.509zm0 0"
          class="prefix__cls-3"
          data-name="Tracé 2451"
          transform="translate(-270.014 -300.521)"
        />
        <path
          id="prefix__Tracé_2452"
          d="M8.8 321.939a13.568 13.568 0 0 1-6.383-3.439q-.244-.243-.473-.5a12.406 12.406 0 0 0-1.709 1.515.824.824 0 0 0 0 1.1c.131.146 3.255 3.584 7.234 3.584a9.159 9.159 0 0 0 4.988-1.689 20.092 20.092 0 0 1-3.657-.571zm0 0"
          class="prefix__cls-3"
          data-name="Tracé 2452"
          transform="translate(-.023 -300.521)"
        />
        <path
          id="prefix__Tracé_2453"
          d="M160.812 20.149a.824.824 0 0 0 1.1 0c.2-.181 4.952-4.5 4.952-9.969S162.113.391 161.911.21a.824.824 0 0 0-1.1 0c-.2.181-4.952 4.5-4.952 9.969s4.75 9.789 4.953 9.97zm0 0"
          class="prefix__cls-2"
          data-name="Tracé 2453"
          transform="translate(-147.296)"
        />
        <path
          id="prefix__Tracé_2454"
          d="M344.3 28.733a16.313 16.313 0 0 0-.857-2.345.824.824 0 0 0-1.016-.421 16.368 16.368 0 0 0-2.264 1.052 17.263 17.263 0 0 1 1.381 2.52 17.339 17.339 0 0 1 2.756-.806zm0 0"
          class="prefix__cls-3"
          data-name="Tracé 2454"
          transform="translate(-321.471 -24.497)"
        />
        <path
          id="prefix__Tracé_2455"
          d="M100.691 27.018a16.343 16.343 0 0 0-2.264-1.052.824.824 0 0 0-1.016.421 16.37 16.37 0 0 0-.857 2.346 17.322 17.322 0 0 1 2.755.806 17.279 17.279 0 0 1 1.382-2.521zm0 0"
          class="prefix__cls-3"
          data-name="Tracé 2455"
          transform="translate(-91.25 -24.495)"
        />
        <path
          id="prefix__Tracé_2456"
          d="M311.13 104.279a12.426 12.426 0 0 1-.853 4.428 16.946 16.946 0 0 1-1.831 3.467 20.439 20.439 0 0 1-1.676 2.14 13.346 13.346 0 0 0 7.716-3.4c3.87-3.869 3.563-10.28 3.548-10.551a.824.824 0 0 0-.777-.777c-.037 0-.219-.011-.514-.011a17.792 17.792 0 0 0-6.178 1.115 12.105 12.105 0 0 1 .565 3.589zm0 0"
          class="prefix__cls-2"
          data-name="Tracé 2456"
          transform="translate(-289.915 -94.1)"
        />
        <path
          id="prefix__Tracé_2457"
          d="M11.276 114.315a20.466 20.466 0 0 1-1.676-2.141 16.948 16.948 0 0 1-1.832-3.467 12.431 12.431 0 0 1-.853-4.428 12.1 12.1 0 0 1 .565-3.594A17.791 17.791 0 0 0 1.3 99.57c-.3 0-.477.009-.514.011a.824.824 0 0 0-.777.777c-.009.272-.319 6.683 3.55 10.552a13.348 13.348 0 0 0 7.717 3.405zm0 0"
          class="prefix__cls-2"
          data-name="Tracé 2457"
          transform="translate(0 -94.1)"
        />
      </g>
      <g
        id="prefix__tools-and-utensils"
        transform="rotate(-90 26.953 6.823)"
      >
        <g
          id="prefix__Groupe_666"
          data-name="Groupe 666"
          transform="translate(.988 .995)"
        >
          <path
            id="prefix__Tracé_2458"
            d="M385.995 22.654a1.036 1.036 0 0 1-.72-.29l-2.79-2.79a.989.989 0 0 1-.29-.7 1.037 1.037 0 0 1 .29-.72l2.79-2.791a1.019 1.019 0 0 1 1.4 0l2.811 2.811a.989.989 0 0 1 0 1.4l-2.791 2.79a.989.989 0 0 1-.7.29z"
            class="prefix__cls-4"
            data-name="Tracé 2458"
            transform="translate(-357.97 -15.085)"
          />
          <path
            id="prefix__Rectangle_1217"
            d="M0 0H5.937V16.44H0z"
            class="prefix__cls-4"
            data-name="Rectangle 1217"
            transform="rotate(-135 11.023 9.378)"
          />
          <path
            id="prefix__Tracé_2459"
            d="M18.773 389.713a.986.986 0 0 1-.7-.29l-2.811-2.81a.989.989 0 0 1 0-1.4l2.791-2.79a.989.989 0 0 1 1.4 0l2.81 2.81a.989.989 0 0 1 0 1.4l-2.79 2.79a.987.987 0 0 1-.7.29z"
            class="prefix__cls-4"
            data-name="Tracé 2459"
            transform="translate(-14.972 -357.92)"
          />
        </g>
        <g
          id="prefix__Groupe_667"
          data-name="Groupe 667"
          transform="translate(2.683 2.679)"
        >
          <path
            id="prefix__Tracé_2460"
            d="M42.08 413.42a.989.989 0 0 0 1.4 0l2.79-2.79a.989.989 0 0 0 0-1.4l-1.405-1.405-4.189 4.189z"
            class="prefix__cls-4"
            data-name="Tracé 2460"
            transform="translate(-40.675 -383.602)"
          />
          <path
            id="prefix__Tracé_2461"
            d="M409.13 46.2a1.037 1.037 0 0 0 .72.29.989.989 0 0 0 .7-.29l2.791-2.79a.989.989 0 0 0 0-1.4l-1.406-1.406-4.2 4.2z"
            class="prefix__cls-4"
            data-name="Tracé 2461"
            transform="translate(-383.521 -40.61)"
          />
          <path
            id="prefix__Rectangle_1218"
            d="M0 0H16.44V2.968H0z"
            class="prefix__cls-4"
            data-name="Rectangle 1218"
            transform="rotate(-45 28.365 -.119)"
          />
        </g>
        <path
          id="prefix__Tracé_2462"
          d="M293.069 15.273L280.475 2.679l2.1-2.1a1.979 1.979 0 0 1 2.8 0l9.8 9.8a1.979 1.979 0 0 1 0 2.8z"
          class="prefix__cls-3"
          data-name="Tracé 2462"
          transform="translate(-261.973)"
        />
        <path
          id="prefix__Tracé_2463"
          d="M226.725 49.192L212.731 35.2a.989.989 0 0 1 0-1.4l1.4-1.4a1.979 1.979 0 0 1 2.8 0l12.595 12.595a1.979 1.979 0 0 1 0 2.8l-1.4 1.4a.989.989 0 0 1-1.401-.003z"
          class="prefix__cls-2"
          data-name="Tracé 2463"
          transform="translate(-198.427 -29.721)"
        />
        <path
          id="prefix__Tracé_2464"
          d="M2.679 280.475l12.594 12.595-2.1 2.1a1.979 1.979 0 0 1-2.8 0l-9.8-9.8a1.979 1.979 0 0 1 0-2.8z"
          class="prefix__cls-3"
          data-name="Tracé 2464"
          transform="translate(0 -261.973)"
        />
        <path
          id="prefix__Tracé_2465"
          d="M35.2 212.731l13.994 13.994a.989.989 0 0 1 0 1.4l-1.4 1.4a1.979 1.979 0 0 1-2.8 0L32.4 216.93a1.979 1.979 0 0 1 0-2.8l1.4-1.4a.989.989 0 0 1 1.4.001z"
          class="prefix__cls-2"
          data-name="Tracé 2465"
          transform="translate(-29.721 -198.427)"
        />
        <g
          id="prefix__Groupe_668"
          data-name="Groupe 668"
          transform="translate(5.478 5.478)"
        >
          <path
            id="prefix__Tracé_2466"
            d="M87.936 384.333a1.979 1.979 0 0 0 2.8 0l2.1-2.1-6.3-6.3-3.5 3.5z"
            class="prefix__cls-3"
            data-name="Tracé 2466"
            transform="translate(-83.038 -356.615)"
          />
          <path
            id="prefix__Tracé_2467"
            d="M375.939 86.534l6.3 6.3 2.1-2.1a1.979 1.979 0 0 0 0-2.8l-4.9-4.9z"
            class="prefix__cls-3"
            data-name="Tracé 2467"
            transform="translate(-356.617 -83.036)"
          />
        </g>
        <g
          id="prefix__Groupe_669"
          data-name="Groupe 669"
          transform="translate(8.976 8.976)"
        >
          <path
            id="prefix__Tracé_2468"
            d="M142.367 332.7a1.979 1.979 0 0 0 2.8 0l1.4-1.4a.989.989 0 0 0 0-1.4l-7-7-3.5 3.5z"
            class="prefix__cls-2"
            data-name="Tracé 2468"
            transform="translate(-136.07 -310.579)"
          />
          <path
            id="prefix__Tracé_2469"
            d="M329.9 146.562a.99.99 0 0 0 1.4 0l1.4-1.4a1.979 1.979 0 0 0 0-2.8l-6.3-6.3-3.5 3.5z"
            class="prefix__cls-2"
            data-name="Tracé 2469"
            transform="translate(-310.581 -136.067)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';


@Component
export default class SvgCultureCategory extends Vue {
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.prefix__cls-3 {
  fill: var(--v-stickers_secondaire-base);
}

.prefix__cls-4 {
  fill: var(--v-homeglobal_texte_fond-base);
}

.prefix__cls-2 {
  fill: var(--v-sticker_primaire-base);
}
</style>
