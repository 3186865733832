













import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class SSOSignInView extends Vue {
  // ===== computed =======
  get ssoOptions() {
    return this.$store.getters['platform/ssoOptions'];
  }

  get route() {
    return this.$route.meta.title;
  }

  get signInCustomTitle() {
    const { signInCustomTitle } = this.ssoOptions;
    return signInCustomTitle && signInCustomTitle !== '<p></p>' ? signInCustomTitle : undefined;
  }

  get signUpCustomTitle() {
    const { signUpCustomTitle } = this.ssoOptions;
    return signUpCustomTitle && signUpCustomTitle !== '<p></p>' ? signUpCustomTitle : undefined;
  }

  get title() {
    if (this.$route.meta.title === 'Connexion' && this.signInCustomTitle) {
      return this.signInCustomTitle;
    }

    if (this.$route.meta.title === 'Inscription' && this.signUpCustomTitle) {
      return this.signUpCustomTitle;
    }

    return this.$route.meta.title;
  }
}
