





























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SvgPdf extends Vue {
  @Prop({
    type: [String, Number],
    required: false,
    default: 34,
  })
  readonly size!: string | number;
}
