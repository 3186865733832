

























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ErrorDialog from '@/components/Card/OfferDetailCard/ErrorDialog.vue';
import SvgTitle from '@/components/Svg/SvgTitle.vue';
import { User } from '@/services/userSpace/types';
import { sendPaymentRequestMail, executePaymentRequest } from '@/api/resources/orderApi';

const iban = require('iban');

@Component({
  components: {
    ErrorDialog,
    SvgTitle,
  },
})
export default class ExecutePaymentCard extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  readonly value!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  readonly user!: User;

  @Prop({
    type: Number,
    required: true,
  })
  readonly amount!: number;

  formValid: boolean = false;

  contactingEOI: boolean = false;

  error: string = '';

  formInfos = {
    lastname: '',
    firstname: '',
    iban: '',
    bic: '',
  };

  lastnameRules = [(v) => !!v || 'Nom requis'];

  firstnameRules = [(v) => !!v || 'Prénom requis'];

  ibanRules = [(v) => !!v || 'IBAN requis', (v) => iban.isValid(v) || 'IBAN invalide'];

  bicRules = [(v) => !!v || 'BIC requis'];

  @Watch('user')
  onUserPropLoaded(): void {
    this.formInfos.lastname = this.user.lastname ? this.user.lastname : '';
    this.formInfos.firstname = this.user.firstname ? this.user.firstname : '';
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get show() {
    return this.value;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  async validate(): Promise<void> {
    if ((this.$refs.form as any).validate()) {
      try {
        this.error = '';
        this.contactingEOI = true;
        await executePaymentRequest(`${process.env.VUE_APP_PLATFORM_UUID}_${this.user.id}`, {
          amount: this.amount,
          iban: this.formInfos.iban,
          ibic: this.formInfos.bic,
          firstname: this.formInfos.firstname,
          lastname: this.formInfos.lastname,
        });
        await sendPaymentRequestMail(this.amount);
        this.$emit('validate');
        this.contactingEOI = false;
      } catch (e) {
        this.contactingEOI = false;
        this.error = 'Une erreur est survenue, veuillez réessayer plus tard ou contacter le support.';
      }
    }
  }
}
