


































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import { Answer, Question } from '@/services/userSpace/types';

@Component({
  components: {
    SvgPopup,
  },
})
export default class QuestionDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly value!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  readonly question!: Question;

  @Prop({
    type: Number,
    required: true,
  })
  readonly points!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly stepper: number = 1;

  @Prop({
    type: String,
    required: false,
  })
  error: string = '';

  choice: Answer = { id: -1, name: '' };

  openChoice: string = '';

  openChoiceInputRule = [(v) => !!v || 'Réponse requise'];

  answer: Answer = { id: -1, name: '' };

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }

  get isConfirmDisabled() {
    if (this.question.questionTypeId === 1) {
      return this.choice.id === -1;
    }
    return this.openChoice === '';
  }

  closeDialog() {
    this.$emit('triggerQuestionDialog');
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  async onConfirmClick() {
    // if question type id = 1, answers are defined
    if (this.question.questionTypeId === 1) {
      this.answer.name = this.choice.name;
      this.answer.id = this.choice.id;

      // else, the answer is open
    } else {
      this.answer.name = this.openChoice;
    }
    this.$emit('click', this.answer);
  }

  async onCancelClick() {
    this.$emit('close');
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get sortedAnswers(): Answer[] {
    if (this.question && this.question.answers) {
      return this.question.answers.sort((a: Answer, b: Answer) => a.id - b.id);
    }
    return [];
  }
}
