var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _c(
        "v-col",
        { attrs: { cols: "10", sm: "10", md: "6", lg: "4" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                [
                  _c("v-spacer"),
                  _vm.compShowStickers
                    ? _c("div", [_c("SvgPopup")], 1)
                    : _vm._e(),
                  _c("h2", { staticClass: "title-text" }, [_vm._v("Oups !")]),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.errorMessage))
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "homeglobal_fond_bouton",
                        large: "",
                        rounded: "",
                        block: "",
                        to: "/shop"
                      }
                    },
                    [_vm._v(" Voir la boutique ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }