import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=fb90eadc&scoped=true&"
import script from "./ForgotPassword.vue?vue&type=script&lang=ts&"
export * from "./ForgotPassword.vue?vue&type=script&lang=ts&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&id=fb90eadc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb90eadc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VContainer,VDialog,VForm,VIcon,VSnackbar,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb90eadc')) {
      api.createRecord('fb90eadc', component.options)
    } else {
      api.reload('fb90eadc', component.options)
    }
    module.hot.accept("./ForgotPassword.vue?vue&type=template&id=fb90eadc&scoped=true&", function () {
      api.rerender('fb90eadc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dialogs/ForgotPassword.vue"
export default component.exports