
















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import BasicDialog from './BasicDialog.vue';

@Component({
  components: {
    BasicDialog,
  },
})
export default class Goodbye extends Vue {

  title = 'Au revoir';

  contentLine1 = 'Votre compte a bien été supprimé.';

  contentLine2 = 'À bientôt peut-être.'

  buttontText= `Retour à l'accueil`;


  @Emit('onClose')
  onClose(): void { }
}
