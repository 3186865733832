var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-results" }, [
    _c("p", [_vm._v("Vous n’avez pas encore de point.")]),
    _vm.validatedService && !_vm.isEmailConfirmed
      ? _c("p", [
          _vm._v(
            "Pour gagner vos premiers points, veuillez confirmer votre adresse mail."
          )
        ])
      : !_vm.validatedService && !_vm.isEmailConfirmed
      ? _c("p", [
          _vm._v(
            " Pour gagner vos premiers points, vous devez confirmer votre adresse email et votre compte doit être validé par nos services. "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }