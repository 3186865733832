var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        {
          staticClass: "home__first-block",
          style: _vm.compShowStickers ? null : _vm.compHeaderImage
        },
        [
          _c("HomeTitle", {
            staticClass: "home__first-block__title",
            attrs: {
              title:
                "Changez vos habitudes pour une mobilité plus durable et soyez récompensé !"
            }
          }),
          _c("HomeIntro", {
            staticClass: "home__first-block__intro",
            attrs: {
              signinTitle: "Comment ça marche ?",
              signinSubtitle: "",
              signinDescription:
                "Changez vos habitudes, déplacez-vous en transport en commun pour gagner des points !"
            }
          })
        ],
        1
      ),
      _c("HomePoints", {
        staticClass: "home__points",
        attrs: {
          pointsTitle: "Gagnez des points !",
          pointsSubtitle: "Des récompenses à la clé",
          pointsDescription:
            "À chaque trajet, cumulez des points en fonction de votre mobilité et bénéficiez d’avantages exclusifs proposés par nos partenaires !"
        }
      }),
      _c("OfferShow", {
        staticClass: "home__offers",
        attrs: {
          title: "Nos offres du moment",
          subtitle: "Dépensez vos points !"
        }
      }),
      _c("PartnersIntro", {
        staticClass: "home__partners",
        attrs: {
          title: "Nos partenaires marchands",
          subtitle: "Ils vous gâtent !",
          description:
            "Nos partenaires vous gâtent tout au long de l’année avec des avantages exclusifs ! Des offres locales et nationales, des challenges et de nombreux lots à gagner. De quoi vous motiver pour adopter une mobilité plus durable !"
        }
      }),
      false
        ? _c("ServiceProviders", { staticClass: "home__service" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }