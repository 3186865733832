

































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

import { HttpStatus } from '@/services/userSpace/types';
import { formRules, requestPasswordReset } from '../../services/userSpace/UserService';

@Component({
  components: {
    SvgPopup,
  },
})
export default class ForgotPassword extends Vue {
  isValid: boolean = false;

  isLoading: boolean = false;

  successRequest: boolean = false;

  email: string = '';

  emailRules = formRules.email;

  snackbar: boolean = false;

  snackbarText: string = '';

  isOpen: boolean = false;

  showError() {
    this.snackbarText = 'Service indisponible; Réessayez plus tard';
    this.snackbar = true;
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  async onSubmit() {
    if ((this.$refs.form as any).validate()) {
      this.isLoading = true;
      const response = await requestPasswordReset(this.email.toLowerCase(), window.location.origin);
      this.isLoading = false;

      if (response.status === HttpStatus.CREATED || response.status === HttpStatus.NOT_FOUND) {
        this.successRequest = true;
      } else {
        console.error(response);
        if (response.status === HttpStatus.INTERNAL_SERVER_ERROR || response.status === HttpStatus.SERVICE_UNAVAILABLE) {
          this.showError();
        }
      }
    }
  }

  @Emit('input')
  showDialog(bool: boolean = false) {
    this.$router.replace('/');
    return bool;
  }
}
