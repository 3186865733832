






































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'RGPDEcomotiveApp',
})
export default class RGPDEcomotiveApp extends Vue {
  mounted() {
    // Redirect to home page if the app name is not Ecomotive
    const name = process.env.VUE_APP_NAME_APP || "";
    if (name.toLowerCase() !== "ecomotive") {
      this.$router.push({ path: '/' });
    }
  }
}
