var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "line" }, [
    _c(
      "div",
      { staticClass: "line__content align-center px-2 px-md-6" },
      [
        _c("div", { staticClass: "line__content-content" }, [
          _vm._v(" " + _vm._s(_vm.companyName) + " ")
        ]),
        _c(
          "v-tooltip",
          {
            attrs: { bottom: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "span",
                      _vm._g({ staticClass: "line__content-title" }, on),
                      [_vm._v(_vm._s(_vm.title))]
                    )
                  ]
                }
              }
            ])
          },
          [_c("span", [_vm._v(_vm._s(_vm.title))])]
        ),
        _c("span", { staticClass: "line__content-points" }, [
          _vm._v(_vm._s(_vm.points) + " Pts")
        ]),
        _c("span", { staticClass: "line__content-date" }, [
          _vm._v(_vm._s(_vm.formatedDate))
        ]),
        _c("span", { staticClass: "line__content-status" }, [
          _vm._v(_vm._s(_vm.status))
        ]),
        _c(
          "span",
          {
            staticClass: "line__content-pdf",
            on: {
              click: function($event) {
                return _vm.getPDF(_vm.number, _vm.uuid)
              }
            }
          },
          [_c("svg-pdf", { staticClass: "line__content-pdf-icon" })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }