// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-34e0c708] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-34e0c708] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-34e0c708] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.background[data-v-34e0c708] {\n  fill: var(--v-stickers_secondaire-base);\n  opacity: 0.1;\n}\n.primaire[data-v-34e0c708] {\n  fill: var(--v-stickers_primaire-base);\n}\n.secondaire[data-v-34e0c708] {\n  fill: var(--v-stickers_secondaire-base);\n}\n.skin[data-v-34e0c708] {\n  fill: #d0a0a7;\n}\n.hair[data-v-34e0c708] {\n  fill: #000000;\n}\n.cls-10[data-v-34e0c708],\n.cls-9[data-v-34e0c708],\n.cls-4[data-v-34e0c708] {\n  fill: none;\n  stroke: #fff;\n  stroke-linecap: round;\n  stroke-width: 2px;\n}\n.cls-9[data-v-34e0c708] {\n  stroke-linejoin: round;\n}", ""]);
// Exports
module.exports = exports;
