var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLaPoste
    ? _c("div", { staticClass: "platform-closed" }, [
        _c("div", { staticClass: "platform-closed__header" }, [
          _c("img", {
            staticClass: "platform-closed__header__logo",
            attrs: { src: _vm.platformLogo, alt: "" }
          })
        ]),
        _c(
          "div",
          {
            staticClass: "platform-closed__wrapper",
            style: _vm.backgroundImage
          },
          [
            _c("h2", { staticClass: "platform-closed__wrapper__text" }, [
              _vm._v(
                "La période de test étant achevée, cette plateforme de fidélité La Poste Pro n’est plus disponible."
              )
            ]),
            _vm._m(0)
          ]
        )
      ])
    : _c("div", { staticClass: "maintenance-page" }, [
        _c("h1", { staticClass: "maintenance-page__title" }, [
          _vm._v("Votre site est en maintenance")
        ]),
        _vm._m(1),
        _vm._m(2)
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "platform-closed__wrapper__text" }, [
      _vm._v(" Rendez-vous sur "),
      _c(
        "a",
        {
          staticClass: "platform-closed__wrapper__text__link",
          attrs: { href: "http://www.laposte.fr/pro" }
        },
        [_vm._v("http://www.laposte.fr/pro")]
      ),
      _vm._v(
        " pour découvrir les offres et contenus à destination des professionnels. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "maintenance-page__subtitle" }, [
      _vm._v("Pas de panique, "),
      _c("br"),
      _vm._v("le service revient vite !")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "maintenance-page__svg" }, [
      _c("img", {
        staticClass: "maintenance-page__svg-icon",
        attrs: {
          src: require("@/assets/maintenance/maintenanceIcon.svg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }