var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NotificationApp" },
    [
      _vm.currentNotification
        ? _c(
            "v-snackbar",
            {
              key: _vm.currentNotification.id,
              attrs: {
                timeout: _vm.currentNotification.timeout,
                value: true,
                elevation: "24",
                color: _vm.currentNotification.type,
                top: "",
                right: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.currentNotification.message) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }