<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.751"
    height="65.472"
    viewBox="0 0 64.751 65.472"
  >
    <g
      id="prefix__ticket"
      transform="rotate(-38 -1.836 36.712)"
      style="opacity:1"
    >
      <path
        id="prefix__Tracé_2489"
        d="M98.329 38.551a4.573 4.573 0 1 1-6.468-6.468L87.779 28 64 51.779h31.1l8.231-8.231z"
        class="prefix__cls-2"
        data-name="Tracé 2489"
        transform="translate(-56.683)"
      />
      <path
        id="prefix__Tracé_2490"
        d="M64 172.231h31.1l8.227-8.231-33.839 2.744z"
        class="prefix__cls-3"
        data-name="Tracé 2490"
        transform="translate(-56.683 -120.452)"
      />
      <path
        id="prefix__Tracé_2491"
        d="M120 121.719L133.719 108l10.06 10.06-3.658 3.658z"
        class="prefix__cls-4"
        data-name="Tracé 2491"
        transform="translate(-106.281 -70.854)"
      />
      <path
        id="prefix__Tracé_2492"
        d="M144 142.975L154.914 132l7.378 7.378-3.537 3.6z"
        class="prefix__cls-5"
        data-name="Tracé 2492"
        transform="translate(-127.538 -92.111)"
      />
      <path
        id="prefix__Tracé_2493"
        d="M48.472 239.889a4.573 4.573 0 0 1 4.573-4.573V228H0v7.317a4.573 4.573 0 0 1 4.573 4.573A4.573 4.573 0 0 1 0 244.462v7.317h53.045v-7.317a4.573 4.573 0 0 1-4.573-4.573z"
        class="prefix__cls-2"
        data-name="Tracé 2493"
        transform="translate(0 -177.136)"
      />
      <path
        id="prefix__Tracé_2494"
        d="M0 251.779h53.045v-7.317a4.573 4.573 0 1 1 0-9.146V228z"
        class="prefix__cls-3"
        data-name="Tracé 2494"
        transform="translate(0 -177.136)"
      />
      <g
        id="prefix__Groupe_671"
        data-name="Groupe 671"
        transform="translate(8.231 50.864)"
      >
        <path
          id="prefix__Tracé_2495"
          d="M376 252h1.829v3.658H376z"
          class="prefix__cls-6"
          data-name="Tracé 2495"
          transform="translate(-341.246 -249.256)"
        />
        <path
          id="prefix__Tracé_2496"
          d="M376 300h1.829v3.658H376z"
          class="prefix__cls-6"
          data-name="Tracé 2496"
          transform="translate(-341.246 -291.769)"
        />
        <path
          id="prefix__Tracé_2497"
          d="M376 348h1.829v3.658H376z"
          class="prefix__cls-6"
          data-name="Tracé 2497"
          transform="translate(-341.246 -334.281)"
        />
        <path
          id="prefix__Tracé_2498"
          d="M376 396h1.829v3.658H376z"
          class="prefix__cls-6"
          data-name="Tracé 2498"
          transform="translate(-341.246 -376.794)"
        />
        <path
          id="prefix__Tracé_2499"
          d="M72 228h1.829v3.658H72z"
          class="prefix__cls-6"
          data-name="Tracé 2499"
          transform="translate(-72 -228)"
        />
        <path
          id="prefix__Tracé_2500"
          d="M72 276h1.829v3.658H72z"
          class="prefix__cls-6"
          data-name="Tracé 2500"
          transform="translate(-72 -270.513)"
        />
        <path
          id="prefix__Tracé_2501"
          d="M72 324h1.829v3.658H72z"
          class="prefix__cls-6"
          data-name="Tracé 2501"
          transform="translate(-72 -313.025)"
        />
        <path
          id="prefix__Tracé_2502"
          d="M72 372h1.829v3.658H72z"
          class="prefix__cls-6"
          data-name="Tracé 2502"
          transform="translate(-72 -355.538)"
        />
        <path
          id="prefix__Tracé_2503"
          d="M72 420h1.829v1.829H72z"
          class="prefix__cls-6"
          data-name="Tracé 2503"
          transform="translate(-72 -398.05)"
        />
      </g>
      <path
        id="prefix__Tracé_2504"
        d="M145.608 273.719v-10.975a2.744 2.744 0 0 1-2.744-2.744h-20.12a2.744 2.744 0 0 1-2.744 2.744v10.975a2.744 2.744 0 0 1 2.744 2.744h20.121a2.744 2.744 0 0 1 2.743-2.744z"
        class="prefix__cls-4"
        data-name="Tracé 2504"
        transform="translate(-106.281 -205.477)"
      />
      <path
        id="prefix__Tracé_2505"
        d="M157.95 286.67v-8.537A2.25 2.25 0 0 1 155.6 276h-17.249a2.25 2.25 0 0 1-2.351 2.134v8.537a2.25 2.25 0 0 1 2.351 2.134H155.6a2.25 2.25 0 0 1 2.35-2.135z"
        class="prefix__cls-5"
        data-name="Tracé 2505"
        transform="translate(-120.452 -219.648)"
      />
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgCultureCategory extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.prefix__cls-3 {
  fill: var(--v-stickers_secondaire-base);
}

.prefix__cls-2 {
  fill: var(--v-stickers_primaire-base);
}

.prefix__cls-4 {
  fill: var(--v-stickers_secondaire-base);
}

.prefix__cls-5 {
  fill: var(--v-stickers_primaire-base);
}

.prefix__cls-6 {
  fill: var(--v-stickers_primaire-base);
}
</style>
