// This is not a real i18n file. But the keys should remain the same anyway
import { PlatformKey, ServiceKey } from '@/constants';

export default {
  [PlatformKey.IRIGO]: {
    signUpForm: {
      newsletterLabel:
        "J'accepte de recevoir de la part de Club by Irigo les newsletters pour être informé(e) des nouvelles offres, événements et jeux concours sur le programme",
    },
  },

  [PlatformKey.BKLUB]: {
    signUpForm: {
      newsletterLabel:
        "J'accepte de recevoir de b-klub les newsletters pour être informé(e) des nouvelles offres, événements et jeux concours sur le programme",
    },
  },

  [PlatformKey.ASTUCE]: {
    signUpForm: {
      newsletterLabel: "J'accepte de recevoir les newsletters pour être informé(e) des nouvelles offres, événements et jeux concours sur le programme",
      externalAccountLabel : "Numéro de client Astuce"
    },
    signInForm: {
      connectWith: 'My Astuce',
    },
  },

  [PlatformKey.STAR]: {
    SSOSignUpDialog: {
      content: 'Créez votre compte sur star.fr puis revenez sur fidélité.star.fr pour activer le cumul de vos points.',
      signUpButton: 'Je crée mon compte STAR',
    },
    externalCGUAgreement: {
      title: 'Bienvenue',
      // USE SPACE TO AVOID CONTENT
      agreementContent: ' ',
      agreeBtnText: 'J’accepte les CGU',
    },
  },

  [ServiceKey.WEFLO]: {
    synchronization: {
      appTexts: {
        name: 'WeFlo',
        intro: `La synchronisation avec votre compte WeFlo vous permet de valoriser en points vos trajets détectés et validés sur l'application.
        <br />
        <br />
        Avec cette synchronisation, vous acceptez de partager votre solde de points avec WeFlo afin de bénéficier d’une expérience intégrée entre vos deux comptes.`,
        connect: `Connexion au compte WeFlo`,
      },
      validationTexts: {
        mailSent:
          'Un email de confirmation vient de vous être envoyé.<br />' +
          'Merci de cliquer sur le lien de confirmation pour<br/>' +
          'valider votre synchronisation.',
        synchroSuccess: 'Félicitations votre synchronisation est validée !',
        expiredToken:
          'Oups ! Votre synchronisation n’est pas validée car<br />' +
          'votre lien de validation a expiré. Veuillez<br />' +
          'synchroniser à nouveau votre application depuis la<br />' +
          'page <a href="/dashboard/profile/applications/">«Synchroniser mes app»</a>.',
        alreadySynchronized:
          'Votre synchronisation est déjà validée.<br />' +
          'Vous pouvez retrouvez vos applications ici: <a href="/dashboard/profile/applications/">«Mes applications»</a>',
        badToken: `Ce lien est invalide, veuillez ré-éssayer.`,
        unknownError: 'Une erreur inconnue est survenue, merci de réessayer plus tard.',
      },
    },
  },

  [ServiceKey.BIBUS_MTICKET]: {
    synchronization: {
      appTexts: {
        name: 'M-ticket Bibus',
        intro: `En connectant votre espace personnel à M-ticket bibus, vous acceptez de partager vos informations contenus dans cette application et ainsi
        améliorer votre expérience avec votre programme de fidélité. <br />
        Cela inclut vos trajets en transports en commun et l’achat de titres de transports.
        <br />
        <br />
        Vous pouvez à tout moment déconnecter votre compte de votre programme de fidélité.`,
        connect: `Connexion au compte M-ticket bibus`,
      },
      validationTexts: {
        mailSent:
          'Un email de confirmation vient de vous être envoyé.<br />' +
          'Merci de cliquer sur le lien de confirmation pour<br/>' +
          'valider votre synchronisation.',
        synchroSuccess: 'Félicitations votre synchronisation est validée !',
        expiredToken:
          'Oups ! Votre synchronisation n’est pas validée car<br />' +
          'votre lien de validation a expiré. Veuillez<br />' +
          'synchroniser à nouveau votre application depuis la<br />' +
          'page <a href="/dashboard/profile/applications/">«Synchroniser mes app»</a>.',
        alreadySynchronized:
          'Votre synchronisation est déjà validée.<br />' +
          'Vous pouvez retrouvez vos applications ici: <a href="/dashboard/profile/applications/">«Mes applications»</a>',
        badToken: `Ce lien est invalide, veuillez ré-éssayer.`,
        unknownError: 'Une erreur inconnue est survenue, merci de réessayer plus tard.',
      },
    },
  },

  [ServiceKey.TBM]: {
    signUpForm: {
      needExternalAccountField: {
        info: 'Merci de saisir votre code client TBM pour que nous puissions valider votre inscription.',
        label: 'Code client TBM',
      },
    },
  },

  [ServiceKey.MODALIS]: {
    signUpForm: {
      needExternalAccountField: {
        info: 'Merci de saisir votre numéro de carte Modalis pour que nous puissions valider votre inscription (10 chiffres).',
        label: 'Numéro carte Modalis',
      },
    },
  },

  [ServiceKey.IRIGO_ATOUT]: {
    synchronization: {
      appTexts: {
        name: `Carte A'Tout`,
        intro: `En connectant votre espace personnel [....] programme de fidélité.`,
        connect: `Connexion au compte A'Tout`,
      },
      validationTexts: {
        mailSent:
          'Un email de confirmation vient de vous être envoyé.<br />' +
          'Merci de cliquer sur le lien de confirmation pour<br/>' +
          'valider votre synchronisation.',
        synchroSuccess: 'Félicitations votre synchronisation est validée !',
        expiredToken:
          'Oups ! Votre synchronisation n’est pas validée car<br />' +
          'votre lien de validation a expiré. Veuillez<br />' +
          'synchroniser à nouveau votre application depuis la<br />' +
          'page <a href="/dashboard/profile/applications/">«Synchroniser mes app»</a>.',
        alreadySynchronized:
          'Votre synchronisation est déjà validée.<br />' +
          'Vous pouvez retrouvez vos applications ici: <a href="/dashboard/profile/applications/">«Mes applications»</a>',
        badToken: `Ce lien est invalide, veuillez ré-éssayer.`,
        unknownError: 'Une erreur inconnue est survenue, merci de réessayer plus tard.',
      },
    },

    signUpForm: {
      needExternalAccountField: {
        info: `Merci d'entrer votre numéro de carte A'Tout pour que nous puissions valider votre inscription. Le numéro se situe sous votre photo et est composé de 10 chiffres.`,
        label: 'Numéro de carte',
      },
    },
  },

  [ServiceKey.IRIGO_MTICKET]: {
    synchronization: {
      appTexts: {
        name: 'M-Ticket Irigo',
        intro: `En connectant votre espace personnel à M-ticket Irigo, vous acceptez de partager vos informations contenus dans cette application et ainsi
        améliorer votre expérience avec votre programme de fidélité. <br />
        Cela inclut vos trajets en transports en commun et l’achat de titres de transports.
        <br />
        <br />
        Vous pouvez à tout moment déconnecter votre compte de votre programme de fidélité.`,
        connect: `Connexion au compte M-ticket Irigo`,
      },
      validationTexts: {
        mailSent:
          'Un email de confirmation vient de vous être envoyé.<br />' +
          'Merci de cliquer sur le lien de confirmation pour<br/>' +
          'valider votre synchronisation.',
        synchroSuccess: 'Félicitations votre synchronisation est validée !',
        expiredToken:
          'Oups ! Votre synchronisation n’est pas validée car<br />' +
          'votre lien de validation a expiré. Veuillez<br />' +
          'synchroniser à nouveau votre application depuis la<br />' +
          'page <a href="/dashboard/profile/applications/">«Synchroniser mes app»</a>.',
        alreadySynchronized:
          'Votre synchronisation est déjà validée.<br />' +
          'Vous pouvez retrouvez vos applications ici: <a href="/dashboard/profile/applications/">«Mes applications»</a>',
        badToken: `Ce lien est invalide, veuillez ré-éssayer.`,
        unknownError: 'Une erreur inconnue est survenue, merci de réessayer plus tard.',
      },
    },
  },

  [ServiceKey.KORRIGO_BREST]: {
    signUpForm: {
      needExternalAccountField: {
        error: 'Numéro client requis',
      },
    },
  },

  // ======== FALLBACK =========== //

  signUpForm: {
    newsletterLabel:
      "J'accepte de recevoir de Transway les newsletters pour être informé(e) des nouvelles offres, événements et jeux concours sur le programme",
    needExternalAccountField: {
      info: 'Merci de rentrer votre numéro client pour que nous puissions valider votre inscription',
      label: 'Numéro client',
      error: 'Numéro de compte requis',
    },
  },
  signInForm: {
    connectWith: 'connexion auto',
  },
};
