












import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BasicDialog from './BasicDialog.vue';

@Component({
  components: {
    BasicDialog,
  },
})
export default class ReactivateAccount extends Vue {

  @Prop({type: Number, required: true})
  numberOfDays?: number;


  title = 'Compte désactivé';

  paragraph1 = `Votre compte a été désactivé et sera définitivement supprimé dans ${this.numberOfDays} jours.`;

  paragraph2 = 'Vous pouvez toutefois le réactiver en cliquant sur le bouton ci-dessous ou en vous reconnectant avant sa suppression définitive. Vos identifiants de connexion restent les mêmes.'

  buttontText = 'Réactiver mon compte';

  @Emit('onClose')
  onClose(): void {
  }

  @Emit('onReactivation')
  onReactivation(): void {
  }
}
