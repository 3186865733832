var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cashback" },
    [
      _c("PageTitle", { attrs: { text: "Cashback" } }),
      _c("div", { staticClass: "cashback-avatar mt-4" }, [
        _c(
          "div",
          { staticClass: "mb-md-4" },
          [
            _c("Avatar", {
              attrs: {
                price: _vm.compPrice,
                size: _vm.isMobile ? "medium" : "large"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "cashback-info" },
        [
          _c(
            "v-btn",
            {
              staticClass: "cashback-info__btn",
              attrs: {
                disabled: _vm.priceAvailable < 20,
                color: "homeglobal_fond_bouton",
                large: ""
              },
              on: { click: _vm.claimCashback }
            },
            [_vm._v(" Recevoir ")]
          ),
          _c("span", { staticClass: "cashback-info__text" }, [
            _vm._v(
              "Vous trouverez ci-dessous vos différentes transactions ainsi que vos ordres de paiement."
            )
          ]),
          _c("span", { staticClass: "cashback-info__text" }, [
            _vm._v(
              "Sachez que pour récupérer votre cashback vous devez avoir un minimum de 20€ dans votre cagnotte."
            )
          ])
        ],
        1
      ),
      _c("ExecutePaymentCard", {
        attrs: { amount: _vm.priceAvailable, user: _vm.user },
        on: { validate: _vm.onValidate },
        model: {
          value: _vm.showExecutePaymentCard,
          callback: function($$v) {
            _vm.showExecutePaymentCard = $$v
          },
          expression: "showExecutePaymentCard"
        }
      }),
      _c("PaymentValidatedCard", {
        on: { accept: _vm.onProfileReturn },
        model: {
          value: _vm.showPaymentValidatedCard,
          callback: function($$v) {
            _vm.showPaymentValidatedCard = $$v
          },
          expression: "showPaymentValidatedCard"
        }
      }),
      !_vm.transactionLoaded && !_vm.paymentLoaded
        ? _c("v-progress-circular", {
            staticClass: "cashback-loader",
            attrs: {
              size: 70,
              width: 7,
              color: "homeglobal_picto",
              indeterminate: ""
            }
          })
        : _c(
            "div",
            { staticClass: "cashback-data" },
            [
              _c("CashbackTransaction", {
                attrs: { transactions: _vm.transactions }
              }),
              _c("CashbackPayOrder", { attrs: { payments: _vm.payments } })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }