















































































import { Component, Vue } from 'vue-property-decorator';
import ProfileBanner from '@/components/Account/ProfileBanner.vue';
import PageTitle from '@/components/PageTitle.vue';
import * as UserService from '@/services/userSpace/UserService';
import { User } from '@/services/userSpace/types';

@Component({
  components: {
    ProfileBanner,
    PageTitle,
  },
})
export default class Documentation extends Vue {
  user: User = {
    id: -1,
    email: '',
    password: '',
    externalAccountId: '',
    firstname: '',
    lastname: '',
    userServices: [],
  };

  async mounted() {
    this.user = await UserService.getCurrentUser();
  }

  navigateToGCU(): void {
    this.$router.push({ name: 'gcu-legal-notice' });
  }

  navigateToGamesRegulation(): void {
    this.$router.push({ name: 'gcu-user-route' });
  }

  navigateToPrivacyPolicy(): void {
    this.$router.push({ name: 'gcu-rgpd' });
  }

  navigateToDeleteAccount(): void {
    this.$router.push({ name: 'DeleteAccount' });
  }
}
