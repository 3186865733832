var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SSOPairingModeSelection" },
    [
      _c("SSOAccountOptionsTitle", [
        _vm._v("Avez vous un compte " + _vm._s(_vm.platform.name) + " ?")
      ]),
      _c(
        "router-link",
        {
          staticClass: "text-decoration-none",
          attrs: {
            to: { name: "sso:bind_existing_account", query: _vm.$route.query }
          }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-actif",
              attrs: { block: "", dark: "", depressed: "" }
            },
            [_vm._v(" J'ai un compte " + _vm._s(_vm.platform.name) + " ")]
          )
        ],
        1
      ),
      _c(
        "router-link",
        {
          staticClass: "text-decoration-none",
          attrs: { to: { name: "sso:pairing", query: _vm.$route.query } }
        },
        [
          _c("v-btn", { attrs: { block: "", depressed: "" } }, [
            _vm._v(" Je n'ai pas de compte " + _vm._s(_vm.platform.name) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }