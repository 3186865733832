












import { Vue, Component } from 'vue-property-decorator';
import OrdersDatatable from '@/components/Account/OrdersDatatable.vue';
import OrdersMobile from '@/components/Account/OrdersMobile.vue';
import PageTitle from '@/components/PageTitle.vue';

import * as OrderService from '@/services/merchantSpace/OrderService';

@Component({
  components: {
    OrdersDatatable,
    OrdersMobile,
    PageTitle,
  },
})
export default class OrdresProfile extends Vue {}
