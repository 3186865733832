
























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SSOPartnerErrorDialog extends Vue {
  @Prop({ type: String, required: false }) urlRedirection!: string;

  @Prop({ type: String, required: false }) customMessage?: string;

  @Prop({ type: String, required: false }) buttonText!: string;

  onVisibilityChange(isVisible) {
    if (!isVisible) {
      this.$emit('close');
    }
  }
}
