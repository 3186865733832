
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Avatar extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '52px',
  })
  readonly size!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly selectedImageUrl!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly initial!: string;
}
