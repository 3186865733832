


































































import {Vue, Component, Emit} from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import * as ServiceService from '@/services/merchantSpace/ServiceService';
import RetailScalePoints from '@/components/Dialogs/RetailScalePoints.vue';
import ScalePointDatabase from '@/views/account/ScalePointsDatatable.vue'

const moment = require('moment');

@Component({
  components: {
    PageTitle,
    RetailScalePoints,
    ScalePointDatabase,
  },
})

export default class ScalePoints extends Vue {
  uuidPlatform!: string;

  services: any = {};

  scalePoints: any[] = [];

  frequency = '';

  actionCreditSelected = null;

  async mounted() {
    await this.createData();
  }

  filterActionCreditList(actionCreditList) {
    return actionCreditList.filter((actionCredit) => {
      return !actionCredit.isRevoked && actionCredit.actionCategory.id !== 8;
    });
  }


  async createData() {
    this.services = await ServiceService.getActions();
    this.services.forEach((element) => {
      element.actionCreditList.sort((a, b) => a.position - b.position);
    });
  }

  textualization(actionCredit) {
    const {
      limit,
      value,
      frequency: {id: frequencyId},
      targetAudience: {id: targetAudienceId},
      actionCategory: {id: actionCategoryId, labelFr: actionCategoryLabel},
      ...others
    } = actionCredit;

    let text = '';
    let textLimit = '';
    let textFrequency = '';
    let textTargetAudience = '';
    let textPoint = '';

    if (targetAudienceId === 2) {
      textTargetAudience = ' pro';
    } else {
      textTargetAudience = '';
    }

    if (value < 2) {
      textPoint = ' point';
    } else {
      textPoint = 'points';
    }

    const textStart = `Gagnez ${value} ${textPoint}${textTargetAudience}`;

    switch (frequencyId) {
      case 1:
        textFrequency = '';
        break;
      case 2:
        textFrequency = ' par jour';
        break;
      case 3:
        textFrequency = ' par semaine';
        break;
      case 4:
        textFrequency = ' par mois';
        break;
      case 5:
        textFrequency = ' par an';
        break;
      default:
        textFrequency = '';
    }

    if (limit === 0 || (limit === 1 && frequencyId === 1)) {
      textLimit = ``;
    } else if (limit > 1 && frequencyId === 1) {
      textLimit = `, dans la limite de ${limit} fois`;
    } else if (limit === 1 && frequencyId !== 1) {
      textLimit = `, dans la limite de 1 fois${textFrequency}`;
    } else {
      textLimit = `, dans la limite de ${limit} fois${textFrequency}`;
    }

    switch (actionCategoryId) {
      case 1:
        text = `${textStart} pour la validation de votre email${textLimit}.`;
        break;
      case 2:
        text = `${textStart} pour votre ${actionCategoryLabel}${textLimit}.`;
        break;
      case 3:
        text = `${textStart} par ${actionCategoryLabel}${textLimit}.`;
        break;
      case 4:
        text = `${textStart} par ${actionCategoryLabel}${textLimit}.`;
        break;
      case 5:
        text = `${textStart} pour l'${actionCategoryLabel} standard${textLimit}.`;
        break;
      case 6:
        text = `${textStart} pour la ${actionCategoryLabel}${textLimit}.`;
        break;
      case 7:
        text = `${textStart} pour votre inscription avec un code parrainage${textLimit}.`;
        break;
      default:
        text = `${textStart} par ${actionCategoryLabel}${textLimit}.`;
    }
    return text;
  }

  formatedDate(date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  formatedHour(hour): string {
    const tableau = hour.split(':');
    const popedTab = tableau.pop();
    const hours = tableau.join('h');
    return hours;
  }

  formatedDays(days: number[]) {
    const matchDay = {
      0: 'lundi',
      1: 'mardi',
      2: 'mercredi',
      3: 'jeudi',
      4: 'vendredi',
      5: 'samedi',
      6: 'dimanche',
    };

    return days.map((day) => matchDay[day]).join(', ');
  }
}
