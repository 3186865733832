







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Errors, PlatformKey } from '@/constants';
import { startCodeFlow } from '@/services/authSpace/sso.service';

const descriptionErrors = {
  [Errors.SSO_LOGIN_REQUIRED]: `Nous ne parvenons pas à vous identifier... <br/>veuillez vous connecter et réessayer ultérieurement.`,
  default: `Mais pas de panique… <br />toute l’équipe est en action pour vous permettre de profiter au plus vite du service.`,
};

@Component
export default class Error extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  reason!: string;

  SSO_LOGIN_REQUIRED_ERROR = Errors.SSO_LOGIN_REQUIRED;

  displayView: boolean = true;

  // ===== lifecyle ================
  created() {
    // Ecobonus souhaite forcer une nouvelle tentative de connexion par formulaire
    // lorsque la connexion sans prompt n'a pas fonctionné.
    if ([PlatformKey.ECOBONUS, PlatformKey.TBM, PlatformKey.ASTUCE].includes(this.platform.key) && this.reason === this.SSO_LOGIN_REQUIRED_ERROR) {
      this.displayView = false;
      startCodeFlow({ prompt: 'login' });
    }
  }

  // ===== computed ================

  get description() {
    return descriptionErrors[this.reason || ''] || descriptionErrors.default;
  }

  get landingPath() {
    return this.platform.config.landingPath;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  // ===== methods ================
  ssoConnect() {
    startCodeFlow();
  }
}
