var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-line" }, [
    _c("div", { staticClass: "order-line__header" }, [
      _c("h2", { staticClass: "order-line__header-number" }, [
        _vm._v("COMMANDE N° " + _vm._s(_vm.number))
      ]),
      _c("h3", { staticClass: "order-line__header-date" }, [
        _vm._v("DATE D'ACHAT : " + _vm._s(_vm.formatedDate))
      ])
    ]),
    _c(
      "div",
      { staticClass: "order-line__content" },
      [
        _c("div", { staticClass: "order-line__content-content" }, [
          _vm._v(" " + _vm._s(_vm.companyName) + " ")
        ]),
        _c("span", { staticClass: "order-line__content-points" }, [
          _vm._v(_vm._s(_vm.points) + " Pts")
        ]),
        _c("span", { staticClass: "order-line__content-title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _c(
          "v-btn",
          {
            staticClass: "order-line__content-pdf",
            attrs: { icon: "", small: "", plain: "" },
            on: {
              click: function($event) {
                return _vm.getPDF(_vm.number, _vm.uuid)
              }
            }
          },
          [_c("svg-pdf")],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "order-line__subcontent" }, [
      _c("span", { staticClass: "order-line__subcontent-status" }, [
        _vm._v(_vm._s(_vm.status))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }