var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "providers" },
    [
      _c("div", { staticClass: "providers-header" }, [
        _c(
          "div",
          { staticClass: "providers-header__svg" },
          [_c("SvgHomeServiceProvidersPicto")],
          1
        ),
        _vm._m(0)
      ]),
      _c(
        "div",
        { staticClass: "providers-logos" },
        _vm._l(_vm.providersLogo, function(logo) {
          return _c(
            "div",
            { key: logo, staticClass: "providers-logos__block" },
            [
              _c("img", {
                staticStyle: { width: "90%" },
                attrs: { src: logo, alt: "" }
              })
            ]
          )
        }),
        0
      ),
      _c(
        "v-btn",
        {
          staticClass: "providers-btn",
          attrs: {
            href: "mailto:" + _vm.contactEmail,
            target: "_blank",
            rounded: "",
            large: "",
            color: "homeglobal_fond_bouton"
          }
        },
        [_vm._v(" Devenir fournisseur ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "providers-header-title" }, [
      _c("h1", { staticClass: "providers-header-title__title" }, [
        _vm._v(" Nos fournisseurs"),
        _c("br"),
        _vm._v(" de services ")
      ]),
      _c("h2", { staticClass: "providers-header-title__subtitle" }, [
        _vm._v("Synchronisez vos"),
        _c("br"),
        _vm._v("fournisseurs d'écomobilité")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }