











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgHomePartnerPicto from '@/components/Home/SvgHomePartnerPicto.vue';
import SvgHomePartnerPictoShopping from '@/components/Home/SvgHomePartnerPictoShopping.vue';
import { getMerchantHome } from '@/api/resources/platformApi';

@Component({
  components: {
    SvgHomePartnerPicto,
    SvgHomePartnerPictoShopping,
  },
})
export default class PartnersIntro extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly subtitle!: string;

  @Prop({ type: String, required: true })
  readonly description!: string;

  @Prop({ type: Boolean, default: false })
  readonly noPartnersLogo!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noPartnersInducement!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly noPartnersButton!: boolean;

  @Prop({ type: String, default: 'store' })
  readonly typeImg!: string;

  @Prop({ type: String, default: null })
  readonly srcImg!: string;

  @Prop({ type: String, default: 'Devenir partenaire' })
  readonly btnPartnerText!: string;

  @Prop({ type: String, default: null })
  readonly redirectUrl!: string;

  @Prop({ type: Boolean, default: false })
  readonly bigLine!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly applyRadius!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly squareImage!: boolean;

  @Prop({type: Boolean, default: false})
  readonly leftButton!: boolean;

  partnersLogo: any[] = [
    { img: require('@/assets/partners/cheerz.png'), alt: 'cheerz' },
    { img: require('@/assets/partners/foodette.png'), alt: 'foodette' },
    { img: require('@/assets/partners/caliceo.svg'), alt: 'caliceo' },
  ];

  customPathMerchant: any = null;

  get thirdBlockImage() {
    return this.$store.getters['platform/getPlatform'].style.thirdBlockImage;
  }

  get compThirdBlockImage() {
    return `${process.env.VUE_APP_API_URL}/api${this.thirdBlockImage}`;
  }

  get merchantUrl(): string {
    if (process.env.VUE_APP_MERCHANT_URL) {
      return `${process.env.VUE_APP_MERCHANT_URL}${this.customPathMerchant}`;
    }
    return '#';
  }

  get isWeb() {
    return this.$vuetify.breakpoint.mdAndUp ? 'web' : '';
  }

  get titleClass() {
    return this.bigLine ? 'partners__header-title-text partners__header-title-text--bigline' : 'partners__header-title-text';
  }

  get radiusClass() {
    return this.applyRadius ? 'partners__header-img-place  partners__header-img-place--radius' : 'partners__header-img-place';
  }

  get imageSquareStyle() {
    return this.squareImage
      ? {
          width: '120%',
          height: '100%',
          'object-fit': 'cover',
        }
      : {
          width: '100%',
          'margin-right': '1rem',
        };
  }

  async mounted() {
    this.customPathMerchant = (await getMerchantHome()).path;
  }
}
