


































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgAvatar from '@/components/Svg/SvgAvatar.vue';

@Component({
  components: {
    SvgAvatar,
  },
})
export default class Avatar extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly initials!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly price!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly size!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
