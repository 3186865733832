

















































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

import SvgSignup from '@/components/Svg/SvgSignup.vue';
import SvgSignupStrip from '@/components/Svg/SvgSignupStrip.vue';

@Component({
  components: {
    SvgSignup,
    SvgSignupStrip,
  },
})
export default class SignupMessage extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  logo!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  title!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  desc!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  showSignin() {
    this.$store.dispatch('showLogin', true);
  }

  @Emit('showSignup')
  showSignup() {}
}
