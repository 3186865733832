



















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import PageTitle from '@/components/PageTitle.vue';
import ApplicationCard from '@/components/Account/ApplicationCard.vue';
import { synchronizeApplication, formRules, unsynchronizeApplication, getAppSyncText } from '@/services/userSpace/UserService';
import { getServiceConfig } from '@/configs';
import { Service, UserServiceProduct } from '@/services/merchantSpace/types';

@Component({
  components: {
    ApplicationCard,
    PageTitle,
  },
})
export default class ApplicationSync extends Vue {
  @Prop({ type: Object, required: true })
  service!: Service;

  @Prop({ type: String, required: false })
  appLogo?: string;

  @Prop({ type: Object, required: false })
  product?: UserServiceProduct;

  loading = false;

  error = '';

  form = {
    email: undefined,
    externalAccountId: undefined,
  };

  formRules: Object = formRules;

  async synchronize() {
    if (!(this.$refs.form as any).validate()) {
      return Promise.resolve();
    }

    this.loading = true;
    const externalValue = this.form[this.synchronizeBy];
    try {
      await synchronizeApplication(this.service, this.synchronizeBy, externalValue || '');
      this.$router.push(`${this.$router.currentRoute.path}/mailSent`);
    } catch (error) {
      const { data } = error.response || ({} as any);

      let errorMessage = 'Service not available';
      if (data && data.message) {
        if (typeof data.message === 'object') {
          errorMessage = data.message.fr;
        } else if (typeof data.message === 'string') {
          errorMessage = data.message;
        }
      }

      this.error = errorMessage || 'Oops! Something went wrong';
    } finally {
      this.loading = false;
    }

    return undefined;
  }

  async unsynchronize() {
    const result = await unsynchronizeApplication(this.service);
    if (result.status) {
      this.error = result.data.message.fr;
    } else {
      this.$router.push(`/dashboard/profile/applications`);
    }
  }

  get synced(): boolean {
    return !!this.product;
  }

  get synchronizeBy(): 'email' | 'externalAccountId' {
    return getServiceConfig(this.service.key, 'synchronizeBy');
  }

  get formattedProductCreatedAt() {
    return this.product ? moment(this.product.createdAt).format('DD MMMM YYYY') : '';
  }

  get appText() {
    return getAppSyncText(this.service.key).appTexts;
  }
}
