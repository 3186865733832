import { render, staticRenderFns } from "./ShopSlider.vue?vue&type=template&id=9d7a6360&scoped=true&"
import script from "./ShopSlider.vue?vue&type=script&lang=ts&"
export * from "./ShopSlider.vue?vue&type=script&lang=ts&"
import style0 from "./ShopSlider.vue?vue&type=style&index=0&id=9d7a6360&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d7a6360",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9d7a6360')) {
      api.createRecord('9d7a6360', component.options)
    } else {
      api.reload('9d7a6360', component.options)
    }
    module.hot.accept("./ShopSlider.vue?vue&type=template&id=9d7a6360&scoped=true&", function () {
      api.rerender('9d7a6360', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Shop/ShopSlider.vue"
export default component.exports