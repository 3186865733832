var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "100",
        height: "100",
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0 2)",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("circle", {
            attrs: {
              fill: "var(--v-stickers_secondaire-base)",
              "fill-rule": "nonzero",
              cx: "14.1",
              cy: "9.9",
              r: "9.9"
            }
          }),
          _c("circle", {
            attrs: {
              fill: "var(--v-stickers_primaire-base)",
              "fill-rule": "nonzero",
              cx: "6.8",
              cy: "13",
              r: "6.8"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M7.852 12.701l8.75-5.928m-8.945 4.65l7.84-5.312m-6.295 7.298l7.84-5.312m-6.516 5.975l6.487-4.396m-9.356.203l6.487-4.396",
              stroke: "#ffffff",
              "stroke-width": "0.5",
              "stroke-linecap": "round"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }