

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import BalancePointsCard from '@/components/Card/BalancePointsCard.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import { Order, Offer } from '@/services/merchantSpace/types';

const moment = require('moment');

moment.locale('fr');

@Component({
  components: { BalancePointsCard },
})
export default class OrderConfirmationCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly offer!: Offer;

  @Prop({ type: Array, required: true })
  readonly order!: Order[];

  @Prop({ type: Number, required: true })
  readonly pointsBefore!: number;

  @Prop({ type: Number, required: true })
  readonly pointsAfter!: number;

  trackingUrl: string = '';

  async mounted() {
    if (this.order[0]) {
      if (this.order[0].trackingUrl) this.trackingUrl = this.order[0].trackingUrl;
    }
  }

  get beforeTitleText(): string {
    if (this.isFundingOffer) {
      return `Solde de points avant contribution`;
    }
    return this.isStandardOffer ? 'Solde de points avant achat' : 'Solde de points avant participation';
  }

  get afterTitleText(): string {
    if (this.isFundingOffer) {
      return `Solde de points après contribution`;
    }
    return this.isStandardOffer ? 'Solde de points après achat' : 'Solde de points après participation';
  }

  get isStandardOffer(): boolean {
    return OfferService.isStandardOffer(this.offer.offerTypeId);
  }

  get isFundingOffer(): boolean {
    return OfferService.isFundingOffer(this.offer.offerTypeId);
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.offer.offerTypeId);
  }

  get drawDate(): string {
    const date = moment(this.offer.drawAt).format('D MMMM YYYY');
    return date;
  }
}
