var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-points", attrs: { color: "popup_texte" } },
    [
      _c("v-card-text", { staticClass: "card-points-title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c("v-card-text", { staticClass: "card-points-points" }, [
        _vm._v(" " + _vm._s(_vm.points) + " points ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }