var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BasicDialog",
    {
      attrs: { "button-text": _vm.buttontText, title: _vm.title },
      on: { onClose: _vm.onClose, onSubmit: _vm.onReactivation }
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.paragraph1))]),
      _c("p", [_vm._v(_vm._s(_vm.paragraph2))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }