var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "documentation-wrapper" },
    [
      _c("PageTitle", {
        staticClass: "d-md-flex",
        attrs: { text: "Confidentialité" }
      }),
      _c("ProfileBanner", {
        attrs: { firstname: _vm.user.firstname, lastname: _vm.user.lastname }
      }),
      _c(
        "v-list",
        { staticClass: "linksList mb-md-12" },
        [
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToGCU()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Mentions légales")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "homeglobal_picto", "x-large": "" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToGamesRegulation()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Parcours utilisateurs")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "homeglobal_picto", "x-large": "" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToPrivacyPolicy()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Données personnelles - RGPD")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "homeglobal_picto", "x-large": "" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "listItem",
              on: {
                click: function($event) {
                  return _vm.navigateToDeleteAccount()
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "listItemText" }, [
                    _vm._v("Supprimer mon compte")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "homeglobal_picto", "x-large": "" } },
                    [_vm._v(" fas fa-angle-right ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }