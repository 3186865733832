var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "newcgu",
        "max-width": "600px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "newcgu__card", attrs: { "data-cy": "cgu-dialog" } },
        [
          _c("v-card-title", { staticClass: "newcgu__title" }, [
            _c(
              "h2",
              { staticClass: "newcgu__title-text" },
              [
                _vm._v(" Nos "),
                _c(
                  "router-link",
                  {
                    staticClass: "newcgu__title-text",
                    attrs: { to: _vm.GCURouter },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [_vm._v(" CGU ")]
                ),
                _vm._v(" ont évolué ! ")
              ],
              1
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "newcgu__content" },
            [
              _c("span", {
                staticClass: "newcgu__description",
                domProps: { innerHTML: _vm._s(_vm.consent.changeDescription) }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "newcgu-button",
                  attrs: {
                    large: "",
                    color: "popup_fond_bouton",
                    rounded: "",
                    "data-cy": "cgu-dialog-accept-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("signNewCgu")
                    }
                  }
                },
                [_vm._v(" Accepter les CGU ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }