var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-question" },
    [_c("SvgQuestion", { staticClass: "dashboard-question__picto" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }