var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h2", { staticClass: "title" }, [
              _vm._v("Synchronisation de mon compte")
            ])
          ]),
          !_vm.showTimeOutMessage && !_vm.showErrorMessage
            ? _c("v-card-text", [
                _c("p", { staticClass: "content" }, [
                  _vm._v("Synchronisation en cours, merci de patienter...")
                ])
              ])
            : _c("v-card-text", [
                _c("p", { staticClass: "content" }, [
                  _vm._v(
                    "Veuillez recommencer le processus de synchronisation ou contacter notre support."
                  )
                ])
              ]),
          _vm.showTimeOutMessage
            ? _c("div", { staticClass: "content" }, [
                _vm._v(" Votre lien a expiré, merci de vous reconnecter. "),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      { staticClass: "btn", on: { click: _vm.reconnect } },
                      [_vm._v(" Connexion ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.showErrorMessage
            ? _c(
                "v-alert",
                {
                  staticClass: "mb-0 mt-6",
                  attrs: { color: "red lighten-2", dark: "", dense: "" }
                },
                [
                  _vm._v(
                    " Une erreur est survenue lors de la synchronisation. "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }