var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "countdown", attrs: { "data-cy": "luck-offer-countdown" } },
    [
      _c("span", [_vm._v("L'offre se termine dans :")]),
      _c("span", [_vm._v(_vm._s(_vm.luckOfferCountdown))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }