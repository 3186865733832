<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="43.974"
    viewBox="0 0 48 43.974"
  >
    <g
      id="prefix__food-and-restaurant"
      transform="translate(0 -21.472)"
      style="opacity:1"
    >
      <path
        id="prefix__Tracé_2470"
        d="M71.4 271.442a3.138 3.138 0 0 1-3.139 3.139H31.939a3.139 3.139 0 1 1 0-6.278h36.322a3.136 3.136 0 0 1 3.139 3.139z"
        class="prefix__cls-1"
        data-name="Tracé 2470"
        transform="translate(-26.1 -223.69)"
      />
      <path
        id="prefix__Tracé_2471"
        d="M73.836 270.142a3.144 3.144 0 0 1-2.858 1.837H34.656a1.915 1.915 0 1 1 0-3.676h36.322a3.14 3.14 0 0 1 2.858 1.839z"
        class="prefix__cls-1"
        data-name="Tracé 2471"
        transform="translate(-28.817 -223.691)"
      />
      <path
        id="prefix__Tracé_2472"
        d="M48 333.07a10.368 10.368 0 0 1-4.9 9.044c-3.028 1.995-7.21 2.977-11.83 2.977H16.729C7.49 345.091 0 341.16 0 333.07a2.69 2.69 0 0 1 .315-1.263 3.044 3.044 0 0 1 .7-.875 3.7 3.7 0 0 1 2.442-.886h41.089a3.512 3.512 0 0 1 3.139 1.761A2.692 2.692 0 0 1 48 333.07z"
        class="prefix__cls-1"
        data-name="Tracé 2472"
        transform="translate(0 -279.645)"
      />
      <path
        id="prefix__Tracé_2473"
        d="M50.732 331.808a10.382 10.382 0 0 1-4.584 6.306c-3.028 1.995-7.21 2.977-11.83 2.977H19.776c-8.128 0-14.9-3.043-16.414-9.283a3.045 3.045 0 0 1 .7-.875 3.7 3.7 0 0 1 2.442-.886h41.089a3.512 3.512 0 0 1 3.139 1.761z"
        class="prefix__cls-2"
        data-name="Tracé 2473"
        transform="translate(-3.047 -279.646)"
      />
      <g
        id="prefix__Composant_104_1"
        data-name="Composant 104 – 1"
        transform="translate(14.857 43.296)"
      >
        <path
          id="prefix__Tracé_2474"
          d="M180.969 254.252l-8.121 8.121a4.419 4.419 0 0 1-6.25 0l-8.121-8.121z"
          class="prefix__cls-3"
          data-name="Tracé 2474"
          transform="translate(-158.478 -254.252)"
        />
      </g>
      <path
        id="prefix__Tracé_2475"
        d="M223.728 254.252l-8.121 8.121a4.442 4.442 0 0 1-.652.538 4.384 4.384 0 0 1-1.7-1.058l-7.6-7.6z"
        class="prefix__cls-3"
        data-name="Tracé 2475"
        transform="translate(-186.379 -210.956)"
      />
      <path
        id="prefix__Tracé_2476"
        d="M55.895 236.444a3.8 3.8 0 0 1-.222 1.285c-.6 1.664-2.193 2.31-3.437 1.391l-1.492-1.1a2.1 2.1 0 0 0-3.123.742l-.063.1a2.109 2.109 0 0 1-3.013.818l-3.345-2.187a2 2 0 0 0-2.425.145l-2.675 2.179a2 2 0 0 1-2.538.067l-3.054-2.264a2 2 0 0 0-2.515.049l-2.781 2.208a2 2 0 0 1-2.457.091l-3.6-2.5a1.984 1.984 0 0 0-2.28-.036l-3.359 2.2a3.5 3.5 0 0 1-3.325-1.354 3.748 3.748 0 0 1-.3-2.961 2.506 2.506 0 0 1 2.264-1.959h41.3a2.516 2.516 0 0 1 2.263 1.954 3.779 3.779 0 0 1 .177 1.132z"
        class="prefix__cls-3"
        data-name="Tracé 2476"
        transform="translate(-8.806 -192.016)"
      />
      <path
        id="prefix__Tracé_2477"
        d="M57.4 235.3c-.015.049-.031.1-.049.145-.6 1.665-2.193 2.311-3.437 1.392l-1.492-1.1a2.105 2.105 0 0 0-3.123.742l-.063.1a2.109 2.109 0 0 1-3.013.818l-3.341-2.185a2 2 0 0 0-2.425.145l-2.678 2.178a2 2 0 0 1-2.538.066l-3.054-2.264a2 2 0 0 0-2.515.049L26.9 237.6a2 2 0 0 1-2.457.091l-3.6-2.5a1.981 1.981 0 0 0-2.28-.036l-3.359 2.2a3.5 3.5 0 0 1-3.325-1.354 3.491 3.491 0 0 1-.3-.683 2.506 2.506 0 0 1 2.264-1.959h41.3A2.517 2.517 0 0 1 57.4 235.3z"
        class="prefix__cls-3"
        data-name="Tracé 2477"
        transform="translate(-10.49 -192.014)"
      />
      <path
        id="prefix__Tracé_2478"
        d="M63.105 182.142a3.14 3.14 0 0 1-3.146 3.211c-5.64 0-20.663-.127-22.929-.052h-.151c-2.861.108-3.513.567-3.513 2.092v5.581a1.039 1.039 0 0 1-1.039 1.04 1.04 1.04 0 0 0-1.04 1.04v2.431a2.991 2.991 0 0 1-.086.716 3.037 3.037 0 0 1-2.961 2.316h-.077a2.958 2.958 0 0 1-2.958-2.967l.014-4.4a1.226 1.226 0 0 1 1.227-1.223 1.227 1.227 0 0 0 1.227-1.227v-3.3a2.045 2.045 0 0 0-2.045-2.045H21.8a3.187 3.187 0 0 1-3.207-3.082 3.148 3.148 0 0 1 3.147-3.211H59.9a3.187 3.187 0 0 1 3.205 3.08z"
        class="prefix__cls-2"
        data-name="Tracé 2478"
        transform="translate(-16.85 -142.814)"
      />
      <path
        id="prefix__Tracé_2479"
        d="M97.063 182.142a3.169 3.169 0 0 1-.12.934c-5.971-.006-20.238-.124-22.442-.052-2.99.1-3.664.546-3.664 2.1v.184c-2.861.108-3.513.567-3.513 2.092v5.581a1.039 1.039 0 0 1-1.039 1.04 1.04 1.04 0 0 0-1.04 1.04v2.431a2.991 2.991 0 0 1-.086.716 2.958 2.958 0 0 1-2.482-2.929l.014-4.4a1.226 1.226 0 0 1 1.227-1.223 1.227 1.227 0 0 0 1.227-1.227v-3.307a2.045 2.045 0 0 0-2.045-2.045h-3.829a3.187 3.187 0 0 1-3.207-3.082 3.146 3.146 0 0 1 .121-.935h37.669a3.187 3.187 0 0 1 3.209 3.082z"
        class="prefix__cls-2"
        data-name="Tracé 2479"
        transform="translate(-50.808 -142.814)"
      />
      <path
        id="prefix__Tracé_2480"
        d="M82.068 144.94l-6.962 6.965a6.193 6.193 0 0 1-8.759 0l-6.965-6.965z"
        class="prefix__cls-3"
        data-name="Tracé 2480"
        transform="translate(-53.814 -111.892)"
      />
      <path
        id="prefix__Tracé_2481"
        d="M120.42 144.94l-6.962 6.965a6.146 6.146 0 0 1-2.4 1.488 6.139 6.139 0 0 1-2.395-1.488l-6.963-6.965z"
        class="prefix__cls-3"
        data-name="Tracé 2481"
        transform="translate(-92.167 -111.892)"
      />
      <path
        id="prefix__Tracé_2482"
        d="M48 34.027v.153a2.591 2.591 0 0 1-2.588 2.44H2.593A2.593 2.593 0 0 1 0 34.027a8.975 8.975 0 0 1 3.615-7.205 20.038 20.038 0 0 1 6.371-3.244A51.2 51.2 0 0 1 24 21.472s24-.129 24 12.555z"
        class="prefix__cls-1"
        data-name="Tracé 2482"
      />
      <path
        id="prefix__Tracé_2483"
        d="M98.937 34.027v.153H58.8a2.593 2.593 0 0 1-2.593-2.593 8.975 8.975 0 0 1 3.615-7.205q.524-.425 1.1-.8a51.2 51.2 0 0 1 14.014-2.106s24.001-.133 24.001 12.551z"
        class="prefix__cls-2"
        data-name="Tracé 2483"
        transform="translate(-50.937)"
      />
      <g
        id="prefix__Groupe_670"
        data-name="Groupe 670"
        transform="translate(10.943 24.677)"
      >
        <path
          id="prefix__Tracé_2484"
          d="M369.734 60.315a1.1 1.1 0 0 1-1.595 1.507c-.416-.44-1.182-1.966-.742-2.383s1.921.436 2.337.876z"
          class="prefix__cls-3"
          data-name="Tracé 2484"
          transform="translate(-343.777 -58.984)"
        />
        <path
          id="prefix__Tracé_2485"
          d="M309.982 111.046a1.1 1.1 0 0 1-2.049.787c-.217-.566-.345-2.268.221-2.486s1.61 1.133 1.828 1.699z"
          class="prefix__cls-3"
          data-name="Tracé 2485"
          transform="translate(-289.841 -104.292)"
        />
        <path
          id="prefix__Tracé_2486"
          d="M246.491 57.9a1.1 1.1 0 1 1-2.194 0c0-.606.491-2.241 1.1-2.241s1.094 1.63 1.094 2.241z"
          class="prefix__cls-3"
          data-name="Tracé 2486"
          transform="translate(-232.338 -55.654)"
        />
        <path
          id="prefix__Tracé_2487"
          d="M182.476 111.294a1.1 1.1 0 1 1-2.125-.549c.152-.587 1.036-2.047 1.623-1.9s.654 1.863.502 2.449z"
          class="prefix__cls-3"
          data-name="Tracé 2487"
          transform="translate(-174.355 -103.853)"
        />
        <path
          id="prefix__Tracé_2488"
          d="M118.815 59.581a1.1 1.1 0 1 1-1.975-.957c.264-.545 1.419-1.8 1.965-1.539s.275 1.951.01 2.496z"
          class="prefix__cls-3"
          data-name="Tracé 2488"
          transform="translate(-116.731 -56.919)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgFoodCategory extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.prefix__cls-3 {
  fill: var(--v-stickers_secondaire-base);
}

.prefix__cls-1 {
  fill: var(--v-homebloc_texte-base);
}

.prefix__cls-2 {
  fill: var(--v-sticker_primaire-base);
}
</style>
