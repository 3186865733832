var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "cgu-external-agreement",
        persistent: "",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "700px"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "cgu-external-agreement-card",
          attrs: { "data-cy": "cgu-external-agreement" }
        },
        [
          _c("h1", { staticClass: "cgu-external-agreement-title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "div",
            { staticClass: "cgu-external-agreement-message" },
            [
              _c(
                "p",
                { staticClass: "cgu-external-agreement-text" },
                [
                  _vm._v(
                    " Pour continuer sur notre plateforme, veuillez accepter les "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "cgu-external-agreement-link",
                      attrs: {
                        to: { name: "gcu-legal-notice" },
                        target: "_blank"
                      }
                    },
                    [_vm._v(" CGU ")]
                  ),
                  _c("span", [_vm._v(", la gestion des "), _c("br")]),
                  _c(
                    "router-link",
                    {
                      staticClass: "cgu-external-agreement-link",
                      attrs: { to: { name: "gcu-rgpd" }, target: "_blank" }
                    },
                    [_vm._v(" Données personnelles - RGPD ")]
                  ),
                  _c("span", [_vm._v(" et les ")]),
                  _c(
                    "router-link",
                    {
                      staticClass: "cgu-external-agreement-link",
                      attrs: {
                        to: { name: "gcu-user-route" },
                        target: "_blank"
                      }
                    },
                    [_vm._v(" Parcours utilisateurs. ")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "cgu-external-agreement-checkbox" }, [
                _vm.errorContactingService
                  ? _c("span", { staticClass: "errorUpdateNewsletter" }, [
                      _vm._v(_vm._s(_vm.errorContactingService) + " ")
                    ])
                  : _vm._e()
              ]),
              _c("p", {
                staticClass: "cgu-external-agreement-content mt-5",
                domProps: { innerHTML: _vm._s(_vm.agreementContent) }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "cgu-external-agreement-btn",
                  attrs: {
                    color: "homebloc_fond_bouton",
                    large: "",
                    rounded: "",
                    "data-cy": "cgu-dialog-accept-button"
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" " + _vm._s(_vm.agreementBtnText) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }