<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <title></title>
    <pattern
      id="pattern"
      width="890"
      height="600"
      patternUnits="userSpaceOnUse"
      viewBox="0 0 890 600"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <circle
          class="secondary"
          cx="808.2"
          cy="506"
          r="81.2"
        />
        <circle
          class="secondary"
          cx="448.9"
          cy="460.7"
          r="113"
        />
        <circle
          class="secondary"
          cx="242.9"
          cy="503.9"
          r="84.5"
        />
        <circle
          class="secondary"
          cx="164.7"
          cy="163.3"
          r="84.5"
        />
        <circle
          fill="none"
          class="primary"
          stroke-width="5"
          stroke-miterlimit="10"
          cx="575.2"
          cy="510.7"
          r="51.8"
        />
        <ellipse
          class="accent"
          cx="328.1"
          cy="340.3"
          rx="78.7"
          ry="79.4"
        />
        <ellipse
          class="accent"
          cx="112.3"
          cy="297.9"
          rx="110.2"
          ry="111.2"
        />
        <path
          class="accent"
          d="M183.8,380.9"
        />
        <ellipse
          fill="none"
          class="dark"
          stroke-width="5"
          stroke-miterlimit="10"
          cx="301"
          cy="208.8"
          rx="79.8"
          ry="80.6"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="539.5"
          y1="364.8"
          x2="456.2"
          y2="299"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="546.2"
          y1="397.5"
          x2="419.7"
          y2="301.5"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="542.6"
          y1="424.5"
          x2="397"
          y2="309.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="534.4"
          y1="447.3"
          x2="372.4"
          y2="321.7"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="527.1"
          y1="463.9"
          x2="359.5"
          y2="338.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="516.2"
          y1="483.5"
          x2="348.6"
          y2="357.9"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="493"
          y1="495.8"
          x2="339.4"
          y2="380.7"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="464.4"
          y1="503.9"
          x2="342.9"
          y2="406.3"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="435.2"
          y1="506"
          x2="344.1"
          y2="432.8"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="608.5"
          y1="426.8"
          x2="619.5"
          y2="430.4"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="648.5"
          y1="435.3"
          x2="658.9"
          y2="430.5"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="655.3"
          y1="470.4"
          x2="658.9"
          y2="459.4"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="683.5"
          y1="471.8"
          x2="694.8"
          y2="473.7"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="701"
          y1="437.2"
          x2="708.8"
          y2="445.6"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="742.6"
          y1="425.7"
          x2="734"
          y2="433.4"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="749"
          y1="458.9"
          x2="740.6"
          y2="458.3"
        />
        <circle
          class="secondary"
          cx="734.5"
          cy="163.3"
          r="81.2"
        />
        <circle
          class="accent"
          cx="684.5"
          cy="298.2"
          r="114"
        />
        <circle
          class="accent"
          cx="393.7"
          cy="125"
          r="114"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="697.1"
          y1="364.8"
          x2="689.1"
          y2="363.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="732.6"
          y1="400.7"
          x2="726.3"
          y2="395.5"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="688.5"
          y1="411.9"
          x2="680.4"
          y2="411.7"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="662.8"
          y1="372.3"
          x2="650.6"
          y2="366.5"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="632.7"
          y1="397.9"
          x2="633.3"
          y2="384.3"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="742.8"
          y1="359.7"
          x2="752.9"
          y2="357.9"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="745.1"
          y1="329.6"
          x2="752.3"
          y2="322.3"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="734.5"
          y1="294.9"
          x2="744.7"
          y2="294.6"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="709.9"
          y1="331.5"
          x2="717"
          y2="333.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="701.4"
          y1="290.5"
          x2="700.4"
          y2="297.9"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="677.7"
          y1="276.9"
          x2="670.2"
          y2="277"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="671.5"
          y1="319.9"
          x2="665.3"
          y2="324.2"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="646.8"
          y1="297.9"
          x2="639.7"
          y2="300.3"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="636.9"
          y1="336.9"
          x2="629.4"
          y2="337.3"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="601.6"
          y1="341"
          x2="596.5"
          y2="335.5"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="14"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="599.2"
          y1="376.8"
          x2="591.9"
          y2="378.7"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="606.6"
          y1="163.3"
          x2="606.3"
          y2="174.9"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="574.7"
          y1="175.9"
          x2="581.6"
          y2="185.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="551.1"
          y1="203.3"
          x2="562.5"
          y2="201.5"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="548.2"
          y1="172"
          x2="546.4"
          y2="160.5"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="572.3"
          y1="144.4"
          x2="578.9"
          y2="134.9"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="619.3"
          y1="135.4"
          x2="608.7"
          y2="131.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="523.1"
          y1="196.8"
          x2="523.8"
          y2="185.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="511.6"
          y1="148.8"
          x2="520.9"
          y2="142"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="491.7"
          y1="192.3"
          x2="492.3"
          y2="180.7"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="489.7"
          y1="155.2"
          x2="481.3"
          y2="147.2"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="458.3"
          y1="156.8"
          x2="461.2"
          y2="145.6"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="470.6"
          y1="83.1"
          x2="470.9"
          y2="71.5"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="502.5"
          y1="70.5"
          x2="495.6"
          y2="61.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="526.1"
          y1="43.1"
          x2="514.7"
          y2="44.9"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="529"
          y1="74.4"
          x2="530.8"
          y2="85.9"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="504.9"
          y1="102"
          x2="498.3"
          y2="111.5"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="457.9"
          y1="111"
          x2="468.5"
          y2="115.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="554.1"
          y1="49.6"
          x2="553.4"
          y2="61.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="565.6"
          y1="97.6"
          x2="556.3"
          y2="104.4"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="585.5"
          y1="54.1"
          x2="584.9"
          y2="65.7"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="587.5"
          y1="91.2"
          x2="595.9"
          y2="99.2"
        />

        <line
          fill="none"
          class="dark"
          stroke-width="11"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="618.9"
          y1="89.6"
          x2="616"
          y2="100.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="109.6"
          y1="441.6"
          x2="156.8"
          y2="381.9"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="86.2"
          y1="446.4"
          x2="155"
          y2="355.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="66.9"
          y1="443.8"
          x2="149.5"
          y2="339.5"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="50.5"
          y1="437.9"
          x2="140.5"
          y2="321.9"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="38.6"
          y1="432.7"
          x2="128.7"
          y2="312.6"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="24.6"
          y1="424.9"
          x2="114.6"
          y2="304.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="15.8"
          y1="408.3"
          x2="98.2"
          y2="298.2"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="10"
          y1="387.8"
          x2="79.9"
          y2="300.8"
        />

        <line
          fill="none"
          class="primary"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          x1="8.5"
          y1="366.9"
          x2="60.9"
          y2="301.6"
        />
      </g>
    </pattern>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      fill="url(#pattern)"
    ></rect>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgHomeBackground extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.secondary {
  fill: var(--v-stickers_secondaire-base);
}

.primary {
  stroke: #ffffff;
}

.accent {
  fill: var(--v-stickers_primaire-base);
}

.dark {
  stroke: #020203;
}
</style>
