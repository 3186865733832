
















import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import ShopOffers from '@/components/Shop/ShopOffers.vue';
import ShopSlider from '@/components/Shop/ShopSlider.vue';
import PageTitle from '@/components/PageTitle.vue';

@Component({
  components: { ShopOffers, ShopSlider, PageTitle },
})
export default class Shop extends Vue {
  christmasImage: string = require('@/assets/background/tcl-christmas.png');

  @Prop({ type: Array, required: false })
  searchEvent?: number[];

  get christmasFideliteTcl(): object | undefined {
    const platform = this.$store.getters['platform/getPlatform'];
    // UUID de la plateforme TCL en Prod et en Preprod
    const uuidTCL = ['b851a5c3-956c-4129-84d1-d0b8eea068b3', '3427a539-cb2d-48e9-b829-d9f2f97ecf8b'];
    if (!uuidTCL.includes(platform.uuid)) {
      return undefined;
    }

    const dateNow = new Date().getTime();
    const dateFrom = new Date('2023-12-01T00:00:00Z').getTime();
    const dateTo = new Date('2023-12-31T23:59:59Z').getTime();

    const style = {
      backgroundImage: `url(${this.christmasImage})`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover  ',
      backgroundPosition: 'center center',
    };

    return dateNow > dateFrom && dateNow < dateTo ? style : undefined;
  }
}
