




























































































































import { Vue, Component } from 'vue-property-decorator';
import SvgSignin from '@/components/Svg/SvgSignin.vue';
import * as UserService from '../../services/userSpace/UserService';
import store from '@/store';
import { SSOBehavior } from '@/services/merchantSpace/types';
import { startCodeFlow } from '@/services/authSpace/sso.service';
import { getTranslation } from '@/i18n';

@Component({
  components: {
    SvgSignin,
  },
})
export default class Signin extends Vue {
  signinValid: boolean = false;

  formInfos = {
    email: '',
    password: '',
  };

  error = '';

  emailRules = UserService.formRules.email;

  passwordRules = [(v) => !!v || 'Mot de passe requis', (v) => v.length >= 8 || 'Le mot de passe doit contenir au moins 8 caractères'];

  passwordShow: boolean = false;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get withSSOSignIn(): boolean {
    const ssoBehavior = this.$store.getters['platform/ssoBehavior'];
    return ssoBehavior === SSOBehavior.WITH_CLASSIC;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get externalSignUpURL() {
    return this.$store.getters['platform/getExternalSignUpURL'];
  }

  get connectionLabel() {
    return getTranslation(`${this.platform.key}.signInForm.connectWith`) || getTranslation(`signInForm.connectWith`);
  }

  mounted(): void {
    this.formInfos.email = '';
    this.formInfos.password = '';
  }

  async validate(): Promise<void> {
    if ((this.$refs.form as any).validate()) {
      try {
        const migrated = await UserService.login(this.formInfos.email.toLowerCase(), this.formInfos.password);

        if (migrated) {
          const response = await UserService.requestPasswordReset(this.formInfos.email.toLowerCase(), window.location.origin, migrated);
          this.$emit('input2');
          return;
        }

        const user = await UserService.getCurrentUser(true);
        await this.$store.dispatch('user/setConfirmationEmail', user.emailConfirmation);

        if (user.deactivated) {
          this.$emit('onDeactivated', { user });
        } else {
          if (this.$route.fullPath === '/' || this.$route.fullPath === '/signup') {
            const pageToShow = store.getters['platform/getPlatform'].config.landingPath;
            await this.$router.push(pageToShow);
          }
          this.$emit('input');
        }
      } catch (e) {
        this.error = 'Vos identifiants ne sont pas corrects';
      }
    }
  }

  openForgotPassword() {
    this.$emit('input');
    this.$emit('is-open');
  }

  goTo(route): void {
    this.$router.push(route);
    this.$emit('input');
  }

  externalSignIn() {
    this.$store.dispatch('showLogin', false);
    startCodeFlow();
  }
}
