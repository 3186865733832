import store from '@/store';

export const isConnected = () => {
  return !!store.getters['user/getAccessToken'];
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj)); // allow to deep copy an array or an object
};

export const logout = () => {
  store.dispatch('user/resetTokens');
  localStorage.removeItem('synchroString');
  window.location.reload();
};
