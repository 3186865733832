var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-profile-card",
      class: { "has-avatar": _vm.avatar },
      on: {
        click: function($event) {
          return _vm.navigateToUpdateProfile("card")
        }
      }
    },
    [
      _vm.compShowStickers
        ? _c("MiniProfileIcon", { staticClass: "dashboard-profile-card__icon" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dashboard-profile-card__content" },
        [
          _c(
            "div",
            { staticClass: "dashboard-profile-card__avatar" },
            [
              _c("Avatar", { attrs: { initials: _vm.initials, size: "small" } })
            ],
            1
          ),
          _c("span", { staticClass: "dashboard-profile-card__name" }, [
            _vm._v(_vm._s(_vm.fullName))
          ]),
          _vm.avatar
            ? _c(
                "button",
                {
                  staticClass: "dashboard-profile-card__button",
                  attrs: { "data-cy": "dashboard-profile-card__button" },
                  on: {
                    click: function($event) {
                      return _vm.navigateToUpdateProfile("button")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            : _c(
                "v-icon",
                {
                  staticClass: "dashboard-profile-card__angle",
                  attrs: { "x-large": "", color: "homeoffers_fond_bouton" }
                },
                [_vm._v(" fas fa-angle-right ")]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }