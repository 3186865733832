var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders-list" },
    [
      _vm._l(_vm.list, function(item, $index) {
        return _c(
          "div",
          { key: $index, staticClass: "orders-list__line" },
          [
            _c("OrderLine", {
              attrs: {
                number: item.id,
                date: item.createdAt,
                companyName: item.companyName,
                companyAvatar: item.companyAvatar || "",
                points: item.offerPoints,
                title: item.offerTitle,
                status: item.status,
                uuid: item.uuid
              }
            })
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _vm.infinite
            ? _c(
                "infinite-loading",
                { on: { infinite: _vm.infiniteHandler } },
                [
                  _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
                  _c("div", {
                    attrs: { slot: "no-results" },
                    slot: "no-results"
                  }),
                  _c("div", { attrs: { slot: "error" }, slot: "error" })
                ]
              )
            : _vm._e(),
          !_vm.isLoading && !_vm.list.length
            ? _c(
                "div",
                [_c("NoOrders", { attrs: { button: _vm.infinite } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }