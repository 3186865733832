export async function generatePKCE() {
  const codeVerifier = generateCodeVerifier(50);
  saveCodeVerifier(codeVerifier);

  const codeChallenge = await generateChallenge(codeVerifier);

  return {
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };
}

export function generateCodeVerifier(length: number): string {
  return randomString(length);
}

export function saveCodeVerifier(codeVerifier: string) {
  return localStorage.setItem('sso:codeVerifier', codeVerifier);
}

export function getLastCodeVerifier() {
  return localStorage.getItem('sso:codeVerifier');
}

export async function generateChallenge(codeVerifier: string) {
  const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
  return base64UrlEncode(buffer);
}

function base64UrlEncode(buffer) {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=/g, '');
}

function getRandomValues(size: number) {
  return window.crypto.getRandomValues(new Uint8Array(size));
}

function randomString(size: number) {
  const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
  let result = '';
  const randomUints = getRandomValues(size);
  for (let i = 0; i < size; i++) {
    // cap the value of the randomIndex to mask.length - 1
    const randomIndex = randomUints[i] % mask.length;
    result += mask[randomIndex];
  }
  return result;
}
