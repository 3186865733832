var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-results" },
    [
      _vm._v(
        " " +
          _vm._s(
            "Vous n’avez pas encore de commande. \n\nPour passer votre première commande rendez-vous dans la boutique."
          ) +
          " "
      ),
      _vm.button
        ? _c(
            "v-btn",
            {
              staticClass: "btn",
              attrs: {
                to: "/shop",
                large: "",
                rounded: "",
                color: "homeglobal_fond_bouton"
              }
            },
            [_vm._v(" Accéder à la boutique ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }