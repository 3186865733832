














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import OfferCard from '@/components/Card/OfferCard.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import * as offerApi from '@/api/resources/offerApi';
import * as UserService from '@/services/userSpace/UserService';
import * as common from '@/services/common/common';
import SvgBackgroundShop from '@/components/Svg/SvgBackgroundShop.vue';
import ShopSlider from '@/components/Shop/ShopSlider.vue';

import FilterOffers from '@/components/Forms/Filter/FilterOffers.vue';

@Component({
  components: {
    OfferCard,
    SvgBackgroundShop,
    FilterOffers,
    ShopSlider,
  },
})
export default class ShopOffers extends Vue {
  @Prop({ type: Array, required: false })
  searchEvent?: number[];

  loading: boolean = false;

  displayFilter = false;

  displayShop = true;

  list: any = [];

  page: number = 1;

  balance = '0';

  filterParams?: OfferService.FilterValues;

  allMerchantsList: OfferService.Merchant[] = [];

  allCategoriesList: OfferService.Category[] = [];

  infiniteId = +new Date();

  maxPoints: number = 10000;

  minPoints: number = 100;

  targetAudience: number = 1;

  eventSearch: string = '';

  events = [];

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  async mounted() {
    this.loading = true;
    const filterInfos = await offerApi.getFilterInfos();
    this.minPoints = filterInfos.min !== filterInfos.max ? filterInfos.min : 100;
    this.maxPoints = filterInfos.max;
    this.allMerchantsList = filterInfos.companies;
    this.allCategoriesList = filterInfos.categories;
    this.events = filterInfos.events;

    if (common.isConnected()) {
      this.balance = await UserService.getUserPoints(1);
    }
    this.loading = false;
  }

  get isConnected() {
    return common.isConnected();
  }

  get webViewIsActive() {
    return this.$store.getters['platform/webViewIsActive'];
  }

  async infiniteHandler($state: any) {
    if (common.isConnected()) {
      this.targetAudience = OfferService.targetAudienceEnum.PRO.id;
    } else {
      this.targetAudience = OfferService.targetAudienceEnum.PUBLIC.id;
    }

    let response: any;
    if (this.filterParams) {
      response = await OfferService.getOffersFiltered(this.page, 10, this.filterParams, this.targetAudience);
    } else {
      response = await OfferService.getOffers(this.page, 10, this.targetAudience);
    }
    if (response.items.length > 0 && this.list.length < response.meta.totalItems) {
      this.page += 1;
      this.list.push(...response.items);
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  onOfferCardClick(offerId: number): void {
    if (this.webViewIsActive) {
      this.$router.push(`/shop-webview/${offerId}`);
    } else {
      this.$router.push(`/shop/${offerId}`);
    }
  }

  showFilter(): void {
    this.displayFilter = true;
    this.displayShop = false;
  }

  onValidate(value: any): void {
    this.filterParams = value;
    this.closeFilter();
    this.page = 1;
    this.list = [];
    this.infiniteId += 1;
  }

  closeFilter(): void {
    this.displayFilter = false;
    this.displayShop = true;
  }
}
