export function loadCookiesConsentment({ scriptId = 'cookies-consentment' }: { scriptId?: string } = {}) {
  // lib is already loaded
  if (document.getElementById(scriptId)) {
    return;
  }

  const libLink = process.env.VUE_APP_TARTEAUCITRON_URI || 'https://tarteaucitron.io/load.js?domain=ireby.fr&uuid=bb0abdec9792ea8708613510b8bc69faaef98895';

  const script = document.createElement('script');
  script.setAttribute('id', scriptId);
  script.setAttribute('src', libLink);
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('defer', '');
  script.addEventListener('load', () => {
    (window as any).tarteaucitron.initEvents.loadEvent();
  });
  document.head.appendChild(script);
}
