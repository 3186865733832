var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "synchro",
        persistent: "",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("declineSynchro")
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "synchro-card" } },
        [
          _c(
            "v-card-title",
            { staticClass: "synchro-card-title" },
            [
              _c("h1", { staticClass: "synchro-card-title-text" }, [
                _vm._v(_vm._s(_vm.titleMessage || "Email déjà existant"))
              ]),
              _c("v-icon", {
                staticClass: "synchro-card-close",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-times") },
                on: {
                  click: function($event) {
                    return _vm.$emit("declineSynchro")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "synchro-card-content" },
            [
              _c("v-container", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.customMessage ||
                        " Un autre compte avec cet email existe déjà. Afin de vous permettre de vous connecter par vos identifiants classiques et vos identifiants My Astuce, nous vous proposons de synchroniser ces deux comptes. "
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "synchro-card-btn",
              attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
              on: { click: _vm.handleAcceptSynchro }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.buttonText || "Accepter la synchronisation") +
                  " "
              )
            ]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: "4000" },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }