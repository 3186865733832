var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "42.374",
        height: "50",
        viewBox: "0 0 42.374 50"
      }
    },
    [
      _c(
        "g",
        {
          staticStyle: { opacity: "1" },
          attrs: {
            id: "prefix__commerce-and-shopping",
            transform: "translate(-15.359)"
          }
        },
        [
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Tracé_2506",
              d:
                "M85.238 12.712H72.526a.848.848 0 0 1-.847-.847V7.2a7.2 7.2 0 0 1 14.407 0v4.661a.848.848 0 0 1-.848.851zm-11.864-1.695h11.017V7.2a5.508 5.508 0 1 0-11.017 0z",
              "data-name": "Tracé 2506",
              transform: "translate(-42.337)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-5",
            staticStyle: { opacity: "0.5" },
            attrs: {
              id: "prefix__Tracé_2507",
              d: "M62.886 52.024H25.6l2.542-4.237h32.2z",
              "data-name": "Tracé 2507",
              transform: "translate(-7.697 -35.923)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2508",
              d:
                "M60.32 50.3H23.032a.848.848 0 0 1-.727-1.284l2.542-4.237a.846.846 0 0 1 .727-.411h32.2a.846.846 0 0 1 .727.411l2.542 4.237a.848.848 0 0 1-.723 1.284zm-35.792-1.69h34.295L57.3 46.067H26.054z",
              "data-name": "Tracé 2508",
              transform: "translate(-5.131 -33.355)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-5",
            attrs: {
              id: "prefix__Rectangle_1219",
              d: "M0 0H11.864V12.711H0z",
              "data-name": "Rectangle 1219",
              transform: "rotate(-166.838 25.23 7.562)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2509",
              d:
                "M109.6 35.9a.857.857 0 0 1-.194-.022l-11.553-2.7a.847.847 0 0 1-.632-1.018l2.9-12.377a.846.846 0 0 1 1.018-.632l11.553 2.7a.847.847 0 0 1 .632 1.018l-2.9 12.377a.847.847 0 0 1-.824.654zm-10.54-4.184l9.9 2.316 2.509-10.727-9.9-2.316z",
              "data-name": "Tracé 2509",
              transform: "translate(-61.518 -14.375)"
            }
          }),
          _c("path", {
            staticStyle: { fill: "#3d3f42" },
            attrs: {
              id: "prefix__Rectangle_1220",
              d: "M0 0H14.273V15.293H0z",
              "data-name": "Rectangle 1220",
              transform: "rotate(173.258 18.04 10.4)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2510",
              d:
                "M38.138 28.931a.848.848 0 0 1-.841-.748L35.5 13a.848.848 0 0 1 .742-.941l14.174-1.675a.848.848 0 0 1 .941.742l1.795 15.187a.848.848 0 0 1-.742.941l-14.172 1.671a.924.924 0 0 1-.1.006zm-.853-15.292l1.6 13.5 12.491-1.476-1.6-13.5z",
              "data-name": "Tracé 2510",
              transform: "translate(-15.138 -7.798)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Rectangle_1221",
              d: "M0 0H14.043V14.778H0z",
              "data-name": "Rectangle 1221",
              transform: "rotate(166.41 20.94 14.326)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2511",
              d:
                "M65.3 52.116a.847.847 0 0 1-.823-.649L61.02 37.1a.847.847 0 0 1 .626-1.022L75.3 32.794a.847.847 0 0 1 1.022.625l3.455 14.369a.847.847 0 0 1-.626 1.022L65.5 52.092a.828.828 0 0 1-.2.024zm-2.433-14.59l3.059 12.721 12-2.886L74.87 34.64z",
              "data-name": "Tracé 2511",
              transform: "translate(-34.307 -24.634)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-5",
            attrs: {
              id: "prefix__Tracé_2512",
              d: "M59.45 97.9H18.772l1.695-33.051h37.288z",
              "data-name": "Tracé 2512",
              transform: "translate(-2.566 -48.751)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2513",
              d:
                "M56.884 96.187H16.206a.847.847 0 0 1-.846-.891l1.695-33.051a.848.848 0 0 1 .847-.8H55.19a.848.848 0 0 1 .847.8L57.731 95.3a.847.847 0 0 1-.847.891zM17.1 94.492h38.892l-1.608-31.356H18.706z",
              "data-name": "Tracé 2513",
              transform: "translate(0 -46.187)"
            }
          }),
          _c("circle", {
            staticClass: "prefix__cls-5",
            attrs: {
              id: "prefix__Ellipse_143",
              cx: "1.695",
              cy: "1.695",
              r: "1.695",
              "data-name": "Ellipse 143",
              transform: "translate(41.206 19.491)"
            }
          }),
          _c("path", {
            attrs: {
              id: "prefix__Tracé_2514",
              d:
                "M118.594 80.178a2.542 2.542 0 1 1 2.542-2.542 2.545 2.545 0 0 1-2.542 2.542zm0-3.39a.847.847 0 1 0 .847.847.848.848 0 0 0-.847-.847z",
              "data-name": "Tracé 2514",
              transform: "translate(-75.693 -56.449)"
            }
          }),
          _c("circle", {
            staticClass: "prefix__cls-5",
            attrs: {
              id: "prefix__Ellipse_144",
              cx: "1.695",
              cy: "1.695",
              r: "1.695",
              "data-name": "Ellipse 144",
              transform: "translate(28.494 19.491)"
            }
          }),
          _c("path", {
            attrs: {
              id: "prefix__Tracé_2515",
              d:
                "M67.394 80.178a2.542 2.542 0 1 1 2.542-2.542 2.545 2.545 0 0 1-2.542 2.542zm0-3.39a.847.847 0 1 0 .847.847.848.848 0 0 0-.847-.847z",
              "data-name": "Tracé 2515",
              transform: "translate(-37.205 -56.449)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Tracé_2516",
              d:
                "M78.882 98.046a7.211 7.211 0 0 1-7.2-7.2v-4.665a.847.847 0 1 1 1.695 0v4.661a5.509 5.509 0 0 0 11.017 0v-4.334a.837.837 0 0 1-.065-.327.848.848 0 0 1 .847-.847h.065a.848.848 0 0 1 .847.847v4.661a7.211 7.211 0 0 1-7.206 7.204z",
              "data-name": "Tracé 2516",
              transform: "translate(-42.337 -64.147)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }