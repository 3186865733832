















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgProgramIntro from '@/components/Home/SvgProgramIntro.vue';
import SvgProgramIntroWeb from '@/components/Home/SvgProgramIntroWeb.vue';

@Component({
  components: {
    SvgProgramIntro,
    SvgProgramIntroWeb,
  },
})
export default class HomeTitle extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: false, default: '500px' })
  titleMaxWidth!: string;

  @Prop({ type: Boolean, default: false })
  readonly centerTitleAccent!: boolean;

  @Prop({ type: Boolean, default: false })
  titleDivider!: boolean;

  @Prop({ type: Boolean, default: true })
  showPageSvg!: boolean;

  @Prop({ type: Boolean, default: true })
  highlightTitle!: boolean;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
