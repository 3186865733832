var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBanner
    ? _c(
        "v-alert",
        {
          staticClass: "banner",
          attrs: {
            type: _vm.banner.postSignupBannerType,
            align: "center",
            dismissible: "",
            prominent: ""
          },
          model: {
            value: _vm.showBanner,
            callback: function($$v) {
              _vm.showBanner = $$v
            },
            expression: "showBanner"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "banner-text",
                  attrs: { "align-self": "center" }
                },
                [
                  _c("div", {
                    attrs: { "data-cy": "post-signup-banner-text-block" },
                    domProps: {
                      innerHTML: _vm._s(_vm.banner.postSignupBannerText)
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }