var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.displayView,
          expression: "displayView"
        }
      ],
      staticClass: "error-page",
      class: {
        "page-without-app-menu": _vm.$route.meta.hideHeader
      }
    },
    [
      _vm._m(0),
      _c("h2", {
        staticClass: "error-page__subtitle",
        domProps: { innerHTML: _vm._s(_vm.description) }
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.reason === _vm.SSO_LOGIN_REQUIRED_ERROR
            ? _c("v-btn", { on: { click: _vm.ssoConnect } }, [
                _vm._v(" Réessayer ")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "error-page__title" }, [
      _vm._v(" Oh non ! "),
      _c("br"),
      _vm._v(" Une erreur est survenue. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-page__svg" }, [
      _c("img", {
        staticClass: "error-page__svg-circle",
        attrs: { src: require("@/assets/error/errorpageCircle.svg") }
      }),
      _c("img", {
        staticClass: "error-page__svg-icon",
        attrs: { src: require("@/assets/error/errorpageIcon.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }