






































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

@Component({ components: { SvgPopup } })
export default class CashbackError extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly alreadyConsumed!: boolean;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get errorMessage(): string {
    if (this.alreadyConsumed) {
      return 'Vous avez déjà consommé l’offre.';
    }
    return 'L’offre n’est plus disponible. Vous serez remboursé dans quelques jours si vous n’avez pas eu le temps de la consommer.';
  }
}
