var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intro", style: _vm.blockIntroStyle }, [
    _vm.showStickers
      ? _c(
          "div",
          { staticClass: "intro__background" },
          [_c("SvgHomeBackground")],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "intro__block",
        class: _vm.introBlockClass,
        style: _vm.blockIntroBlockStyle
      },
      [
        !_vm.isMobile
          ? _c(
              "div",
              { staticClass: "intro__block__img-block" },
              [
                _vm.firstBlockImage && !_vm.isMobile
                  ? _c("img", {
                      staticClass: "intro__block__img-block--img",
                      attrs: { src: _vm.compFirstBlockImage }
                    })
                  : _vm._e(),
                !_vm.firstBlockImage
                  ? _c("SvgHomePointsPicto", {
                      staticClass: "intro__block__img-block--svg"
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "intro__block__text" },
          [
            _c("h1", {
              class: _vm.blockTextTitleClass,
              domProps: { innerHTML: _vm._s(_vm.signinTitle) }
            }),
            _c("h2", { staticClass: "intro__block__text--subtitle" }, [
              _vm._v(_vm._s(_vm.signinSubtitle))
            ]),
            _vm.isMobile
              ? _c("div", { staticClass: "intro__block__text__img-block" }, [
                  _vm.firstBlockImage && _vm.isMobile
                    ? _c("img", {
                        staticClass: "intro__block__text__img-block--img",
                        attrs: { src: _vm.compFirstBlockImage }
                      })
                    : _vm._e()
                ])
              : _vm._e(),
            _c("p", {
              staticClass: "intro__block__text--description",
              style: "text-align:" + _vm.signinDescriptionAlign,
              domProps: { innerHTML: _vm._s(_vm.signinDescription) }
            }),
            _vm.showSignUpBtn
              ? _c(
                  "SignUpButton",
                  {
                    staticClass: "intro__block__text--btn",
                    attrs: {
                      color: "homebloc_fond_bouton",
                      large: "",
                      rounded: ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.authTextBtn) + " ")]
                )
              : _c(
                  "SignInButton",
                  {
                    staticClass: "intro__block__text--btn",
                    attrs: {
                      color: "homebloc_fond_bouton",
                      large: "",
                      rounded: ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.authTextBtn) + " ")]
                )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }