var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SSOBindExistingAccountForm" },
    [
      _c("SSOAccountOptionsTitle", [
        _vm._v(
          " Associez votre compte fidélité " +
            _vm._s(_vm.platform.name) +
            " à votre compte " +
            _vm._s(_vm.ssoName) +
            " "
        )
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4" },
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  rules: _vm.formRules.email,
                  label: "Email " + _vm.platform.name,
                  required: "",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.form.currentEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "currentEmail", $$v)
                  },
                  expression: "form.currentEmail"
                }
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "##/##/####",
                    expression: "'##/##/####'"
                  }
                ],
                ref: "dateOfBirth",
                staticClass: "form-input",
                attrs: {
                  rules: _vm.formRules.dateOfBirth,
                  label: "Date de naissance",
                  hint: "au format jour/mois/année - exemple: 20/10/1992",
                  "persistent-hint": "",
                  "validate-on-blur": "",
                  required: ""
                },
                model: {
                  value: _vm.form.dateOfBirth,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "dateOfBirth", $$v)
                  },
                  expression: "form.dateOfBirth"
                }
              })
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn-actif mt-8 mb-4",
              attrs: {
                loading: _vm.submitting,
                type: "submit",
                dark: "",
                block: "",
                depressed: ""
              }
            },
            [_vm._v(" Valider ")]
          ),
          _vm.errorMessage
            ? _c("v-alert", { attrs: { color: "red lighten-2", dark: "" } }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }