var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 81.7 82.1" },
      attrs: {
        id: "showcase",
        "xmlns:x": "&ns_extend;",
        "xmlns:i": "&ns_ai;",
        "xmlns:graph": "&ns_graphs;",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 81.7 82.1",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M20.9,35.3v1.3c0,0-1.8,0.2-3.3-1.3c-1.7-1.7-1.7-4.3,0.1-6.1c1.8-1.8,4.4-1.8,6.1-0.1\n\t\tc1.4,1.4,1.3,3.1,1.3,3.1h-1.4c0,0,0.1-1.2-0.9-2.2c-1.2-1.2-3-1-4.1,0.1s-1.3,2.9-0.1,4.1C19.6,35.5,20.9,35.3,20.9,35.3z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M27,39.4L24.4,42l-1-1l2.3-2.3c0.6-0.6,0.5-1.2,0-1.6c-0.5-0.5-1.3-0.3-1.3-0.3l-2.6,2.6l-1-1l6.4-6.4l1,1\n\t\tl-2.9,2.9c0,0,0.9-0.2,1.7,0.6S28,38.4,27,39.4z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M31.6,43.9L29,46.6l-1-1l0.5-0.5c0,0-1,0.2-1.9-0.7c-0.8-0.8-1-1.9-0.1-2.8c0.8-0.8,2-0.6,2.9,0.2\n\t\tc0.7,0.7,0.8,1.4,0.8,1.4l0.2-0.2c0.5-0.5,0.2-1.2-0.2-1.6c-0.6-0.6-1.5-0.9-1.5-0.9l0.5-1.2c0,0,1.1,0.4,2,1.2\n\t\tC32.4,41.8,32.5,43,31.6,43.9z M29.2,44.3l0.4-0.4c0,0-0.2-0.6-0.7-1.1c-0.3-0.3-0.9-0.6-1.3-0.2c-0.4,0.4-0.1,0.9,0.2,1.2\n\t\tC28.4,44.5,29.2,44.3,29.2,44.3z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M36.2,48.6l-2.6,2.6l-1-1l2.3-2.3c0.6-0.6,0.5-1.2,0-1.6c-0.5-0.5-1.3-0.3-1.3-0.3L31,48.6l-1-1l4-4l0.9,0.9\n\t\tl-0.6,0.6c0,0,1-0.3,1.9,0.5C37,46.4,37.2,47.6,36.2,48.6z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M38.9,53.8v1.3c0,0-1.3,0.2-2.4-0.9c-1.2-1.2-1.2-3.1-0.1-4.3c1.2-1.2,3.1-1.2,4.3,0.1\n\t\tc1.1,1.1,0.9,2.4,0.9,2.4l-1.3-0.1c0,0,0.1-0.8-0.5-1.4c-0.7-0.7-1.7-0.6-2.3,0s-0.7,1.6,0,2.3C38.1,54,38.9,53.8,38.9,53.8z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M44.7,58.2c-0.1,0.1-0.2,0.2-0.4,0.4l-3-3C41,56,41,56.9,41.6,57.5c0.7,0.7,1.5,0.7,1.5,0.7l-0.3,1.3\n\t\tc0,0-1.2-0.1-2.2-1c-1.2-1.2-1.2-3.1-0.1-4.3s3-1.3,4.1-0.1C45.8,55.2,45.9,57,44.7,58.2z M44,56.8c0.3-0.4,0.5-1.2-0.2-1.8\n\t\tc-0.7-0.7-1.4-0.5-1.8-0.2L44,56.8z"
          }
        })
      ]),
      _c(
        "g",
        { attrs: { id: "Composant_102_1", transform: "translate(0 0)" } },
        [
          _c("g", { attrs: { id: "Composant_101_3" } }, [
            _c("g", { attrs: { id: "Composant_49_1" } }, [
              _c("path", {
                staticClass: "st1",
                attrs: { id: "accent", d: "M0,0l81.7,82.1H51.1L0,30.6V0z" }
              })
            ])
          ])
        ]
      ),
      _c("g", [
        _c("path", {
          staticClass: "st2",
          attrs: {
            d: "M15.8,40.1l4.6-11.8l1.5,1.5l-3,7.3l7.3-3.1l1.5,1.5L15.8,40.1z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M29.1,36.8c0.5,0.5,0.5,1.3,0,1.7c-0.5,0.5-1.3,0.5-1.7,0c-0.5-0.5-0.5-1.3,0-1.7\n\t\tC27.9,36.4,28.7,36.4,29.1,36.8z M25.9,38.8l1.3,1.3l-5.4,5.4l-1.3-1.3L25.9,38.8z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M28.3,41.3l1.1,1.1l1.6-1.6l1.3,1.3l-1.6,1.6l1.6,1.6l-1.2,1.2L29.5,45l-2.2,2.2c-0.7,0.7-0.5,1.2-0.1,1.6\n\t\tc0.3,0.3,0.8,0.4,0.8,0.4l-0.7,1.6c0,0-0.8-0.1-1.6-0.9c-1.1-1.1-1.1-2.6,0-3.8l2.4-2.4l-1.1-1.1L28.3,41.3z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M37.2,50.2l-1.6,0.7c0,0-0.1-0.4-0.5-0.8c-0.8-0.8-1.9-0.4-1.9-0.4l-3.5,3.5l-1.3-1.3l5.4-5.4l1.3,1.3\n\t\tl-0.8,0.8c0,0,1.3-0.4,2.4,0.5C36.9,49.5,37.2,50.2,37.2,50.2z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M41.5,49.1c0.5,0.5,0.5,1.3,0,1.7c-0.5,0.5-1.3,0.5-1.7,0c-0.5-0.5-0.5-1.3,0-1.7\n\t\tC40.3,48.6,41.1,48.6,41.5,49.1z M38.2,51l1.3,1.3l-5.4,5.4l-1.3-1.3L38.2,51z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M44.2,60.8l-3.5,3.5L39.3,63l3.1-3.2c0.8-0.8,0.7-1.6,0-2.2c-0.7-0.7-1.7-0.4-1.7-0.4l-3.5,3.6l-1.3-1.3\n\t\tl5.4-5.4l1.3,1.2l-0.8,0.8c0,0,1.3-0.4,2.6,0.7C45.2,57.7,45.5,59.5,44.2,60.8z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M49.8,68c-0.1,0.1-0.3,0.3-0.5,0.5l-4-4c-0.4,0.5-0.4,1.7,0.4,2.6c0.9,0.9,2,0.9,2,0.9l-0.3,1.7\n\t\tc0,0-1.6-0.1-3-1.3c-1.6-1.6-1.6-4.2-0.1-5.8c1.6-1.6,4-1.7,5.5-0.1C51.4,63.9,51.5,66.3,49.8,68z M49,66.1\n\t\tc0.4-0.5,0.7-1.6-0.3-2.4c-0.9-0.9-1.9-0.7-2.4-0.3L49,66.1z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }