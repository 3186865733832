var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    [
      _c("h3", { staticClass: "presentation mb-6" }, [
        _vm._v(" Besoin d’aide ? "),
        _c("br"),
        _c("br"),
        _vm._v(
          " Remplissez le formulaire ci-dessous, notre équipe vous répondra dans les plus brefs délais. "
        )
      ]),
      _c(
        "div",
        { staticClass: "support-desk__form" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "help-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validate($event)
                }
              },
              model: {
                value: _vm.sendValid,
                callback: function($$v) {
                  _vm.sendValid = $$v
                },
                expression: "sendValid"
              }
            },
            [
              _c("v-text-field", {
                ref: "email",
                staticClass: "text-field",
                attrs: {
                  error: !!_vm.error,
                  rules: _vm.formRules.email,
                  label: "Email",
                  required: "",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.formInfos.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfos, "email", $$v)
                  },
                  expression: "formInfos.email"
                }
              }),
              _c("v-select", {
                ref: "requestTypeId",
                staticClass: "select-field",
                attrs: {
                  items: _vm.requestTypes,
                  rules: _vm.formRules.requestTypeId,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Quelle est la catégorie de votre demande ?",
                  "validate-on-blur": "",
                  required: ""
                },
                model: {
                  value: _vm.formInfos.requestTypeId,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfos, "requestTypeId", $$v)
                  },
                  expression: "formInfos.requestTypeId"
                }
              }),
              _c("v-text-field", {
                ref: "subject",
                staticClass: "text-field",
                attrs: {
                  rules: _vm.formRules.subject,
                  error: !!_vm.error,
                  "validate-on-blur": "",
                  label: "Quel est le sujet de votre demande ?",
                  required: ""
                },
                model: {
                  value: _vm.formInfos.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfos, "subject", $$v)
                  },
                  expression: "formInfos.subject"
                }
              }),
              _c("v-textarea", {
                ref: "description",
                staticClass: "help-form-description text-field",
                attrs: {
                  outlined: "",
                  "validate-on-blur": "",
                  rules: _vm.formRules.description,
                  error: !!_vm.error,
                  label: "Description de la demande",
                  required: "",
                  "no-resize": "",
                  height: "227px"
                },
                model: {
                  value: _vm.formInfos.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfos, "description", $$v)
                  },
                  expression: "formInfos.description"
                }
              }),
              _c(
                "div",
                { staticClass: "help-form-actions" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "help-form-actions-button",
                      attrs: {
                        rounded: "",
                        color: "homeglobal_fond_bouton",
                        loading: _vm.contactRunning,
                        "x-large": "",
                        type: "submit"
                      }
                    },
                    [_vm._v(" Envoyer ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "content-class": "dialog" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "dialog__card" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "dialog__title" },
                    [
                      _vm.compShowStickers
                        ? _c(
                            "div",
                            { staticClass: "dialog__title__picto" },
                            [_c("SvgPopup")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" Merci ! "),
                      _c("v-icon", {
                        staticClass: "sigin-close",
                        attrs: { medium: "" },
                        domProps: { textContent: _vm._s("fas fa-times") },
                        on: {
                          click: function($event) {
                            _vm.dialog = false
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "dialog__text" }, [
                    _c("span", { staticClass: "dialog__text__content" }, [
                      _vm._v(
                        " Nous avons bien pris en compte votre demande. Nous reviendrons vers vous pour répondre à vos questions dans les plus brefs délais. "
                      )
                    ])
                  ]),
                  _c("v-spacer"),
                  _c("v-card-actions", [
                    _c(
                      "div",
                      { staticClass: "dialog__action" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "dialog__action__text",
                            attrs: {
                              "x-large": "",
                              rounded: "",
                              color: "popup_fond_bouton",
                              width: "100%"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToProfile()
                              }
                            }
                          },
                          [_vm._v(" Retour au profil ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }