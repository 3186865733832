var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c(
        "div",
        { staticClass: "profile-background" },
        [_vm.compShowStickers ? _c("SvgBackgroundProfile") : _vm._e()],
        1
      ),
      !_vm.isMobile
        ? [
            _c(
              "MiniProfile",
              _vm._b({}, "MiniProfile", _vm.miniProfileOption, false)
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "profile-banner",
                on: {
                  click: function($event) {
                    return _vm.navigateToUpdateProfile()
                  }
                }
              },
              [
                _c("div", { staticClass: "profile-banner-avatar" }, [
                  _c(
                    "div",
                    { staticClass: "profile-banner-avatar-photo" },
                    [
                      _vm.compInitials
                        ? _c(
                            "v-avatar",
                            {
                              staticClass: "elevation-3",
                              attrs: {
                                size: "88px",
                                color: "homebloc_sous_titre"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.compInitials) + " ")]
                          )
                        : _c(
                            "v-avatar",
                            {
                              staticClass: "elevation-3",
                              attrs: {
                                size: "88px",
                                color: "homebloc_sous_titre"
                              }
                            },
                            [
                              _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s("fas fa-user-circle")
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "profile-banner-text" }, [
                  _c("div", { staticClass: "profile-banner-text-name" }, [
                    _vm._v(_vm._s(_vm.compDisplayedName))
                  ]),
                  _c("div", { staticClass: "profile-banner-text-subtitle" }, [
                    _vm._v("nom, âge, adresse, ...")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "profile-banner-go" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { "x-large": "", color: "homebloc_sous_titre" }
                      },
                      [_vm._v(" fas fa-angle-right ")]
                    )
                  ],
                  1
                )
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }