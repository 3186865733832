import * as Cookie from 'js-cookie';
import * as authApi from '@/api/authApi';
import store from '@/store';
import router from '@/router';
import { refreshUser, refreshUserPoints } from '../userSpace/UserService';

export async function signIn({ platformUUID, sessionName }: { platformUUID: string; sessionName: string }): Promise<any> {
  const externalSession = getExternalSession(sessionName);
  if (!externalSession) {
    return undefined;
  }

  const response = await authApi.signInWithExternalSession(platformUUID, { externalSession });
  const { tokens } = response;
  if (tokens) {
    store.dispatch('user/login', { tokens });
    await Promise.all([refreshUser(), refreshUserPoints()]);
    return redirectToLandingPage();
  }

  return undefined;
}

export function getExternalSession(sessionName): string | undefined {
  return Cookie.get(sessionName);
}

function redirectToLandingPage() {
  const pageToShow = store.getters['platform/getPlatform'].config.landingPath;
  if (router.currentRoute.path !== pageToShow) {
    router.push(pageToShow);
  }
}
