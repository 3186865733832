










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NoPoints extends Vue {
  validatedService: boolean = false;

  mounted() {
    const currentUserServices = this.$store.getters['user/getUser'].userServices;

    const platformServices = this.$store.getters['platform/getPlatform'].platformService;
    const forSignupPlatformService = platformServices.find(({ forSignup }) => forSignup);

    if (forSignupPlatformService) {
      const forSignupService = forSignupPlatformService.service;
      this.validatedService = currentUserServices.some(({ serviceId, validated }) => serviceId === forSignupService.id && validated);
    }
  }

  get isEmailConfirmed() {
    return this.$store.getters['user/getConfirmationEmail'];
  }
}
