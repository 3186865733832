var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto carousel-card" },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            continuous: true,
            "show-arrows": _vm.compShowArrow,
            cycle: "",
            width: _vm.compSize + "px",
            height: _vm.compSize + "px",
            interval: "5000",
            "hide-delimiters": "",
            "next-icon": "fas fa-chevron-right",
            "previous-icon": "fas fa-chevron-left"
          }
        },
        _vm._l(_vm.pictures, function(item, i) {
          return _c("v-carousel-item", { key: i, attrs: { src: item } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }