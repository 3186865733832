import { apiPublicInstance } from '@/services/API';

export interface SupportDeskTicket {
  serviceDeskId: string;
  requestTypeId: string;
  raiseOnBehalfOf: string;
  requestFieldValues: {
    summary: string;
    description: string;
  };
}

export const serviceDeskId = process.env.VUE_APP_SERVICE_DESK_ID;
export const requestTypeId = process.env.VUE_APP_SERVICE_DESK_REQUEST_ID;
export const irebyUrl = process.env.VUE_APP_IREBY_URL;

export const createSupportTicket = async (ticket: SupportDeskTicket): Promise<boolean> => {
  try {
    const result = await apiPublicInstance().post('/api/serviceDesk', ticket);
    return result.data;
  } catch (error) {
    return false;
  }
};

export const createHeaderSupportTicket = async (email: string, currentRoute: string): Promise<string> => {
  try {
    const platform = await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}`);
    return `
    Platform ID: ${process.env.VUE_APP_PLATFORM_UUID}
    Nom de la plateforme: ${platform.data.name}
    Path courant du ticket: ${currentRoute}
    Email de l'utilisateur: ${email}
  `;
  } catch (error) {
    return `
    Platform ID: ${process.env.VUE_APP_PLATFORM_UUID}
    Nom de la plateforme: ${error}
    Path courant du ticket: ${currentRoute}
    Email de l'utilisateur: ${email}
  `;
  }
};

export const getServiceDeskRequestTypes = async (sdId: number): Promise<any> => {
  return apiPublicInstance()
    .get(`/api/serviceDesk/${sdId}/requestTypes?general=true`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
