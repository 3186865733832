export enum PlatformKey {
  TBM = "tbm-fid'",
  AVANTAG = 'avantag',
  IRIGO = 'irigo',
  STAN = 'stan',
  ECOBONUS = 'ecobonus',
  BKLUB = 'b-klub',
  ASTUCE = 'astuce',
  STAR = 'star',
}

export enum ServiceKey {
  MODALIS = 'modalis',
  TBM = 'tbm',
  IRIGO_ATOUT = 'irigo-atout',
  IRIGO_MTICKET = 'irigo-mticket',
  AVANTAG = 'avantag',
  WEFLO = 'weflo',
  BIBUS_MTICKET = 'm-ticket-bibus',
  KORRIGO_BREST = 'korrigo-brest',
  MY_ASTUCE = 'maas-rouen--cityway',
  CLUB_ASTUCE = 'club-astuce',
  TBM_MTICKET = 'tbm-mticket',
}

export enum PageName {
  ERROR = 'error',
  HOME = 'home',
}

export enum Errors {
  SSO_LOGIN_REQUIRED = 'login_required',
}
