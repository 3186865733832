














































import { Component, Vue, Prop } from 'vue-property-decorator';
import CashbackTransactionLine from '@/components/Account/Cashback/CashbackTransactionLine.vue';
import { Transaction } from '@/api/resources/orderApi';

@Component({
  components: {
    CashbackTransactionLine,
  },
})
export default class CashbackTransaction extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  readonly transactions!: Transaction[];

  displayTransactions: boolean = false;

  triggerTransactions() {
    this.displayTransactions = !this.displayTransactions;
  }

  get compTransactionsLength() {
    return this.transactions.length;
  }
}
