var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SSOPairingForm primary-text-color" },
    [
      _c("p", {
        staticClass: "text-center mb-12 signup-subtitle",
        domProps: { innerHTML: _vm._s(_vm.ssoOptions.signUpCustomSubtitle) }
      }),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("v-text-field", {
            staticClass: "form-field",
            attrs: { label: "Code de parrainage", "validate-on-blur": "" },
            model: {
              value: _vm.form.referrerCode,
              callback: function($$v) {
                _vm.$set(_vm.form, "referrerCode", $$v)
              },
              expression: "form.referrerCode"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##/##/####",
                expression: "'##/##/####'"
              }
            ],
            staticClass: "form-field",
            attrs: {
              error: _vm.errorDescription && Boolean(_vm.errorDescription.dob),
              rules: _vm.formRules.dateOfBirth,
              label: "Date de naissance *",
              name: "dateOfBirth",
              hint: "au format jour/mois/année - exemple: 20/10/1992",
              required: "",
              "validate-on-blur": ""
            },
            model: {
              value: _vm.form.dateOfBirth,
              callback: function($$v) {
                _vm.$set(_vm.form, "dateOfBirth", $$v)
              },
              expression: "form.dateOfBirth"
            }
          }),
          _vm.disableServiceSelection
            ? [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.externalAccountLabel,
                    error:
                      _vm.errorDescription &&
                      Boolean(_vm.errorDescription.externalAccountId),
                    required: "",
                    "validate-on-blur": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _vm.cardTooltip.mainDesc
                              ? _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: (_vm.showCardTooltip = false),
                                        expression: "(showCardTooltip = false)"
                                      }
                                    ],
                                    attrs: { color: "homeglobal_picto" },
                                    on: {
                                      click: function($event) {
                                        _vm.showCardTooltip = !_vm.showCardTooltip
                                      }
                                    }
                                  },
                                  [_vm._v(" fa-info-circle ")]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1181757251
                  ),
                  model: {
                    value: _vm.form.externalAccountId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "externalAccountId", $$v)
                    },
                    expression: "form.externalAccountId"
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showCardTooltip,
                        expression: "showCardTooltip"
                      }
                    ],
                    staticClass: "tooltip"
                  },
                  [
                    _c("div", { staticClass: "tooltip-arrow-up" }),
                    _c("v-card", { staticClass: "tooltip-card" }, [
                      _vm._v(" " + _vm._s(_vm.cardTooltip.mainDesc) + " "),
                      _vm.cardTooltipImageSrc
                        ? _c("img", { attrs: { src: _vm.cardTooltipImageSrc } })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.cardTooltip.optionalDesc) + " ")
                    ])
                  ],
                  1
                )
              ]
            : [
                _vm.hasServiceToSelect
                  ? _c(
                      "v-radio-group",
                      {
                        staticClass: "service-group",
                        attrs: {
                          label: "Quel service utilisez vous ? *",
                          mandatory: "",
                          row: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.form.service,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "service", $$v)
                          },
                          expression: "form.service"
                        }
                      },
                      _vm._l(_vm.sortedServices, function(service) {
                        return _c("v-radio", {
                          key: service.id,
                          staticClass: "select-service form-input",
                          attrs: {
                            label: service.name,
                            value: service,
                            color: "actif"
                          }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.state === "need-external-account"
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "need-external-account__info" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.needExternalAccountField.info) +
                                " "
                            )
                          ]
                        ),
                        _c("v-text-field", {
                          attrs: {
                            rules: [_vm.externalAccountIdRule],
                            label: _vm.needExternalAccountField.label,
                            error:
                              _vm.errorDescription &&
                              Boolean(_vm.errorDescription.externalAccountId),
                            required: "",
                            "validate-on-blur": ""
                          },
                          model: {
                            value: _vm.form.externalAccountId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "externalAccountId", $$v)
                            },
                            expression: "form.externalAccountId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
          _c("v-checkbox", {
            staticClass: "mt-6 mb-4 form-input",
            attrs: {
              rules: _vm.formRules.gcuAndConfidentiality,
              color: "actif",
              dense: "",
              "hide-details": ""
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c("span", [
                      _vm._v(" Veuillez accepter les "),
                      _c(
                        "a",
                        {
                          staticClass: "link-color",
                          attrs: {
                            href: _vm.$router.resolve({
                              name: "gcu-legal-notice"
                            }).href,
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [_vm._v(" CGU ")]
                      ),
                      _vm._v(" , la gestion des "),
                      _c(
                        "a",
                        {
                          staticClass: "link-color",
                          attrs: {
                            href: _vm.$router.resolve({ name: "gcu-rgpd" })
                              .href,
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [_vm._v(" Données personnelles - RGPD ")]
                      ),
                      _vm._v(" et les "),
                      _c(
                        "a",
                        {
                          staticClass: "link-color",
                          attrs: {
                            href: _vm.$router.resolve({
                              name: "gcu-user-route"
                            }).href,
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [_vm._v(" Parcours utilisateurs ")]
                      ),
                      _vm._v(
                        " de Transway, opérateur du programme de fidélité " +
                          _vm._s(_vm.platform.name) +
                          ". "
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.form.cguValidated,
              callback: function($$v) {
                _vm.$set(_vm.form, "cguValidated", $$v)
              },
              expression: "form.cguValidated"
            }
          }),
          _c("v-checkbox", {
            staticClass: "form-input",
            attrs: {
              label: _vm.newsletterLabel,
              color: "actif",
              "hide-details": "",
              dense: ""
            },
            model: {
              value: _vm.form.newsletterIsAccepted,
              callback: function($$v) {
                _vm.$set(_vm.form, "newsletterIsAccepted", $$v)
              },
              expression: "form.newsletterIsAccepted"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "primary-button-color mt-6 mb-4 submit-btn",
              attrs: {
                loading: _vm.submitting,
                type: "submit",
                elevation: "0",
                rounded: "",
                block: ""
              }
            },
            [_vm._v(" Suivant ")]
          )
        ],
        2
      ),
      _vm.errorDescription
        ? [
            _vm.showDialogError
              ? _c("AskSSOSynchroDialog", {
                  attrs: {
                    "title-message": _vm.errorDescription.title,
                    "custom-message": _vm.errorDescription.message,
                    "button-text": "Recevoir l'email de synchronisation",
                    "user-infos": _vm.userInput
                  },
                  on: { declineSynchro: _vm.declineSynchro }
                })
              : _c(
                  "v-alert",
                  {
                    staticClass: "mb-0 mt-6",
                    attrs: { color: "red lighten-2", dark: "", dense: "" }
                  },
                  [
                    _c("span", {
                      staticClass: "error-message",
                      domProps: {
                        innerHTML: _vm._s(_vm.errorDescription.message)
                      }
                    })
                  ]
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }