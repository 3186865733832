// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-256764a6] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-256764a6] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-256764a6] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.history-line[data-v-256764a6] {\n  border-bottom: 1px solid #dedede;\n}\n.history-line__content[data-v-256764a6] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n  color: var(--v-homeglobal_texte_fond-base);\n  padding: 0.4rem;\n  text-align: center;\n}\n.history-line__content .col[data-v-256764a6] {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  padding: 0.7rem 0.2rem;\n}", ""]);
// Exports
module.exports = exports;
