






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import SignInButton from '@/components/Button/SignInButton.vue';
import { PlatformKey } from '../../constants';
import { startCodeFlow } from '../../services/authSpace/sso.service';
import { SSOBehavior } from '../../services/merchantSpace/types';

@Component({
  components: {
    SvgPopup,
    SignInButton,
  },
})
export default class LogoutMessage extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  readonly value!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  readonly title!: string;

  @Prop({
    type: String,
    required: false,
  })
  readonly description!: string;

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get showStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get externalSignUpURL() {
    return this.$store.getters['platform/getExternalSignUpURL'];
  }

  get specificSignUp() {
    return this.$store.getters['platform/ssoBehavior'] === SSOBehavior.SIGNIN_TO_EXTERNAL 
      && this.$store.getters['platform/getPlatform'].key === PlatformKey.TBM;
  }

  signUpAction() {
    startCodeFlow()
  }

}
