var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "request-confirm-card" },
    [
      _c(
        "v-card-title",
        {
          staticClass: "request-confirm-card-title",
          attrs: { "primary-title": "" }
        },
        [_vm._v(" " + _vm._s(_vm.titleText) + " ")]
      ),
      _c("v-card-text", { staticClass: "request-confirm-card-subtitle" }, [
        _vm._v(_vm._s(_vm.subtitleMessage))
      ]),
      _vm.offer.deliveryMethodId === 3
        ? _c("v-card-text", [
            _vm._v(" Votre commande sera livrée à l'adresse suivante : "),
            _c("strong", [_vm._v(_vm._s(_vm.formatAddress))])
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "request-confirm-card-info" },
        [
          _c("OfferAvatar", {
            attrs: {
              size: "52px",
              selectedImageUrl: _vm.compCompanyAvatar,
              initial: _vm.companyInitials
            }
          }),
          _c("span", { staticClass: "request-confirm-card-info-offer-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ],
        1
      ),
      !_vm.isFundingOffer
        ? _c("v-card-text", { staticClass: "request-confirm-card-points" }, [
            _vm._v(" " + _vm._s(_vm.points) + " points ")
          ])
        : _vm._e(),
      _vm.isFundingOffer
        ? _c("v-slider", {
            staticClass: "request-confirm-card-slider",
            attrs: {
              min: "10",
              max: _vm.maxPointsToGive,
              step: "10",
              "thumb-label": "always",
              "thumb-size": "36",
              "thumb-color": "actif",
              "track-color": "inactif",
              "track-fill-color": "actif",
              "hide-details": "true"
            },
            model: {
              value: _vm.pointsToGive,
              callback: function($$v) {
                _vm.pointsToGive = $$v
              },
              expression: "pointsToGive"
            }
          })
        : _vm._e(),
      _vm.isFundingOffer
        ? _c(
            "v-card-text",
            { staticClass: "request-confirm-card-subtitle-2" },
            [_vm._v(" " + _vm._s(_vm.subtitleMessage2) + " ")]
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "request-confirm-card-button",
          attrs: {
            large: "",
            rounded: "",
            color: "popup_fond_bouton",
            loading: _vm.loading,
            "data-cy": "offer-buy-confirm-btn"
          },
          on: { click: _vm.onConfirmClick }
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      ),
      _c("AlmostDoneCollectConfirmationCard", {
        attrs: { points: _vm.remainingPoints },
        on: { click: _vm.onConfirmClick },
        model: {
          value: _vm.showAlmostDoneMessage,
          callback: function($$v) {
            _vm.showAlmostDoneMessage = $$v
          },
          expression: "showAlmostDoneMessage"
        }
      }),
      _c("div", { staticClass: "request-confirm-card-bottom" }),
      _c("ErrorDialog", {
        attrs: { message: _vm.dialogText },
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }