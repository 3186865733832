var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "logout-message",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "logout-message-card" } },
        [
          _c("v-card-title", { staticClass: "logout-message-card-title" }, [
            _vm.showStickers
              ? _c(
                  "div",
                  { staticClass: "logout-message-card-title-picto" },
                  [_c("SvgPopup", { attrs: { width: "80", height: "80" } })],
                  1
                )
              : _vm._e(),
            _c("h1", { staticClass: "logout-message-card-title-text" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _c("div", { staticClass: "logout-message-card-subtitle" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } })
          ]),
          _c(
            "div",
            { staticClass: "interactions-bar" },
            [
              _c(
                "SignInButton",
                {
                  staticClass: "logout-message-card-btn interactions-bar__item",
                  attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input")
                    }
                  }
                },
                [_vm._v(" Se connecter ")]
              ),
              _vm.specificSignUp
                ? _c(
                    "a",
                    {
                      staticClass:
                        "logout-message-card-link interactions-bar__item-underline",
                      on: {
                        click: function($event) {
                          return _vm.signUpAction()
                        }
                      }
                    },
                    [_vm._v(" Pas encore inscrit(e) ? ")]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "logout-message-card-link interactions-bar__item",
                      attrs: {
                        href: _vm.externalSignUpURL || "signup",
                        target: _vm.externalSignUpURL ? "_blank" : "_self"
                      }
                    },
                    [_vm._v(" Pas encore inscrit(e) ? ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }