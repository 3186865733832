var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "privacy-policy-app parent bg-white" },
      [
        _c("div", { staticClass: "max-w-[760px] m-auto" }, [
          _c("div", { staticClass: "container px-4 md:px-0" }, [
            _c("h1", { staticClass: "header-1" }, [
              _vm._v("Politique de Confidentialité")
            ]),
            _c("p", [_vm._v("Dernière mise à jour : 03 juillet 2024")]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [
                _vm._v(
                  "1. Notice d'information relative aux données à caractère personnel"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Sur l'application Ecomotive de SNCF TER Pays de la Loire, gérée par TransWay SAS, TransWay SAS, peuvent être amenés à collecter et traiter des informations vous identifiant. Ces informations sont ci-après dénommées « Données à caractère personnel » ou « Données ». "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Données à caractère personnel : «les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978). Les termes « données à caractère personnel », « personne concernée », « sous-traitant » et « données sensibles » ont le sens défini par le Règlement Général sur la Protection des Données (RGPD : n° 2016-679). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Cette notice d'information vous explique pourquoi TransWay SAS peuvent collecter vos Données, comment vos Données seront utilisées et protégées, combien de temps elles seront conservées et les droits dont vous disposez. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [
                _vm._v(
                  "2. Qui sont les personnes dont les Données sont collectées ?"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les personnes concernées par la collecte de Données sont les utilisateurs de l'application Ecomotive gérée par TransWay SAS. Un utilisateur est une personne qui a téléchargé et qui utilise l'application Ecomotive. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("3. Collecte des Données")]),
              _c("p", [
                _vm._v(
                  " TransWay SAS ne collecte et ne traite les Données à caractère personnel que pour le besoin des services proposés par l'application Ecomotive. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les données à caractère personnel collectées par l'application Ecomotive proviennent des sources suivantes : "
                )
              ]),
              _c("ul", { staticClass: "space-y-3" }, [
                _c("li", [
                  _c("b", [
                    _vm._v(" Formulaire d'inscription et page profil :")
                  ]),
                  _vm._v(
                    " Les informations sont recueillies directement auprès de l'utilisateur lors de son inscription et via la gestion de son profil. "
                  )
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v("Synchronisation des données de mobilité :")
                  ]),
                  _vm._v(
                    " Les données GPS, accéléromètre, détection d'activités (comme la marche, le vélo, etc.) et autres capteurs des téléphones des utilisateurs sont synchronisées pour analyser les déplacements et pour estimer les modes de déplacement utilisés. "
                  )
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v(
                      "Synchronisation des informations sur les appareils des utilisateurs :"
                    )
                  ]),
                  _vm._v(
                    " Les informations sur la consommation de la batterie des téléphones des utilisateurs, ainsi que d'autres informations sur les appareils, sont collectées afin d'optimiser les fonctionnalités de l'application. "
                  )
                ])
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("4. Fondement de la collecte des données")]),
              _c("p", [
                _vm._v(
                  " Lorsque l'utilisateur choisit de communiquer ses données à caractère personnel aux fins de l'exécution des services que TransWay SAS propose dans le cadre de l'application Ecomotive et des programmes de fidélité dédiée à la mobilité, l'utilisateur donne expressément son consentement pour la collecte et le traitement de celles-ci conformément à la législation et aux réglementations en vigueur. "
                )
              ]),
              _c("p", [
                _c("b", [_vm._v("Réglementations : ")]),
                _vm._v("RGPD")
              ]),
              _c("div", [_c("b", [_vm._v("Consentement : ")])]),
              _c("ul", [
                _c("li", [_vm._v("Recueil depuis la l'application Ecomotive")]),
                _c("li", [_vm._v("Données de connexion,")]),
                _c("li", [_vm._v("Données GPS (tracking)")])
              ]),
              _c("p", [
                _vm._v(
                  " L'Utilisateur est par ailleurs informé que son consentement sera demandé, de manière individuelle lors de l'activation de son compte sur Ecomotive et de nouveau après chaque mise à jour le cas échéant. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " L'utilisateur a la possibilité, à tout moment, de retirer ou modifier son consentement pour ces finalités et pour la communication de ces données à caractère personnel. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [
                _vm._v(
                  "5. Finalités des traitements des données à caractère personnel"
                )
              ]),
              _c("p", [
                _vm._v(
                  " TransWay SAS, en sa qualité de responsable de traitement, peut être amenée à collecter et traiter les types de Données suivants : "
                )
              ]),
              _c("h3", [
                _vm._v("5.1. Inscription, connexion, gestion du profil")
              ]),
              _c("p", [
                _vm._v(
                  " Les utilisateurs de l'application doivent d'abord créer un compte utilisateur personnalisé. Dans le cadre du processus d'inscription, les utilisateurs sont informés des informations obligatoires requises, et ces données sont traitées dans le but de fournir le compte utilisateur. "
                )
              ]),
              _c("ul", [
                _c("li", [
                  _c("p", [
                    _c("b", [_vm._v("Nom et Prénom :")]),
                    _vm._v(
                      " Ces informations sont nécessaires pour identifier les utilisateurs, personnaliser l'expérience utilisateur, et faciliter la communication entre l'utilisateur et le support client. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _c("b", [_vm._v("Adresse e-mail :")]),
                    _vm._v(
                      " Cette information permet l'accès au compte de l'utilisateur et sert d'adresse de contact, notamment pour les confirmations de commande. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _c("b", [_vm._v("Date de naissance :")]),
                    _vm._v(
                      " cette information permet d'analyser le profil des utilisateurs et de déterminer des tendances. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("b", [_vm._v("Poids :")]),
                  _vm._v(
                    " Cette information est utilisée pour calculer les calories brûlées si l'utilisateur choisit de fournir ces données, sinon une valeur par défaut est utilisée. "
                  )
                ])
              ]),
              _c("h3", [
                _vm._v(
                  "5.2. Les données de mobilité, l'analyse des données et les statistiques"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Lorsque l'utilisateur utilise l'application, certaines données provenant des capteurs des appareils sont enregistrées, ainsi que les informations des appareils. Ces informations sont chiffrées et transférées de manière sécurisée à un serveur d'analyse d'un prestataire de services désigné pour déterminer les moyens de transport utilisés. Une analyse ultérieure des données est ensuite effectuée pour calculer la quantité de CO2 émise et évitée. "
                )
              ]),
              _c("ul", [
                _c("li", [
                  _c("p", [
                    _c("b", [
                      _vm._v(
                        "Les données de GPS, d'accélération et d'activité détectées :"
                      )
                    ]),
                    _vm._v(
                      " Ces données permettent de collecter les trajets d'un utilisateur afin de déterminer les modes de transport utilisés et de les créditer sous forme de points appropriés. Ces données sont collectées selon l'autorisation de l'utilisateur. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "L'autorisation de tracking est paramétrable sur la page du tableau de bord de l'application Ecomotive (switch d'activation du tracking). Lorsque cette option est désactivée, aucune donnée n'est collectée. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " L'accès au GPS du smartphone et l'autorisation d'utilisation par l'application Ecomotive peuvent être réglés dans les paramètres du téléphone de l'utilisateur. "
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " L'application collecte des données de localisation même lorsque l'application est fermée ou non utilisée. Cela est nécessaire pour assurer la fonctionnalité principale de l'application et améliorer l'expérience utilisateur globale. Les données de localisation sont traitées de manière sécurisée et conformément aux réglementations applicables pour garantir la confidentialité et la protection des données des utilisateurs. Les utilisateurs ont la possibilité de gérer les autorisations de localisation via les paramètres de leur appareil pour contrôler quand et comment ces données sont collectées et utilisées par l'application. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("b", [_vm._v("Les données d'usage de la batterie :")]),
                  _vm._v(
                    " cette information permet d'étudier l'impact du tracking sur la batterie du smartphone et d'optimiser les ressources utilisées "
                  )
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v(
                      "Les modes de déplacement déclarés par l'utilisateur pour chaque trajet :"
                    )
                  ]),
                  _vm._v(
                    " Cette information permet à TransWay SAS d'évaluer l'efficacité du traceur et la fiabilité de la détection automatique des modes de déplacement fournie par l'application Ecomotive. "
                  )
                ])
              ]),
              _c("h3", [
                _vm._v(
                  "5.3. Les informations sur les appareils des utilisateurs"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les informations sur les appareils des utilisateurs sont collectées dans le but d'améliorer les fonctionnalités de l'application, de garantir la compatibilité avec différents modèles de téléphones portables et systèmes d'exploitation, ainsi que d'optimiser l'expérience utilisateur. "
                )
              ]),
              _c("ul", [
                _c("li", [_vm._v("Système d'exploitation")]),
                _c("li", [_vm._v("Version du système d'exploitation (OS)")]),
                _c("li", [
                  _vm._v("Modèle de téléphone (par exemple : iPhone 13)")
                ])
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("6. Qui a accès à vos données")]),
              _c("h3", [
                _vm._v(
                  "6.1 Respect des droits de limitation d'accès aux données"
                )
              ]),
              _c("p", [
                _vm._v(
                  "SNCF TER Pays de la Loire et TransWay SAS prennent toutes les mesures nécessaires pour garantir la sécurité des Données qu'elles ont collectées mais aussi leur confidentialité, c'est-à-dire s'assurer que seules les personnes autorisées y accèdent. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Seules les personnes habilitées en raison de leurs activités au sein des services compétents de SNCF TER Pays de la Loire, en charge des traitements correspondants, ont accès à vos Données dans la limite de leurs habilitations. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " De même,la société TransWay SAS et ses partenaires/prestataires peuvent avoir accès à vos données autant que de besoin et de manière sécurisée dans le cadre de l'exécution de leur prestation. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Certaines autorités se verront également communiquer vos Données, conformément aux lois et réglementations applicables. "
                )
              ]),
              _c("h3", [_vm._v("6.2 Destinataires des données collectées")]),
              _c("p", [
                _vm._v(
                  " En dehors de l'usage de l'application Ecomotive, TransWay SAS et SNCF TER Pays de la Loire ne vendent ni ne louent, en aucun cas, les données personnelles d'un Utilisateur à des tiers à des fins de marketing. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les données personnelles d'un Utilisateur collectées sur Ecomotive sont destinées pour la propre utilisation du programme de fidélité de SNCF TER Pays de la Loire. "
                )
              ]),
              _c("h4", [_vm._v("6.2.1. Services TransWay :")]),
              _c("ul", [
                _c("li", [
                  _c("b", [
                    _vm._v(
                      "DPO Externe en cas de réclamation ou de violation :"
                    )
                  ]),
                  _vm._v(" Toutes les données ")
                ]),
                _c("li", [
                  _c("b", [_vm._v("Support technique :")]),
                  _vm._v(" Toutes les données ")
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v(
                      "Responsable d'Affaires en charge du programme de fidélité :"
                    )
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Caractéristiques personnelles (Date de naissance) "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Données d'identification (Identifiant compte, Nom, Prénom) "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Données d'identification électronique (Adress e-mail) "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        " Habitudes de vie (Actions de Mobilité, Habitudes de consommation, Points fidélité) "
                      )
                    ])
                  ])
                ])
              ]),
              _c("h4", [_vm._v("6.2.2. Clients et Partenaires :")]),
              _c("p", [
                _vm._v(
                  ' Chaque exploitant de mobilité (Partenaire ou client) sera destinataire des données "Action de mobilité" et "Point de fidélité" exclusivement produites dans le cadre de leur exploitation de mobilité circonscrites au territoire délimité par le Contrat ; les données produites hors exploitation seront assujetties au consentement des usagers. '
                )
              ]),
              _c("h4", [_vm._v("6.2.3. Services SNCF TER Pays de la Loire :")]),
              _c("ul", [
                _c("li", [
                  _c("b", [_vm._v("Equipe SNCF TER Pays de la Loire :")]),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Caractéristiques personnelles (Date de naissance)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Données d'identification (Identifiant compte, Nom, Prénom)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Données d'identification électronique (Adress e-mail)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Habitudes de vie (Actions de Mobilité, Points fidélité)"
                      )
                    ])
                  ])
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v(
                      "DPO Externe en cas de réclamation ou de violation :"
                    )
                  ]),
                  _vm._v(" Toutes les données ")
                ]),
                _c("li", [
                  _c("b", [_vm._v("SNCF :")]),
                  _vm._v(
                    " Dates d'inscription/désinscription au Programme de Fidélité "
                  )
                ])
              ]),
              _c("h4", [
                _vm._v(
                  "6.2.4. TransWay SAS, le SNCF et SNCF TER Pays de la Loire pourront également partager :"
                )
              ]),
              _c("ul", [
                _c("li", [_vm._v("Les données statistiques anonymisées")]),
                _c("li", [
                  _vm._v(
                    "Certaines données à caractère personnel sous réserve d'avoir reçu préalablement le consentement de l'Utilisateur lorsque cela est nécessaire."
                  )
                ])
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("7. Synthèse des traitements de vos données")]),
              _c("p", [
                _vm._v(
                  " Les données à caractère personnel d'un Utilisateur ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de sa relation avec SNCF TER Pays de la Loire et/ou TransWay SAS. Pour chacun des traitements suivants, les durées de conservation des données, les destinataires, le stockage et la finalité, sont : "
                )
              ]),
              _c("p", [
                _c("b", [
                  _vm._v(
                    " Les données liées aux habitudes de consommation sont anonymisées et utilisées à des fins statistiques et de conseils pour l'amélioration de l'animation de la plateforme. "
                  )
                ])
              ]),
              _c("p", [
                _vm._v(
                  " Les données des Utilisateurs peuvent être utilisées à des fins d'élaboration de statistiques destinées à l'amélioration de l'animation de la plateforme. Dans ce cadre, les données sont anonymisées. Ces données sont utilisées par TransWay SAS via l'outil Tableau proposé par la société SALESFORCE. Les données des Utilisateurs ainsi collectées ne sont transmises à aucun tiers (tel que partenaire, marchands...) et ce de quelque manière et à quelque titre que ce soit. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Toutefois, les données permettant d'établir la preuve d'un droit ou d'un Contrat, devant être conservées au titre du respect d'une obligation légale et/ou pour la défense éventuelle des intérêts de TransWay SAS ou de SNCF TER Pays de la Loire en justice, le seront pendant la durée prévue par la loi en vigueur, soit pour une durée de cinq (5) ans à compter de leur collecte, sur un support d'archivage intermédiaire. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [
                _vm._v("8. Combien de temps sont conservées vos Données ?")
              ]),
              _c("p", [
                _vm._v(
                  " Les données à caractère personnel d'un utilisateur ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de sa relation avec SNCF TER Pays de la Loire et/ou TransWay SAS. "
                )
              ]),
              _c("div", [
                _c("h3", [
                  _vm._v(
                    "8.1. Création de compte et gestion des profils Utilisateurs"
                  )
                ]),
                _c("b", [_vm._v("Durée de conservation :")]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " 3 ans après la date de la fin de la relation commerciale / 1 mois après la suppression des données par l'utilisateur (supprimées) "
                    )
                  ])
                ]),
                _c("div", [_c("b", [_vm._v("Données collectées :")])]),
                _c("ul", [
                  _c("li", [_vm._v(" Nom")]),
                  _c("li", [_vm._v(" Prénom")]),
                  _c("li", [_vm._v(" Adresse e-mail")]),
                  _c("li", [_vm._v(" Date de naissance")]),
                  _c("li", [_vm._v(" Poids")])
                ]),
                _c("div", [_c("b", [_vm._v("Base légale : ")])]),
                _c("ul", [
                  _c("li", [_vm._v(" Intérêt légitime")]),
                  _c("li", [_vm._v("Consentement ")]),
                  _c("li", [_vm._v("Contrat")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les destinataires de données : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("TransWay SAS")]),
                  _c("li", [_vm._v("Le client ou le(s) partenaire(s)")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les données sont stockées chez : ")])
                ]),
                _c("ul", [_c("li", [_vm._v("Serveur DRI")])])
              ]),
              _c("div", [
                _c("h3", [
                  _vm._v("8.2. Traçage des accès et gestion des incidents")
                ]),
                _c("div", [_c("b", [_vm._v("Durée de conservation :")])]),
                _c("span", [
                  _vm._v(
                    "1 année après la date de la collecte des données (supprimées)"
                  )
                ]),
                _c("div", [_c("b", [_vm._v("Données collectées :")])]),
                _c("ul", [_c("li", [_vm._v(" Logs")])]),
                _c("div", [_c("b", [_vm._v("Base légale : ")])]),
                _c("ul", [
                  _c("li", [_vm._v(" Intérêt légitime")]),
                  _c("li", [_vm._v("Consentement ")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les destinataires de données : ")])
                ]),
                _c("ul", [_c("li", [_vm._v("TransWay SAS")])]),
                _c("div", [
                  _c("b", [_vm._v("Les données sont stockées chez : ")])
                ]),
                _c("ul", [_c("li", [_vm._v("Serveur DRI")])])
              ]),
              _c("div", [
                _c("h3", [
                  _vm._v(
                    "8.3. Suivi des violations et réclamations RGPD par Digitémis"
                  )
                ]),
                _c("div", [_c("b", [_vm._v("Durée de conservation :")])]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " La prescription des actions afférentes à la violation/réclamation, ou en cas de contentieux : à partir de la date d'expiration des voies de recours. "
                    )
                  ])
                ]),
                _c("div", [_c("b", [_vm._v("Données collectées :")])]),
                _c("ul", [
                  _c("li", [_vm._v(" Nom")]),
                  _c("li", [_vm._v("Prénom")]),
                  _c("li", [_vm._v("Adresse e-mail:")]),
                  _c("li", [_vm._v("Date de naissance")]),
                  _c("li", [_vm._v("Logs")]),
                  _c("li", [_vm._v("Les données de GPS, ")]),
                  _c("li", [_vm._v("Les données d'accélération")]),
                  _c("li", [_vm._v("Les données d'activité détectées")]),
                  _c("li", [_vm._v("Système d'exploitation")]),
                  _c("li", [_vm._v("Version du système d'exploitation (OS)")]),
                  _c("li", [
                    _vm._v("Modèle de téléphone (par exemple : iPhone 13)")
                  ])
                ]),
                _c("div", [_c("b", [_vm._v("Base légale : ")])]),
                _c("ul", [_c("li", [_vm._v(" Intérêt légitime")])]),
                _c("div", [
                  _c("b", [_vm._v("Les destinataires de données : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("TransWay SAS")]),
                  _c("li", [_vm._v("DPO")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les données sont stockées chez : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("TransWay SAS")]),
                  _c("li", [_vm._v("DPO externe")]),
                  _c("li", [_vm._v("DPO client")])
                ])
              ]),
              _c("div", [
                _c("h3", [
                  _vm._v(
                    "8.4. Réalisation de statistiques et conseils client pour l'amélioration du service"
                  )
                ]),
                _c("div", [_c("b", [_vm._v("Durée de conservation :")])]),
                _c("ul", [_c("li", [_vm._v("N'A (anonymisées)")])]),
                _c("div", [_c("b", [_vm._v("Données collectées :")])]),
                _c("ul", [_c("li", [_vm._v(" Action de mobilité")])]),
                _c("div", [_c("b", [_vm._v("Base légale : ")])]),
                _c("ul", [_c("li", [_vm._v("Contrat")])]),
                _c("div", [
                  _c("b", [_vm._v("Les destinataires de données : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("TransWay SAS")]),
                  _c("li", [_vm._v("Le client ou le(s) partenaire(s)")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les données sont stockées chez : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Ordinateurs TransWay SAS")]),
                  _c("li", [_vm._v("Serveur DRI")]),
                  _c("li", [
                    _vm._v(
                      "L'outil Tableau (données anonymisées transmises à cet outil)"
                    )
                  ])
                ])
              ]),
              _c("div", [
                _c("h3", [
                  _vm._v(
                    "8.5. Gestion des demandes Utilisateurs à travers le formulaire de contact / SAV"
                  )
                ]),
                _c("div", [_c("b", [_vm._v("Durée de conservation :")])]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "5 années après la date de la collecte des données (supprimées)"
                    )
                  ])
                ]),
                _c("div", [_c("b", [_vm._v("Données collectées :")])]),
                _c("ul", [
                  _c("li", [_vm._v(" Nom")]),
                  _c("li", [_vm._v("Prénom")]),
                  _c("li", [_vm._v("Adresse e-mail:")]),
                  _c("li", [_vm._v("Date de naissance")]),
                  _c("li", [_vm._v("Logs")]),
                  _c("li", [_vm._v("Les données de GPS, ")]),
                  _c("li", [_vm._v("Les données d'accélération")]),
                  _c("li", [_vm._v("Les données d'activité détectées")]),
                  _c("li", [_vm._v("Système d'exploitation")]),
                  _c("li", [_vm._v("Version du système d'exploitation (OS)")]),
                  _c("li", [
                    _vm._v("Modèle de téléphone (par exemple : iPhone 13)")
                  ])
                ]),
                _c("div", [_c("b", [_vm._v("Base légale : ")])]),
                _c("ul", [_c("li", [_vm._v("Contrat")])]),
                _c("div", [
                  _c("b", [_vm._v("Les destinataires de données : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("TransWay SAS")]),
                  _c("li", [_vm._v("Le client ou le(s) partenaire(s)")])
                ]),
                _c("div", [
                  _c("b", [_vm._v("Les données sont stockées chez : ")])
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Ordinateurs TransWay SAS")]),
                  _c("li", [_vm._v("Client via l'outil Jira")])
                ])
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("9. Quels sont vos droits sur vos Données ?")]),
              _c("p", [
                _c("b", [
                  _vm._v(
                    "Dans les limites et conditions autorisées par la réglementation en vigueur, vous pouvez : "
                  )
                ])
              ]),
              _c("ul", { staticClass: "space-y-2" }, [
                _c("li", [_vm._v(" Accéder à l'ensemble de vos données. ")]),
                _c("li", [
                  _vm._v(
                    " Rectifier et mettre à jour vos données : les Utilisateurs peuvent à tout instant modifier leurs nom, prénom, et poids depuis leur profil. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Effacer vos données, sous réserve de motifs légitimes. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Vous opposez au traitement de vos données pour motifs légitimes et au traitement de vos données à des fins de prospection sans aucun motif. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Demander la portabilité de vos données, pour les traitements reposant sur votre consentement ou sur l'exécution d'un contrat conclu ou à conclure. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Demander une limitation des traitements que nous opérons relatifs à vos données. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Retirer votre consentement à tout moment (pour tout traitement soumis à votre consentement). "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Droit de définir des directives post-mortem, à savoir le droit de définir l'avenir de vos données personnelles en cas de décès. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Introduire une réclamation auprès d'une autorité de contrôle compétente, à savoir celle du pays de l'Espace Économique Européen dans lequel se trouve votre résidence habituelle, ou votre lieu de travail ou le lieu où la violation présumée de la réglementation aurait été commise (en France, la CNIL). "
                  )
                ])
              ]),
              _c("p", [
                _vm._v(
                  " Néanmoins TransWay SAS et/ou SNCF TER Pays de la Loire pourront dans certaines situations ne pas répondre favorablement à votre demande notamment si les données concernées par l'exercice de votre droit sont nécessaires à la constatation, à l'exercice ou à la défense de droits en justice ou si TransWay SAS et/ou SNCF TER Pays de la Loire sont tenues de les conserver notamment lorsque le traitement concerné repose sur un autre fondement que votre consentement (respect d'une obligation légale ou réglementaire, exécution de dispositions contractuelles ou de mesures précontractuelles, poursuite d'un intérêt légitime). En outre, vous avez la possibilité de nous communiquer des directives relatives à la conservation, à l'effacement et à la communication de vos Données après votre décès, lesquelles directives peuvent être enregistrées également auprès « d'un tiers de confiance numérique certifié ». Ces directives peuvent désigner une personne chargée de leur exécution. A défaut, vos héritiers seront désignés. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("10. Comment exercer vos droits ?")]),
              _c("p", [
                _vm._v(
                  " Pour exercer vos droits, merci de contacter notre Délégué à la Protection Des Données par courrier électronique ou postal en indiquant, nom, prénom, coordonnées de contact et en fournissant une copie de votre pièce d'identité : "
                )
              ]),
              _c("ul", [
                _c("li", [_vm._v("Délégué à la Protection Des Données")]),
                _c("li", [
                  _vm._v("Adresse postale : SNCF TER Pays de la Loire")
                ]),
                _c("li", [_vm._v("Courriel : dpo-sncf-voyageurs@sncf.fr")])
              ]),
              _c("p", [
                _vm._v(
                  " Dans le cas de demandes touchant à des traitements de la responsabilité de la société TransWay SAS, merci de contacter le Délégué à la Protection Des Données par courrier électronique ou postal en indiquant, nom, prénom, coordonnées de contact et en fournissant une copie de votre pièce d'identité : "
                )
              ]),
              _c("ul", [
                _c("li", [_vm._v("Direction RGPD")]),
                _c("li", [
                  _vm._v(
                    "Adresse : TransWay SAS, 9 rue du petit Châtelier, 44300 Nantes"
                  )
                ]),
                _c("li", [_vm._v("Courriel : rgpd@transway.fr")])
              ]),
              _c("p", [
                _vm._v(
                  " Si l'Utilisateur souhaite supprimer ses données il lui suffit de compléter le formulaire de contact en choisissant l'objet “Réclamation RGPD”. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à TransWay SAS et SNCF TER Pays de la Loire par la loi, notamment en matière de conservation ou d'archivage des documents. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("11. Sécurité")]),
              _c("p", [
                _vm._v(
                  " Pour assurer la sécurité et la confidentialité des Données Personnelles, TransWay SAS utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, l'encryption et mot de passe. Lors du traitement des Données à Caractère Personnel, TransWay SAS prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction. "
                )
              ]),
              _c("p", [_vm._v("Ces mesures sont notamment les suivantes : ")]),
              _c("b", [_vm._v("Hébergement des données en France : ")]),
              _c("ul", [_c("li", [_vm._v("DRI")])]),
              _c("b", [_vm._v("Bouclier de sécurité black shield : ")]),
              _c("ul", [
                _c("li", [_vm._v("Protection déni de services (DDoS)")]),
                _c("li", [
                  _vm._v("Protection bordure de réseau (filtrage IP dynamique)")
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(" Protection service mail, fichiers (backdoor) ")
                  ]),
                  _c("ul", [
                    _c("li", [_vm._v("Solution antivirus et antispam")])
                  ])
                ])
              ]),
              _c("p", [_c("b", [_vm._v("WAF - Firewall Applicatif")])]),
              _c("p", [
                _c("b", [_vm._v("Transmission cryptée des données en SSL")])
              ]),
              _c("ul", [_c("li", [_vm._v("Solutions VPN / protocole https")])]),
              _c("p", [
                _vm._v(
                  " TransWay SAS s'interdit de traiter, héberger ou transférer les Informations collectées sur ses Utilisateurs vers un pays situé en dehors de l'Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement l'Utilisateur. Pour autant, TransWay SAS reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu'ils présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données [RGPD : n° 2016-679]. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " TransWay SAS s'engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu'elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l'intégrité ou la confidentialité des Informations de l'Utilisateur est portée à la connaissance de TransWay SAS et/ou de SNCF TER Pays de la Loire, ceux-ci s'engagent conjointement à informer l'Utilisateur et lui communiquer les mesures de corrections prises. Cet engagement s'étend également aux prestataires et sous-traitants de TransWay SAS. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Les données de mobilité transmises à notre prestataire de services via une connexion sécurisée par encryption TLS et hébergées sur des serveurs situées au sein de l'Union Européenne (Allemagne). Toutes les données sont encryptées via des algorithmes de cryptage AWS - AES-256 et ne contiennent aucune information personnelle de l'utilisateur dans le respect du Règlement Général sur la Protection des Données. "
                )
              ])
            ]),
            _c("article", { staticClass: "article" }, [
              _c("h2", [_vm._v("12. Notification d'incident")]),
              _c("p", [
                _vm._v(
                  " Quelque soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. TransWay SAS et/ou SNCF TER Pays de la Loire ne peuvent en conséquence pas garantir une sécurité absolue. Si TransWay SAS et/ou SNCF TER Pays de la Loire prennent connaissance d'une brèche de la sécurité, TransWay SAS et SNCF TER Pays de la Loire avertiront les Utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Les procédures de notification d'incident tiennent compte des obligations légales au niveau national voire européen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " TransWay SAS et SNCF TER Pays de la Loire s'engagent à informer pleinement ses Utilisateurs de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting. "
                )
              ]),
              _vm._v(
                " En cas de menace avérée pour la protection des données TransWay SAS et SNCF TER Pays de la Loire pourront conjointement suspendre l'accès au service Ecomotive sans notification préalable et ce jusqu'au rétablissement des conditions normales d'exploitation. "
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }