

























import { Vue, Component } from 'vue-property-decorator';
import OfferSlider from '@/components/Offer/Slider.vue';

@Component({
  components: {
    OfferSlider,
  },
})
export default class DashboardHintOffer extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
