import { render, staticRenderFns } from "./SvgPictoCopy.vue?vue&type=template&id=3662da31&"
import script from "./SvgPictoCopy.vue?vue&type=script&lang=ts&"
export * from "./SvgPictoCopy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3662da31')) {
      api.createRecord('3662da31', component.options)
    } else {
      api.reload('3662da31', component.options)
    }
    module.hot.accept("./SvgPictoCopy.vue?vue&type=template&id=3662da31&", function () {
      api.rerender('3662da31', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Svg/SvgPictoCopy.vue"
export default component.exports