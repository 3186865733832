var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-last-order",
      class: { "is-mobile": _vm.isMobile }
    },
    [
      _c(
        "div",
        {
          staticClass: "dashboard-last-order__header",
          on: {
            click: function($event) {
              return _vm.navigateToOrders("card")
            }
          }
        },
        [
          _c("h3", { staticClass: "dashboard-last-order__header-title" }, [
            _vm._v("Mes commandes")
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "dashboard-last-order__content" },
        [
          !_vm.isMobile
            ? [_c("OrdersDatatable", { attrs: { limit: 2, infinite: false } })]
            : [_c("OrdersMobile", { attrs: { limit: 3, infinite: false } })]
        ],
        2
      ),
      _c(
        "v-btn",
        {
          staticClass: "dashboard-last-order__btn",
          attrs: { text: "", color: "homeglobal_sous_titre" },
          on: {
            click: function($event) {
              return _vm.navigateToOrders("btn")
            }
          }
        },
        [_vm._v(" Voir plus ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }