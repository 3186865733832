var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SSOSignInForm primary-text-color" },
    [
      _c("p", {
        staticClass: "text-center mb-12 signin-title",
        domProps: { innerHTML: _vm._s(_vm.ssoOptions.signInCustomSubtitle) }
      }),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("v-text-field", {
            staticClass: "form-field",
            attrs: {
              rules: _vm.formRules.email,
              error: !!_vm.error,
              label: "Email",
              required: "",
              "validate-on-blur": ""
            },
            model: {
              value: _vm.form.externalEmail,
              callback: function($$v) {
                _vm.$set(_vm.form, "externalEmail", $$v)
              },
              expression: "form.externalEmail"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-field",
            attrs: {
              rules: _vm.formRules.externalPassword,
              type: _vm.showPassword ? "text" : "password",
              label: "Mot de passe",
              "error-messages": _vm.error,
              required: "",
              "validate-on-blur": ""
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            _vm.showPassword = !_vm.showPassword
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showPassword
                                ? "fas fa-eye"
                                : "fas fa-eye-slash"
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.form.externalPassword,
              callback: function($$v) {
                _vm.$set(_vm.form, "externalPassword", $$v)
              },
              expression: "form.externalPassword"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "primary-button-color mt-4",
              attrs: {
                loading: _vm.submitting,
                type: "submit",
                rounded: "",
                block: "",
                elevation: "0"
              }
            },
            [_vm._v(" Me connecter ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "text-center mt-4" }, [
        _c(
          "a",
          {
            staticClass: "link-color text-subtitle-2 link-redirection",
            attrs: {
              href: _vm.ssoOptions.passwordForgottenURL,
              target: "_blank"
            }
          },
          [_vm._v(" Mot de passe oublié ? ")]
        )
      ]),
      _vm.signupRGPDMessage || _vm.signupRGPDMessage !== "<p></p>"
        ? _c(
            "div",
            { staticClass: "text-center mt-4 rgpd-message" },
            [
              _c("v-card-text", {
                domProps: { innerHTML: _vm._s(_vm.signupRGPDMessage) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showDialogError
        ? _c("SSOPartnerErrorDialog", {
            attrs: { "custom-message": _vm.ssoOptions.popupCustomMessage },
            on: {
              close: function($event) {
                _vm.showDialogError = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }