var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "request-confirm", "max-width": "600px" },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "request-confirm-card" } },
        [
          _c("v-card-title", { staticClass: "request-confirm-card-title" }, [
            _c(
              "div",
              { staticClass: "request-confirm-card-title-picto" },
              [_c("SvgSignin")],
              1
            ),
            _c("h1", { staticClass: "request-confirm-card-title-text" }, [
              _vm._v(_vm._s(_vm.titleText))
            ])
          ]),
          _c("v-card-text", { staticClass: "request-confirm-card-subtitle" }, [
            _vm._v(_vm._s(_vm.message))
          ]),
          _c("v-card-text", { staticClass: "request-confirm-card-subtitle" }, [
            _vm._v(_vm._s(_vm.subtitleMessage))
          ]),
          _c("v-card-text", { staticClass: "request-confirm-card-subtitle" }, [
            _vm._v(_vm._s(_vm.subtitleMessage2))
          ]),
          _c(
            "v-btn",
            {
              staticClass: "request-confirm-card-button",
              attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
              on: { click: _vm.onConfirmClick }
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          ),
          _c("div", { staticClass: "request-confirm-card-bottom" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }