





























import { Vue, Component, Prop } from 'vue-property-decorator';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';
import * as OrderService from '@/services/merchantSpace/OrderService';
import SvgPdf from "@/components/Svg/SvgPdf.vue";

const moment = require('moment');

@Component({
  components: {
    SvgPdf,
    OfferAvatar,
  },
})
export default class OrderLineWeb extends Vue {
  @Prop({
    type: Number,
    required: true,
    default: -1,
  })
  readonly number!: number;

  @Prop({
    type: String,
    required: true,
    default: '22/02/2019',
  })
  readonly date!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly companyName!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly companyAvatar!: string;

  @Prop({
    type: Number,
    required: true,
    default: 200,
  })
  readonly points!: number;

  @Prop({
    type: String,
    required: true,
    default: '-20% sur votre premier tirage',
  })
  readonly title!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly status!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly uuid!: string;

  async getPDF(number: number, uuid: string): Promise<void> {
    return OrderService.downloadPDF(number, uuid);
  }

  get formatedDate(): string {
    return moment(this.date).format('DD/MM/YYYY');
  }

  get compCompanyAvatar() {
    return this.companyAvatar ? `${process.env.VUE_APP_API_URL}/api${this.companyAvatar}` : '';
  }

  get companyInitials() {
    return this.companyName.charAt(0).toUpperCase();
  }
}
