


























import { Vue, Component } from 'vue-property-decorator';
import SvgQuestion from '@/components/Svg/SvgQuestion.vue';

@Component({
  components: {
    SvgQuestion,
  },
})
export default class DashboardQuestionWeb extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  openQuestion() {
    this.$emit('triggerQuestionDialog');
  }
}
