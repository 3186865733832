var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mini-profile",
      class: { "has-illustration": _vm.hasIllustration }
    },
    [
      _vm.hasIllustration
        ? _c("MiniProfileIcon", { staticClass: "mini-profile__illustration" })
        : _vm._e(),
      _c("div", { staticClass: "mini-profile__content" }, [
        _c("div", [_c("Avatar", { attrs: { initials: _vm.initials } })], 1),
        _c("span", { staticClass: "mini-profile__name mb-10" }, [
          _vm._v(_vm._s(_vm.fullName))
        ]),
        _c(
          "button",
          {
            staticClass: "mini-profile__button mb-8",
            attrs: { "data-cy": "mini-profile-button" },
            on: {
              click: function($event) {
                return _vm.navigateToUpdateProfile()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }