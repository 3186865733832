var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partners", class: [_vm.isWeb] },
    [
      _c("div", { staticClass: "partners__header" }, [
        _c(
          "div",
          { staticClass: "partners__header-title" },
          [
            _c("h1", {
              class: _vm.titleClass,
              domProps: { innerHTML: _vm._s(_vm.title) }
            }),
            _c("h2", { staticClass: "partners__header-title-subtitle" }, [
              _vm._v(_vm._s(_vm.subtitle))
            ]),
            _vm.isWeb
              ? _c("p", { staticClass: "partners__description" }, [
                  _vm._v(" " + _vm._s(_vm.description) + " ")
                ])
              : _vm._e(),
            _vm.leftButton && !_vm.noPartnersButton
              ? _c(
                  "v-btn",
                  {
                    staticClass: "partners__btn__left",
                    attrs: {
                      href: _vm.redirectUrl || _vm.merchantUrl,
                      color: "homeglobal_fond_bouton",
                      large: "",
                      rounded: "",
                      target: "_blank"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.btnPartnerText) + " ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "partners__header-img-place" }, [
          _vm.typeImg === "store"
            ? _c(
                "div",
                [
                  _vm.thirdBlockImage
                    ? _c("img", {
                        class: _vm.radiusClass,
                        style: _vm.imageSquareStyle,
                        attrs: { src: _vm.compThirdBlockImage }
                      })
                    : _c("SvgHomePartnerPicto")
                ],
                1
              )
            : _vm._e(),
          _vm.typeImg === "shopping"
            ? _c(
                "div",
                [
                  _vm.thirdBlockImage
                    ? _c("img", {
                        class: _vm.radiusClass,
                        style: _vm.imageSquareStyle,
                        attrs: { src: _vm.compThirdBlockImage }
                      })
                    : _c("SvgHomePartnerPictoShopping")
                ],
                1
              )
            : _vm._e(),
          _vm.typeImg === "gif"
            ? _c(
                "div",
                [
                  _vm.thirdBlockImage
                    ? _c("img", {
                        class: _vm.radiusClass,
                        style: _vm.imageSquareStyle,
                        attrs: { src: _vm.srcImg }
                      })
                    : _c("SvgHomePartnerPictoShopping")
                ],
                1
              )
            : _vm._e()
        ]),
        !_vm.isWeb
          ? _c("p", { staticClass: "partners__description mobile" }, [
              _vm._v(" " + _vm._s(_vm.description) + " ")
            ])
          : _vm._e()
      ]),
      !_vm.noPartnersLogo
        ? _c(
            "div",
            { staticClass: "partners__logos" },
            _vm._l(_vm.partnersLogo, function(logo) {
              return _c(
                "div",
                { key: logo.alt, staticClass: "partners__logos-img" },
                [
                  _c("img", {
                    attrs: { alt: "logo partenaire " + logo.alt, src: logo.img }
                  })
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.noPartnersInducement
        ? _c("p", { staticClass: "partners__merchant" }, [
            _vm._v(" Vous êtes partenaire ? ")
          ])
        : _vm._e(),
      !_vm.noPartnersInducement
        ? _c("p", { staticClass: "partners__merchant" }, [
            _vm._v(" Rejoignez notre communauté ! ")
          ])
        : _vm._e(),
      !_vm.leftButton && !_vm.noPartnersButton
        ? _c(
            "v-btn",
            {
              staticClass: "partners__btn",
              attrs: {
                href: _vm.redirectUrl || _vm.merchantUrl,
                color: "homeglobal_fond_bouton",
                large: "",
                rounded: "",
                target: "_blank"
              }
            },
            [_vm._v(" " + _vm._s(_vm.btnPartnerText) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }