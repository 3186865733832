var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-wrapper" },
    [
      _vm.signupMessage.signupMessageActivated
        ? _c("SignupMessage", {
            attrs: {
              logo: _vm.fullLogoUrl,
              title: _vm.signupMessage.signupMessageTitle,
              desc: _vm.signupMessage.signupMessageDescription
            },
            on: { showSignin: _vm.showSignin, showSignup: _vm.showSignup }
          })
        : _c("SignupStep", {
            attrs: {
              formValues: _vm.formValues,
              loading: _vm.stepLoading,
              error: _vm.curError
            },
            on: { onValidate: _vm.onValidateStep, showSignin: _vm.showSignin }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }