var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 387.377 292.1",
        preserveAspectRatio: "xMinYMin slice"
      }
    },
    [
      _c("title"),
      _c(
        "g",
        {
          staticClass: "cls-1",
          attrs: { id: "Calque_14", transform: "translate(0 250) rotate(-90)" }
        },
        [
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2463",
              "data-name": "Tracé 2463",
              d:
                "M190.394,0C203.9,13.507,210,29.834,210,50.422A74.65,74.65,0,1,1,80.3,0Z",
              transform: "translate(-21.313 -0.103)"
            }
          }),
          _c("circle", {
            staticClass: "cls-2",
            attrs: {
              id: "Ellipse_173",
              "data-name": "Ellipse 173",
              cx: "51.602",
              cy: "51.602",
              r: "51.602",
              transform: "translate(87.645 232.665)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2464",
              "data-name": "Tracé 2464",
              d:
                "M87.3,564.778C100.479,549.3,120.478,539.6,142.968,539.6a73.148,73.148,0,0,1,55.471,24.916",
              transform: "translate(-30.472 -185.896)"
            }
          }),
          _c("path", {
            staticClass: "cls-3",
            attrs: {
              id: "Tracé_2465",
              "data-name": "Tracé 2465",
              d: "M253.2,578",
              transform: "translate(-87.594 -199.118)"
            }
          }),
          _c("path", {
            staticClass: "cls-3",
            attrs: {
              id: "Tracé_2466",
              "data-name": "Tracé 2466",
              d: "M128.91,578H196.9",
              transform: "translate(-31.298 -199.118)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_807",
              "data-name": "Ligne 807",
              x2: "0.197",
              y2: "7.54",
              transform: "translate(188.227 362.687)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_808",
              "data-name": "Ligne 808",
              x2: "0.197",
              y2: "4.852",
              transform: "translate(166.655 365.375)"
            }
          }),
          _c("line", {
            staticClass: "cls-5",
            attrs: {
              id: "Ligne_809",
              "data-name": "Ligne 809",
              x1: "3.934",
              y2: "2.951",
              transform: "translate(140.493 361.113)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2467",
              "data-name": "Tracé 2467",
              d: "M429.1,254.7",
              transform: "translate(-148.159 -87.801)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2468",
              "data-name": "Tracé 2468",
              d:
                "M327.988,340.83A73.962,73.962,0,1,1,293.761,201.3c13.114,0,23.539,2.426,34.227,8.393",
              transform: "translate(-76.094 -69.414)"
            }
          }),
          _c("line", {
            staticClass: "cls-6",
            attrs: {
              id: "Ligne_810",
              "data-name": "Ligne 810",
              y2: "131.071",
              transform: "translate(251.894 139.623)"
            }
          }),
          _c("circle", {
            staticClass: "cls-7",
            attrs: {
              id: "Ellipse_174",
              "data-name": "Ellipse 174",
              cx: "52.324",
              cy: "52.324",
              r: "52.324",
              transform: "translate(1.554 249.712)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_2469",
              "data-name": "Tracé 2469",
              d: "M440.5,151.7",
              transform: "translate(-152.084 -52.336)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_2470",
              "data-name": "Tracé 2470",
              d:
                "M364.83,200.1c-21.965,0-38.03-17.835-38.03-39.8s16.064-39.8,38.03-39.8",
              transform: "translate(-112.936 -41.593)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2471",
              "data-name": "Tracé 2471",
              d:
                "M-.2,0V62.552s14.294,13.507,41.964,5.442c0,0-9.9-41.964,17.572-67.994Z",
              transform: "translate(-0.344 -0.103)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2472",
              "data-name": "Tracé 2472",
              d:
                "M353.587,528.206l-41.636.131s-38.292-35.472-2.557-78.813c16.982-18.753,44.128-16,44.128-16v94.681Z",
              transform: "translate(-101.693 -149.324)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2473",
              "data-name": "Tracé 2473",
              d:
                "M-.2,518.374l.131,37.112H57.172s11.606-11.343,16-13.769C73.171,541.716,49.7,497.064-.2,518.374Z",
              transform: "translate(-0.344 -176.603)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2474",
              "data-name": "Tracé 2474",
              d:
                "M-1.2,254.2a74.687,74.687,0,0,1,74.682,74.682A74.687,74.687,0,0,1-1.2,403.565",
              transform: "translate(0 -87.628)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_811",
              "data-name": "Ligne 811",
              y1: "54.619",
              x2: "42.751",
              transform: "translate(112.43 145.655)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_812",
              "data-name": "Ligne 812",
              y1: "82.944",
              x2: "62.356",
              transform: "translate(114.069 141.262)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_813",
              "data-name": "Ligne 813",
              y1: "95.468",
              x2: "74.879",
              transform: "translate(119.052 143.623)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_814",
              "data-name": "Ligne 814",
              y1: "106.221",
              x2: "81.633",
              transform: "translate(127.183 148.999)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_815",
              "data-name": "Ligne 815",
              y1: "109.893",
              x2: "81.633",
              transform: "translate(137.871 153.786)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_816",
              "data-name": "Ligne 816",
              y1: "109.893",
              x2: "81.633",
              transform: "translate(150.656 160.933)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_817",
              "data-name": "Ligne 817",
              y1: "100.713",
              x2: "74.748",
              transform: "translate(165.541 176.145)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_818",
              "data-name": "Ligne 818",
              y1: "79.666",
              x2: "63.405",
              transform: "translate(182.129 194.897)"
            }
          }),
          _c("line", {
            staticClass: "cls-9",
            attrs: {
              id: "Ligne_819",
              "data-name": "Ligne 819",
              y1: "59.733",
              x2: "47.472",
              transform: "translate(199.374 214.043)"
            }
          }),
          _c("path", {
            staticClass: "cls-6",
            attrs: {
              id: "Tracé_2475",
              "data-name": "Tracé 2475",
              d: "M318.8-.3h43.275V16.813S335.126,18.125,318.8-.3Z",
              transform: "translate(-110.181)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_820",
              "data-name": "Ligne 820",
              x1: "2.36",
              y2: "7.213",
              transform: "translate(195.44 93.201)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_821",
              "data-name": "Ligne 821",
              x2: "3.082",
              y2: "6.819",
              transform: "translate(197.866 67.367)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_822",
              "data-name": "Ligne 822",
              x2: "7.147",
              y2: "2.36",
              transform: "translate(216.618 67.367)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_823",
              "data-name": "Ligne 823",
              x1: "1.18",
              y2: "7.409",
              transform: "translate(224.683 43.828)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_824",
              "data-name": "Ligne 824",
              x1: "5.508",
              y2: "5.114",
              transform: "translate(202.193 34.648)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_825",
              "data-name": "Ligne 825",
              x1: "5.049",
              y1: "5.639",
              transform: "translate(194.718 12.486)"
            }
          }),
          _c("line", {
            staticClass: "cls-10",
            attrs: {
              id: "Ligne_826",
              "data-name": "Ligne 826",
              y1: "5.508",
              x2: "0.393",
              transform: "translate(215.897 8.289)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_827",
              "data-name": "Ligne 827",
              y1: "5.245",
              x2: "0.59",
              transform: "translate(154.591 42.319)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_828",
              "data-name": "Ligne 828",
              y1: "4.131",
              x2: "3.344",
              transform: "translate(175.179 19.043)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_829",
              "data-name": "Ligne 829",
              y1: "5.311",
              x2: "0.131",
              transform: "translate(185.67 47.958)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_830",
              "data-name": "Ligne 830",
              y1: "7.999",
              x2: "3.737",
              transform: "translate(156.295 64.809)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_831",
              "data-name": "Ligne 831",
              x2: "8.786",
              y2: "0.393",
              transform: "translate(167.901 84.152)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_832",
              "data-name": "Ligne 832",
              x2: "1.115",
              y2: "6.622",
              transform: "translate(150.722 5.732)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_833",
              "data-name": "Ligne 833",
              x2: "4.786",
              y2: "4.721",
              transform: "translate(126.265 1.536)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_834",
              "data-name": "Ligne 834",
              x2: "0.197",
              y2: "6.688",
              transform: "translate(109.611 11.109)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_835",
              "data-name": "Ligne 835",
              x1: "1.508",
              y2: "4.655",
              transform: "translate(133.543 29.271)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_836",
              "data-name": "Ligne 836",
              x1: "4.852",
              y1: "0.656",
              transform: "translate(106.922 39.5)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_837",
              "data-name": "Ligne 837",
              x1: "0.066",
              y1: "4.918",
              transform: "translate(98.071 55.04)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_838",
              "data-name": "Ligne 838",
              x1: "2.819",
              y1: "4.065",
              transform: "translate(126.003 59.105)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_839",
              "data-name": "Ligne 839",
              x1: "1.508",
              y1: "4.655",
              transform: "translate(111.774 75.3)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_840",
              "data-name": "Ligne 840",
              x1: "0.262",
              y1: "4.918",
              transform: "translate(137.018 81.792)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_841",
              "data-name": "Ligne 841",
              y1: "3.344",
              x2: "3.606",
              transform: "translate(136.166 104.937)"
            }
          }),
          _c("line", {
            staticClass: "cls-11",
            attrs: {
              id: "Ligne_842",
              "data-name": "Ligne 842",
              x1: "1.18",
              y1: "4.786",
              transform: "translate(162.983 106.511)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_843",
              "data-name": "Ligne 843",
              x1: "7.54",
              y1: "0.197",
              transform: "translate(24.306 101.659)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_844",
              "data-name": "Ligne 844",
              x1: "6.032",
              y2: "4.524",
              transform: "translate(32.502 118.051)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_845",
              "data-name": "Ligne 845",
              x2: "1.115",
              y2: "7.475",
              transform: "translate(49.157 130.575)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_846",
              "data-name": "Ligne 846",
              y1: "1.18",
              x2: "7.409",
              transform: "translate(22.536 139.951)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_847",
              "data-name": "Ligne 847",
              x2: "6.163",
              y2: "4.328",
              transform: "translate(5.881 119.821)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_848",
              "data-name": "Ligne 848",
              y1: "6.95",
              x2: "2.754",
              transform: "translate(3.455 93.332)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_849",
              "data-name": "Ligne 849",
              x2: "7.54",
              y2: "0.459",
              transform: "translate(38.534 155.95)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_850",
              "data-name": "Ligne 850",
              x2: "4.459",
              y2: "6.098",
              transform: "translate(10.471 157.851)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_851",
              "data-name": "Ligne 851",
              x2: "7.475",
              y2: "0.393",
              transform: "translate(35.649 176.604)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_852",
              "data-name": "Ligne 852",
              y1: "5.508",
              x2: "5.18",
              transform: "translate(13.881 178.308)"
            }
          }),
          _c("line", {
            staticClass: "cls-4",
            attrs: {
              id: "Ligne_853",
              "data-name": "Ligne 853",
              x2: "7.278",
              y2: "1.901",
              transform: "translate(12.832 196.995)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }