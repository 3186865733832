var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "expirable-tooltip",
      attrs: { "data-cy": "peremption-tooltip" }
    },
    [
      _c(
        "span",
        { staticClass: "expirable-tooltip__balance primary-text-color" },
        [_vm._v(_vm._s(_vm.expirableBalance) + " pts")]
      ),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "expirable-tooltip__text" }, [
      _vm._v("Arrivent à expiration"),
      _c("br"),
      _vm._v("le mois prochain")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }