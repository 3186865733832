import { AuthTokens, User } from '@/services/userSpace/types';

enum USER_ROLES {
  USER = 'USER',
  USER_READ_ONLY = 'USER_READ_ONLY',
}

export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    displayHttpError: null,
    breadCrumb: [],
    platform: null,
    emailConfirmation: true,
    userServiceValidation: true,
    currentUser: null,
    userPoints: null,
    userPeremptionBalance: null,
    userSynchroURL: null,
  },
  mutations: {
    setAccessToken(state: any, token: string) {
      state.accessToken = token;
    },
    setRefreshToken(state: any, token: string) {
      state.refreshToken = token;
    },
    setDisplayHttpError(state: any, error: any) {
      state.displayHttpError = error;
    },
    setBreadCrumb(state: any, items: any[]) {
      state.breadCrumb = items;
    },
    setConfirmationEmail(state: any, emailConfirmation: boolean) {
      state.emailConfirmation = emailConfirmation;
    },
    setValidationUserService(state: any, userServiceValidation: boolean) {
      state.userServiceValidation = userServiceValidation;
    },
    setUser(state: any, user: User) {
      state.currentUser = user;
    },
    setUserPoints(state: any, points: number) {
      state.userPoints = points;
    },
    setUserPeremptionBalance(state: any, balance: number) {
      state.userPeremptionBalance = balance;
    },
    setUserSynchroURL(state: any, infos: string) {
      state.userSynchroURL = infos;
    },
  },
  actions: {
    async login({ commit }: any, { tokens }: { tokens: AuthTokens }) {
      commit('setAccessToken', tokens.accessToken);
      commit('setRefreshToken', tokens.refreshToken);
    },
    async resetAccessToken({ commit }: any) {
      commit('setAccessToken', null);
    },
    async resetTokens({ commit }: any) {
      commit('setAccessToken', null);
      commit('setRefreshToken', null);
      commit('setUser', null);
    },
    async showError({ commit }: any, error: any) {
      commit('setDisplayHttpError', error);
    },
    async hideError({ commit }: any) {
      commit('setDisplayHttpError', null);
    },
    async setBreadCrumb({ commit }: any, items: any) {
      commit('setBreadCrumb', items);
    },
    async setConfirmationEmail({ commit }: any, emailConfirmation: boolean) {
      commit('setConfirmationEmail', emailConfirmation);
    },
    async setValidationUserService({ commit }: any, userServiceValidation: boolean) {
      commit('setValidationUserService', userServiceValidation);
    },
    async setUser({ commit }: any, user: any) {
      commit('setUser', user);
    },
    async setUserPoints({ commit }: any, points: number) {
      commit('setUserPoints', points);
    },
    async setUserPeremptionBalance({ commit }: any, balance: number) {
      commit('setUserPeremptionBalance', balance);
    },
    async setUserSynchroURL({ commit }: any, url: string) {
      commit('setUserSynchroURL', url);
    },
  },
  getters: {
    getAccessToken(state: any) {
      return state.accessToken;
    },
    getRefreshToken(state: any) {
      return state.refreshToken;
    },
    getDisplayHttpError(state: any) {
      return state.displayHttpError ? state.displayHttpError : false;
    },
    getBreadCrumb(state: any) {
      return state.breadCrumb;
    },
    getConfirmationEmail(state: any) {
      if (state.currentUser) {
        return state.currentUser.emailConfirmation;
      }
      // je retourne true par défaut pour ne pas bloquer l'inscription
      return true;
    },
    getValidationUserService(state: any) {
      if (state.currentUser) {
        return state.currentUser.userServices.filter(({ revoked }) => !revoked).some(({ validated }) => !validated);
      }
      // je retourne true par défaut pour ne pas bloquer
      return true;
    },
    getUser(state: any): User {
      return state.currentUser;
    },
    getUserAddress(state: any) {
      const { city, deliveryAdress, zipCode } = state.currentUser;
      return { city, deliveryAdress, zipCode };
    },
    getUserPoints(state: any): number {
      return state.userPoints || 0;
    },
    getUserPeremptionBalance(state: any): number {
      return state.userPeremptionBalance || 0;
    },
    getUserSynchroURL(state: any): string {
      return state.userSynchroURL;
    },
    isConnected(state: any): boolean {
      return !!state.accessToken;
    },
    isReadOnly(state: any): boolean {
      return state.currentUser && state.currentUser.role.some((r) => r === USER_ROLES.USER_READ_ONLY);
    },
  },
};
