<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.129"
    height="30.128"
    viewBox="0 0 30.129 30.128"
  >
    <g
      id="chance"
      transform="translate(0 0.001)"
    >
      <path
        id="Tracé_3574"
        data-name="Tracé 3574"
        d="M0,0H30.129V30.128H0Z"
        transform="translate(0 -0.001)"
        fill="none"
      />
      <path
        id="Tracé_3575"
        data-name="Tracé 3575"
        d="M14.552,21.771,6.8,25.844l1.48-8.628-6.277-6.11,8.662-1.255L14.543,2l3.874,7.85,8.662,1.255L20.8,17.217l1.48,8.628Z"
        transform="translate(0.513 0.51)"
        class="st1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgLuckOfferType extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.st1 {
  stroke: var(--v-inactif-base);
  fill: none;
}
</style>
