





















































import { Component, Vue } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';
import CashbackPayOrder from '@/components/Account/Cashback/CashbackPayOrder.vue';
import CashbackTransaction from '@/components/Account/Cashback/CashbackTransaction.vue';
import ExecutePaymentCard from '@/components/Account/Cashback/ExecutePaymentCard.vue';
import PaymentValidatedCard from '@/components/Account/Cashback/PaymentValidatedCard.vue';
import * as UserService from '@/services/userSpace/UserService';
import ConfirmPassword from '@/components/Dialogs/ConfirmPassword.vue';
import PageTitle from '@/components/PageTitle.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import { User } from '@/services/userSpace/types';
import { getCashbackTransactions, getCashbackPayments, Transaction, Payment } from '@/api/resources/orderApi';

const moment = require('moment');

@Component({
  components: {
    ConfirmPassword,
    Avatar,
    PageTitle,
    CashbackPayOrder,
    CashbackTransaction,
    ExecutePaymentCard,
    PaymentValidatedCard,
  },
})
export default class Cashback extends Vue {
  user: User = {
    id: -1,
    email: '',
    password: '',
    externalAccountId: '',
    firstname: '',
    lastname: '',
    userServices: [],
  };

  transactions: Transaction[] = [];

  payments: Payment[] = [];

  transactionLoaded: boolean = false;

  paymentLoaded: boolean = false;

  priceAvailable: number = 0;

  showExecutePaymentCard: boolean = false;

  showPaymentValidatedCard: boolean = false;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get compPrice() {
    return `${this.priceAvailable}€`;
  }

  async mounted() {
    this.user = await UserService.getCurrentUser();
    try {
      this.transactions = await getCashbackTransactions(`${process.env.VUE_APP_PLATFORM_UUID}_${this.user.id}`);
      this.transactions.sort((a, b) => moment(b.date).format('YYYYMMDD') - moment(a.date).format('YYYYMMDD'));
      this.transactionLoaded = true;
      const unformatedPayments = await getCashbackPayments(`${process.env.VUE_APP_PLATFORM_UUID}_${this.user.id}`);

      this.payments = unformatedPayments.map((p) => {
        return {
          date: p.Date,
          comment: p.Comment,
          amout: p.Amount,
          detail: p.Detail,
          idUser: p.IdUser,
          orderId: p.OrderId,
          status: p.Status,
        };
      });

      this.paymentLoaded = true;
      this.payments.sort((a, b) => moment(b.date).format('YYYYMMDD') - moment(a.date).format('YYYYMMDD'));
      this.priceAvailable = this.calculatePriceAvailable();
    } catch (error) {
      throw error;
    }
  }

  calculatePriceAvailable(): number {
    if (this.transactions.length === 0) {
      return 0;
    }
    let sum = 0;
    for (let index = 0; index < this.transactions.length; index++) {
      sum += this.transactions[index].transacState.code === 3 ? this.transactions[index].gain : 0;
    }
    return sum;
  }

  claimCashback() {
    this.showExecutePaymentCard = true;
  }

  onValidate() {
    this.showExecutePaymentCard = false;
    this.showPaymentValidatedCard = true;
  }

  onProfileReturn() {
    this.$router.back();
  }
}
