



































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

const iban = require('iban');

@Component({
  components: {
    SvgPopup,
  },
})
export default class MigrationMessage extends Vue {
  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
