
























import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import PageTitle from '@/components/PageTitle.vue';

@Component({
  name: 'RGPDContent',
  components: {
    PageTitle,
  },
  filters: {
    removeBreakTag(value: string) {
      return value.replace('<br/>', '');
    },
  },
})
export default class LegalMentions extends Vue {
  title: string = 'Données personnelles - RGPD';

  key: string = 'rgpd';

  dateFormat(date: any) {
    const cguHistory = this.cgu.lastCguHistory;
    return moment(cguHistory[`${date}Updated`]).format('DD MMMM YYYY');
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get cgu() {
    return this.$store.getters['platform/getPlatform'].config.cgu;
  }

  get rgpd() {
    return this.cgu.rgpd ? this.cgu.rgpd : null;
  }
}
