var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Composant_109_1",
        "data-name": "Composant 109 – 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "83",
        height: "83.2",
        viewBox: "0 0 83 83.2"
      }
    },
    [
      _c("title"),
      _c(
        "g",
        {
          attrs: { id: "Composant_102_1", transform: "translate(0.085 0.985)" }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Composant_100_3",
                transform: "translate(0.115 29.115)"
              }
            },
            [
              _c("path", {
                staticClass: "cls-1",
                attrs: {
                  id: "secondary",
                  d: "M-.2-.1l53,53.2h-29L-.2,28.9V-.1Z"
                }
              }),
              _c("g", { attrs: { id: "accent_4_" } }, [
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    id: "accent_3_",
                    d:
                      "M16.7,23.9l2.5,2.5a3.27,3.27,0,0,1,.1,4.8,3.193,3.193,0,0,1-4.8-.1L13.2,30l-2.7,2.7L9.2,31.4ZM18,30c.7-.7.7-1.6-.2-2.5l-1.1-1.1-2.3,2.3,1.1,1.1C16.4,30.7,17.3,30.7,18,30Z"
                  }
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    id: "accent_2_",
                    d:
                      "M23.4,35.5l-1.6.7a1.39,1.39,0,0,0-.5-.7,1.736,1.736,0,0,0-1.8-.4l-3.3,3.3L14.9,37l5-5,1.2,1.2-.7.7a2.343,2.343,0,0,1,2.2.5A4.79,4.79,0,0,1,23.4,35.5Z"
                  }
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    id: "accent_1_",
                    d:
                      "M26.2,43.3a3.748,3.748,0,1,1,0-5.3A3.684,3.684,0,0,1,26.2,43.3ZM24.9,42a1.936,1.936,0,0,0,.1-2.8,1.852,1.852,0,0,0-2.8.1A1.914,1.914,0,1,0,24.9,42Z"
                  }
                })
              ])
            ]
          )
        ]
      ),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_1215",
          "data-name": "Rectangle 1215",
          width: "83",
          height: "83",
          transform: "translate(0)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }