<template>
  <svg
    id="Composant_117_1"
    data-name="Composant 117 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="325.861"
    height="170.601"
    viewBox="0 0 325.861 170.601"
  >
    <path
      id="Tracé_2176"
      data-name="Tracé 2176"
      class="cls-1"
      d="M578.3,500.778l-1.6,35.774s4.152,33.858,3.194,34.816-3.514,2.875-1.6,5.111,8.944,3.833,9.263,2.555.958-37.691.958-37.691l2.236-43.121Z"
      transform="translate(-423.402 -426.2)"
    />
    <path
      id="Tracé_2177"
      data-name="Tracé 2177"
      class="cls-2"
      d="M578.3,500.778l-1.6,35.774s4.152,33.858,3.194,34.816-3.514,2.875-1.6,5.111,8.944,3.833,9.263,2.555.958-37.691.958-37.691l2.236-43.121Z"
      transform="translate(-423.402 -426.2)"
    />
    <path
      id="Tracé_2178"
      data-name="Tracé 2178"
      class="cls-1"
      d="M555.584,741.222s-7.985,6.069-8.944,6.069-9.582,2.875-3.514,4.152,20.762.639,21.081-1.278-.958-7.733-.958-7.733Z"
      transform="translate(-399.091 -591.582)"
    />
    <path
      id="Tracé_2179"
      data-name="Tracé 2179"
      class="cls-2"
      d="M555.584,741.222s-7.985,6.069-8.944,6.069-9.582,2.875-3.514,4.152,20.762.639,21.081-1.278-.958-7.733-.958-7.733Z"
      transform="translate(-399.091 -591.582)"
    />
    <path
      id="accent"
      class="cls-3"
      d="M501.382,422.222s-3.514,1.278-3.514,2.236-3.194,13.1-3.833,13.735-.958,3.514-.958,3.514-3.194,7.347-2.555,7.347,6.069-1.278,6.388,0,2.875-7.985,2.875-7.985l5.43-10.86Z"
      transform="translate(-364.692 -374.475)"
    />
    <path
      id="Tracé_2181"
      data-name="Tracé 2181"
      class="cls-4"
      d="M490.7,507.5s.958,7.027,3.514,7.666,0-8.944,0-8.944Z"
      transform="translate(-364.873 -431.645)"
    />
    <path
      id="Tracé_2182"
      data-name="Tracé 2182"
      class="cls-4"
      d="M516.7,400.124s1.916,4.152,2.875,6.388,9.9-5.749,9.9-5.749-4.791-3.514-4.791-5.111S516.7,400.124,516.7,400.124Z"
      transform="translate(-382.567 -356.21)"
    />
    <path
      id="Tracé_2185"
      data-name="Tracé 2185"
      class="cls-1"
      d="M404.768,652.356a29.067,29.067,0,1,1,29.067-29.067,29.067,29.067,0,0,1-29.067,29.067Zm0-57.495A28.428,28.428,0,1,0,433.2,623.289,28.428,28.428,0,0,0,404.768,594.861Z"
      transform="translate(-286.605 -491.536)"
    />
    <path
      id="Tracé_2186"
      data-name="Tracé 2186"
      class="cls-1"
      d="M684.768,652.356a29.067,29.067,0,1,1,29.067-29.067,29.067,29.067,0,0,1-29.067,29.067Zm0-57.495A28.428,28.428,0,1,0,713.2,623.289,28.428,28.428,0,0,0,684.768,594.861Z"
      transform="translate(-477.168 -491.536)"
    />
    <circle
      id="Ellipse_120"
      data-name="Ellipse 120"
      class="cls-1"
      cx="1.916"
      cy="1.916"
      r="1.916"
      transform="translate(116.247 129.836)"
    />
    <circle
      id="Ellipse_121"
      data-name="Ellipse 121"
      class="cls-1"
      cx="1.916"
      cy="1.916"
      r="1.916"
      transform="translate(206.322 129.836)"
    />
    <path
      id="Tracé_2187"
      data-name="Tracé 2187"
      class="cls-1"
      d="M343.168,469.349l-.588-.251,16.694-39.167,47.34.966.092.144,23.317,36.413-.538.345-23.226-36.27-46.567-.951Z"
      transform="translate(-222.155 -340.345)"
    />
    <path
      id="Tracé_2188"
      data-name="Tracé 2188"
      class="cls-1"
      d="M500.125,483.939l12.27-43.267.615.174L501,483.183l32.1-4.091.08.634Z"
      transform="translate(-329.378 -347.655)"
    />
    <rect
      id="Rectangle_1049"
      data-name="Rectangle 1049"
      class="cls-1"
      width="0.639"
      height="46.981"
      transform="matrix(0.714, -0.7, 0.7, 0.714, 135.503, 99.714)"
    />
    <rect
      id="Rectangle_1050"
      data-name="Rectangle 1050"
      class="cls-1"
      width="7.284"
      height="0.639"
      transform="matrix(0.263, -0.965, 0.965, 0.263, 184.294, 89.824)"
    />
    <path
      id="Tracé_2189"
      data-name="Tracé 2189"
      class="cls-1"
      d="M388.964,400.876l-.623-.142,1.509-6.637H384.5v-4.152h.639v3.514h5.511Z"
      transform="translate(-250.686 -313.131)"
    />
    <path
      id="Tracé_2190"
      data-name="Tracé 2190"
      class="cls-1"
      d="M643.7,509.609s0-1.917,4.152-.958,16.929,3.514,17.887,3.194.639,1.917-3.514,2.875-8.944-1.278-9.9-1.916S643.7,509.609,643.7,509.609Z"
      transform="translate(-469.002 -433.115)"
    />
    <rect
      id="accent-2"
      data-name="accent"
      class="cls-3"
      width="8.305"
      height="3.194"
      transform="translate(154.896 133.669)"
    />
    <path
      id="Tracé_2191"
      data-name="Tracé 2191"
      class="cls-1"
      d="M619.09,691a6.388,6.388,0,1,1,6.388-6.388A6.388,6.388,0,0,1,619.09,691Zm0-12.138a5.749,5.749,0,1,0,5.749,5.749A5.749,5.749,0,0,0,619.09,678.861Z"
      transform="translate(-447.903 -548.705)"
    />
    <circle
      id="Ellipse_122"
      data-name="Ellipse 122"
      class="cls-4"
      cx="7.666"
      cy="7.666"
      r="7.666"
      transform="translate(129.343 31.776)"
    />
    <path
      id="Tracé_2192"
      data-name="Tracé 2192"
      class="cls-1"
      d="M555.415,498.845s-22.04,12.777-21.72,16.929,16.929,26.831,16.929,26.831,1.278,6.708,2.555,6.708,9.263,2.875,9.582.958-2.555-4.152-2.236-5.43-14.054-24.595-14.054-24.595,30.762-11.5,24.325-21.4S555.415,498.845,555.415,498.845Z"
      transform="translate(-394.13 -423.629)"
    />
    <path
      id="Tracé_2193"
      data-name="Tracé 2193"
      class="cls-1"
      d="M574.578,662.222s-3.833.958-4.472,2.236-11.818,5.43-8.624,6.388,21.72,1.278,22.359.639,0-8.053-2.555-8.019S575.855,664.458,574.578,662.222Z"
      transform="translate(-412.654 -537.816)"
    />
    <path
      id="accent-3"
      data-name="accent"
      class="cls-3"
      d="M519.576,413.23l7.187-8.784s8.145-2.4,11.659,2.4,13.1,16.61,16.29,19.165,7.666,12.457,6.069,12.777-10.541-3.514-14.054-.958-10.221,2.875-10.541,1.6S516.7,416.744,516.7,413.869s2.042-3.492,2.042-3.492Z"
      transform="translate(-382.567 -361.97)"
    />
    <rect
      id="Rectangle_1052"
      data-name="Rectangle 1052"
      class="cls-1"
      width="12.138"
      height="1.916"
      rx="0.958"
      transform="matrix(0.943, 0.333, -0.333, 0.943, 130.329, 72.929)"
    />
    <path
      id="Tracé_2195"
      data-name="Tracé 2195"
      class="cls-4"
      d="M447.8,500.222s-11.18,3.194-10.221,6.708,11.18-4.152,11.18-4.152Z"
      transform="translate(-328.677 -427.561)"
    />
    <path
      id="accent-4"
      data-name="accent"
      class="cls-3"
      d="M494.477,412.98s-4.472,3.833-5.43,8.3-5.749,12.457-5.749,12.457l-19.484,6.388s-1.278,6.069.639,6.069,23.317-4.472,23.956-5.43S502.781,421.6,502.781,421.6,504.059,411.7,494.477,412.98Z"
      transform="translate(-346.289 -368.108)"
    />
    <path
      id="Tracé_2197"
      data-name="Tracé 2197"
      class="cls-1"
      d="M501.915,365.074a1.512,1.512,0,0,1-.658.33.47.47,0,0,1-.544-.377,1.952,1.952,0,0,1-.747.958c-.366.191-.931.031-.984-.378a1.726,1.726,0,0,1-.4.839.6.6,0,0,1-.837.028,21.5,21.5,0,0,1,.112,2.664,3.392,3.392,0,0,0,.958,2.415c.979.852,2.451.642,3.739.48a1.136,1.136,0,0,1,.586.032c.482.205.46.877.467,1.4a3.892,3.892,0,0,0,3.408,3.7,1.951,1.951,0,0,0,1.45-.354c.366-.31.552-.815.971-1.048.681-.379,1.508.174,2.006.773s.989,1.337,1.761,1.447c1.021.145,1.8-.9,2.18-1.853a10.8,10.8,0,0,0-.215-8.394C513.039,363.087,505.757,362.352,501.915,365.074Z"
      transform="translate(-369.667 -334.505)"
    />
    <rect
      id="Rectangle_1056"
      data-name="Rectangle 1056"
      class="cls-1"
      width="289"
      transform="translate(11 161.065)"
    />
    <ellipse
      id="Ellipse_118"
      data-name="Ellipse 118"
      class="cls-5"
      cx="36.561"
      cy="4.528"
      rx="36.561"
      ry="4.528"
      transform="translate(0 136.009)"
    />
    <ellipse
      id="Ellipse_213"
      data-name="Ellipse 213"
      class="cls-5"
      cx="48.061"
      cy="4.528"
      rx="48.061"
      ry="4.528"
      transform="translate(226 97.065)"
    />
    <path
      id="acent"
      class="cls-3"
      d="M895.352,664.061a1.354,1.354,0,0,1-1.008-.61c-1.008-1.525-3.685-5.878-1.805-6.479a7.372,7.372,0,0,1,.91-.223,9.264,9.264,0,0,1,8.98,3.51,8.673,8.673,0,0,1,.737,1.121,1.155,1.155,0,0,1-.692,1.665A22.072,22.072,0,0,1,895.352,664.061Z"
      transform="translate(-871.418 -535.426)"
    />
    <path
      id="Tracé_2136"
      data-name="Tracé 2136"
      class="cls-6"
      d="M816.767,540.635l10.253-1.855h15.351l-2.193,3.382H818.857Z"
      transform="translate(-794.691 -412.24)"
    />
    <path
      id="Tracé_2137"
      data-name="Tracé 2137"
      class="cls-7"
      d="M925.82,219.572c1.151-.585,2.651.006,4.02.139,1.775.172,3.533-.474,5.321-.416a6.66,6.66,0,0,1,4.363,1.8,5.33,5.33,0,0,1,1.606,3.657,4.253,4.253,0,0,1-.561,2.352,6.217,6.217,0,0,1-4.809,2.541,9,9,0,0,1-5.617-1.473,28.251,28.251,0,0,1-3.365-3.136c-.8-.746-2.892-1.69-2.908-2.662A5.145,5.145,0,0,1,925.82,219.572Z"
      transform="translate(-895.953 -199.954)"
    />
    <path
      id="Tracé_2138"
      data-name="Tracé 2138"
      class="cls-8"
      d="M925.82,219.572c1.151-.585,2.651.006,4.02.139,1.775.172,3.533-.474,5.321-.416a6.66,6.66,0,0,1,4.363,1.8,5.33,5.33,0,0,1,1.606,3.657,4.253,4.253,0,0,1-.561,2.352,6.217,6.217,0,0,1-4.809,2.541,9,9,0,0,1-5.617-1.473,28.251,28.251,0,0,1-3.365-3.136c-.8-.746-2.892-1.69-2.908-2.662A5.145,5.145,0,0,1,925.82,219.572Z"
      transform="translate(-895.953 -199.954)"
    />
    <path
      id="Tracé_2139"
      data-name="Tracé 2139"
      class="cls-7"
      d="M950.047,643.8l-.157,3.256a43.4,43.4,0,0,1,4.627-.088,3.457,3.457,0,0,0-.1-1.5,11.346,11.346,0,0,1-.187-2.917c.018-.268-2.865-.543-3.156-.555-.355-.015-.722-.062-.892.307A4.487,4.487,0,0,0,950.047,643.8Z"
      transform="translate(-915.914 -524.206)"
    />
    <path
      id="Tracé_2140"
      data-name="Tracé 2140"
      class="cls-7"
      d="M933.742,648.307l-.157,3.256a43.368,43.368,0,0,1,4.627-.088,3.456,3.456,0,0,0-.1-1.5,11.351,11.351,0,0,1-.187-2.917c.018-.268-2.865-.543-3.156-.555-.355-.015-.722-.062-.892.307A4.48,4.48,0,0,0,933.742,648.307Z"
      transform="translate(-903.406 -527.665)"
    />
    <path
      id="Tracé_2141"
      data-name="Tracé 2141"
      class="cls-7"
      d="M948.633,649.152a1.714,1.714,0,0,0-.591.37l-1.164.973c-.393.328-.907.677-1.375.467a3.076,3.076,0,0,1-.544-.427.536.536,0,0,0-.639-.073.655.655,0,0,0-.179.4,30.943,30.943,0,0,0-.506,6.268,3.683,3.683,0,0,0,1.133.252.184.184,0,0,0,.126-.024c.069-.054.031-.161.022-.249-.032-.3.387-.478.659-.347a1.305,1.305,0,0,1,.536.708.713.713,0,0,0,.2.3.667.667,0,0,0,.276.1,10.21,10.21,0,0,0,1.6.146q3.727.131,7.456,0a2.471,2.471,0,0,0,.764-.108,1.452,1.452,0,0,0,.889-1.128,2,2,0,0,0-.353-1.426,2.46,2.46,0,0,0-.817-.75,4.891,4.891,0,0,0-.772-.327l-1.173-.419a4.548,4.548,0,0,1-3.256-2.554C950.5,650.553,949.809,648.808,948.633,649.152Z"
      transform="translate(-911.11 -529.673)"
    />
    <path
      id="Tracé_2142"
      data-name="Tracé 2142"
      class="cls-8"
      d="M948.633,649.152a1.714,1.714,0,0,0-.591.37l-1.164.973c-.393.328-.907.677-1.375.467a3.076,3.076,0,0,1-.544-.427.536.536,0,0,0-.639-.073.655.655,0,0,0-.179.4,30.943,30.943,0,0,0-.506,6.268,3.683,3.683,0,0,0,1.133.252.184.184,0,0,0,.126-.024c.069-.054.031-.161.022-.249-.032-.3.387-.478.659-.347a1.305,1.305,0,0,1,.536.708.713.713,0,0,0,.2.3.667.667,0,0,0,.276.1,10.21,10.21,0,0,0,1.6.146q3.727.131,7.456,0a2.471,2.471,0,0,0,.764-.108,1.452,1.452,0,0,0,.889-1.128,2,2,0,0,0-.353-1.426,2.46,2.46,0,0,0-.817-.75,4.891,4.891,0,0,0-.772-.327l-1.173-.419a4.548,4.548,0,0,1-3.256-2.554C950.5,650.553,949.809,648.808,948.633,649.152Z"
      transform="translate(-911.11 -529.673)"
    />
    <path
      id="Tracé_2143"
      data-name="Tracé 2143"
      class="cls-6"
      d="M963.945,421.445c-.006.664-.019,1.331-.042,2a100.006,100.006,0,0,1-1.106,12.175c-.139.906-.29,1.808-.512,2.695a27.566,27.566,0,0,0-.925,3.81c-.193,1.866.306,3.784-.116,5.612-.216.935-.667,1.8-.941,2.721a14.83,14.83,0,0,0-.367,1.676l-.706,3.9-.071.4a16.022,16.022,0,0,1-1.529,4.4,13.985,13.985,0,0,0-.921,2.7q-.624,2.311-1.244,4.629a14,14,0,0,0-.69,6.379,14.718,14.718,0,0,1-2.521-.367,14.321,14.321,0,0,1-3.059-1.122,103.08,103.08,0,0,1,.1-10.8,55.359,55.359,0,0,1,.59-6.814c.416-2.453.986-4.793,1.486-7.23a50.556,50.556,0,0,0,1.428-11.053c-.09-4.822.036-9.735,2.141-14.074a3.218,3.218,0,0,1,.973-1.318,3.335,3.335,0,0,1,1.4-.467,26.456,26.456,0,0,1,5.07-.364A3.559,3.559,0,0,1,963.945,421.445Z"
      transform="translate(-915.298 -354.631)"
    />
    <path
      id="Tracé_2144"
      data-name="Tracé 2144"
      class="cls-8"
      d="M963.945,421.445c-.006.664-5.04.157-5.062.821-6.19-.717-3.29.72-3.564.914-.587.413,2.231-.416,1.645,0-.509.361-1.3,1.762-1.827,2.1-1.228.8,2.865,3.275,1.952,4.423a5.331,5.331,0,0,0-.909,3.771c.113,1.322.493,2.6.693,3.913a14.645,14.645,0,0,1-.77,7.379,42.8,42.8,0,0,1-1.953,4.065c-.741,1.48-2.111,2.949-1.947,4.6.374,3.713-.274,7.449-.448,11.179a13.077,13.077,0,0,0,.332,4.252c.148.522.355,1.032.474,1.563a7.474,7.474,0,0,1-.306,3.746,14.317,14.317,0,0,1-3.059-1.122,103.014,103.014,0,0,1,.1-10.8,55.334,55.334,0,0,1,.59-6.814c.416-2.453.986-4.793,1.486-7.23a50.558,50.558,0,0,0,1.428-11.053c-.09-4.822.036-9.735,2.141-14.074a3.218,3.218,0,0,1,.973-1.318,3.336,3.336,0,0,1,1.4-.467,26.446,26.446,0,0,1,5.07-.364A3.559,3.559,0,0,1,963.945,421.445Z"
      transform="translate(-915.298 -354.631)"
    />
    <path
      id="Tracé_2145"
      data-name="Tracé 2145"
      class="cls-6"
      d="M939.969,394.884c.013,1.183.661,3.8-.245,4.56-.6.509-1.347.819-2,1.273-.587.413-1.086.941-1.673,1.357-.509.361-1.077.632-1.6.974-1.228.8-1.767,2.263-2.679,3.41a5.331,5.331,0,0,0-.909,3.771c.113,1.322.493,2.6.693,3.913a14.642,14.642,0,0,1-.771,7.379,42.761,42.761,0,0,1-1.953,4.065c-.741,1.48-2.111,2.949-1.947,4.6.374,3.713-.274,7.449-.448,11.179a13.077,13.077,0,0,0,.332,4.252c.148.522.355,1.032.474,1.563a8.319,8.319,0,0,1-.516,4.4,20.357,20.357,0,0,0-4.971-.451,22.7,22.7,0,0,1-4.365.358,87.481,87.481,0,0,1,.538-11.759c.071-.8.145-1.6.216-2.4.281-3.062.558-6.128.722-9.2a10.363,10.363,0,0,1-.013-2.344q.677-2.345,1.36-4.69a11.54,11.54,0,0,0,.074-7.353l-1.4-6.743c-.155-.748-.31-1.5-.522-2.237a14.83,14.83,0,0,1-.393-1.486,18.777,18.777,0,0,1-.129-2.128c-.071-1.228-.358-2.431-.448-3.655a7.516,7.516,0,0,1,.055-1.763,4.443,4.443,0,0,1,.661-1.779,54.329,54.329,0,0,1,10.608-3.03,14.977,14.977,0,0,1,2.569-.3,17.162,17.162,0,0,1,2.92.326,14.681,14.681,0,0,1,3.949,1.07,5.863,5.863,0,0,1,1.605,1.119c.625.625.245-.82.435.04A15.766,15.766,0,0,1,939.969,394.884Z"
      transform="translate(-890.951 -331.382)"
    />
    <path
      id="Tracé_2146"
      data-name="Tracé 2146"
      class="cls-7"
      d="M932.328,653.661a1.715,1.715,0,0,0-.591.37l-1.164.973c-.393.328-.908.677-1.375.467a3.069,3.069,0,0,1-.544-.427.536.536,0,0,0-.639-.073.656.656,0,0,0-.179.4,30.932,30.932,0,0,0-.506,6.267,3.681,3.681,0,0,0,1.133.252.182.182,0,0,0,.126-.024c.069-.054.031-.161.022-.248-.032-.3.387-.479.659-.347a1.306,1.306,0,0,1,.536.708.714.714,0,0,0,.2.3.671.671,0,0,0,.277.1,10.178,10.178,0,0,0,1.6.146q3.727.131,7.456,0a2.474,2.474,0,0,0,.764-.108A1.452,1.452,0,0,0,941,661.3a2,2,0,0,0-.353-1.426,2.459,2.459,0,0,0-.817-.75,4.9,4.9,0,0,0-.772-.327l-1.173-.419a4.548,4.548,0,0,1-3.256-2.554C934.191,655.062,933.5,653.318,932.328,653.661Z"
      transform="translate(-898.602 -533.132)"
    />
    <path
      id="Tracé_2147"
      data-name="Tracé 2147"
      class="cls-9"
      d="M949.256,204.645a2.306,2.306,0,0,1-.487,1,4.2,4.2,0,0,1-1.876,1.715,3.782,3.782,0,0,1-1.4.126,18.374,18.374,0,0,1-11.021-4.387,6.063,6.063,0,0,0,2.431-4.458,14.676,14.676,0,0,0-.232-4.039c-.068-.393-.139-.783-.21-1.17-.129-.712-.454-1.264.348-1.463a8.015,8.015,0,0,1,2.134-.01,7.257,7.257,0,0,1,4.02,1.257,2.686,2.686,0,0,1,.774.845c.5.932.094,2.028-.023,3.1a4.259,4.259,0,0,0-.016.729,4.5,4.5,0,0,0,1.8,2.966c.915.761,1.973,1.338,2.866,2.118A2.053,2.053,0,0,1,949.256,204.645Z"
      transform="translate(-904.087 -178.93)"
    />
    <path
      id="Tracé_2148"
      data-name="Tracé 2148"
      class="cls-8"
      d="M949.688,197.153a6.769,6.769,0,0,1-1.544.177,6.883,6.883,0,0,1-5.5-2.733c-.068-.393-.139-.783-.21-1.17-.129-.712-.454-1.264.348-1.463a8.014,8.014,0,0,1,2.134-.01,7.258,7.258,0,0,1,4.02,1.257,2.688,2.688,0,0,1,.774.845C950.207,194.987,949.8,196.083,949.688,197.153Z"
      transform="translate(-910.061 -178.93)"
    />
    <circle
      id="Ellipse_119"
      data-name="Ellipse 119"
      class="cls-9"
      cx="6.901"
      cy="6.901"
      r="6.901"
      transform="translate(31.184 4.278)"
    />
    <path
      id="Tracé_2149"
      data-name="Tracé 2149"
      class="cls-8"
      d="M940.732,393.758c-.042.093-.371,1.679-.422,1.77a24.983,24.983,0,0,0-6.063-.461,9.508,9.508,0,0,0-1.763.148c-.49.1-.967.258-1.46.335-2.659.409-5.177-1.67-7.862-1.467a9.542,9.542,0,0,0-2.595.706c-.938.355-1.85.625-2.772.938a4.443,4.443,0,0,1,.661-1.779,54.343,54.343,0,0,1,10.609-3.03,14.988,14.988,0,0,1,2.569-.3,17.167,17.167,0,0,1,2.92.326,14.681,14.681,0,0,1,3.949,1.07,5.863,5.863,0,0,1,1.605,1.119C940.732,393.758,940.542,392.9,940.732,393.758Z"
      transform="translate(-891.292 -331.382)"
    />
    <path
      id="accent-5"
      data-name="accent"
      class="cls-3"
      d="M896.115,226.052a4.066,4.066,0,0,0-1.09-1.782q-1.332-1.493-2.753-2.9a3.96,3.96,0,0,0-1.072-.837,1.4,1.4,0,0,0-1.311.012c-.358.226-.54.642-.826.955-.62.678-1.7.839-2.22,1.6a4.112,4.112,0,0,1-.4.6,1.773,1.773,0,0,1-.781.384l-3.288.985a1.536,1.536,0,0,0-1,.622,2.041,2.041,0,0,0-.146.6c-.26,1.489-1.573,2.522-2.725,3.5a2.95,2.95,0,0,0-.974,1.2,2.9,2.9,0,0,0,.022,1.521,9.935,9.935,0,0,0,1.385,3.637,22.2,22.2,0,0,1,1.549,2.129,7.315,7.315,0,0,1,.643,2.352c.223,1.53.353,3.071.484,4.612a20.98,20.98,0,0,0,.344,4.183c.481,1.6,2.193,2.5,2.952,3.986a7.446,7.446,0,0,1,.584,1.961l.74,3.7a8.142,8.142,0,0,1,.218,1.709c-.02,1.357-.716,2.68-.5,4.019,1.23-.465,2.419-.784,3.649-1.25a9.45,9.45,0,0,1,2.6-.708c2.682-.2,5.2,1.877,7.86,1.467.494-.076.971-.237,1.461-.335a9.81,9.81,0,0,1,1.762-.15,33.168,33.168,0,0,1,6.979.631,4.15,4.15,0,0,0,.427-2.64,21.29,21.29,0,0,0-.633-2.647,19.618,19.618,0,0,1-.507-7.1,15.68,15.68,0,0,0,.159-1.8c-.009-2.25-1.363-4.357-1.238-6.6.124-2.211,2.649-3.449,3.69-5.4a2.315,2.315,0,0,0,.347-1.3,4.8,4.8,0,0,0-.533-1.323c-.461-1.073-.292-2.308-.56-3.445-.094-.4-.241-.783-.328-1.182-.243-1.105-.021-2.278-.3-3.374a6.391,6.391,0,0,0-1.266-2.278,9.769,9.769,0,0,0-3.313-2.894,4.779,4.779,0,0,0-4.273-.076,1.58,1.58,0,0,0-.527.417,2.1,2.1,0,0,0-.289.679,21.04,21.04,0,0,1-1.913,4.455,2.068,2.068,0,0,1-1.69-.507C897.046,227.045,896.238,226.648,896.115,226.052Z"
      transform="translate(-860.332 -200.784)"
    />
    <path
      id="Tracé_2151"
      data-name="Tracé 2151"
      class="cls-6"
      d="M932.037,153.019a2.231,2.231,0,0,0,.167-.977,1.551,1.551,0,0,1,.261-.947c.269-.327.754-.415,1.013-.749.294-.379.2-.918.284-1.39a1.753,1.753,0,0,1,1.618-1.385,7.732,7.732,0,0,1,1.688.369,6.117,6.117,0,0,0,2.241-.116,2.423,2.423,0,0,0,1.51-.654,2.288,2.288,0,0,0,.4-.988,2.322,2.322,0,0,0-.07-1.667c-.289-.495-.893-.731-1.21-1.208-.278-.418-.3-.967-.582-1.382a2.188,2.188,0,0,0-2.128-.663,7,7,0,0,1-2.327.179c-.843-.181-1.677-.847-2.471-.512-.584.246-.828.919-1.186,1.442a3.147,3.147,0,0,1-1.228,1.055c-.76.365-1.649.417-2.378.84a2.656,2.656,0,0,0-1.261,1.807.781.781,0,0,0,.014.416,1.8,1.8,0,0,0,.185.295,1.581,1.581,0,0,1-.187,1.6,4.574,4.574,0,0,0-.779,1.5,2.473,2.473,0,0,0,.416,1.618,12.021,12.021,0,0,1,.859,1.471c.247.606.294,1.28.544,1.885a3.054,3.054,0,0,0,2.281,1.812.7.7,0,0,0,.557-.084.721.721,0,0,0,.207-.4,4.573,4.573,0,0,0,.132-.786,4.425,4.425,0,0,1,.232-1.607C931.137,153.2,931.724,153.609,932.037,153.019Z"
      transform="translate(-897.273 -139.771)"
    />
    <path
      id="Tracé_2152"
      data-name="Tracé 2152"
      class="cls-8"
      d="M949.544,146.158c-.289-.495-.892-.731-1.21-1.208-.278-.418-.3-.967-.582-1.382a1.527,1.527,0,0,0-.872-.59l0,0c.281.415.3.964.582,1.382.318.477.921.713,1.21,1.208a2.321,2.321,0,0,1,.07,1.667,2.288,2.288,0,0,1-.4.988,2.423,2.423,0,0,1-1.51.654A6.117,6.117,0,0,1,944.6,149a7.729,7.729,0,0,0-1.688-.369,1.752,1.752,0,0,0-1.618,1.385c-.089.471.01,1.011-.284,1.39-.259.334-.744.423-1.013.749a1.551,1.551,0,0,0-.262.947,2.232,2.232,0,0,1-.167.977c-.312.59-.9.181-1.2.784a4.422,4.422,0,0,0-.232,1.607,4.574,4.574,0,0,1-.132.786.722.722,0,0,1-.207.4.7.7,0,0,1-.557.083,2.9,2.9,0,0,1-.706-.214,3.027,3.027,0,0,0,1.574.8.7.7,0,0,0,.557-.084.721.721,0,0,0,.207-.4,4.574,4.574,0,0,0,.132-.786,4.422,4.422,0,0,1,.232-1.607c.3-.6.884-.195,1.2-.784a2.232,2.232,0,0,0,.167-.977,1.55,1.55,0,0,1,.262-.947c.269-.327.754-.415,1.013-.749.294-.379.2-.918.284-1.39a1.752,1.752,0,0,1,1.618-1.385,7.729,7.729,0,0,1,1.688.369,6.117,6.117,0,0,0,2.241-.116,2.423,2.423,0,0,0,1.51-.654,2.288,2.288,0,0,0,.4-.988A2.321,2.321,0,0,0,949.544,146.158Z"
      transform="translate(-905.668 -141.414)"
    />
    <path
      id="Tracé_2153"
      data-name="Tracé 2153"
      class="cls-6"
      d="M904.254,696.564a3.71,3.71,0,1,1-7.34,0v-.032a3.992,3.992,0,0,1,3.668-4.223,3.538,3.538,0,0,1,3.007,1.821,4.674,4.674,0,0,1,.664,2.434Z"
      transform="translate(-875.275 -562.813)"
    />
    <path
      id="Tracé_2154"
      data-name="Tracé 2154"
      class="cls-6"
      d="M1019.574,690.952c0,4.526-2.34,8.194-5.225,8.194s-5.222-3.668-5.222-8.194a10.466,10.466,0,0,1,1.915-6.341,4.263,4.263,0,0,1,3.307-1.854,3.426,3.426,0,0,1,.516.039,4.456,4.456,0,0,1,2.756,1.77c.106.132.206.271.3.416A10.74,10.74,0,0,1,1019.574,690.952Z"
      transform="translate(-961.355 -555.487)"
    />
    <path
      id="Tracé_2155"
      data-name="Tracé 2155"
      class="cls-6"
      d="M914.673,298.466l-4.568.455-.587-4.88-.039-.319-.828-6.879-.039-.319-4.194-34.8-.09-.742-2.5-20.769-.061-.5-.261-2.173-.174-1.441h3.656l.2,1.492.032.239.09.667,3.136,23.444.084.629L913,285.994l.98,7.324Z"
      transform="translate(-859.561 -172.381)"
    />
    <path
      id="Tracé_2156"
      data-name="Tracé 2156"
      class="cls-8"
      d="M1020.656,682.758a3.425,3.425,0,0,1,.516.039l-.129.087a2.932,2.932,0,0,0-1.17,2.714l.741,7.043c0,.035.006.074.006.11a1.062,1.062,0,0,1-2.1.2c-.306-1.615-.883-4.912-1.144-7.891-.013-.158-.023-.306-.023-.448A4.263,4.263,0,0,1,1020.656,682.758Z"
      transform="translate(-967.662 -555.487)"
    />
    <path
      id="Tracé_2157"
      data-name="Tracé 2157"
      class="cls-8"
      d="M1036.213,690.939l-.161-.045c-.048-.129-.094-.255-.142-.371C1036.017,690.656,1036.117,690.794,1036.213,690.939Z"
      transform="translate(-981.901 -561.443)"
    />
    <path
      id="Tracé_2158"
      data-name="Tracé 2158"
      class="cls-10"
      d="M1019.323,674.4s-3.716-.244-3.35,3.9c.263,2.979.841,6.274,1.145,7.889a1.061,1.061,0,0,0,1.2.852h0a1.062,1.062,0,0,0,.9-1.161l-.741-7.042a2.932,2.932,0,0,1,1.171-2.715c.876-.617,2.048-.653,3.027,2.055l.67.183S1024.2,673.909,1019.323,674.4Z"
      transform="translate(-966.587 -549.044)"
    />
    <path
      id="Tracé_2159"
      data-name="Tracé 2159"
      class="cls-8"
      d="M991.278,398.254a2.953,2.953,0,0,1-.561.055h-1.309a3.005,3.005,0,0,1-2.979-2.672l-2-18.229a3,3,0,0,1,3.623-3.265Z"
      transform="translate(-942.394 -318.691)"
    />
    <path
      id="Tracé_2160"
      data-name="Tracé 2160"
      class="cls-8"
      d="M992.188,401a2.869,2.869,0,0,1-.645.071h-1.309a2.974,2.974,0,0,1-2.156-.922l-.09-.741-2.5-20.769a2.985,2.985,0,0,1,3.481-1.712l3.136,23.444Z"
      transform="translate(-943.22 -320.808)"
    />
    <path
      id="Tracé_2161"
      data-name="Tracé 2161"
      class="cls-3"
      d="M984.425,378.761l2,18.233a3,3,0,0,0,2.979,2.668h1.306a2.944,2.944,0,0,0,2.83-2.009,2.594,2.594,0,0,0,.122-.778c0-1.389-1.5-14.055-1.965-17.958a2.955,2.955,0,0,0-1.1-1.981,10.448,10.448,0,0,0-1.981-1.243A2.989,2.989,0,0,0,984.425,378.761Z"
      transform="translate(-942.391 -319.722)"
    />
    <path
      id="Tracé_2162"
      data-name="Tracé 2162"
      class="cls-11"
      d="M984.425,378.761l2,18.233a3,3,0,0,0,2.979,2.668h1.306a2.944,2.944,0,0,0,2.83-2.009,2.594,2.594,0,0,0,.122-.778c0-1.389-1.5-14.055-1.965-17.958a2.955,2.955,0,0,0-1.1-1.981,10.448,10.448,0,0,0-1.981-1.243A2.989,2.989,0,0,0,984.425,378.761Z"
      transform="translate(-942.391 -319.722)"
    />
    <path
      id="Tracé_2163"
      data-name="Tracé 2163"
      class="cls-8"
      d="M903.59,694.13l-.983-.042a4.584,4.584,0,0,0-2.5.612l-3.075,1.77a1.018,1.018,0,0,1-.119.061,3.992,3.992,0,0,1,3.668-4.223A3.538,3.538,0,0,1,903.59,694.13Z"
      transform="translate(-875.275 -562.813)"
    />
    <path
      id="Tracé_2164"
      data-name="Tracé 2164"
      class="cls-8"
      d="M938.1,491.984l-4.465.4-.039-.319-.828-6.879,4.352-.525Z"
      transform="translate(-883.674 -370.724)"
    />
    <path
      id="Tracé_2165"
      data-name="Tracé 2165"
      class="cls-10"
      d="M917.681,631.839c.716,10.489-6.621,10.286-6.621,10.286L895.1,641.45a4.615,4.615,0,0,0-2.5.613l-3.074,1.772a1.6,1.6,0,0,1-1.794-.133h0a1.6,1.6,0,0,1-.286-2.214l1.3-1.733a2.712,2.712,0,0,1,2.323-1.08l16.7.952c6.763.122,9.045-14.986,9.045-14.986h0l4.39-.529.98,7.324-4.5.4Z"
      transform="translate(-867.765 -510.498)"
    />
    <path
      id="Tracé_2166"
      data-name="Tracé 2166"
      class="cls-8"
      d="M905.186,227.594l-3.681-.051-.174-1.441h3.656Z"
      transform="translate(-859.561 -172.381)"
    />
    <path
      id="Tracé_2167"
      data-name="Tracé 2167"
      class="cls-6"
      d="M920.395,361.133l30.874.774a.965.965,0,0,1,.94.964h0a.964.964,0,0,1-.978.964l-30.874-.43a.965.965,0,0,1-.951-.964V362.1a.965.965,0,0,1,.989-.964Z"
      transform="translate(-892.529 -308.763)"
    />
    <path
      id="Tracé_2168"
      data-name="Tracé 2168"
      class="cls-9"
      d="M915.074,346.768c1.076.218,2.266.162,3.167.791a3.262,3.262,0,0,1,1.236,2.555,9.651,9.651,0,0,1-.467,2.891c-.08.3-.235.661-.545.669-.29.007-.469-.306-.716-.456-.535-.325-1.293.156-1.831-.161a2.853,2.853,0,0,1-.424-.385,1.4,1.4,0,0,0-1.569-.185,2.514,2.514,0,0,0-2.543-2.978,2.4,2.4,0,0,1-1.234-.079c-.589-.323-.541-1.207-.223-1.8.446-.83.788-1.647,1.8-1.646A17.332,17.332,0,0,1,915.074,346.768Z"
      transform="translate(-885.078 -297.144)"
    />
    <path
      id="Tracé_2169"
      data-name="Tracé 2169"
      class="cls-9"
      d="M1023.054,345.6l-.014,2.263a4.944,4.944,0,0,0,.42,2.489,1.274,1.274,0,0,0,1.043.728c.167,0,.331-.056.5-.05.488.018.927.531,1.385.361.156-.058.277-.189.436-.236.237-.071.5.06.737-.013s.374-.341.473-.58a4.134,4.134,0,0,0-.2-5.047l-1.51-2.232a.777.777,0,0,0-.247-.264.674.674,0,0,0-.338-.062,5.039,5.039,0,0,0-2.121.691C1022.97,343.976,1023.058,344.959,1023.054,345.6Z"
      transform="translate(-972.027 -294.822)"
    />
    <path
      id="accent-6"
      data-name="accent"
      class="cls-3"
      d="M1011.275,291.32a6.481,6.481,0,0,0,.423,1.447,5.511,5.511,0,0,1,.82,1.559c.1.7-.356,1.5.079,2.057.179.227.471.341.654.566.447.55.021,1.363.085,2.068.06.646.54,1.182.672,1.817.137.657-.09,1.423.316,1.958a2.025,2.025,0,0,0-2.075-.651,4.7,4.7,0,0,0-3.091,2,6.135,6.135,0,0,0-.289-2.739,6.655,6.655,0,0,1-.672-2.381,10.751,10.751,0,0,0,.168-1.466,3.384,3.384,0,0,0-1.239-1.9,3.927,3.927,0,0,1-1.363-2.357,3.656,3.656,0,0,1,.911-2.362,4.489,4.489,0,0,1,1.54-1.4c.408-.2,1.643-1.039,1.914-.935C1010.7,288.829,1011.148,290.788,1011.275,291.32Z"
      transform="translate(-958.77 -253.124)"
    />
    <path
      id="accent-7"
      data-name="accent"
      class="cls-3"
      d="M859.694,270.176a2.647,2.647,0,0,0-1.48,1.227,10.073,10.073,0,0,0-.774,1.807,23.863,23.863,0,0,1-1.818,3.837,13.058,13.058,0,0,0-1.457,2.855,14.449,14.449,0,0,1-.715,2.707,11.317,11.317,0,0,0-.676,1.19,3.932,3.932,0,0,0-.1,1.762l.152,1.576a3.019,3.019,0,0,0,1.679,2.8l2.368,1.68a6.146,6.146,0,0,0,1.482.856c1.543.541,3.2-.205,4.827-.41a14.55,14.55,0,0,1,3.368.1,4.632,4.632,0,0,1,.381-2.69c.442-.807,1.792-1.869,2.683-1.642-.886-.749-2.351-.332-3.331-.953-.394-.249-.729-.582-1.115-.843-1.219-.825-2.965-.966-3.751-2.211a.518.518,0,0,1-.093-.232c-.013-.185.143-.332.278-.459a5.626,5.626,0,0,0,1.6-3.619,14.859,14.859,0,0,0-.339-4.017,11.609,11.609,0,0,0-.9-3.112A4.264,4.264,0,0,0,859.694,270.176Z"
      transform="translate(-841.3 -238.989)"
    />
    <ellipse
      id="Ellipse_123"
      data-name="Ellipse 123"
      class="cls-5"
      cx="97.188"
      cy="14.268"
      rx="97.188"
      ry="14.268"
      transform="translate(64 142.065)"
    />
    <g
      id="Groupe_749"
      data-name="Groupe 749"
      transform="translate(112)"
    >
      <g
        id="bus"
        transform="translate(112)"
      >
        <g
          id="Groupe_661"
          data-name="Groupe 661"
          transform="translate(16.142 91.613)"
        >
          <path
            id="Tracé_2417"
            data-name="Tracé 2417"
            class="cls-12"
            d="M79.019,445.642V452.9a3.191,3.191,0,0,0,3.19,3.189H92.38a3.191,3.191,0,0,0,3.189-3.189v-7.263Z"
            transform="translate(-79.019 -445.642)"
          />
          <path
            id="Tracé_2418"
            data-name="Tracé 2418"
            class="cls-12"
            d="M336.958,445.642V452.9a3.191,3.191,0,0,0,3.19,3.189h10.171a3.191,3.191,0,0,0,3.19-3.189v-7.263Z"
            transform="translate(-283.932 -445.642)"
          />
        </g>
        <path
          id="Tracé_2419"
          data-name="Tracé 2419"
          class="cls-13"
          d="M454.536,101.032a1.654,1.654,0,0,1-1.655-1.655,2.124,2.124,0,0,0-2.121-2.121h-1.818a1.655,1.655,0,0,1,0-3.311h1.818a5.437,5.437,0,0,1,5.432,5.429A1.656,1.656,0,0,1,454.536,101.032Z"
          transform="translate(-355.437 -74.632)"
        />
        <rect
          id="Rectangle_1218"
          data-name="Rectangle 1218"
          class="cls-12"
          width="6.641"
          height="17.932"
          transform="translate(95.221 24.451)"
        />
        <path
          id="Tracé_2420"
          data-name="Tracé 2420"
          class="cls-13"
          d="M7.536,101.032a1.654,1.654,0,0,0,1.655-1.655,2.122,2.122,0,0,1,2.121-2.121h1.818a1.655,1.655,0,0,0,0-3.311H11.313a5.437,5.437,0,0,0-5.432,5.429A1.656,1.656,0,0,0,7.536,101.032Z"
          transform="translate(-4.774 -74.632)"
        />
        <rect
          id="Rectangle_1219"
          data-name="Rectangle 1219"
          class="cls-12"
          width="6.641"
          height="17.932"
          transform="translate(0 24.451)"
        />
        <path
          id="Tracé_2421"
          data-name="Tracé 2421"
          class="cls-3"
          d="M119.7,0H47.153a6.379,6.379,0,0,0-6.379,6.379V63.753h85.3V6.379A6.377,6.377,0,0,0,119.7,0Z"
          transform="translate(-32.494)"
        />
        <path
          id="Tracé_2422"
          data-name="Tracé 2422"
          class="cls-14"
          d="M40.774,411.85V413.6a6.381,6.381,0,0,0,6.379,6.379H119.7a6.379,6.379,0,0,0,6.379-6.379V411.85Z"
          transform="translate(-32.494 -327.183)"
        />
        <rect
          id="Rectangle_1220"
          data-name="Rectangle 1220"
          class="cls-12"
          width="85.301"
          height="9.157"
          transform="translate(8.28 75.497)"
        />
        <rect
          id="Rectangle_1221"
          data-name="Rectangle 1221"
          class="cls-15"
          width="85.301"
          height="13.804"
          transform="translate(8.28 61.705)"
        />
        <rect
          id="Rectangle_1222"
          data-name="Rectangle 1222"
          class="cls-16"
          width="85.301"
          height="39.86"
          transform="translate(8.28 17.858)"
        />
        <rect
          id="Rectangle_1223"
          data-name="Rectangle 1223"
          class="cls-17"
          width="4.555"
          height="39.86"
          transform="translate(48.653 17.858)"
        />
        <rect
          id="Rectangle_1224"
          data-name="Rectangle 1224"
          class="cls-3"
          width="85.301"
          height="3.987"
          transform="translate(8.28 57.718)"
        />
        <ellipse
          id="Ellipse_171"
          data-name="Ellipse 171"
          class="cls-15"
          cx="2.494"
          cy="2.494"
          rx="2.494"
          ry="2.494"
          transform="translate(14.978 77.592)"
        />
        <g
          id="Groupe_662"
          data-name="Groupe 662"
          transform="translate(21.325 77.592)"
        >
          <ellipse
            id="Ellipse_172"
            data-name="Ellipse 172"
            class="cls-18"
            cx="2.494"
            cy="2.494"
            rx="2.494"
            ry="2.494"
          />
          <ellipse
            id="Ellipse_173"
            data-name="Ellipse 173"
            class="cls-18"
            cx="2.494"
            cy="2.494"
            rx="2.494"
            ry="2.494"
            transform="translate(54.222)"
          />
        </g>
        <ellipse
          id="Ellipse_174"
          data-name="Ellipse 174"
          class="cls-15"
          cx="2.494"
          cy="2.494"
          rx="2.494"
          ry="2.494"
          transform="translate(81.888 77.592)"
        />
        <g
          id="Groupe_663"
          data-name="Groupe 663"
          transform="translate(13.447 65.14)"
        >
          <path
            id="Tracé_2423"
            data-name="Tracé 2423"
            class="cls-3"
            d="M374.218,316.866H368.1a3.465,3.465,0,1,0,0,6.931h6.114a3.465,3.465,0,1,0,0-6.931Z"
            transform="translate(-303.225 -316.866)"
          />
          <path
            id="Tracé_2424"
            data-name="Tracé 2424"
            class="cls-3"
            d="M75.489,316.866H69.375a3.465,3.465,0,1,0,0,6.931h6.114a3.465,3.465,0,0,0,0-6.931Z"
            transform="translate(-65.908 -316.866)"
          />
        </g>
        <path
          id="Tracé_2425"
          data-name="Tracé 2425"
          class="cls-12"
          d="M147.938,13.545H97.527a6.145,6.145,0,0,0,0,12.289h50.411a6.145,6.145,0,0,0,0-12.289Z"
          transform="translate(-72.7 -10.76)"
        />
        <path
          id="Tracé_2426"
          data-name="Tracé 2426"
          class="cls-19"
          d="M126.075,86.869h-85.3v19.316a3.521,3.521,0,0,0,3.253,3.563,3.368,3.368,0,0,0,3.563-3.368v-6.248a2.864,2.864,0,0,1,3.021-2.826h.076a2.887,2.887,0,0,1,3.021,2.826v6.905a3.522,3.522,0,0,0,3.174,3.6,3.423,3.423,0,0,0,3.582-3.317V98.1a3.4,3.4,0,0,1,3.572-3.4,3.526,3.526,0,0,1,3.2,3.572v8.81a3.532,3.532,0,0,0,3.2,3.461A3.4,3.4,0,0,0,74,107.132l.038-7.5a3.4,3.4,0,0,1,3.563-3.41,3.554,3.554,0,0,1,3.215,3.6v8.124a3.388,3.388,0,0,0,6.775-.115V98.151a2.848,2.848,0,0,1,3.1-2.826A2.7,2.7,0,0,1,93.548,98v9.47a3.524,3.524,0,0,0,3.177,3.6,3.434,3.434,0,0,0,3.6-3.406V100.6a2.837,2.837,0,0,1,3.059-2.826,3.017,3.017,0,0,1,3.021,2.982v5.435a3.517,3.517,0,0,0,3.215,3.563,3.4,3.4,0,0,0,3.6-3.368v-6.248a2.854,2.854,0,0,1,2.982-2.826h.115a2.854,2.854,0,0,1,2.982,2.826v6.905a3.554,3.554,0,0,0,3.215,3.6,3.4,3.4,0,0,0,3.563-3.41V86.869Z"
          transform="translate(-32.494 -69.011)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgHomeProgramPicto extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.cls-1 {
  fill: #33323d;
}

.cls-11,
.cls-2 {
  opacity: 0.2;
  fill: var(--v-stickers_secondaire-base);
}

.cls-3,
.cls-5 {
  fill: var(--v-stickers_primaire-base);
}

.cls-4 {
  fill: #ffb8b8;
}

.cls-19,
.cls-5,
.cls-8 {
  opacity: 0.1;
}

.cls-6 {
  fill: #33323d;
}

.cls-7 {
  fill: #4c4981;
}

.cls-9 {
  fill: #fbbebe;
}

.cls-10 {
  fill: #504f60;
}

.cls-11,
.cls-19 {
  fill: #fff;
}

.cls-12 {
  fill: var(--v-stickers_secondaire-base);
}

.cls-13 {
  fill: #cacaca;
}

.cls-14 {
  fill: #3d3f42;
}

.cls-15 {
  fill: #f3f3f3;
}

.cls-16 {
  fill: #6f6f6f;
}

.cls-17 {
  fill: #454545;
}

.cls-18 {
  fill: #ababab;
}
</style>
