









































import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { startCodeFlow } from '@/services/authSpace/sso.service';
import { encryptEmailBeforeAssign } from '@/api/resources/ssoApi';

@Component({})
export default class SynchronizationProposalBanner extends Vue {

  @Prop({type: String, required: true})
  readonly service!: string;


  goToProfile() {
    this.$router.push('/dashboard/profile/update');
  }

  get textCols() {
    return this.isMobile ? 12 : 10;
  }

  get icon() {
    return this.isMobile ? false : 'mdi-alert';
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  async assignToSSO() {

    const storedUser = await this.$store.getters['user/getUser'];
    const platform = await this.$store.getters['platform/getPlatform'].uuid;
    const encryptedEmail = await encryptEmailBeforeAssign({email: storedUser.email, platformUUID: platform, date: dayjs().toDate()})
    
    localStorage.setItem('synchroString', encryptedEmail);

    if(encryptedEmail && localStorage.getItem('synchroString')) {
      await this.$emit('is-logout');
      await this.$store.dispatch('user/resetTokens');
      startCodeFlow();
    }     

    
  }

}
