<template>
  <svg
    id="Draw_1"
    xmlns="http://www.w3.org/2000/svg"
    width="555.911"
    height="341.918"
    viewBox="0 0 114.613 81"
  >
    <title></title>
    <rect
      id="Rectangle_756"
      data-name="Rectangle 756"
      class="cls-1"
      width="0.997"
      height="39.381"
      transform="translate(99.867 39.52)"
    />
    <path
      id="Tracé_1513"
      data-name="Tracé 1513"
      class="cls-1"
      d="M934.764,268.649c.063,13.77-9.625,24.978-9.625,24.978s-9.791-11.118-9.855-24.889,9.625-24.978,9.625-24.978S934.7,254.879,934.764,268.649Z"
      transform="translate(-824.7 -224.111)"
    />
    <rect
      id="Rectangle_757"
      data-name="Rectangle 757"
      class="cls-1"
      width="0.513"
      height="20.254"
      transform="translate(80.416 58.049)"
    />
    <path
      id="Tracé_1514"
      data-name="Tracé 1514"
      class="cls-1"
      d="M777.952,534.45c.033,7.082-4.95,12.847-4.95,12.847s-5.036-5.718-5.068-12.8,4.95-12.847,4.95-12.847S777.92,527.367,777.952,534.45Z"
      transform="translate(-692.291 -473.82)"
    />
    <ellipse
      id="Ellipse_19"
      data-name="Ellipse 19"
      class="cls-1"
      cx="13.893"
      cy="2.13"
      rx="13.893"
      ry="2.13"
      transform="translate(77.143 76.741)"
    />
    <path
      id="Tracé_1515"
      data-name="Tracé 1515"
      class="cls-2"
      d="M850.552,319.813a4.572,4.572,0,0,0-3.506-2.081s-2.269-.29-3.725,2.739c-1.357,2.823-3.229,5.549-.3,6.21l.529-1.646.328,1.769a11.46,11.46,0,0,0,1.253.021c3.135-.1,6.121.03,6.025-1.1C851.027,324.233,851.685,321.726,850.552,319.813Z"
      transform="translate(-758.37 -290.574)"
    />
    <path
      id="Tracé_1516"
      data-name="Tracé 1516"
      class="cls-3"
      d="M889.234,368.069s1.521,2.13.608,3.853,2.13,3.549,2.13,3.549L894.2,370.6s-2.637-1.724-1.927-3.346Z"
      transform="translate(-801.291 -335.085)"
    />
    <circle
      id="Ellipse_20"
      data-name="Ellipse 20"
      class="cls-3"
      cx="2.637"
      cy="2.637"
      r="2.637"
      transform="translate(86.321 28.624)"
    />
    <path
      id="Tracé_1517"
      data-name="Tracé 1517"
      class="cls-3"
      d="M809.255,338.007l2.2-2.843s.776-2.98,1.9-2.829-.444,3.573-.444,3.573l-2.189,3.147Z"
      transform="translate(-729.422 -303.699)"
    />
    <path
      id="Tracé_1518"
      data-name="Tracé 1518"
      class="cls-3"
      d="M871.469,707.319l.71,3.752,1.521.406-.406-4.462Z"
      transform="translate(-780.483 -635.192)"
    />
    <path
      id="Tracé_1519"
      data-name="Tracé 1519"
      class="cls-3"
      d="M1022.469,662.826l2.738,3.752,1.115.507.913-1.521-2.738-3.549Z"
      transform="translate(-916.171 -594.756)"
    />
    <path
      id="Tracé_1520"
      data-name="Tracé 1520"
      class="cls-4"
      d="M866.042,396.314l-1.692-2.227a20.6,20.6,0,0,0-3.582.909c-.3.3.811,5.882.811,5.882a4.315,4.315,0,0,0,1.115,2.332l.913.608,7.5-1.521.282-3.339a8.763,8.763,0,0,0-2.31-6.7h0l-1.306.178Z"
      transform="translate(-775.665 -357.55)"
    />
    <path
      id="Tracé_1521"
      data-name="Tracé 1521"
      class="cls-4"
      d="M799.632,381.719l-.507-.2-2.332-.2s-.811-.2-.608-.608.406-.507,0-.608-.507-.2-.406-.507a4.038,4.038,0,0,1,.71-.913l-1.724-1.42s-.093.066-.244.188c-.856.693-3.564,3.193-1.581,5.693,2.332,2.941,5.07,4.665,7.3,4.056Z"
      transform="translate(-714.123 -344.071)"
    />
    <path
      id="Tracé_1522"
      data-name="Tracé 1522"
      class="cls-2"
      d="M881.293,492.272v1.42a5.923,5.923,0,0,0-.608,3.346c.3,1.622.406,2.434.406,2.434a13.845,13.845,0,0,0,.71,4.056c.71,2.028-1.622,15.312,1.318,15.515s4.563.406,5.476-.608-1.521-18.456-1.521-18.456,8.315,17.341,10.039,16.631,6.084-2.332,5.577-3.245-13.284-21.194-13.284-21.194l-.406-.913Z"
      transform="translate(-793.552 -446.51)"
    />
    <path
      id="Tracé_1523"
      data-name="Tracé 1523"
      class="cls-2"
      d="M912.678,791.184s-1.622-.1-1.622.406a10.631,10.631,0,0,1-.811,2.231s-.608,2.028,1.014,1.825a4.191,4.191,0,0,0,2.637-2.028l-.406-1.927Z"
      transform="translate(-820.07 -716.015)"
    />
    <path
      id="Tracé_1524"
      data-name="Tracé 1524"
      class="cls-2"
      d="M1090.506,747.227s-1.42-.913-1.318-.3.2,2.231.913,2.332a11.25,11.25,0,0,1,2.941.913c.1.2,2.535,1.014,2.535-.3s-1.521-2.332-1.521-2.332l-1.724-1.318s-1.115-.1-1.318.608S1090.506,747.227,1090.506,747.227Z"
      transform="translate(-980.964 -675.607)"
    />
    <circle
      id="Ellipse_21"
      data-name="Ellipse 21"
      class="cls-2"
      cx="1.684"
      cy="1.684"
      r="1.684"
      transform="translate(87.278 25.484)"
    />
    <path
      id="Tracé_1525"
      data-name="Tracé 1525"
      class="cls-2"
      d="M877.781,298.607a1.684,1.684,0,0,1,1.506-1.674,1.684,1.684,0,1,0,0,3.348A1.684,1.684,0,0,1,877.781,298.607Z"
      transform="translate(-790.681 -271.883)"
    />
    <path
      id="Tracé_1526"
      data-name="Tracé 1526"
      class="cls-2"
      d="M823.773,274.512l-2.3-1.205-3.177.493-.657,2.9,1.636-.063.457-1.066v1.049l.755-.029.438-1.7.274,1.808,2.684-.055Z"
      transform="translate(-732.111 -245.465)"
    />
    <path
      id="Tracé_1527"
      data-name="Tracé 1527"
      class="cls-3"
      d="M867.722,487.405l-3.548.1s-3.027.589-2.857-.633,3.123-.794,3.123-.794l3.233-.493Z"
      transform="translate(-776.198 -441.409)"
    />
    <path
      id="Tracé_1528"
      data-name="Tracé 1528"
      class="cls-4"
      d="M918.854,390.486a.757.757,0,0,1,.835.52c.695,2.189,2.834,9.513.708,10.518a10.477,10.477,0,0,1-4.331,1.132l-.978-2.408.89-.836.951-6.112.456-2.591Z"
      transform="translate(-824.523 -355.95)"
    />
    <path
      id="Tracé_1529"
      data-name="Tracé 1529"
      class="cls-5"
      d="M862.924,379.515l-.406,4.056-3.549,1.521,3.955-.913Z"
      transform="translate(-769.251 -340.903)"
    />
    <path
      id="Tracé_1530"
      data-name="Tracé 1530"
      class="cls-1"
      d="M743.618,298.705h0a4.75,4.75,0,0,1,2.774,2.015l.332.514.017.021,5.819,1.7a.424.424,0,0,1,.29.519l-.452,1.656a.424.424,0,0,1-.523.3l-5.821-1.627h0l-.585.221a5.224,5.224,0,0,1-3.359.114h0Z"
      transform="translate(-669.086 -273.484)"
    />
    <ellipse
      id="Ellipse_22"
      data-name="Ellipse 22"
      class="cls-6"
      cx="0.355"
      cy="0.666"
      rx="0.355"
      ry="0.666"
      transform="translate(79.281 29.991) rotate(-73.69)"
    />
    <circle
      id="Ellipse_23"
      data-name="Ellipse 23"
      class="cls-1"
      cx="0.244"
      cy="0.244"
      r="0.244"
      transform="translate(80.119 29.71)"
    />
    <ellipse
      id="Ellipse_24"
      data-name="Ellipse 24"
      class="cls-4"
      cx="2.849"
      cy="1.288"
      rx="2.849"
      ry="1.288"
      transform="matrix(0.308, -0.951, 0.951, 0.308, 71.689, 30.274)"
    />
    <path
      id="Tracé_1531"
      data-name="Tracé 1531"
      class="cls-5"
      d="M742.316,301.847c.468-1.442.34-2.786-.275-3.078a.6.6,0,0,1,.109.024c.677.219.832,1.611.347,3.107s-1.427,2.532-2.1,2.312a.594.594,0,0,1-.072-.029C740.987,304.268,741.857,303.265,742.316,301.847Z"
      transform="translate(-667.479 -273.541)"
    />
    <path
      id="Tracé_1532"
      data-name="Tracé 1532"
      class="cls-7"
      d="M119.761,76.342,106.554,66.1a87.408,87.408,0,0,1-6.715-5.819,33.632,33.632,0,0,0-14.826-7.985C70.557,48.582,59.1,55.162,53.9,64.825,45.936,79.59,39.836,103.55,75.129,90.8c7.719-2.789,15.532-3.086,21.456-4.555l21.451-4.423Z"
      transform="matrix(0.995, -0.105, 0.105, 0.995, -52.86, -38.443)"
    />
    <circle
      id="Ellipse_25"
      data-name="Ellipse 25"
      class="cls-8"
      cx="0.71"
      cy="0.71"
      r="0.71"
      transform="translate(34.249 6.568)"
    />
    <path
      id="Tracé_1533"
      data-name="Tracé 1533"
      class="cls-8"
      d="M451.355,85.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-402.607 -76.139)"
    />
    <path
      id="Tracé_1534"
      data-name="Tracé 1534"
      class="cls-8"
      d="M634.355,237.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-567.049 -212.726)"
    />
    <path
      id="Tracé_1535"
      data-name="Tracé 1535"
      class="cls-8"
      d="M621.355,284.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-555.368 -254.959)"
    />
    <path
      id="Tracé_1536"
      data-name="Tracé 1536"
      class="cls-8"
      d="M62.355,324.058H62.17v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-53.054 -290.903)"
    />
    <path
      id="Tracé_1537"
      data-name="Tracé 1537"
      class="cls-8"
      d="M142.355,106.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-124.941 -95.01)"
    />
    <path
      id="Tracé_1538"
      data-name="Tracé 1538"
      class="cls-8"
      d="M453.355,292.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-404.404 -262.148)"
    />
    <path
      id="Tracé_1539"
      data-name="Tracé 1539"
      class="cls-8"
      d="M262.355,42.058h-.185v-.185h-.036v.185h-.185v.036h.185v.185h.036v-.185h.185Z"
      transform="translate(-232.773 -37.5)"
    />
    <path
      id="Tracé_1540"
      data-name="Tracé 1540"
      class="cls-9"
      d="M312.375,238.915l-.23.035c.072.46.129.931.17,1.4l.232-.02C312.506,239.855,312.448,239.381,312.375,238.915Z"
      transform="translate(-282.722 -219.757)"
    />
    <path
      id="Tracé_1541"
      data-name="Tracé 1541"
      class="cls-9"
      d="M306.228,211.575l-.223.068c.135.446.256.9.358,1.359l.228-.052C306.487,212.49,306.364,212.028,306.228,211.575Z"
      transform="translate(-277.205 -195.19)"
    />
    <path
      id="Tracé_1542"
      data-name="Tracé 1542"
      class="cls-9"
      d="M296.417,185.355l-.212.1c.195.425.377.861.543,1.3l.218-.082C296.8,186.227,296.615,185.784,296.417,185.355Z"
      transform="translate(-268.399 -171.629)"
    />
    <path
      id="Tracé_1543"
      data-name="Tracé 1543"
      class="cls-10"
      d="M283.152,160.775l-.2.125c.25.393.491.8.714,1.21l.2-.112C283.649,161.585,283.405,161.173,283.152,160.775Z"
      transform="translate(-256.492 -149.541)"
    />
    <path
      id="Tracé_1544"
      data-name="Tracé 1544"
      class="cls-10"
      d="M266.662,138.245l-.177.151c.3.355.6.726.874,1.1l.187-.139C267.266,138.98,266.969,138.6,266.662,138.245Z"
      transform="translate(-241.692 -129.296)"
    />
    <path
      id="Tracé_1545"
      data-name="Tracé 1545"
      class="cls-10"
      d="M247.28,118.185l-.155.173c.349.311.691.639,1.016.972l.167-.163C247.979,118.83,247.633,118.5,247.28,118.185Z"
      transform="translate(-224.296 -111.27)"
    />
    <path
      id="Tracé_1546"
      data-name="Tracé 1546"
      class="cls-10"
      d="M225.376,100.965l-.131.193c.387.261.771.538,1.139.825l.144-.184C226.155,101.51,225.767,101.229,225.376,100.965Z"
      transform="translate(-204.634 -95.796)"
    />
    <path
      id="Tracé_1547"
      data-name="Tracé 1547"
      class="cls-10"
      d="M206.4,89.005v.264c.242.127.483.26.718.4l.117-.2Q206.829,89.226,206.4,89.005Z"
      transform="translate(-187.705 -85.049)"
    />
    <path
      id="Tracé_1548"
      data-name="Tracé 1548"
      class="cls-9"
      d="M314.57,267.184l.233,0c0,.235,0,.472,0,.7l-.233,0C314.574,267.65,314.574,267.416,314.57,267.184Z"
      transform="translate(-284.901 -245.155)"
    />
    <path
      id="Tracé_1549"
      data-name="Tracé 1549"
      class="cls-10"
      d="M177.375,435.915l-.23.035c.072.46.129.931.17,1.4l.232-.02C177.506,436.855,177.448,436.381,177.375,435.915Z"
      transform="translate(-161.412 -396.78)"
    />
    <path
      id="Tracé_1550"
      data-name="Tracé 1550"
      class="cls-10"
      d="M171.228,408.575l-.223.068c.135.446.256.9.358,1.359l.228-.052C171.487,409.49,171.364,409.028,171.228,408.575Z"
      transform="translate(-155.895 -372.213)"
    />
    <path
      id="Tracé_1551"
      data-name="Tracé 1551"
      class="cls-10"
      d="M161.417,382.355l-.212.1c.195.425.377.861.543,1.3l.218-.082C161.8,383.227,161.615,382.784,161.417,382.355Z"
      transform="translate(-147.089 -348.652)"
    />
    <path
      id="Tracé_1552"
      data-name="Tracé 1552"
      class="cls-10"
      d="M148.152,357.775l-.2.125c.25.393.491.8.714,1.21l.2-.112C148.649,358.585,148.405,358.173,148.152,357.775Z"
      transform="translate(-135.182 -326.564)"
    />
    <path
      id="Tracé_1553"
      data-name="Tracé 1553"
      class="cls-10"
      d="M131.662,335.245l-.177.151c.3.355.6.726.874,1.1l.187-.139C132.266,335.98,131.969,335.6,131.662,335.245Z"
      transform="translate(-120.382 -306.319)"
    />
    <path
      id="Tracé_1554"
      data-name="Tracé 1554"
      class="cls-10"
      d="M112.28,315.185l-.155.173c.349.311.691.639,1.016.973l.167-.163C112.979,315.83,112.633,315.5,112.28,315.185Z"
      transform="translate(-102.985 -288.293)"
    />
    <path
      id="Tracé_1555"
      data-name="Tracé 1555"
      class="cls-10"
      d="M90.376,297.965l-.131.193c.387.261.771.538,1.139.825l.144-.184C91.155,298.51,90.767,298.229,90.376,297.965Z"
      transform="translate(-83.324 -272.819)"
    />
    <path
      id="Tracé_1556"
      data-name="Tracé 1556"
      class="cls-10"
      d="M71.4,286.005v.264c.242.127.483.26.718.4l.117-.2Q71.829,286.226,71.4,286.005Z"
      transform="translate(-66.395 -262.072)"
    />
    <path
      id="Tracé_1557"
      data-name="Tracé 1557"
      class="cls-10"
      d="M179.57,464.184l.233,0c0,.235,0,.472,0,.7l-.233,0C179.574,464.65,179.574,464.416,179.57,464.184Z"
      transform="translate(-163.591 -422.178)"
    />
    <path
      id="Tracé_1558"
      data-name="Tracé 1558"
      class="cls-10"
      d="M639.375,336.915l-.23.035c.072.46.129.931.17,1.4l.232-.02C639.506,337.855,639.448,337.381,639.375,336.915Z"
      transform="translate(-576.562 -307.819)"
    />
    <path
      id="Tracé_1559"
      data-name="Tracé 1559"
      class="cls-10"
      d="M633.228,309.575l-.223.068c.135.446.255.9.358,1.359l.228-.052C633.487,310.49,633.364,310.028,633.228,309.575Z"
      transform="translate(-571.045 -283.252)"
    />
    <path
      id="Tracé_1560"
      data-name="Tracé 1560"
      class="cls-10"
      d="M623.417,283.355l-.212.1c.195.425.377.861.543,1.3l.218-.082C623.8,284.227,623.615,283.784,623.417,283.355Z"
      transform="translate(-562.239 -259.691)"
    />
    <path
      id="Tracé_1561"
      data-name="Tracé 1561"
      class="cls-10"
      d="M610.152,258.775l-.2.125c.25.393.491.8.714,1.21l.2-.112C610.649,259.585,610.405,259.173,610.152,258.775Z"
      transform="translate(-550.332 -237.603)"
    />
    <path
      id="Tracé_1562"
      data-name="Tracé 1562"
      class="cls-10"
      d="M593.662,236.245l-.177.151c.3.355.6.726.874,1.1l.187-.139C594.266,236.98,593.969,236.6,593.662,236.245Z"
      transform="translate(-535.533 -217.358)"
    />
    <path
      id="Tracé_1563"
      data-name="Tracé 1563"
      class="cls-10"
      d="M574.28,216.185l-.155.173c.349.311.691.639,1.016.972l.167-.163C574.979,216.83,574.633,216.5,574.28,216.185Z"
      transform="translate(-518.136 -199.332)"
    />
    <path
      id="Tracé_1564"
      data-name="Tracé 1564"
      class="cls-10"
      d="M552.376,198.965l-.131.193c.387.261.771.538,1.139.825l.144-.184C553.155,199.51,552.767,199.229,552.376,198.965Z"
      transform="translate(-498.475 -183.858)"
    />
    <path
      id="Tracé_1565"
      data-name="Tracé 1565"
      class="cls-10"
      d="M533.4,187.005v.264c.242.127.483.26.718.4l.117-.2Q533.829,187.226,533.4,187.005Z"
      transform="translate(-481.545 -173.111)"
    />
    <path
      id="Tracé_1566"
      data-name="Tracé 1566"
      class="cls-10"
      d="M641.57,365.184l.233,0c0,.235,0,.472,0,.7l-.233,0C641.574,365.65,641.574,365.416,641.57,365.184Z"
      transform="translate(-578.741 -333.217)"
    />
    <path
      id="Tracé_1567"
      data-name="Tracé 1567"
      class="cls-8"
      d="M145.95,198.371h-.172V198.2h-.034v.172h-.172v.033h.172v.172h.034V198.4h.172Z"
      transform="translate(-128.198 -177.973)"
    />
    <path
      id="Tracé_1568"
      data-name="Tracé 1568"
      class="cls-8"
      d="M126.409,300.728h-.172v-.172H126.2v.172h-.172v.034h.172v.172h.034v-.172h.172Z"
      transform="translate(-110.639 -269.951)"
    />
    <path
      id="Tracé_1569"
      data-name="Tracé 1569"
      class="cls-8"
      d="M69.072,390.436H68.9v-.172h-.034v.172h-.172v.034h.172v.172H68.9v-.172h.172Z"
      transform="translate(-59.116 -350.562)"
    />
    <path
      id="Tracé_1570"
      data-name="Tracé 1570"
      class="cls-1"
      d="M260.813,157.468a12.454,12.454,0,1,1,5.778,4.639,4.028,4.028,0,1,1-5.778-4.639Z"
      transform="translate(-234.682 -129.132)"
    />
    <path
      id="Tracé_1571"
      data-name="Tracé 1571"
      class="cls-4"
      d="M340.133,190.679c2.855,0,4.819,1.584,4.819,3.86a3.724,3.724,0,0,1-2.139,3.38c-1.325.769-1.774,1.332-1.774,2.307h0a.6.6,0,0,1-.6.6h-1.46a.6.6,0,0,1-.6-.58l0-.074a3.169,3.169,0,0,1,1.8-3.35c1.287-.769,1.827-1.256,1.827-2.2a1.848,1.848,0,0,0-2.048-1.637,1.951,1.951,0,0,0-1.858,1.1,1.344,1.344,0,0,1-1.228.768h0a1.321,1.321,0,0,1-1.18-1.926A4.714,4.714,0,0,1,340.133,190.679Zm-2,13.049a1.634,1.634,0,1,1,1.629,1.568A1.595,1.595,0,0,1,338.131,203.729Z"
      transform="translate(-303.739 -176.413)"
    />
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgHomePointsPicto extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.cls-1 {
  fill: var(--v-stickers_secondaire-base);
}

.cls-2 {
  fill: #2f2e41;
}

.cls-3 {
  fill: #fbbebe;
}

.cls-4,
.cls-7 {
  fill: var(--v-stickers_primaire-base);
}

.cls-5 {
  opacity: 0.4;
}

.cls-6 {
  fill: #575a89;
}

.cls-7 {
  opacity: 0.497;
}

.cls-8 {
  fill: #f2f2f2;
}

.cls-9 {
  fill: #e6e6e6;
}

.cls-10 {
  fill: var(--v-stickers_secondaire-base);
}
</style>
