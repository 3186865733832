









































































































import { Vue, Component } from 'vue-property-decorator';
import Avatar from '@/components/Avatar.vue';

import { formRules, resetPassword } from '@/services/userSpace/UserService';
import PasswordResetSuccess from '@/components/Dialogs/PasswordResetSuccess.vue';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import { HttpStatus } from '@/services/userSpace/types';

@Component({ components: { SvgPopup, PasswordResetSuccess } })
export default class ResetPassword extends Vue {
  isLoading: boolean = false;

  showDialog: boolean = false;

  successRequest: boolean = false;

  password: string = '';

  confirmPassword: string = '';

  error: string = '';

  rules = {
    password: formRules.password,
    confirmPassword: formRules.confirmPassword,
  };

  show = {
    password: false,
    confirmPassword: false,
  };

  snackbar: boolean = false;

  snackbarText: string = '';

  get matchingPassword() {
    return this.password === this.confirmPassword || 'Les mots de passe ne coïncident pas';
  }

  migrated: boolean = false;

  clearForm() {
    this.password = '';
    this.confirmPassword = '';
  }

  showSnackBar(message: string) {
    this.snackbarText = message;
    this.snackbar = true;
  }

  mounted() {
    const { migrated }: any = this.$route.query;
    if (migrated) {
      this.migrated = true;
    }
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  async onSubmit() {
    if ((this.$refs.form as any).validate()) {
      this.isLoading = true;
      const { email, token, platformUUID }: any = this.$route.query;
      const response = await resetPassword(this.password, { email, token, platformUUID });
      this.isLoading = false;

      const { status } = response;
      if (status === HttpStatus.CREATED) {
        this.clearForm();
        this.showDialog = true;
      } else {
        console.error(response);
        if (status === HttpStatus.INTERNAL_SERVER_ERROR || status === HttpStatus.SERVICE_UNAVAILABLE) {
          this.showSnackBar('Service indisponible; Réessayez plus tard');
        } else if (status === HttpStatus.UNAUTHORIZED) {
          this.clearForm();
          await this.$router.replace({ name: 'expired-token' });
        }
      }
    }
  }
}
