var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "66",
        height: "39.752",
        viewBox: "0 0 66 39.752"
      }
    },
    [
      _c(
        "g",
        {
          staticStyle: { opacity: "1" },
          attrs: { id: "prefix__business", transform: "translate(0 -101.81)" }
        },
        [
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Tracé_2517",
              d:
                "M163.323 133.317l-1.676-13.617-3.142.387-6.091-3.428a3.671 3.671 0 0 0-2.138-.456l-9.617.889a3.67 3.67 0 0 0-2.835 1.81l-6.778 11.667a1.612 1.612 0 0 0 .824 2.318l.277.1a6.013 6.013 0 0 0 1.63.364l-.859.748-1.718 1.5-4.887 4.169a2.088 2.088 0 0 0-.2 2.947l.5.568a2.684 2.684 0 0 0 3.785.258l1.868 2.142a2.679 2.679 0 0 0 3.785.258l1.866 2.143a2.685 2.685 0 0 0 3.788.26l1.014.876a2.685 2.685 0 0 0 3.788.26l1.378-1.2 11.588-9.025.013-.011h4.572z",
              "data-name": "Tracé 2517",
              transform: "translate(-109.407 -12.521)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Tracé_2518",
              d:
                "M158.367 263.747l-.013.012-11.588 9.025-1.378 1.2a2.685 2.685 0 0 1-3.788-.26l-1.014-.876a2.685 2.685 0 0 1-3.788-.26l-1.866-2.143a2.679 2.679 0 0 1-3.785-.258l-1.868-2.142a2.683 2.683 0 0 1-3.323.158 2.087 2.087 0 0 0 .165 2.524l.5.568a2.684 2.684 0 0 0 3.785.258l1.868 2.142a2.679 2.679 0 0 0 3.785.258l1.866 2.143a2.685 2.685 0 0 0 3.787.26l1.014.876a2.685 2.685 0 0 0 3.788.26l1.378-1.2 11.588-9.025.013-.011h4.572l-.506-4.109z",
              "data-name": "Tracé 2518",
              transform: "translate(-109.418 -140.536)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-3",
            attrs: {
              id: "prefix__Tracé_2519",
              d:
                "M117.9 145.263l-6.209-5.353-1.727-1.488-1.235-1.064-5.312-4.579.613-5.222a3.6 3.6 0 0 0-2.618-3.894l-11.579-3.2a2.68 2.68 0 0 0-3.1 1.361l-1.456 2.842-6.25-.424-1.6 13.628-.7 5.923 6.084.4.013.011 12.71 10.956 1.385 1.195a2.685 2.685 0 0 0 3.786-.281l1.854-2.151a2.685 2.685 0 0 0 3.786-.281l1.854-2.153a2.679 2.679 0 0 0 3.784-.279l1.856-2.153a2.684 2.684 0 0 0 3.784-.279l.493-.571a2.088 2.088 0 0 0-.216-2.944z",
              "data-name": "Tracé 2519",
              transform: "translate(-66.836 -16.16)"
            }
          }),
          _c(
            "g",
            {
              attrs: {
                id: "prefix__Groupe_672",
                "data-name": "Groupe 672",
                transform: "translate(29.245 127.471)"
              }
            },
            [
              _c("path", {
                staticClass: "prefix__cls-3",
                attrs: {
                  id: "prefix__Tracé_2520",
                  d:
                    "M248.687 302.514l-1.891-1.631a2.217 2.217 0 0 1-.092 2.78l-.523.606a2.849 2.849 0 0 1-4.017.3l-1.97 2.285a2.844 2.844 0 0 1-4.017.3l-1.968 2.286a2.85 2.85 0 0 1-4.019.3l-1.968 2.283a2.83 2.83 0 0 1-1.35.87l.838.723a2.685 2.685 0 0 0 3.786-.281l1.854-2.151a2.685 2.685 0 0 0 3.786-.281l1.854-2.153a2.679 2.679 0 0 0 3.784-.279l1.856-2.153a2.684 2.684 0 0 0 3.784-.279l.493-.571a2.088 2.088 0 0 0-.22-2.954z",
                  "data-name": "Tracé 2520",
                  transform: "translate(-226.872 -300.883)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-3",
                attrs: {
                  id: "prefix__Tracé_2521",
                  d:
                    "M335.71 316.008l-3.4-2.933a.967.967 0 1 0-1.263 1.465l3.4 2.928.64-.742a2.676 2.676 0 0 0 .84.489.967.967 0 0 0-.217-1.207z",
                  "data-name": "Tracé 2521",
                  transform: "translate(-317.325 -311.299)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-3",
                attrs: {
                  id: "prefix__Tracé_2522",
                  d:
                    "M294.491 337.978a.966.966 0 0 0-.2-1.222l-3.056-2.635a.967.967 0 1 0-1.263 1.464l3.056 2.635.631-.733a2.673 2.673 0 0 0 .832.491z",
                  "data-name": "Tracé 2522",
                  transform: "translate(-281.545 -329.632)"
                }
              })
            ]
          ),
          _c(
            "g",
            {
              attrs: {
                id: "prefix__Groupe_673",
                "data-name": "Groupe 673",
                transform: "translate(15.067 103.663)"
              }
            },
            [
              _c("path", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Tracé_2523",
                  d:
                    "M118.111 298.693l-.736.972a2.4 2.4 0 0 0 .465 3.37 2.4 2.4 0 0 0 3.37-.465l.736-.972a2.4 2.4 0 0 0-.465-3.37 2.4 2.4 0 0 0-3.37.465z",
                  "data-name": "Tracé 2523",
                  transform: "translate(-116.887 -274.336)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Tracé_2524",
                  d:
                    "M148.512 314.56l-1.387 1.831a2.4 2.4 0 0 0 .465 3.37 2.4 2.4 0 0 0 3.37-.465l1.387-1.831a2.4 2.4 0 0 0-.465-3.37 2.4 2.4 0 0 0-3.37.465z",
                  "data-name": "Tracé 2524",
                  transform: "translate(-142.802 -288.158)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Tracé_2525",
                  d:
                    "M178.764 331.935l-1.89 2.5a2.4 2.4 0 0 0 .465 3.37 2.4 2.4 0 0 0 3.37-.465l1.89-2.5a2.4 2.4 0 0 0-.465-3.37 2.4 2.4 0 0 0-3.37.465z",
                  "data-name": "Tracé 2525",
                  transform: "translate(-168.716 -303.293)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Tracé_2526",
                  d:
                    "M208.624 353.341l-2 2.641a2.4 2.4 0 0 0 .465 3.37 2.4 2.4 0 0 0 3.37-.465l2-2.641a2.4 2.4 0 0 0-.465-3.37 2.4 2.4 0 0 0-3.37.465z",
                  "data-name": "Tracé 2526",
                  transform: "translate(-194.631 -321.94)"
                }
              }),
              _c("path", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Tracé_2527",
                  d:
                    "M193.854 120.083l-6.091-3.428a3.671 3.671 0 0 0-2.138-.456l-9.617.889a3.67 3.67 0 0 0-2.835 1.81l-6.773 11.668a1.612 1.612 0 0 0 .824 2.318l.277.1a6.027 6.027 0 0 0 6.909-1.966l2.6-3.38s6.243 7.228 13.116-1.213",
                  "data-name": "Tracé 2527",
                  transform: "translate(-159.823 -116.183)"
                }
              })
            ]
          ),
          _c("path", {
            staticClass: "prefix__cls-3",
            attrs: {
              id: "prefix__Tracé_2528",
              d:
                "M411.825 128.538l-3.186-25.878a.967.967 0 0 0-1.078-.841l-7.449.917a3.539 3.539 0 0 0-3.077 3.941l2.558 20.78a3.534 3.534 0 0 0 3.941 3.077l7.449-.917a.967.967 0 0 0 .842-1.079z",
              "data-name": "Tracé 2528",
              transform: "translate(-345.832 -.001)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2529",
              d:
                "M427.529 287.525l-.35-2.841a1.01 1.01 0 0 1-.713.436l-7.809.961a3.7 3.7 0 0 1-3.62-1.756l.261 2.117a3.534 3.534 0 0 0 3.941 3.077l7.449-.917a.967.967 0 0 0 .841-1.077z",
              "data-name": "Tracé 2529",
              transform: "translate(-361.536 -158.989)"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-2",
            attrs: {
              id: "prefix__Tracé_2530",
              d:
                "M11.72 102.734l-7.449-.917a.967.967 0 0 0-1.078.841L.007 128.537a.967.967 0 0 0 .841 1.078l7.449.917a3.533 3.533 0 0 0 3.941-3.077l2.558-20.78a3.539 3.539 0 0 0-3.076-3.941z",
              "data-name": "Tracé 2530"
            }
          }),
          _c("path", {
            staticClass: "prefix__cls-4",
            attrs: {
              id: "prefix__Tracé_2531",
              d:
                "M.007 287.525l.35-2.841a1.01 1.01 0 0 0 .713.436l7.809.961a3.7 3.7 0 0 0 3.62-1.756l-.261 2.117A3.534 3.534 0 0 1 8.3 289.52l-7.451-.92a.967.967 0 0 1-.842-1.075z",
              "data-name": "Tracé 2531",
              transform: "translate(0 -158.989)"
            }
          }),
          _c(
            "g",
            {
              attrs: {
                id: "prefix__Groupe_674",
                "data-name": "Groupe 674",
                transform: "translate(4.214 122.524)"
              }
            },
            [
              _c("circle", {
                staticClass: "prefix__cls-2",
                attrs: {
                  id: "prefix__Ellipse_145",
                  cx: "1.869",
                  cy: "1.869",
                  r: "1.869",
                  "data-name": "Ellipse 145",
                  transform: "translate(53.729)"
                }
              }),
              _c("circle", {
                staticClass: "prefix__cls-3",
                attrs: {
                  id: "prefix__Ellipse_146",
                  cx: "1.869",
                  cy: "1.869",
                  r: "1.869",
                  "data-name": "Ellipse 146"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }