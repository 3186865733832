var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "password-reset-success",
        "max-width": "600px"
      },
      on: {
        input: function($event) {
          return _vm.showDialog()
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "password-reset-success__card" },
        [
          _c(
            "v-card-title",
            { staticClass: "password-reset-success__title" },
            [
              _vm.compShowStickers
                ? _c(
                    "div",
                    { staticClass: "password-reset-success__title-icon" },
                    [_c("SvgPopup")],
                    1
                  )
                : _vm._e(),
              _c("h2", { staticClass: "password-reset-success__title-text" }, [
                _vm._v("Félicitations !")
              ]),
              _c("v-icon", {
                staticClass: "password-reset-success__close",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-times") },
                on: {
                  click: function($event) {
                    return _vm.showDialog()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "password-reset-success__subtitle" },
            [_vm._v("Votre mot de passe a été réinitialisé ! ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  staticClass: "password-reset-success__form-button",
                  attrs: {
                    large: "",
                    color: "popup_fond_bouton",
                    block: "",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.navigateToHome($event)
                    }
                  }
                },
                [_vm._v(" Ok ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }