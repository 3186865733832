// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-a1c55614] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-a1c55614] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-a1c55614] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.no-results[data-v-a1c55614] {\n  font-family: var(--body-font);\n  color: var(--v-homeglobal_texte_fond-base);\n  font-weight: normal;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  text-align: center;\n  width: 80%;\n  margin: 1rem auto;\n  font-size: 1rem !important;\n  line-height: 1.17;\n  letter-spacing: -0.24px;\n  white-space: pre-line;\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n@media only screen and (max-width: 599.98px) {\n.no-results[data-v-a1c55614] {\n    font-size: 0.938rem;\n}\n}\n@media only screen and (min-width: 600px) {\n.no-results[data-v-a1c55614] {\n    font-size: 1.2rem;\n    line-height: 1.2;\n}\n}\n@media only screen and (min-width: 600px) {\n.no-results[data-v-a1c55614] {\n    line-height: 1.2;\n    letter-spacing: -0.4px;\n    width: 60%;\n    margin: 3rem auto;\n}\n}\n@media only screen and (min-width: 960px) {\n.no-results[data-v-a1c55614] {\n    line-height: 1.2;\n    letter-spacing: -0.4px;\n    width: 70%;\n}\n}", ""]);
// Exports
module.exports = exports;
