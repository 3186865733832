var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "banner",
      attrs: {
        "data-cy": "synchro-proposal-banner-text-block",
        prominent: "",
        color: "var(--v-homebloc_fond_bouton-base)",
        align: "center"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "banner-text",
              attrs: { cols: "12", "align-self": "center" }
            },
            [
              _vm._v(
                " Pour profiter au maximum des avantages, vous pouvez associer votre compte : "
              ),
              !_vm.isMobile
                ? _c(
                    "v-btn",
                    {
                      staticClass: "banner-button",
                      attrs: { rounded: "", large: "" },
                      on: { click: _vm.assignToSSO }
                    },
                    [
                      _vm._v(
                        " Associer mon compte " + _vm._s(_vm.service) + " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isMobile
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", text: "", elevation: "2" },
                      on: { click: _vm.assignToSSO }
                    },
                    [
                      _vm._v(
                        " Associer mon compte " + _vm._s(_vm.service) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }