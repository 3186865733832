var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.smAndUp
    ? _c(
        "div",
        { staticClass: "page-title mb-8" },
        [
          _vm.compShowStickers
            ? _c(
                "div",
                { staticClass: "mr-8" },
                [
                  _vm._t("icon", [
                    _vm.compShowStickers ? _c("PageTitleIcon") : _vm._e()
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._t("text", [
            _c("h2", {
              staticClass: "page-title__text",
              domProps: { innerHTML: _vm._s(_vm.text) }
            })
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }