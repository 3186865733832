










































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class NewCguDialog extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private readonly consent: any;

  get GCURouter() {
    const route = this.$router.resolve({ name: 'gcu-legal-notice' });
    return route.href;
  }
}
