var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "430px"
      },
      on: { input: _vm.onVisibilityChange }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-xl align-content-center" },
        [
          _c("v-card-text", {
            staticClass:
              "text-center black--text text-uppercase font-weight-bold text-sm-h6 pt-9 title",
            domProps: { innerHTML: _vm._s(_vm.titleMessage) }
          }),
          _c("v-card-text", {
            staticClass: "text-center text-subtitle-1 px-5 mt-2 content",
            domProps: { innerHTML: _vm._s(_vm.customMessage) }
          }),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "mt-4 mb-8 text-subtitle-3 white--text font-weight-bold",
                  attrs: {
                    large: "",
                    rounded: "",
                    size: "large",
                    color: "#e2017b",
                    "min-width": "220px"
                  },
                  on: { click: _vm.onSynchronize }
                },
                [_vm._v(" Synchroniser mon compte ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }