















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HistoryLine from '@/components/Account/HistoryLine.vue';
import PageTitle from '@/components/PageTitle.vue';
import NoPoints from '@/components/Account/NoPoints.vue';
import * as UserService from '@/services/userSpace/UserService';
import { targetAudienceEnum } from '@/services/merchantSpace/OfferService';
import ExpirableBalance from '@/components/ExpirableBalance.vue';

@Component({
  components: {
    ExpirableBalance,
    HistoryLine,
    PageTitle,
    NoPoints,
  },
})
export default class PointsHistory extends Vue {
  loading: boolean = false;

  balance = 0;

  textPoints = '';

  page: number = 1;

  listMobile: any = [];

  listDesktop: any = [];

  showBtn: boolean = true;

  @Prop({ type: Number, default: 12 })
  readonly limit!: number;

  @Prop({ type: Boolean, default: false })
  readonly infinite!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly triggerBalance!: boolean;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  async mounted() {
    await this.loadPointsAndHistory();
  }

  async infiniteHandler($state: any) {
    const response = await UserService.getHistory(this.page, this.limit);
    if (response.length > 0) {
      this.page += 1;
      this.listDesktop.push(...response);
      this.listMobile.push(...response);
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  get textPoint() {
    if (this.balance < 1) {
      this.textPoints = 'point';
    } else {
      this.textPoints = 'points';
    }
    return this.textPoints;
  }

  get userPeremptionBalance() {
    return this.$store.getters['user/getUserPeremptionBalance'];
  }

  get showNextPeremption() {
    return this.$store.getters['platform/peremptionIsActive'] && this.userPeremptionBalance > 0;
  }

  navigateToPointsHistory(arg: string) {
    if (arg === 'btn') {
      this.$router.push({ name: 'history' });
    } else if (arg === 'card' && this.isMobile) {
      this.$router.push({ name: 'history' });
    }
  }

  @Watch('triggerBalance')
  async refreshBalance(val: any) {
    await this.loadPointsAndHistory();
  }

  async loadPointsAndHistory() {
    this.loading = true;
    if (!this.infinite) {
      this.listDesktop = await UserService.getHistory(1, 7);
      this.listMobile = [...this.listDesktop].slice(0, 3);
    }
    this.balance = await UserService.getUserPoints(targetAudienceEnum.PUBLIC.id);
    if (this.$router.currentRoute.name === 'history') {
      this.showBtn = false;
    }
    this.loading = false;
  }
}
