import { apiAuthenticatedInstance, apiPublicInstance } from '@/services/API';

export const getOrders = async (page: number, limit: number): Promise<any> => {
  return (await apiAuthenticatedInstance().get(`/api/orders/user?platformUuid=${process.env.VUE_APP_PLATFORM_UUID}&page=${page}&limit=${limit}`)).data;
};

export const getOrdersCount = async (offerId: number, platformId: number): Promise<any> => {
  return (await apiPublicInstance().get(`/api/orders/${offerId}/${platformId}/count`)).data;
};

export const downloadPDF = async (number: number, uuid: string): Promise<void> => {
  const orderUUIDs: string[] = [];
  orderUUIDs.push(uuid);
  return apiAuthenticatedInstance()
    .post(`/api/pdf/order`, { orderUUIDs }, { responseType: 'arraybuffer' })
    .then((response) => {
      forceFileDownload('commande_'.concat(number.toString(), '.pdf'), response);
    })
    .catch(() => console.error('error occured'));
};

const forceFileDownload = async (filename, response) => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const anonymizeOrdersForServiceAccount = async (email: string): Promise<any> => {
  return (await apiAuthenticatedInstance().get(`/api/offers/deleteAccount/${email}`)).data;
};
