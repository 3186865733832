





















import { Vue, Component } from 'vue-property-decorator';
import SignupMessage from '@/components/Dialogs/SignupMessage.vue';
import SignupStep from '@/components/Forms/SignupStep.vue';
import * as UserService from '@/services/userSpace/UserService';
import { SignupStepValues, UserCreationRequest } from '@/services/userSpace/types';
import store from '@/store';

interface SignupMessageValues {
  signupMessageActivated: boolean | null;
  signupMessageTitle: string;
  signupMessageDescription: string;
}

@Component({
  components: {
    SignupMessage,
    SignupStep,
  },
})
export default class Signup extends Vue {
  curError: string = '';

  stepLoading: boolean = false;

  signupMessage: SignupMessageValues = {
    signupMessageActivated: null,
    signupMessageTitle: '',
    signupMessageDescription: '',
  };

  formValues: Partial<SignupStepValues> = {};

  logo: string = '';

  get fullLogoUrl() {
    return this.logo ? `${process.env.VUE_APP_API_URL}/api${this.logo}` : require('@/assets/logo.svg');
  }

  async created() {
    const platform = this.$store.getters['platform/getPlatform'];

    this.signupMessage = {
      signupMessageActivated: platform.config.messageAndBanner.signupMessageActivated,
      signupMessageTitle: platform.config.messageAndBanner.signupMessageTitle,
      signupMessageDescription: platform.config.messageAndBanner.signupMessageDescription,
    };

    this.logo = platform.style.logoUrl;
    this.getUserValues();
    const path = '/signup';
    if (this.$route.fullPath !== path) {
      await this.$router.replace(path);
    }
  }

  async onValidateStep(valid: boolean, formValues: any): Promise<void> {
    this.curError = '';
    this.stepLoading = true;

    try {
      const createdUser = await UserService.createUser(formValues as UserCreationRequest, 'fr');
      if (createdUser) {
        await UserService.login(createdUser.email, formValues.password);
        const pageToShow = store.getters['platform/getPlatform'].config.landingPath;
        await this.$router.push(pageToShow);
      }
    } catch (error) {
      const messageError = error.response.data && error.response.data.message && error.response.data.message.fr;
      if (messageError) {
        this.curError = messageError;
        this.stepLoading = false;
      } else {
        this.stepLoading = false;
        throw error;
      }
    }
  }

  showSignup() {
    this.signupMessage.signupMessageActivated = false;
  }

  showSignin() {
    this.$store.dispatch('showLogin', true);
  }

  getUserValues() {
    this.formValues = {
      ...this.$route.query,
    };
  }
}
