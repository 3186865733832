// Fonctions utilisées pour éviter des dépendances circulaire
import Router from 'vue-router';

type Container = { router?: Router; toto?: number };

const container: Container = {
  router: undefined,
  toto: undefined,
};

export function setSingleton(name: keyof Container, value: Container[keyof Container]) {
  container[name] = value as any;
}

export function getSingleton(name: 'router'): Router;
export function getSingleton(name: keyof Container) {
  return container[name];
}

export function getRouter() {
  return getSingleton('router');
}

export function setRouter(router: Router) {
  return setSingleton('router', router);
}
