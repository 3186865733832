import { isConnected } from '@/services/common/common';
import Storage from '@/libs/storage';
import { scanQrCode } from '@/api/resources/actionApi';
import { getRouter } from '@/libs/singleton';
import { refreshUser, refreshUserPoints } from '../userSpace/UserService';

type Scan = { qrcode: string; hit: number };

export function saveCurrentQrCode() {
  const queue: Scan[] = Storage.get('qrcode:queue') || [];

  const { qrcode } = pullQrcodeFromUrl();
  if (!qrcode) {
    return undefined;
  }

  const scanDuplicata = queue.find((previousScan) => previousScan.qrcode === qrcode);
  if (!scanDuplicata) {
    queue.push({ qrcode, hit: 0 });
    Storage.save('qrcode:queue', queue);
  }

  return { qrcode };
}

export function nextQrCodeReward(): {
  state: 'idle' | 'rewarding';
  job: () => Promise<any>;
} {
  const [firstScan, ...nextQueue]: Scan[] = Storage.get('qrcode:queue') || [];
  if (!isConnected() || !firstScan) {
    return { state: 'idle', job: () => Promise.resolve() };
  }

  const { qrcode, hit } = firstScan;
  return {
    state: 'rewarding',
    job() {
      return (
        scanQrCode({ qrcode })
          .then(async (res) => {
            await Promise.all([refreshUser(), refreshUserPoints()]).catch((e) => console.error('refresh points after qrcode failed', e));
            return res;
          })
          // .catch((e) => {
          //   // TODO only push if e is not a normal error (unknown qrcode, ...)
          //   if (hit < 2) {
          //     nextQueue.push({ qrcode, hit: hit + 1 });
          //   }

          //   throw e;
          // })
          .finally(() => {
            Storage.save('qrcode:queue', nextQueue);
          })
      );
    },
  };
}

function pullQrcodeFromUrl(): { qrcode?: string } {
  const router = getRouter();
  const { currentRoute } = router;

  router.replace({
    query: {
      ...currentRoute.query,
      scan: undefined,
    },
  });

  return {
    qrcode: currentRoute.query.scan as any,
  };
}
