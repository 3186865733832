import { render, staticRenderFns } from "./SvgPictoCashback.vue?vue&type=template&id=87146654&scoped=true&"
import script from "./SvgPictoCashback.vue?vue&type=script&lang=ts&"
export * from "./SvgPictoCashback.vue?vue&type=script&lang=ts&"
import style0 from "./SvgPictoCashback.vue?vue&type=style&index=0&id=87146654&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87146654",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('87146654')) {
      api.createRecord('87146654', component.options)
    } else {
      api.reload('87146654', component.options)
    }
    module.hot.accept("./SvgPictoCashback.vue?vue&type=template&id=87146654&scoped=true&", function () {
      api.rerender('87146654', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Svg/SvgPictoCashback.vue"
export default component.exports