var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Composant_40_51",
        "data-name": "Composant 40 – 51",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 53.939 43.353"
      }
    },
    [
      _c("title", { attrs: { id: "svgPopup" } }, [_vm._v("svg popup")]),
      _c("circle", {
        staticClass: "cls-1",
        attrs: {
          id: "Ellipse_53",
          "data-name": "Ellipse 53",
          cx: "20",
          cy: "20",
          r: "20"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_741",
          "data-name": "Rectangle 741",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "translate(37.919 14.577) rotate(41)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_742",
          "data-name": "Rectangle 742",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "matrix(-0.326, 0.946, -0.946, -0.326, 47.108, 29.189)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_743",
          "data-name": "Rectangle 743",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "translate(32.679 38.747) rotate(173)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_745",
          "data-name": "Rectangle 745",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "matrix(0.342, 0.94, -0.94, 0.342, 43.688, 35.985)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_746",
          "data-name": "Rectangle 746",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "translate(53.939 24.695) rotate(158)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_749",
          "data-name": "Rectangle 749",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "matrix(-0.857, 0.515, -0.515, -0.857, 24.423, 33.645)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_747",
          "data-name": "Rectangle 747",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "translate(31.099 8) rotate(61)"
        }
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          id: "Rectangle_744",
          "data-name": "Rectangle 744",
          width: "4.79",
          height: "8.382",
          rx: "2.395",
          transform: "translate(31.199 25.486) rotate(144)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }