







































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as userService from '@/services/userSpace/UserService';
import { loginAndRedirectToLanding, startCodeFlow } from '@/services/authSpace/sso.service';

@Component({})
export default class SSOAccountSyncronizeView extends Vue {
  @Prop({ type: String, required: true })
  readonly signature!: string;

  @Prop({ type: String, required: true })
  readonly platformUUID!: string;

  @Prop({ type: String, required: true })
  readonly expires!: string;

  @Prop({ type: String, required: true })
  readonly userInfos!: string;

  @Prop({ type: String, required: true })
  readonly basedOn!: string;

  @Prop({ type: String, required: true })
  readonly cryptedPart!: string;

  showTimeOutMessage = false;

  showErrorMessage = false;

  async mounted() {
    if (Number(this.expires) < Date.now()) {
      this.showTimeOutMessage = true;
    } else {
      try {
        const res = await userService.applySSOSynchro(this.userInfos, this.expires, this.signature, this.platformUUID, this.basedOn, this.cryptedPart);
        if (!res.tokens) {
          this.showErrorMessage = true;
          return;
        }
        this.$store.dispatch('showConfirmSSOSynchroMessage', true);
        await loginAndRedirectToLanding(res.tokens);
      } catch (error) {
        this.showErrorMessage = true;
        setTimeout(() => this.$router.push('/'), 5000);
      }
    }
  }

  reconnect() {
    startCodeFlow();
  }
}
