var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "validate",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "validate-card" } },
        [
          _c("v-card-title", { staticClass: "validate-card-title" }, [
            _vm.compShowStickers
              ? _c(
                  "div",
                  { staticClass: "validate-card-title-picto" },
                  [_c("SvgTitle")],
                  1
                )
              : _vm._e(),
            _c("h1", { staticClass: "validate-card-title-text" }, [
              _vm._v("Validé")
            ])
          ]),
          _c("v-card-text", { staticClass: "validate-card-subtitle" }, [
            _vm._v("Votre demande a bien été prise en compte. ")
          ]),
          _c("v-card-text", { staticClass: "validate-card-subtitle" }, [
            _vm._v(
              "Vous allez recevoir un email de confirmation avec le récapitulatif de votre commande. "
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "validate-card-btn",
              attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
              on: { click: _vm.onClick }
            },
            [_vm._v(" Retour profil ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }