var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Groupe_645",
        "data-name": "Groupe 645",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 152.357 92.83"
      }
    },
    [
      _c("title"),
      _c("g", { attrs: { id: "undraw_town_r6pc" } }, [
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_958",
            "data-name": "Rectangle 958",
            width: "26.981",
            height: "0.993",
            transform: "translate(13.939 64.954)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_959",
            "data-name": "Rectangle 959",
            width: "26.981",
            height: "0.993",
            transform: "translate(75.02 64.954)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_960",
            "data-name": "Rectangle 960",
            width: "26.981",
            height: "0.993",
            transform: "translate(116.568 64.954)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1866",
            "data-name": "Tracé 1866",
            d:
              "M988.586,471.117a.742.742,0,0,1-1.385,0,.742.742,0,0,1-1.385,0s-1.076.529-1.073,1.176q0,.024,0,.048c.037.423,1.149.531,1.761.332a2.523,2.523,0,0,0,1.385,0c.609.2,1.744.087,1.777-.342,0-.016,0-.032,0-.048C989.667,471.636,988.586,471.117,988.586,471.117Z",
            transform: "translate(-844.879 -421.207)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1867",
            "data-name": "Tracé 1867",
            d: "M2.159,139.195l-.955-.764,5.346-4.773H20.106v4.2l-8.783,5.919Z",
            transform: "translate(-1.005 -111.534)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1868",
            "data-name": "Tracé 1868",
            d:
              "M73.636,136.335v-2.582h-1.8v.632l-.672-.728-4.391,4.773-1.718,1.146L62.39,159.043l4.018,4.018h9.339V138.622Z",
            transform: "translate(-52.063 -111.534)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_961",
            "data-name": "Rectangle 961",
            width: "14.511",
            height: "24.63",
            transform: "translate(0.199 26.898)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_962",
            "data-name": "Rectangle 962",
            width: "2.673",
            height: "6.014",
            transform: "translate(5.832 45.514)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1869",
            "data-name": "Tracé 1869",
            d: "M12.155,280.526v-.955H11.009v.955h1.146Z",
            transform: "translate(-9.187 -233.294)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1870",
            "data-name": "Tracé 1870",
            d: "M12.059,289.952h-1.05v.955h1.146v-.955Z",
            transform: "translate(-9.187 -241.956)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_963",
            "data-name": "Rectangle 963",
            width: "1.146",
            height: "0.955",
            transform: "translate(3.732 46.277)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_964",
            "data-name": "Rectangle 964",
            width: "1.146",
            height: "0.955",
            transform: "translate(3.732 47.996)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1871",
            "data-name": "Tracé 1871",
            d: "M59.447,280.526v-.955H58.3v.955h1.146Z",
            transform: "translate(-48.65 -233.294)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1872",
            "data-name": "Tracé 1872",
            d: "M59.351,289.952H58.3v.955h1.146v-.955Z",
            transform: "translate(-48.65 -241.956)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_965",
            "data-name": "Rectangle 965",
            width: "1.146",
            height: "0.955",
            transform: "translate(11.56 46.277)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_966",
            "data-name": "Rectangle 966",
            width: "1.146",
            height: "0.955",
            transform: "translate(11.56 47.996)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1873",
            "data-name": "Tracé 1873",
            d: "M107.892,165.179v-.955h-1.146v.955h1.146Z",
            transform: "translate(-89.076 -137.04)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1874",
            "data-name": "Tracé 1874",
            d: "M107.8,174.606h-1.05v.955h1.146v-.955Z",
            transform: "translate(-89.076 -145.703)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_967",
            "data-name": "Rectangle 967",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 27.184)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_968",
            "data-name": "Rectangle 968",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 28.902)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1875",
            "data-name": "Tracé 1875",
            d: "M107.892,197.477v-.955h-1.146v.955h1.146Z",
            transform: "translate(-89.076 -163.992)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1876",
            "data-name": "Tracé 1876",
            d: "M107.8,206.9h-1.05v.955h1.146V206.9Z",
            transform: "translate(-89.076 -172.654)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_969",
            "data-name": "Rectangle 969",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 32.53)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_970",
            "data-name": "Rectangle 970",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 34.249)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1877",
            "data-name": "Tracé 1877",
            d: "M107.892,229.774v-.955h-1.146v.955h1.146Z",
            transform: "translate(-89.076 -190.943)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1878",
            "data-name": "Tracé 1878",
            d: "M107.8,239.2h-1.05v.955h1.146V239.2Z",
            transform: "translate(-89.076 -199.605)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_971",
            "data-name": "Rectangle 971",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 37.876)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_972",
            "data-name": "Rectangle 972",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 39.595)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1879",
            "data-name": "Tracé 1879",
            d: "M107.892,262.071v-.955h-1.146v.955h1.146Z",
            transform: "translate(-89.076 -217.893)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1880",
            "data-name": "Tracé 1880",
            d: "M107.8,271.5h-1.05v.955h1.146V271.5Z",
            transform: "translate(-89.076 -226.556)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_973",
            "data-name": "Rectangle 973",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 43.222)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_974",
            "data-name": "Rectangle 974",
            width: "1.146",
            height: "0.955",
            transform: "translate(19.579 44.941)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_975",
            "data-name": "Rectangle 975",
            width: "11.074",
            height: "2.291",
            transform: "translate(1.822 28.139)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_976",
            "data-name": "Rectangle 976",
            width: "11.074",
            height: "2.291",
            transform: "translate(1.822 32.721)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_977",
            "data-name": "Rectangle 977",
            width: "11.074",
            height: "2.291",
            transform: "translate(1.822 37.304)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_978",
            "data-name": "Rectangle 978",
            width: "11.074",
            height: "2.291",
            transform: "translate(1.822 41.886)"
          }
        }),
        _c("path", {
          staticClass: "cls-4",
          attrs: {
            id: "Tracé_1881",
            "data-name": "Tracé 1881",
            d:
              "M258.626,471.953a3.277,3.277,0,0,0-.687.475,3.267,3.267,0,0,0-.7-.475,3.278,3.278,0,0,0-.687.475,3.266,3.266,0,0,0-.7-.475s-1.076.529-1.073,1.176c0,.016,0,.032,0,.048.037.423,1.149.531,1.761.332a2.523,2.523,0,0,0,1.384,0c.609.2,1.744.087,1.777-.342q0-.024,0-.048C259.707,472.473,258.626,471.953,258.626,471.953Z",
            transform: "translate(-235.749 -421.904)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1882",
            "data-name": "Tracé 1882",
            d:
              "M103.364,139.195l-.955-.764,5.346-4.773h13.556v4.2l-8.783,5.919Z",
            transform: "translate(-85.457 -111.534)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1883",
            "data-name": "Tracé 1883",
            d:
              "M174.841,136.335v-2.582h-1.8v.632l-.672-.728-4.391,4.773-1.718,1.146L163.6,159.043l4.018,4.018h9.339V138.622Z",
            transform: "translate(-136.515 -111.534)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_979",
            "data-name": "Rectangle 979",
            width: "14.511",
            height: "24.63",
            transform: "translate(16.952 26.898)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_980",
            "data-name": "Rectangle 980",
            width: "2.673",
            height: "6.014",
            transform: "translate(22.584 45.514)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1884",
            "data-name": "Tracé 1884",
            d: "M113.36,280.526v-.955h-1.146v.955h1.146Z",
            transform: "translate(-93.639 -233.294)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1885",
            "data-name": "Tracé 1885",
            d: "M113.264,289.952h-1.05v.955h1.146v-.955Z",
            transform: "translate(-93.639 -241.956)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_981",
            "data-name": "Rectangle 981",
            width: "1.146",
            height: "0.955",
            transform: "translate(20.484 46.277)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_982",
            "data-name": "Rectangle 982",
            width: "1.146",
            height: "0.955",
            transform: "translate(20.484 47.996)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1886",
            "data-name": "Tracé 1886",
            d: "M160.652,280.526v-.955h-1.146v.955h1.146Z",
            transform: "translate(-133.103 -233.294)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1887",
            "data-name": "Tracé 1887",
            d: "M160.556,289.952h-1.05v.955h1.146v-.955Z",
            transform: "translate(-133.103 -241.956)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_983",
            "data-name": "Rectangle 983",
            width: "1.146",
            height: "0.955",
            transform: "translate(28.312 46.277)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_984",
            "data-name": "Rectangle 984",
            width: "1.146",
            height: "0.955",
            transform: "translate(28.312 47.996)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1888",
            "data-name": "Tracé 1888",
            d: "M209.1,165.179v-.955h-1.146v.955H209.1Z",
            transform: "translate(-173.529 -137.04)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1889",
            "data-name": "Tracé 1889",
            d: "M209,174.606h-1.05v.955H209.1v-.955Z",
            transform: "translate(-173.529 -145.703)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_985",
            "data-name": "Rectangle 985",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 27.184)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_986",
            "data-name": "Rectangle 986",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 28.902)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1890",
            "data-name": "Tracé 1890",
            d: "M209.1,197.477v-.955h-1.146v.955H209.1Z",
            transform: "translate(-173.529 -163.992)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1891",
            "data-name": "Tracé 1891",
            d: "M209,206.9h-1.05v.955H209.1V206.9Z",
            transform: "translate(-173.529 -172.654)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_987",
            "data-name": "Rectangle 987",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 32.53)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_988",
            "data-name": "Rectangle 988",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 34.249)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1892",
            "data-name": "Tracé 1892",
            d: "M209.1,229.774v-.955h-1.146v.955H209.1Z",
            transform: "translate(-173.529 -190.943)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1893",
            "data-name": "Tracé 1893",
            d: "M209,239.2h-1.05v.955H209.1V239.2Z",
            transform: "translate(-173.529 -199.605)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_989",
            "data-name": "Rectangle 989",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 37.876)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_990",
            "data-name": "Rectangle 990",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 39.595)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1894",
            "data-name": "Tracé 1894",
            d: "M209.1,262.071v-.955h-1.146v.955H209.1Z",
            transform: "translate(-173.529 -217.893)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1895",
            "data-name": "Tracé 1895",
            d: "M209,271.5h-1.05v.955H209.1V271.5Z",
            transform: "translate(-173.529 -226.556)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_991",
            "data-name": "Rectangle 991",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 43.222)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_992",
            "data-name": "Rectangle 992",
            width: "1.146",
            height: "0.955",
            transform: "translate(36.332 44.941)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_993",
            "data-name": "Rectangle 993",
            width: "11.074",
            height: "2.291",
            transform: "translate(18.575 28.139)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_994",
            "data-name": "Rectangle 994",
            width: "11.074",
            height: "2.291",
            transform: "translate(18.575 32.721)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_995",
            "data-name": "Rectangle 995",
            width: "11.074",
            height: "2.291",
            transform: "translate(18.575 37.304)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_996",
            "data-name": "Rectangle 996",
            width: "11.074",
            height: "2.291",
            transform: "translate(18.575 41.886)"
          }
        }),
        _c("circle", {
          staticClass: "cls-5",
          attrs: {
            id: "Ellipse_77",
            "data-name": "Ellipse 77",
            cx: "4.741",
            cy: "4.741",
            r: "4.741",
            transform: "translate(15.412 0)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_997",
            "data-name": "Rectangle 997",
            width: "0.462",
            height: "18.261",
            transform: "translate(50.075 33.18)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1896",
            "data-name": "Tracé 1896",
            d:
              "M426.813,328.913q0,.3-.006.6c-.166,7.189-8.784,7.228-9.016.042q-.01-.3-.011-.6a28.431,28.431,0,0,1,4.449-14.57A28.431,28.431,0,0,1,426.813,328.913Z",
            transform: "translate(-371.765 -290.418)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1897",
            "data-name": "Tracé 1897",
            d:
              "M416.013,471.709a3.279,3.279,0,0,0-.687.475,1.9,1.9,0,0,1-2.082-.475s-1.076.529-1.073,1.176q0,.024,0,.048c.037.423,1.149.531,1.761.332a2.524,2.524,0,0,0,1.384,0c.609.2,1.744.087,1.777-.342q0-.024,0-.048C417.094,472.228,416.013,471.709,416.013,471.709Z",
            transform: "translate(-367.083 -421.7)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1898",
            "data-name": "Tracé 1898",
            d:
              "M370.852,215.147l-.573-.955,5.346-8.736h13.747v1.336l-4.964,9.5-5.728,1.146Z",
            transform: "translate(-308.987 -171.448)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1899",
            "data-name": "Tracé 1899",
            d:
              "M456.228,210.8v-7.141h-1.8v.942l-.567-.644-4.582,8.736-.764.764-.955,3.819,1.718,4.2h8.783V212.88Z",
            transform: "translate(-373.476 -169.944)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_998",
            "data-name": "Rectangle 998",
            width: "14.511",
            height: "8.783",
            transform: "translate(61.292 42.745)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_999",
            "data-name": "Rectangle 999",
            width: "3.055",
            height: "2.673",
            transform: "translate(70.743 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_1000",
            "data-name": "Rectangle 1000",
            width: "2.673",
            height: "6.11",
            transform: "translate(66.829 45.418)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1900",
            "data-name": "Tracé 1900",
            d: "M379.5,275.336v-.955h-1.146v.955H379.5Z",
            transform: "translate(-315.724 -228.963)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1901",
            "data-name": "Tracé 1901",
            d: "M379.4,284.762h-1.05v.955H379.5v-.955Z",
            transform: "translate(-315.724 -237.625)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1001",
            "data-name": "Rectangle 1001",
            width: "1.146",
            height: "0.955",
            transform: "translate(64.538 45.418)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1002",
            "data-name": "Rectangle 1002",
            width: "1.146",
            height: "0.955",
            transform: "translate(64.538 47.137)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1902",
            "data-name": "Tracé 1902",
            d: "M476.967,276.749v-.955h-1.146v.955h1.146Z",
            transform: "translate(-397.058 -230.142)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1903",
            "data-name": "Tracé 1903",
            d: "M476.871,286.175h-1.05v.955h1.146v-.955Z",
            transform: "translate(-397.058 -238.804)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1003",
            "data-name": "Rectangle 1003",
            width: "1.146",
            height: "0.955",
            transform: "translate(80.672 45.652)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1004",
            "data-name": "Rectangle 1004",
            width: "1.146",
            height: "0.955",
            transform: "translate(80.672 47.371)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1904",
            "data-name": "Tracé 1904",
            d:
              "M409.276,214.295l-.151-.251,1.407-2.3h3.617v.352l-1.306,2.5-1.507.3Z",
            transform: "translate(-341.403 -176.695)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1905",
            "data-name": "Tracé 1905",
            d:
              "M431.117,211.745l-1.206,2.3-.2.2-.251,1,.452,1.105h2.311v-2.261Z",
            transform: "translate(-358.371 -176.695)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1005",
            "data-name": "Rectangle 1005",
            width: "3.818",
            height: "2.311",
            transform: "translate(67.723 37.349)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1906",
            "data-name": "Tracé 1906",
            d: "M416.14,229.349v-.456h-.547v.456h.547Z",
            transform: "translate(-346.8 -191.004)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1907",
            "data-name": "Tracé 1907",
            d: "M416.094,233.85h-.5v.456h.547v-.456Z",
            transform: "translate(-346.8 -195.141)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1006",
            "data-name": "Rectangle 1006",
            width: "0.547",
            height: "0.456",
            transform: "translate(69.705 37.889)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1007",
            "data-name": "Rectangle 1007",
            width: "0.547",
            height: "0.456",
            transform: "translate(69.705 38.709)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1908",
            "data-name": "Tracé 1908",
            d:
              "M475.4,215.147l-.573-.955,5.346-8.736h13.747v1.336l-4.964,9.5-5.728,1.146Z",
            transform: "translate(-396.231 -171.448)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1909",
            "data-name": "Tracé 1909",
            d:
              "M560.778,210.8v-7.141h-1.8v.942l-.567-.644-4.582,8.736-.764.764-.955,3.819,1.719,4.2h8.783V212.88Z",
            transform: "translate(-460.72 -169.944)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1008",
            "data-name": "Rectangle 1008",
            width: "14.511",
            height: "8.783",
            transform: "translate(78.598 42.745)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1009",
            "data-name": "Rectangle 1009",
            width: "3.055",
            height: "2.673",
            transform: "translate(88.05 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_1010",
            "data-name": "Rectangle 1010",
            width: "2.673",
            height: "6.11",
            transform: "translate(84.136 45.418)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1910",
            "data-name": "Tracé 1910",
            d: "M484.049,275.335v-.955H482.9v.955h1.146Z",
            transform: "translate(-402.968 -228.962)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1911",
            "data-name": "Tracé 1911",
            d: "M483.953,284.762H482.9v.955h1.146v-.955Z",
            transform: "translate(-402.968 -237.625)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1011",
            "data-name": "Rectangle 1011",
            width: "1.146",
            height: "0.955",
            transform: "translate(81.844 45.418)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1012",
            "data-name": "Rectangle 1012",
            width: "1.146",
            height: "0.955",
            transform: "translate(81.844 47.137)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1912",
            "data-name": "Tracé 1912",
            d: "M581.517,275.912v-.955h-1.146v.955h1.146Z",
            transform: "translate(-484.302 -229.443)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1913",
            "data-name": "Tracé 1913",
            d: "M581.421,285.338h-1.05v.955h1.146v-.955Z",
            transform: "translate(-484.302 -238.106)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1013",
            "data-name": "Rectangle 1013",
            width: "1.146",
            height: "0.955",
            transform: "translate(97.978 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1014",
            "data-name": "Rectangle 1014",
            width: "1.146",
            height: "0.955",
            transform: "translate(97.978 47.232)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1914",
            "data-name": "Tracé 1914",
            d:
              "M576.607,215.147l-.573-.955,5.346-8.736h13.747v1.336l-4.964,9.5-5.728,1.146Z",
            transform: "translate(-480.683 -171.448)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1915",
            "data-name": "Tracé 1915",
            d:
              "M661.983,210.8v-7.141h-1.8v.942l-.567-.644-4.582,8.736-.764.764-.955,3.819,1.719,4.2h8.783V212.88Z",
            transform: "translate(-545.173 -169.944)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1015",
            "data-name": "Rectangle 1015",
            width: "14.511",
            height: "8.783",
            transform: "translate(95.351 42.745)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1016",
            "data-name": "Rectangle 1016",
            width: "3.055",
            height: "2.673",
            transform: "translate(104.802 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_1017",
            "data-name": "Rectangle 1017",
            width: "2.673",
            height: "6.11",
            transform: "translate(100.888 45.418)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1916",
            "data-name": "Tracé 1916",
            d: "M585.254,275.335v-.955h-1.145v.955h1.145Z",
            transform: "translate(-487.421 -228.962)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1917",
            "data-name": "Tracé 1917",
            d: "M585.159,284.762h-1.05v.955h1.145v-.955Z",
            transform: "translate(-487.421 -237.625)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1018",
            "data-name": "Rectangle 1018",
            width: "1.146",
            height: "0.955",
            transform: "translate(98.597 45.418)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1019",
            "data-name": "Rectangle 1019",
            width: "1.146",
            height: "0.955",
            transform: "translate(98.597 47.137)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1918",
            "data-name": "Tracé 1918",
            d: "M682.722,275.912v-.955h-1.146v.955h1.146Z",
            transform: "translate(-568.755 -229.443)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1919",
            "data-name": "Tracé 1919",
            d: "M682.626,285.338h-1.05v.955h1.146v-.955Z",
            transform: "translate(-568.755 -238.106)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1020",
            "data-name": "Rectangle 1020",
            width: "1.146",
            height: "0.955",
            transform: "translate(114.731 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1021",
            "data-name": "Rectangle 1021",
            width: "1.146",
            height: "0.955",
            transform: "translate(114.731 47.232)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1920",
            "data-name": "Tracé 1920",
            d:
              "M511.318,215.131l-.151-.251,1.406-2.3h3.617v.352l-1.306,2.5-1.507.3Z",
            transform: "translate(-426.554 -177.392)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1921",
            "data-name": "Tracé 1921",
            d:
              "M533.159,212.581l-1.206,2.3-.2.2-.251,1,.452,1.105h2.311V214.93Z",
            transform: "translate(-443.522 -177.392)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1022",
            "data-name": "Rectangle 1022",
            width: "3.818",
            height: "2.311",
            transform: "translate(84.613 37.487)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1922",
            "data-name": "Tracé 1922",
            d: "M518.181,230.185v-.456h-.547v.456h.547Z",
            transform: "translate(-431.95 -191.702)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1923",
            "data-name": "Tracé 1923",
            d: "M518.135,234.686h-.5v.456h.547v-.456Z",
            transform: "translate(-431.95 -195.838)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1023",
            "data-name": "Rectangle 1023",
            width: "0.547",
            height: "0.456",
            transform: "translate(86.596 38.027)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1024",
            "data-name": "Rectangle 1024",
            width: "0.547",
            height: "0.456",
            transform: "translate(86.596 38.848)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1924",
            "data-name": "Tracé 1924",
            d:
              "M613.359,214.295l-.151-.251,1.407-2.3h3.617v.352l-1.306,2.5-1.507.3Z",
            transform: "translate(-511.704 -176.695)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1925",
            "data-name": "Tracé 1925",
            d: "M635.2,211.745l-1.206,2.3-.2.2-.251,1,.452,1.105h2.311v-2.261Z",
            transform: "translate(-528.672 -176.695)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1025",
            "data-name": "Rectangle 1025",
            width: "3.818",
            height: "2.311",
            transform: "translate(101.504 37.349)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1926",
            "data-name": "Tracé 1926",
            d: "M620.223,229.349v-.456h-.547v.456h.547Z",
            transform: "translate(-517.101 -191.004)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1927",
            "data-name": "Tracé 1927",
            d: "M620.177,233.85h-.5v.456h.547v-.456Z",
            transform: "translate(-517.101 -195.141)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1026",
            "data-name": "Rectangle 1026",
            width: "0.547",
            height: "0.456",
            transform: "translate(103.487 37.889)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1027",
            "data-name": "Rectangle 1027",
            width: "0.547",
            height: "0.456",
            transform: "translate(103.487 38.709)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1928",
            "data-name": "Tracé 1928",
            d:
              "M681.158,215.147l-.573-.955,5.346-8.736h13.747v1.336l-4.964,9.5-5.728,1.146Z",
            transform: "translate(-567.928 -171.448)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1929",
            "data-name": "Tracé 1929",
            d:
              "M766.534,205.179v-1.524h-1.8v.942l-.566-.644-4.583,8.736-.764.764-.955,3.819,1.718,4.2h8.783V212.88Z",
            transform: "translate(-632.417 -169.944)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1028",
            "data-name": "Rectangle 1028",
            width: "14.511",
            height: "8.783",
            transform: "translate(112.657 42.745)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1029",
            "data-name": "Rectangle 1029",
            width: "3.055",
            height: "2.673",
            transform: "translate(122.108 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-3",
          attrs: {
            id: "Rectangle_1030",
            "data-name": "Rectangle 1030",
            width: "2.673",
            height: "6.11",
            transform: "translate(118.194 45.418)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1930",
            "data-name": "Tracé 1930",
            d: "M689.8,275.335v-.955h-1.146v.955H689.8Z",
            transform: "translate(-574.665 -228.962)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1931",
            "data-name": "Tracé 1931",
            d: "M689.709,284.762h-1.05v.955H689.8v-.955Z",
            transform: "translate(-574.665 -237.625)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1031",
            "data-name": "Rectangle 1031",
            width: "1.146",
            height: "0.955",
            transform: "translate(115.903 45.418)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1032",
            "data-name": "Rectangle 1032",
            width: "1.146",
            height: "0.955",
            transform: "translate(115.903 47.137)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1932",
            "data-name": "Tracé 1932",
            d: "M787.273,275.912v-.955h-1.146v.955h1.146Z",
            transform: "translate(-655.999 -229.443)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1933",
            "data-name": "Tracé 1933",
            d: "M787.177,285.338h-1.05v.955h1.146v-.955Z",
            transform: "translate(-655.999 -238.106)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1033",
            "data-name": "Rectangle 1033",
            width: "1.146",
            height: "0.955",
            transform: "translate(132.037 45.514)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1034",
            "data-name": "Rectangle 1034",
            width: "1.146",
            height: "0.955",
            transform: "translate(132.037 47.232)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1934",
            "data-name": "Tracé 1934",
            d:
              "M715.4,215.131l-.151-.251,1.406-2.3h3.617v.352l-1.306,2.5-1.507.3Z",
            transform: "translate(-596.855 -177.392)"
          }
        }),
        _c("path", {
          staticClass: "cls-3",
          attrs: {
            id: "Tracé_1935",
            "data-name": "Tracé 1935",
            d:
              "M737.241,212.581l-1.206,2.3-.2.2-.251,1,.452,1.105h2.311V214.93Z",
            transform: "translate(-613.822 -177.392)"
          }
        }),
        _c("rect", {
          staticClass: "cls-1",
          attrs: {
            id: "Rectangle_1035",
            "data-name": "Rectangle 1035",
            width: "3.818",
            height: "2.311",
            transform: "translate(118.395 37.487)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1936",
            "data-name": "Tracé 1936",
            d: "M722.264,230.185v-.456h-.547v.456h.547Z",
            transform: "translate(-602.251 -191.702)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1937",
            "data-name": "Tracé 1937",
            d: "M722.218,234.686h-.5v.456h.547v-.456Z",
            transform: "translate(-602.251 -195.838)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1036",
            "data-name": "Rectangle 1036",
            width: "0.547",
            height: "0.456",
            transform: "translate(120.377 38.027)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1037",
            "data-name": "Rectangle 1037",
            width: "0.547",
            height: "0.456",
            transform: "translate(120.377 38.848)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1038",
            "data-name": "Rectangle 1038",
            width: "152.218",
            height: "0.277",
            transform: "translate(0.138 51.433)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1039",
            "data-name": "Rectangle 1039",
            width: "152.218",
            height: "0.277",
            transform: "translate(0 77.323)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1040",
            "data-name": "Rectangle 1040",
            width: "0.462",
            height: "18.256",
            transform: "translate(141.938 33.178)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1938",
            "data-name": "Tracé 1938",
            d:
              "M981.784,328.909q0,.3-.005.6c-.166,7.186-8.781,7.226-9.013.042q-.01-.3-.011-.6a28.423,28.423,0,0,1,4.448-14.566A28.423,28.423,0,0,1,981.784,328.909Z",
            transform: "translate(-834.874 -290.418)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1041",
            "data-name": "Rectangle 1041",
            width: "4.871",
            height: "0.773",
            transform: "translate(46.027 69.311)"
          }
        }),
        _c("path", {
          staticClass: "cls-5",
          attrs: {
            id: "Tracé_1939",
            "data-name": "Tracé 1939",
            d:
              "M457.292,493.973l-1.005.233-38.2.02-.343-.8a8.078,8.078,0,0,1-.594-4.368,3.4,3.4,0,0,1,1.513-2.68l1.7-11.29,21.132-.038,8.176,6.594s3.857-.069,5.952,2.539a6.123,6.123,0,0,1,1.281,3.372l.083,1.357Z",
            transform: "translate(-371.175 -424.489)"
          }
        }),
        _c("circle", {
          staticClass: "cls-2",
          attrs: {
            id: "Ellipse_78",
            "data-name": "Ellipse 78",
            cx: "4.717",
            cy: "4.717",
            r: "4.717",
            transform: "translate(73.012 64.579)"
          }
        }),
        _c("circle", {
          staticClass: "cls-3",
          attrs: {
            id: "Ellipse_79",
            "data-name": "Ellipse 79",
            cx: "2.482",
            cy: "2.482",
            r: "2.482",
            transform: "translate(75.246 66.813)"
          }
        }),
        _c("circle", {
          staticClass: "cls-2",
          attrs: {
            id: "Ellipse_80",
            "data-name": "Ellipse 80",
            cx: "4.717",
            cy: "4.717",
            r: "4.717",
            transform: "translate(49.738 64.591)"
          }
        }),
        _c("circle", {
          staticClass: "cls-3",
          attrs: {
            id: "Ellipse_81",
            "data-name": "Ellipse 81",
            cx: "2.482",
            cy: "2.482",
            r: "2.482",
            transform: "translate(51.972 66.826)"
          }
        }),
        _c("path", {
          staticClass: "cls-7",
          attrs: {
            id: "Tracé_1940",
            "data-name": "Tracé 1940",
            d:
              "M378.169,317.648l-11.41,0h-1.393l-2.352,0v-.171l0-2.068,0-2.322h1.564l1.393,0,2.467,0h1.393l2.848,0Z",
            transform: "translate(-302.923 -261.263)"
          }
        }),
        _c("path", {
          staticClass: "cls-7",
          attrs: {
            id: "Tracé_1941",
            "data-name": "Tracé 1941",
            d:
              "M321.4,317.689l-6.03,0,0-1.183v-2.068l0-1.31h.883l1.393,0,3.756,0Z",
            transform: "translate(-263.166 -261.296)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1042",
            "data-name": "Rectangle 1042",
            width: "0.773",
            height: "1.469",
            transform: "translate(60.095 59.794)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1043",
            "data-name": "Rectangle 1043",
            width: "0.773",
            height: "1.469",
            transform: "matrix(0.001, 1, -1, 0.001, 73.624, 57.699)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1942",
            "data-name": "Tracé 1942",
            d:
              "M439.169,331.106h.085a1.616,1.616,0,0,1,1.616,1.616h0a1.616,1.616,0,0,1-1.616,1.616h-.085Z",
            transform: "translate(-366.473 -276.298)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1943",
            "data-name": "Tracé 1943",
            d:
              "M644.234,534.957a2.5,2.5,0,0,1-1.363-4.729,6.123,6.123,0,0,1,1.281,3.372Z",
            transform: "translate(-558.424 -470.533)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1944",
            "data-name": "Tracé 1944",
            d: "M365.978,313.108l-2.954,4.391,0-2.068,1.563-2.322Z",
            transform: "translate(-302.931 -261.279)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1945",
            "data-name": "Tracé 1945",
            d: "M317.653,313.138l-2.274,3.38V314.45l.881-1.31Z",
            transform: "translate(-263.174 -261.304)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1946",
            "data-name": "Tracé 1946",
            d: "M381.7,313.1l-3.069,4.563h-1.393l3.069-4.563Z",
            transform: "translate(-314.795 -261.271)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1044",
            "data-name": "Rectangle 1044",
            width: "2.959",
            height: "0.47",
            transform: "matrix(-1, -0.001, 0.001, -1, 115.823, 59.596)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1947",
            "data-name": "Tracé 1947",
            d:
              "M692.383,469.473l.61.141,23.2.012.209-.486a4.907,4.907,0,0,0,.361-2.653,2.065,2.065,0,0,0-.919-1.628L714.817,458l-12.837-.023-4.966,4.006a4.972,4.972,0,0,0-3.615,1.542,3.719,3.719,0,0,0-.778,2.048l-.05.824Z",
            transform: "translate(-600.913 -410.243)"
          }
        }),
        _c("circle", {
          staticClass: "cls-2",
          attrs: {
            id: "Ellipse_82",
            "data-name": "Ellipse 82",
            cx: "2.865",
            cy: "2.865",
            r: "2.865",
            transform: "translate(93.702 56.25)"
          }
        }),
        _c("circle", {
          staticClass: "cls-3",
          attrs: {
            id: "Ellipse_83",
            "data-name": "Ellipse 83",
            cx: "1.508",
            cy: "1.508",
            r: "1.508",
            transform: "translate(95.059 57.608)"
          }
        }),
        _c("circle", {
          staticClass: "cls-2",
          attrs: {
            id: "Ellipse_84",
            "data-name": "Ellipse 84",
            cx: "2.865",
            cy: "2.865",
            r: "2.865",
            transform: "translate(107.839 56.258)"
          }
        }),
        _c("circle", {
          staticClass: "cls-3",
          attrs: {
            id: "Ellipse_85",
            "data-name": "Ellipse 85",
            cx: "1.508",
            cy: "1.508",
            r: "1.508",
            transform: "translate(109.196 57.615)"
          }
        }),
        _c("path", {
          staticClass: "cls-7",
          attrs: {
            id: "Tracé_1948",
            "data-name": "Tracé 1948",
            d: "M592.486,295.789l6.931,0h2.275v-2.771h-5.871Z",
            transform: "translate(-494.412 -244.516)"
          }
        }),
        _c("path", {
          staticClass: "cls-7",
          attrs: {
            id: "Tracé_1949",
            "data-name": "Tracé 1949",
            d: "M654.908,295.815l3.663,0v-2.771h-3.664Z",
            transform: "translate(-546.501 -244.536)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1045",
            "data-name": "Rectangle 1045",
            width: "0.47",
            height: "0.892",
            transform: "matrix(-1, -0.001, 0.001, -1, 107.278, 54.237)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1046",
            "data-name": "Rectangle 1046",
            width: "0.47",
            height: "0.892",
            transform: "matrix(-0.001, 1, -1, -0.001, 99.953, 52.073)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1950",
            "data-name": "Tracé 1950",
            d: "M0,0H.052a.982.982,0,0,1,.982.982h0a.982.982,0,0,1-.982.982H0Z",
            transform: "matrix(-1, -0.001, 0.001, -1, 99.623, 52.279)"
          }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            id: "Tracé_1951",
            "data-name": "Tracé 1951",
            d:
              "M693.511,494.369a1.517,1.517,0,0,0,.828-2.873,3.718,3.718,0,0,0-.778,2.048Z",
            transform: "translate(-601.854 -438.212)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1952",
            "data-name": "Tracé 1952",
            d: "M637.256,293.031l1.794,2.668v-1.256l-.949-1.411Z",
            transform: "translate(-531.771 -244.526)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1953",
            "data-name": "Tracé 1953",
            d: "M668.7,293.05l1.381,2.053v-1.256l-.535-.8Z",
            transform: "translate(-558.008 -244.541)"
          }
        }),
        _c("path", {
          staticClass: "cls-1",
          attrs: {
            id: "Tracé_1954",
            "data-name": "Tracé 1954",
            d: "M623.091,293.025l1.865,2.772h.846l-1.864-2.772Z",
            transform: "translate(-519.951 -244.521)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1955",
            "data-name": "Tracé 1955",
            d:
              "M295.159,612.733s0,5.25,1.2,6.628c0,0,.258.689.344.947a3.615,3.615,0,0,0,.172.43v.344s2.324-.258,2.582-.861l-.43-.516s-.086-.086,0-.344a.177.177,0,0,0-.172-.258l-.6-1.377.086-5.681-.43-7.832-3.7-.172Z",
            transform: "translate(-268.651 -532.126)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1956",
            "data-name": "Tracé 1956",
            d:
              "M310.021,702.139s-.6,1.807,0,1.894,5.853.172,5.767-.516-1.807-.6-1.807-.6l-1.807-1.119Z",
            transform: "translate(-281.62 -613.7)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1957",
            "data-name": "Tracé 1957",
            d:
              "M252.231,605.08a3.905,3.905,0,0,0,.516,2.324c.689,1.205.258,5.25.258,5.25l-6.111,5.336s1.2,2.066,1.635,1.98,7.23-5.595,7.23-5.595l2.668-8.951Z",
            transform: "translate(-229.166 -532.994)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1958",
            "data-name": "Tracé 1958",
            d:
              "M239.222,681.7s-1.849.459-1.6,1.014,2.988,5.036,3.523,4.594-.459-1.849-.459-1.849l-.023-2.126Z",
            transform: "translate(-221.409 -596.93)"
          }
        }),
        _c("circle", {
          staticClass: "cls-9",
          attrs: {
            id: "Ellipse_86",
            "data-name": "Ellipse 86",
            cx: "1.98",
            cy: "1.98",
            r: "1.98",
            transform: "translate(24.442 54.096)"
          }
        }),
        _c("path", {
          staticClass: "cls-9",
          attrs: {
            id: "Tracé_1959",
            "data-name": "Tracé 1959",
            d:
              "M290,512.584l.088,2.547,1.666.658a12.419,12.419,0,0,1,.394-2.753C292.38,512.892,290,512.584,290,512.584Z",
            transform: "translate(-265.138 -455.81)"
          }
        }),
        _c("path", {
          staticClass: "cls-10",
          attrs: {
            id: "Tracé_1960",
            "data-name": "Tracé 1960",
            d:
              "M277.788,522.55s-2.324-2.324-2.926-2.238-.6.344-.6.516.172.258,0,.344-.344,0-.344.258-1.549,2.84-1.033,5.681,1.119,4.218.861,5.767-.086,1.894-.086,1.894,6.455.516,6.541-.086-.258-.258.172-.689.258-.43.258-.689S278.735,522.55,277.788,522.55Z",
            transform: "translate(-250.763 -462.256)"
          }
        }),
        _c("path", {
          staticClass: "cls-9",
          attrs: {
            id: "Tracé_1961",
            "data-name": "Tracé 1961",
            d: "M295.772,600.654s1.033,2.5,1.807,1.893-.344-2.668-.344-2.668Z",
            transform: "translate(-269.952 -528.655)"
          }
        }),
        _c("path", {
          staticClass: "cls-10",
          attrs: {
            id: "Tracé_1962",
            "data-name": "Tracé 1962",
            d:
              "M273.447,534.8s-2.022.232-1.269,3.946,3.679,8.018,3.679,8.018l1.98-.947-2.582-5.078.6-3.185S276.2,534.546,273.447,534.8Z",
            transform: "translate(-250.124 -474.338)"
          }
        }),
        _c("path", {
          staticClass: "cls-11",
          attrs: {
            id: "Tracé_1963",
            "data-name": "Tracé 1963",
            d: "M151.561,401.423l2.84,4.734-.258.258Z",
            transform: "translate(-126.473 -334.975)"
          }
        }),
        _c("path", {
          staticClass: "cls-11",
          attrs: {
            id: "Tracé_1964",
            "data-name": "Tracé 1964",
            d: "M142.479,414.75l2.278,3.733h-.775Z",
            transform: "translate(-118.894 -346.096)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1965",
            "data-name": "Tracé 1965",
            d:
              "M284.136,494.928a2.849,2.849,0,0,1,.52-.155,1.36,1.36,0,0,0,.9-1.525.779.779,0,0,1-.033-.251.48.48,0,0,1,.4-.352,3.945,3.945,0,0,0,.557-.106.844.844,0,0,0,.492-.677,1.4,1.4,0,0,0-.187-.842l-.067.192a.711.711,0,0,1-.218-.326.449.449,0,0,0-.342.275c-.107.063-.122-.2-.241-.234a.213.213,0,0,0-.147.036,1.189,1.189,0,0,1-.77-.045,2.9,2.9,0,0,0-1.346,0,1.611,1.611,0,0,0-.8.409,1.908,1.908,0,0,0-.372.639,3.683,3.683,0,0,0-.3,1.789,2.532,2.532,0,0,0,.6,1.341c.17.2.726.881,1.031.764C284.2,495.711,283.685,495.166,284.136,494.928Z",
            transform: "translate(-258.61 -437.662)"
          }
        }),
        _c("rect", {
          staticClass: "cls-11",
          attrs: {
            id: "Rectangle_1047",
            "data-name": "Rectangle 1047",
            width: "6.356",
            height: "0.122",
            transform: "translate(26.466 81.376) rotate(-69.123)"
          }
        }),
        _c("circle", {
          staticClass: "cls-12",
          attrs: {
            id: "Ellipse_87",
            "data-name": "Ellipse 87",
            cx: "0.122",
            cy: "0.122",
            r: "0.122",
            transform: "translate(26.89 60.521)"
          }
        }),
        _c("line", {
          staticClass: "cls-12",
          attrs: {
            id: "Ligne_7",
            "data-name": "Ligne 7",
            x2: "1.041",
            y2: "3.367",
            transform: "translate(27.012 60.644)"
          }
        }),
        _c("path", {
          staticClass: "cls-12",
          attrs: {
            id: "Tracé_1966",
            "data-name": "Tracé 1966",
            d:
              "M303.71,539.078a.061.061,0,0,1-.058-.043l-1.041-3.367a.061.061,0,0,1,.117-.036L303.768,539a.061.061,0,0,1-.058.079Z",
            transform: "translate(-275.657 -475.006)"
          }
        }),
        _c("circle", {
          staticClass: "cls-8",
          attrs: {
            id: "Ellipse_88",
            "data-name": "Ellipse 88",
            cx: "2.067",
            cy: "2.067",
            r: "2.067",
            transform: "translate(135.957 55.155)"
          }
        }),
        _c("path", {
          staticClass: "cls-13",
          attrs: {
            id: "Tracé_1967",
            "data-name": "Tracé 1967",
            d:
              "M951.493,590.944l-.313.627s-1,1.63-.313,1.818.909-1.222.909-1.222l.125-.752Z",
            transform: "translate(-816.407 -521.199)"
          }
        }),
        _c("path", {
          staticClass: "cls-12",
          attrs: {
            id: "Tracé_1968",
            "data-name": "Tracé 1968",
            d:
              "M815.756,402.033l-.784,2.068-.783,1.442.972.627.783-1.379.94-2.163Z",
            transform: "translate(-679.416 -335.484)"
          }
        }),
        _c("path", {
          staticClass: "cls-13",
          attrs: {
            id: "Tracé_1969",
            "data-name": "Tracé 1969",
            d:
              "M976.891,620.185l1.567,1.661,2.758,4.67,1.191-.376L981,623.35a10.233,10.233,0,0,0-1.222-2.413,8.105,8.105,0,0,1-1.1-2.131l-.752-.6Z",
            transform: "translate(-838.326 -543.951)"
          }
        }),
        _c("path", {
          staticClass: "cls-13",
          attrs: {
            id: "Tracé_1970",
            "data-name": "Tracé 1970",
            d:
              "M938.27,635.156l-.125.533a7.68,7.68,0,0,1-1.034,2.413l-1.5,2.6-1.128-.376,2.225-5.266Z",
            transform: "translate(-802.933 -558.014)"
          }
        }),
        _c("circle", {
          staticClass: "cls-13",
          attrs: {
            id: "Ellipse_89",
            "data-name": "Ellipse 89",
            cx: "1.692",
            cy: "1.692",
            r: "1.692",
            transform: "translate(135.979 56.252)"
          }
        }),
        _c("path", {
          staticClass: "cls-13",
          attrs: {
            id: "Tracé_1971",
            "data-name": "Tracé 1971",
            d: "M971.641,524.673l.219,1.818-1.41.69s0-1.567-.376-1.975Z",
            transform: "translate(-832.637 -465.897)"
          }
        }),
        _c("path", {
          staticClass: "cls-13",
          attrs: {
            id: "Tracé_1972",
            "data-name": "Tracé 1972",
            d:
              "M1002.131,595.489l.157.533s1.034,2.037.157,2.037-1.034-1.881-1.034-1.881l-.094-.47Z",
            transform: "translate(-858.708 -524.991)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1973",
            "data-name": "Tracé 1973",
            d:
              "M949.575,582.613l.219,1.536-2.664,5.61-.157,1.63,4.983.627,1.1-3.04,1.755,2.257-1-2.319a9.318,9.318,0,0,1-.219-1.975,8.146,8.146,0,0,0-.345-2.946C952.96,583.491,949.575,582.613,949.575,582.613Z",
            transform: "translate(-813.361 -514.247)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1974",
            "data-name": "Tracé 1974",
            d:
              "M993.966,665.859l1.5-.5.47.752s.909.251.878.909a11.192,11.192,0,0,0,.157,1.787l-.658.063-.251-1.285-1.285,1.536s-2.288.125-2.382-.251,1.63-.784,1.63-.784l.282-1.348Z",
            transform: "translate(-851.263 -583.294)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1975",
            "data-name": "Tracé 1975",
            d:
              "M917.16,665.736l1.379.439v.313a2.135,2.135,0,0,1,.345,1.16c-.063.533-.345,1.818-.345,1.818h-.721l.031-1.191-1.442,1.191s-2.57-.188-2.57-.6,1.975-.69,1.975-.69Z",
            transform: "translate(-785.71 -583.61)"
          }
        }),
        _c("path", {
          staticClass: "cls-12",
          attrs: {
            id: "Tracé_1976",
            "data-name": "Tracé 1976",
            d:
              "M962.93,532.4s1.285-1.285,1.881-.972v.564s.878.407.909,1.034.94,6.331.94,6.331l2.131,7.428s-.658,1.034-3.072-.125l-1.5-5.109s1.6,5.767.815,5.673-3.855-7.24-3.855-7.24l.219-3.385a2.565,2.565,0,0,1,.5-2.476Z",
            transform: "translate(-825.211 -471.495)"
          }
        }),
        _c("path", {
          staticClass: "cls-12",
          attrs: {
            id: "Tracé_1977",
            "data-name": "Tracé 1977",
            d:
              "M979.7,538.685a1.841,1.841,0,0,1,.909,1c.376.815,2.257,6.551,2.257,6.551l.5,2.037-1.41.345-.69-2.1-2.57-4.764S978.29,539.249,979.7,538.685Z",
            transform: "translate(-839.788 -477.59)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1978",
            "data-name": "Tracé 1978",
            d:
              "M963.01,498.085a1.188,1.188,0,0,0-.559-.966,1.545,1.545,0,0,0,.212-1.109.846.846,0,0,0-.637-.57,1.663,1.663,0,0,0-.5.006l-.772.088a1.686,1.686,0,0,0-.724.195.839.839,0,0,0-.1,1.346,3.089,3.089,0,0,0-1.147,1.407,2.324,2.324,0,0,1,1.459-.522,1.391,1.391,0,0,1,1.461,1.274,2.574,2.574,0,0,0,.047.54.4.4,0,0,0,.39.31.958.958,0,0,1-.07-.668l0,0a.2.2,0,0,1,.11-.094.316.316,0,0,1,.42.22c.06.23.156.524.24.446.11-.1.064-1,.093-1.142A2.623,2.623,0,0,0,963.01,498.085Z",
            transform: "translate(-823.221 -441.491)"
          }
        }),
        _c("path", {
          staticClass: "cls-12",
          attrs: {
            id: "Tracé_1979",
            "data-name": "Tracé 1979",
            d: "M968.194,505.439a14.6,14.6,0,0,1-2.511-.037",
            transform: "translate(-828.973 -449.816)"
          }
        }),
        _c("path", {
          staticClass: "cls-8",
          attrs: {
            id: "Tracé_1980",
            "data-name": "Tracé 1980",
            d:
              "M979.848,496.563a1.545,1.545,0,0,0,.212-1.109.838.838,0,0,0-.3-.417.848.848,0,0,1,.453.511,1.545,1.545,0,0,1-.212,1.109C979.868,496.636,980.19,496.745,979.848,496.563Z",
            transform: "translate(-840.723 -441.168)"
          }
        }),
        _c("path", {
          staticClass: "cls-11",
          attrs: {
            id: "Tracé_1981",
            "data-name": "Tracé 1981",
            d: "M837.194,387.737l2.445,4.325.94,2.351-1.317-2.257Z",
            transform: "translate(-698.613 -323.555)"
          }
        }),
        _c("path", {
          staticClass: "cls-5",
          attrs: {
            id: "Tracé_1982",
            "data-name": "Tracé 1982",
            d:
              "M239.646,705.849a7.789,7.789,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136,7.789,7.789,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136s-2.177,2.225-2.171,3.279a.774.774,0,0,0,0,.079c.074.69,2.325.866,3.561.541a6.293,6.293,0,0,0,2.8,0c1.231.33,3.527.141,3.594-.557a.77.77,0,0,0,0-.079C241.832,708.058,239.646,705.849,239.646,705.849Z",
            transform: "translate(-216.633 -617.084)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1983",
            "data-name": "Tracé 1983",
            d:
              "M515.886,705.849a7.789,7.789,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136,7.789,7.789,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136s-2.177,2.225-2.171,3.279a.768.768,0,0,0,0,.079c.074.69,2.325.866,3.561.541a6.3,6.3,0,0,0,2.8,0c1.231.33,3.527.141,3.594-.557a.787.787,0,0,0,0-.079C518.072,708.058,515.886,705.849,515.886,705.849Z",
            transform: "translate(-447.146 -617.084)"
          }
        }),
        _c("path", {
          staticClass: "cls-5",
          attrs: {
            id: "Tracé_1984",
            "data-name": "Tracé 1984",
            d:
              "M790.337,705.849a7.79,7.79,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136,7.788,7.788,0,0,1-1.389,2.136,7.852,7.852,0,0,1-1.411-2.136s-2.177,2.225-2.171,3.279a.752.752,0,0,0,0,.079c.074.69,2.325.866,3.561.541a6.3,6.3,0,0,0,2.8,0c1.231.33,3.527.141,3.594-.557a.753.753,0,0,0,0-.079C792.524,708.058,790.337,705.849,790.337,705.849Z",
            transform: "translate(-676.168 -617.084)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1985",
            "data-name": "Tracé 1985",
            d:
              "M258.615,215.973l-.02.011a1.483,1.483,0,0,1-1.414,0l-.037-.02a1.49,1.49,0,0,0-1.413,0l-.019.01a1.483,1.483,0,0,1-1.414,0l-.039-.021a1.482,1.482,0,0,0-1.4,0c-.658.354-1.547.96-1.543,1.644a.8.8,0,0,0,0,.081c.076.71,2.393.891,3.666.557a6.48,6.48,0,0,0,2.883,0c1.267.34,3.631.145,3.7-.573a.807.807,0,0,0,0-.081c0-.684-.9-1.284-1.563-1.633A1.48,1.48,0,0,0,258.615,215.973Z",
            transform: "translate(-232.856 -208.145)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1986",
            "data-name": "Tracé 1986",
            d:
              "M518.738,261.328l-.02.011a1.483,1.483,0,0,1-1.414,0l-.037-.02a1.49,1.49,0,0,0-1.413,0l-.019.01a1.483,1.483,0,0,1-1.414,0l-.039-.021a1.482,1.482,0,0,0-1.4,0c-.658.354-1.547.96-1.543,1.644a.8.8,0,0,0,0,.081c.077.71,2.393.891,3.666.557a6.479,6.479,0,0,0,2.883,0c1.267.34,3.631.145,3.7-.573q0-.04,0-.081c0-.684-.9-1.284-1.563-1.633a1.48,1.48,0,0,0-1.4.013Z",
            transform: "translate(-449.921 -245.992)"
          }
        }),
        _c("path", {
          staticClass: "cls-6",
          attrs: {
            id: "Tracé_1987",
            "data-name": "Tracé 1987",
            d:
              "M698.522,217.94l-.02.011a1.483,1.483,0,0,1-1.414,0l-.037-.02a1.49,1.49,0,0,0-1.413,0l-.019.01a1.483,1.483,0,0,1-1.414,0l-.039-.021a1.482,1.482,0,0,0-1.4,0c-.658.354-1.547.96-1.543,1.644a.78.78,0,0,0,0,.081c.076.71,2.393.891,3.666.557a6.48,6.48,0,0,0,2.883,0c1.267.34,3.631.145,3.7-.573a.8.8,0,0,0,0-.081c0-.684-.9-1.284-1.563-1.633A1.48,1.48,0,0,0,698.522,217.94Z",
            transform: "translate(-599.945 -209.787)"
          }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: {
            id: "Rectangle_1048",
            "data-name": "Rectangle 1048",
            width: "152.218",
            height: "0.277",
            transform: "translate(0 92.553)"
          }
        })
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "undraw_biking_kc4f",
            transform: "translate(92.349 52.298)"
          }
        },
        [
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1805",
              "data-name": "Tracé 1805",
              d:
                "M577,498.7l-.3,6.7s.778,6.344.6,6.524-.658.539-.3.958,1.676.718,1.736.479.18-7.062.18-7.062l.419-8.08Z",
              transform: "translate(-564.672 -490.159)"
            }
          }),
          _c("path", {
            staticClass: "cls-11",
            attrs: {
              id: "Tracé_1806",
              "data-name": "Tracé 1806",
              d:
                "M577,498.7l-.3,6.7s.778,6.344.6,6.524-.658.539-.3.958,1.676.718,1.736.479.18-7.062.18-7.062l.419-8.08Z",
              transform: "translate(-564.672 -490.159)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1807",
              "data-name": "Tracé 1807",
              d:
                "M543.716,741.222a13.536,13.536,0,0,1-1.676,1.137c-.18,0-1.8.539-.658.778s3.89.12,3.95-.239a6.664,6.664,0,0,0-.18-1.449Z",
              transform: "translate(-531.087 -718.615)"
            }
          }),
          _c("path", {
            staticClass: "cls-11",
            attrs: {
              id: "Tracé_1808",
              "data-name": "Tracé 1808",
              d:
                "M543.716,741.222a13.536,13.536,0,0,1-1.676,1.137c-.18,0-1.8.539-.658.778s3.89.12,3.95-.239a6.664,6.664,0,0,0-.18-1.449Z",
              transform: "translate(-531.087 -718.615)"
            }
          }),
          _c("path", {
            staticClass: "cls-5",
            attrs: {
              id: "Tracé_1809",
              "data-name": "Tracé 1809",
              d:
                "M492.488,422.222s-.658.239-.658.419a18.134,18.134,0,0,1-.718,2.574,1.817,1.817,0,0,0-.18.658s-.6,1.377-.479,1.377,1.137-.239,1.2,0,.539-1.5.539-1.5l1.018-2.035Z",
              transform: "translate(-483.57 -418.707)"
            }
          }),
          _c("path", {
            staticClass: "cls-9",
            attrs: {
              id: "Tracé_1810",
              "data-name": "Tracé 1810",
              d: "M490.7,506.462s.18,1.317.658,1.436,0-1.676,0-1.676Z",
              transform: "translate(-483.819 -497.68)"
            }
          }),
          _c("path", {
            staticClass: "cls-9",
            attrs: {
              id: "Tracé_1811",
              "data-name": "Tracé 1811",
              d:
                "M516.7,396.272s.359.778.539,1.2,1.855-1.077,1.855-1.077-.9-.658-.9-.958S516.7,396.272,516.7,396.272Z",
              transform: "translate(-508.263 -393.475)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1814",
              "data-name": "Tracé 1814",
              d:
                "M381.148,605.115a5.446,5.446,0,1,1,5.446-5.446A5.446,5.446,0,0,1,381.148,605.115Zm0-10.773a5.327,5.327,0,1,0,5.327,5.327A5.327,5.327,0,0,0,381.148,594.342Z",
              transform: "translate(-375.702 -580.413)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1815",
              "data-name": "Tracé 1815",
              d:
                "M661.148,605.115a5.446,5.446,0,1,1,5.446-5.446A5.446,5.446,0,0,1,661.148,605.115Zm0-10.773a5.327,5.327,0,1,0,5.327,5.327A5.327,5.327,0,0,0,661.148,594.342Z",
              transform: "translate(-638.944 -580.413)"
            }
          }),
          _c("ellipse", {
            staticClass: "cls-8",
            attrs: {
              id: "Ellipse_46",
              "data-name": "Ellipse 46",
              cx: "0.359",
              cy: "0.359",
              rx: "0.359",
              ry: "0.359",
              transform: "translate(5.087 18.897)"
            }
          }),
          _c("ellipse", {
            staticClass: "cls-8",
            attrs: {
              id: "Ellipse_47",
              "data-name": "Ellipse 47",
              cx: "0.359",
              cy: "0.359",
              rx: "0.359",
              ry: "0.359",
              transform: "translate(21.965 18.897)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1816",
              "data-name": "Tracé 1816",
              d:
                "M342.69,437.317l-.11-.047,3.128-7.339,8.87.181.017.027,4.369,6.823-.1.065-4.352-6.8-8.726-.178Z",
              transform: "translate(-336.71 -418.576)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1817",
              "data-name": "Tracé 1817",
              d:
                "M500.125,448.779l2.3-8.107.115.033-2.25,7.933,6.015-.767.015.119Z",
              transform: "translate(-484.826 -428.675)"
            }
          }),
          _c("rect", {
            staticClass: "cls-8",
            attrs: {
              id: "Rectangle_891",
              "data-name": "Rectangle 891",
              width: "0.12",
              height: "8.803",
              transform: "matrix(0.714, -0.7, 0.7, 0.714, 8.695, 13.252)"
            }
          }),
          _c("rect", {
            staticClass: "cls-8",
            attrs: {
              id: "Rectangle_892",
              "data-name": "Rectangle 892",
              width: "1.365",
              height: "0.12",
              transform: "matrix(0.263, -0.965, 0.965, 0.263, 17.838, 11.399)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1818",
              "data-name": "Tracé 1818",
              d:
                "M385.336,391.993l-.117-.027.283-1.244h-1v-.778h.12v.658h1.033Z",
              transform: "translate(-376.121 -380.984)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1819",
              "data-name": "Tracé 1819",
              d:
                "M643.7,508.612s0-.359.778-.18a29.068,29.068,0,0,0,3.352.6c.18-.06.12.359-.658.539a2.875,2.875,0,0,1-1.855-.359C645.138,509.091,643.7,508.612,643.7,508.612Z",
              transform: "translate(-627.662 -499.711)"
            }
          }),
          _c("rect", {
            staticClass: "cls-5",
            attrs: {
              id: "Rectangle_893",
              "data-name": "Rectangle 893",
              width: "1.556",
              height: "0.599",
              transform: "translate(12.329 19.615)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1820",
              "data-name": "Tracé 1820",
              d:
                "M613.9,680.616a1.2,1.2,0,1,1,1.2-1.2A1.2,1.2,0,0,1,613.9,680.616Zm0-2.274a1.077,1.077,0,1,0,1.077,1.077A1.077,1.077,0,0,0,613.9,678.342Z",
              transform: "translate(-598.517 -659.386)"
            }
          }),
          _c("ellipse", {
            staticClass: "cls-9",
            attrs: {
              id: "Ellipse_48",
              "data-name": "Ellipse 48",
              cx: "1.436",
              cy: "1.436",
              rx: "1.436",
              ry: "1.436",
              transform: "translate(7.541 0.522)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1821",
              "data-name": "Tracé 1821",
              d:
                "M537.761,495.269s-4.13,2.394-4.07,3.172,3.172,5.027,3.172,5.027.24,1.257.479,1.257,1.736.539,1.8.179-.479-.778-.419-1.017-2.633-4.609-2.633-4.609,5.764-2.155,4.558-4.01S537.761,495.269,537.761,495.269Z",
              transform: "translate(-524.235 -486.607)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1822",
              "data-name": "Tracé 1822",
              d:
                "M563.47,662.222s-.718.18-.838.419-2.214,1.018-1.616,1.2a20.411,20.411,0,0,0,4.19.12c.12-.12,0-1.509-.479-1.5S563.709,662.641,563.47,662.222Z",
              transform: "translate(-549.824 -644.343)"
            }
          }),
          _c("path", {
            staticClass: "cls-5",
            attrs: {
              id: "Tracé_1823",
              "data-name": "Tracé 1823",
              d:
                "M517.24,405.605l1.347-1.646s1.526-.449,2.185.449A32.733,32.733,0,0,0,523.824,408c.6.479,1.436,2.334,1.137,2.394s-1.975-.658-2.633-.18-1.915.539-1.975.3-3.651-4.249-3.651-4.788a.631.631,0,0,1,.383-.654Z",
              transform: "translate(-508.263 -401.432)"
            }
          }),
          _c("rect", {
            staticClass: "cls-8",
            attrs: {
              id: "Rectangle_894",
              "data-name": "Rectangle 894",
              width: "2.274",
              height: "0.359",
              rx: "0.18",
              transform: "matrix(0.943, 0.333, -0.333, 0.943, 7.726, 8.233)"
            }
          }),
          _c("path", {
            staticClass: "cls-9",
            attrs: {
              id: "Tracé_1824",
              "data-name": "Tracé 1824",
              d: "M439.445,500.222s-2.095.6-1.915,1.257,2.095-.778,2.095-.778Z",
              transform: "translate(-433.819 -492.039)"
            }
          }),
          _c("path", {
            staticClass: "cls-5",
            attrs: {
              id: "Tracé_1825",
              "data-name": "Tracé 1825",
              d:
                "M469.219,412.887a3.42,3.42,0,0,0-1.018,1.556,11.056,11.056,0,0,1-1.077,2.334l-3.651,1.2s-.239,1.137.12,1.137a30.98,30.98,0,0,0,4.489-1.017c.12-.18,2.693-3.591,2.693-3.591S471.015,412.648,469.219,412.887Z",
              transform: "translate(-458.147 -409.911)"
            }
          }),
          _c("path", {
            staticClass: "cls-8",
            attrs: {
              id: "Tracé_1826",
              "data-name": "Tracé 1826",
              d:
                "M498.528,363.789a.283.283,0,0,1-.123.062.088.088,0,0,1-.1-.071.366.366,0,0,1-.14.179c-.069.036-.174.006-.184-.071a.324.324,0,0,1-.074.157.112.112,0,0,1-.157.005,4.033,4.033,0,0,1,.021.5.636.636,0,0,0,.18.452.886.886,0,0,0,.7.09.214.214,0,0,1,.11.006c.09.039.086.164.087.262a.729.729,0,0,0,.639.693.366.366,0,0,0,.272-.066c.069-.058.1-.153.182-.2.128-.071.283.032.376.145s.185.251.33.271c.191.027.337-.168.408-.347a2.024,2.024,0,0,0-.04-1.573A1.775,1.775,0,0,0,498.528,363.789Z",
              transform: "translate(-490.442 -363.493)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }