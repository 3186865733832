var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-avatar", { attrs: { size: _vm.size } }, [
    _vm.selectedImageUrl
      ? _c("img", { attrs: { src: _vm.selectedImageUrl, alt: "avatar" } })
      : _c("div", { staticClass: "initial" }, [
          _vm._v(" " + _vm._s(_vm.initial) + " ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }