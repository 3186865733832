








































import { Vue, Component } from 'vue-property-decorator';
import SvgHomeServiceProvidersPicto from '@/components/Home/SvgHomeServiceProvidersPicto.vue';

@Component({
  components: {
    SvgHomeServiceProvidersPicto,
  },
})
export default class ServiceProviders extends Vue {
  providersLogo: String[] = [require('@/assets/providers/boogi.png'), require('@/assets/providers/gotoo.png')];

  get MerchantUrl(): string | null {
    if (process.env.VUE_APP_MERCHANT_URL) {
      return process.env.VUE_APP_MERCHANT_URL;
    }
    return '#';
  }

  get contactEmail() {
    return process.env.VUE_APP_CONTACT_EMAIL || 'contact@transway.fr';
  }
}
