

















import Component from 'vue-class-component';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Notification } from '@/stores/NotificationStore';

@Component({
  computed: {
    ...mapGetters('notifications', {
      notifications: 'getNotifications',
      currentNotification: 'getCurrentNotification',
    }),
  },
})
export default class NotificationApp extends Vue {
  notifications!: Notification[];

  currentNotification!: Notification;
}
