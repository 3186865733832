var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("PageTitle", { attrs: { text: "Infos personnelles" } }),
      _c("Avatar", {
        staticClass: "mt-4",
        attrs: { initials: _vm.userNameAbbreviation }
      }),
      _c(
        "v-form",
        {
          ref: "updateProfileForm",
          attrs: { "data-cy": "profile-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleUpdateUserFields($event)
            }
          },
          model: {
            value: _vm.formValid,
            callback: function($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.columnCount } },
                    [
                      _c("v-text-field", {
                        ref: "lastname",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-lastname",
                          dense: "",
                          label: "Nom",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.lastname,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "lastname", $$v)
                          },
                          expression: "form.lastname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.columnCount } },
                    [
                      _c("v-text-field", {
                        ref: "firstname",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-firstname",
                          dense: "",
                          label: "Prénom",
                          "validate-on-blur": "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.firstname,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "firstname", $$v)
                          },
                          expression: "form.firstname"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.isReadOnly
                    ? _c(
                        "v-col",
                        { attrs: { cols: _vm.columnCount } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'"
                              }
                            ],
                            ref: "dateOfBirth",
                            staticClass: "text-field",
                            class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                            attrs: {
                              "data-cy": "profile-form-dateOfBirth",
                              rules: _vm.formRules.dateOfBirth,
                              dense: "",
                              label: "Date de naissance",
                              "validate-on-blur": "",
                              disabled: _vm.isReadOnly,
                              "error-messages": _vm.errorDateOfBirth
                            },
                            on: { input: _vm.onUserInfoChange },
                            model: {
                              value: _vm.form.dateOfBirth,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dateOfBirth", $$v)
                              },
                              expression: "form.dateOfBirth"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.columnCount } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##########",
                            expression: "'##########'"
                          }
                        ],
                        ref: "phoneNumber",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-phoneNumber",
                          dense: "",
                          label: "Téléphone",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phoneNumber", $$v)
                          },
                          expression: "form.phoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "10",
                        align: "center",
                        justify: "space-around"
                      }
                    },
                    [
                      _c("v-text-field", {
                        ref: "email",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-email",
                          "error-messages": _vm.errorEmail,
                          rules: _vm.formRules.email,
                          dense: "",
                          label: "Adresse email",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      }),
                      _vm.emailResentError !== ""
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "v-messages theme--light error--text",
                              attrs: { role: "alert" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "v-messages__wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [_vm._v(_vm._s(_vm.emailResentError))]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.emailAsExternal && _vm.hasExternalAccountIdInput
                    ? _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-text-field", {
                            ref: "externalAccountId",
                            staticClass: "text-field",
                            class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                            attrs: {
                              "data-cy": "profile-form-externalAccountId",
                              "error-messages": _vm.errorExternalAccountId,
                              dense: "",
                              label: _vm.externalAccountIdLabel,
                              "validate-on-blur": "",
                              required: "",
                              rules: _vm.externalAccountIdRule,
                              disabled: _vm.isReadOnly
                            },
                            on: { input: _vm.onExternalAccountIdChange },
                            model: {
                              value: _vm.form.externalAccountId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "externalAccountId", $$v)
                              },
                              expression: "form.externalAccountId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("v-text-field", {
                        ref: "deliveryAdress",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-deliveryAdress",
                          dense: "",
                          label: "Adresse",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.deliveryAdress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deliveryAdress", $$v)
                          },
                          expression: "form.deliveryAdress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.columnCount } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#####",
                            expression: "'#####'"
                          }
                        ],
                        ref: "zipCode",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-zipCode",
                          dense: "",
                          label: "Code postal",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.zipCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "zipCode", $$v)
                          },
                          expression: "form.zipCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.columnCount } },
                    [
                      _c("v-text-field", {
                        ref: "city",
                        staticClass: "text-field",
                        class: [!_vm.isReadOnly ? "notReadOnly" : ""],
                        attrs: {
                          "data-cy": "profile-form-city",
                          dense: "",
                          label: "Ville",
                          "validate-on-blur": "",
                          required: "",
                          disabled: _vm.isReadOnly
                        },
                        on: { input: _vm.onUserInfoChange },
                        model: {
                          value: _vm.form.city,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "city", $$v)
                          },
                          expression: "form.city"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.errorContactingService !== ""
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "signup-form-custom-error v-messages theme--light error--text",
                          attrs: {
                            cols: _vm.columnCount,
                            role: "alert",
                            "data-cy": "signup-form-globalerror"
                          }
                        },
                        [
                          _c("div", { staticClass: "v-messages__wrapper" }, [
                            _c("div", { staticClass: "v-messages__message" }, [
                              _vm._v(_vm._s(_vm.errorContactingService))
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.showNewsletterSwitch
                ? _c(
                    "div",
                    { staticClass: "switch" },
                    [
                      _c("v-switch", {
                        staticClass: "switch-newsletter",
                        attrs: {
                          color: "actif",
                          "data-cy": "profile-form-newsletter"
                        },
                        on: { change: _vm.onUserInfoChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _vm._v(
                                    " J'accepte de recevoir les newsletters pour être informé(e) des nouvelles offres, événements et animations sur le programme "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2465965273
                        ),
                        model: {
                          value: _vm.form.newsletter,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "newsletter", $$v)
                          },
                          expression: "form.newsletter"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "validate-button" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "validate-button-text",
                  attrs: {
                    "data-cy": "submit-profile-form",
                    disabled: _vm.isDisabled,
                    color: "homeglobal_fond_bouton",
                    "x-large": "",
                    rounded: ""
                  },
                  on: { click: _vm.handleUpdateUserFields }
                },
                [_vm._v(" Valider les modifications ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "validate-button" },
            [
              !_vm.hasConfirmedEmail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "validate-button-text",
                      attrs: {
                        loading: _vm.emailResentLoading,
                        disabled:
                          _vm.emailResentLoading ||
                          _vm.emailResent ||
                          _vm.isDisabledEmail ||
                          _vm.isReadOnly,
                        color: "homeglobal_fond_bouton",
                        "x-large": "",
                        rounded: ""
                      },
                      on: { click: _vm.sendMail }
                    },
                    [_vm._v(" Renvoyer l'email de validation ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              staticClass: "snackbar",
              attrs: { top: "", timeout: 2000, color: "success" },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _c("div", { staticClass: "v-snack__content" }, [
                _vm._v("L'email a bien été renvoyé")
              ])
            ]
          )
        ],
        1
      ),
      _vm.showConfirm
        ? _c("ConfirmProfileUpdate", {
            attrs: { "must-reconnect": _vm.mustReconnect },
            on: { input: _vm.closeConfirm }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }