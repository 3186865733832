var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showEmailBanner
    ? _c("div", [_c("EmailConfirmationBanner")], 1)
    : _vm.canAssignSSO
    ? _c(
        "div",
        [
          _c("SynchronizationProposalBanner", {
            attrs: { service: _vm.serviceLabel }
          })
        ],
        1
      )
    : _vm.showPendingBanner
    ? _c("div", [_c("PendingValidationBanner")], 1)
    : _vm.showCustom
    ? _c(
        "v-alert",
        {
          staticClass: "banner",
          attrs: { type: _vm.banner.bannerType, dismissible: "" },
          model: {
            value: _vm.showCustom,
            callback: function($$v) {
              _vm.showCustom = $$v
            },
            expression: "showCustom"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "banner-text",
                  attrs: { cols: _vm.textCols, "align-self": "center" }
                },
                [
                  _c("div", {
                    attrs: { "data-cy": "banner-text-block" },
                    domProps: { innerHTML: _vm._s(_vm.banner.bannerText) }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }