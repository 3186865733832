<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.129"
    height="30.128"
    viewBox="0 0 30.129 30.128"
  >
    <g
      id="cashback"
      transform="translate(0 0)"
    >
      <path
        id="Tracé_3565"
        data-name="Tracé 3565"
        d="M0,0H30.129V30.128H0Z"
        transform="translate(0 0)"
        fill="none"
      />
      <path
        id="Tracé_3566"
        data-name="Tracé 3566"
        d="M5,25.6V5.511A2.511,2.511,0,0,1,7.511,3H20.064a2.511,2.511,0,0,1,2.511,2.511V25.6l-3.766-2.511L16.3,25.6l-2.511-2.511L11.277,25.6,8.766,23.085,5,25.6"
        transform="translate(1.277 0.766)"
        class="st1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Tracé_3567"
        data-name="Tracé 3567"
        d="M15.021,8.383H11.883a1.883,1.883,0,1,0,0,3.766h1.255a1.883,1.883,0,1,1,0,3.766H10m2.511,0V17.8m0-11.3V8.383"
        transform="translate(2.554 1.659)"
        class="st1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SvgLuckOfferType extends Vue {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/general';

.st1 {
  stroke: var(--v-inactif-base);
  fill: none;
}
</style>
