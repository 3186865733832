var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SSOSignInView" },
    [
      _c("h1", { staticClass: "title-page mb-12" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } })
      ]),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }