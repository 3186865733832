import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        info: '#2196F3',
        error: '#FF5252',
        success: '#4CAF50',
        warning: '#FFC107',
        actif: '#4CAF50',
        inactif: '#FF5252',
        footer_fond: '#FFFFFF',
        header_fond: '#FFFFFF',
        footer_texte: '#1B1C1F',
        header_picto: '#000000',
        header_texte: '#1B1C1F',
        homebloc_fond: '#FFFFFF',
        homebloc_ligne: '#202346',
        homebloc_texte: '#202346',
        homebloc_titre: '#202346',
        shopoffre_fond: '#FFFFFF',
        homeoffers_fond: '#202346',
        shopfiltre_fond: '#FFFFFF',
        shopfiltre_picto: '#202346',
        homeglobal_picto: '#202346',
        homeglobal_liens: '#79C8C0',
        shopoffre_texte: '#3D3F42',
        shopoffre_titre: '#202346',
        homeglobal_titre: '#202346',
        homeglobal_titre_principal: '#202346',
        homeoffers_ligne: '#FFFFFF',
        homeoffers_titre: '#FFFFFF',
        shopfiltre_texte: '#3D3F42',
        shopfiltre_titre: '#202346',
        homeglobal_lignes: '#202346',
        stickers_primaire: '#79C8C0',
        table_fond_entete: '#79C8C0',
        table_texte_entete: '#FFFFFF',
        stickers_secondaire: '#202346',
        homebloc_fond_bouton: '#202346',
        homeglobal_fond_page: '#FFFFFF',
        homebloc_texte_bouton: '#FFFFFF',
        homeglobal_sous_titre: '#202346',
        homeglobal_texte_fond: '#202346',
        homeoffers_sous_titre: '#FFFFFF',
        shopfiltre_fond_titre: '#79C8C0',
        shopoffre_fond_bouton: '#202346',
        homeglobal_fond_bouton: '#202346',
        homeoffers_fond_bouton: '#202346',
        shopfiltre_fond_bouton_A: '#79C8C0',
        shopfiltre_fond_bouton_B: '#202346',
        shopoffre_texte_bouton: '#FFFFFF',
        homeglobal_texte_bouton: '#FFFFFF',
        homeoffers_texte_bouton: '#FFFFFF',
        shopfiltre_texte_bouton_A: '#FFFFFF',
        shopfiltre_texte_bouton_B: '#FFFFFF',
        homebloc_sous_titre: '#202346',
        popup_fond: '#FFFFFF',
        popup_picto: '#79C8C0',
        popup_titre: '#202346',
        popup_sous_titre: '#202346',
        popup_fond_bouton: '#79C8C0',
        popup_texte_bouton: '#FFFFFF',
        popup_texte: '#202346',
        popup_lien: '#79C8C0',
      },
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'fa',
  },
});
