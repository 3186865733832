










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Countdown extends Vue {
  @Prop({
    type: Date,
    required: false,
    default: null,
  })
  readonly drawAt!: Date;

  luckOfferCountdown: string = '';

  mounted() {
    this.getLuckOfferCountdown(this.drawAt);
  }

  getLuckOfferCountdown(date) {
    // Make sure that drawAt hour is 12:00:00
    date.setHours(12, 0, 0)

    // Set the date we're counting down to
    const countDownDate = new Date(date).getTime();

    setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the countdown date
      const distance = countDownDate - now;

      if (distance <= 0) {
        this.luckOfferCountdown = `Offre expirée`;
        return;
      }

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (days === 0) {
        if (hours === 0) {
          if (minutes === 0) {
            this.luckOfferCountdown = `${seconds}s`;
          } else {
            this.luckOfferCountdown = `${minutes}m`;
          }
        } else {
          this.luckOfferCountdown = `${hours}h ${minutes}m`;
        }
      } else {
        this.luckOfferCountdown = `${days}j ${hours}h ${minutes}m`;
      }
      // check every second (1000ms = 1 second)
    }, 1000);
  }
}
