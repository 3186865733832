var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: true, "content-class": "signin", "max-width": "600px" },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "signin-popin", attrs: { "data-cy": "signin-popin" } },
        [
          _c(
            "v-card-title",
            { staticClass: "signin-title" },
            [
              _vm.compShowStickers
                ? _c(
                    "div",
                    { staticClass: "signin-title-picto" },
                    [_c("SvgSignin")],
                    1
                  )
                : _vm._e(),
              _c("h1", { staticClass: "signin-title-text" }, [
                _vm._v("Connexion")
              ]),
              _c("v-icon", {
                staticClass: "signin-close",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-times") },
                on: {
                  click: function($event) {
                    return _vm.$emit("input")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "signin-form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.validate($event)
                        }
                      },
                      model: {
                        value: _vm.signinValid,
                        callback: function($$v) {
                          _vm.signinValid = $$v
                        },
                        expression: "signinValid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        ref: "email",
                        staticClass: "signin-form-field",
                        attrs: {
                          "data-cy": "signin-popin-email-field",
                          error: !!_vm.error,
                          rules: _vm.emailRules,
                          label: "Email",
                          required: "",
                          "validate-on-blur": ""
                        },
                        model: {
                          value: _vm.formInfos.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfos, "email", $$v)
                          },
                          expression: "formInfos.email"
                        }
                      }),
                      _c("v-text-field", {
                        ref: "password",
                        staticClass: "signin-form-field",
                        attrs: {
                          "data-cy": "signin-popin-password-field",
                          rules: _vm.passwordRules,
                          type: _vm.passwordShow ? "text" : "password",
                          "error-messages": _vm.error,
                          label: "Mot de passe",
                          hint:
                            "Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.",
                          "validate-on-blur": "",
                          required: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.passwordShow = !_vm.passwordShow
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.passwordShow
                                            ? "fas fa-eye"
                                            : "fas fa-eye-slash"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.formInfos.password,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfos, "password", $$v)
                          },
                          expression: "formInfos.password"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "signin-form-button",
                          attrs: {
                            large: "",
                            type: "submit",
                            color: "popup_fond_bouton",
                            "data-cy": "signin-popin-connection-button",
                            rounded: ""
                          }
                        },
                        [_vm._v(" Se connecter ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "signin-form-link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openForgotPassword($event)
                            }
                          }
                        },
                        [_vm._v(" Mot de passe oublié ")]
                      ),
                      !_vm.withSSOSignIn
                        ? _c(
                            "a",
                            {
                              staticClass: "signin-form-link",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.goTo("/signup")
                                }
                              }
                            },
                            [_vm._v(" Pas encore inscrit(e) ? ")]
                          )
                        : [
                            _c(
                              "div",
                              { staticClass: "signin-form-separator" },
                              [
                                _c("div", {
                                  staticClass: "signin-form-bottomline"
                                }),
                                _c(
                                  "div",
                                  { staticClass: "signin-form-foreground" },
                                  [_vm._v("OU")]
                                ),
                                _c("div", {
                                  staticClass: "signin-form-bottomline"
                                })
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "signin-form-button-secondary",
                                attrs: {
                                  large: "",
                                  "data-cy":
                                    "signin-popin-connection-button-sso",
                                  color: "shopfiltre_fond_bouton_B",
                                  rounded: ""
                                },
                                on: { click: _vm.externalSignIn }
                              },
                              [
                                _vm._v(
                                  " Se connecter avec " +
                                    _vm._s(_vm.connectionLabel) +
                                    " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "signin-form-newuser" }, [
                              _vm._v(" Vous êtes un nouvel utilisateur ? "),
                              _c(
                                "a",
                                {
                                  staticClass: "signin-form-newuser-link",
                                  attrs: { href: _vm.externalSignUpURL }
                                },
                                [
                                  _vm._v(
                                    " Créez un compte " +
                                      _vm._s(_vm.connectionLabel) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }