var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "shop-offline-message",
        "retain-focus": "",
        "max-width": _vm.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            "content-class":
              "shop-offline-messshowShopOfflineMessage\nshowShopOfflineMessage\nshowShopOfflineMessageage-card",
            "data-cy": "shop-offline-message"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "shop-offline-message-card-title" },
            [
              _vm.showStickers
                ? _c(
                    "div",
                    { staticClass: "shop-offline-message-card-title-picto" },
                    [_c("SvgPopup", { attrs: { width: "80", height: "80" } })],
                    1
                  )
                : _vm._e(),
              _c(
                "h1",
                { staticClass: "shop-offline-message-card-title-text" },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]
          ),
          _c("div", { staticClass: "shop-offline-message-card-subtitle" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } })
          ]),
          _c(
            "div",
            { staticClass: "interactions-bar" },
            [
              _c(
                "SignInButton",
                {
                  staticClass:
                    "shop-offline-message-card-btn interactions-bar__item",
                  attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input")
                    }
                  }
                },
                [_vm._v(" Se connecter ")]
              ),
              1
                ? _c(
                    "a",
                    {
                      staticClass:
                        "shop-offline-message-card-link interactions-bar__item",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.goTo("/signup")
                        }
                      }
                    },
                    [_vm._v(" Pas encore inscrit(e) ? ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }