var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "430px"
      },
      on: { input: _vm.onVisibilityChange }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-xl align-content-center" },
        [
          _c(
            "v-card-text",
            {
              staticClass:
                "text-center black--text text-uppercase font-weight-bold text-sm-h6 pt-9 title"
            },
            [_vm._v(" Oups ... Nous rencontrons un problème ")]
          ),
          !_vm.customMessage || _vm.customMessage === "<p></p>"
            ? _c(
                "v-card-text",
                {
                  staticClass: "text-center text-subtitle-1 px-5 mt-2 content"
                },
                [
                  _vm._v(
                    " Il nous est impossible de créer votre compte, merci de contacter votre programme pour plus d'explications "
                  )
                ]
              )
            : _c("v-card-text", {
                staticClass: "text-center text-subtitle-1 px-5 mt-2 content",
                domProps: { innerHTML: _vm._s(_vm.customMessage) }
              }),
          _vm.buttonText
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "mt-4 mb-8 text-subtitle-3 white--text font-weight-bold",
                      attrs: {
                        large: "",
                        rounded: "",
                        size: "large",
                        color: "popup_fond_bouton",
                        "min-width": "220px",
                        to: { path: "/" }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "mt-4 mb-8 text-subtitle-3 white--text font-weight-bold",
                      attrs: {
                        large: "",
                        rounded: "",
                        size: "large",
                        color: "popup_fond_bouton",
                        "min-width": "220px",
                        to: { name: "help" }
                      }
                    },
                    [_vm._v(" Nous contacter ")]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }