











































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as OfferService from '@/services/merchantSpace/OfferService';

const moment = require('moment');

moment.locale('fr');

@Component
export default class OfferDelivery extends Vue {
  @Prop({
    type: String || null,
    required: false,
    default: null,
  })
  readonly websiteURL!: string | null;

  @Prop({
    type: Number,
    required: true,
  })
  readonly typeId!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly currentStock!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly deliveryMethodId!: number;

  @Prop({
    type: Number,
    required: false,
  })
  readonly collectionDateOption!: number;

  @Prop({
    type: Date || null,
    required: false,
  })
  readonly drawAt!: Date | null;

  @Prop({
    type: Date || null,
    required: false,
  })
  readonly startedAt!: Date | null;

  @Prop({
    type: Date || null,
    required: false,
  })
  readonly expiredAt!: Date | null;

  @Prop({
    type: Date || null,
    required: false,
  })
  readonly collectionDateUnique!: Date | null;

  @Prop({
    type: Date || null,
    required: false,
  })
  readonly collectionDateMin!: Date | null;

  get compUrlWebsite(): string | null {
    if (this.websiteURL) {
      const url = this.websiteURL.replace(/^(?:https?:\/\/)?(?:http?:\/\/)?/i, '');
      return url;
    }
    return null;
  }

  get compTextForDate(): string {
    let text = '';

    if (OfferService.isStandardOffer(this.typeId) || OfferService.isFundingOffer(this.typeId) || OfferService.isCashbackOffer(this.typeId)) {
      if (this.expiredAt) {
        let compStartedDate = '';
        const compExpiredDate = moment(this.expiredAt).format('D MMMM YYYY');
        if (moment(this.startedAt).month() === moment(this.expiredAt).month()) {
          compStartedDate = moment(this.startedAt).format('D');
        } else {
          compStartedDate = moment(this.startedAt).format('D MMMM');
        }
        text = `Valable du ${compStartedDate} au ${compExpiredDate}`;
      } else {
        text = `Offre permanente`;
      }
    } else if (OfferService.isLuckOffer(this.typeId)) {
      const date = moment(this.drawAt).format('D MMMM YYYY');
      text = `Tirage au sort le ${date}`;
    }

    return text;
  }

  get compTextForStock(): string {
    let text = '';
    if (this.currentStock) {
      text = this.currentStock > 1 ? `${this.currentStock} articles disponibles` : `${this.currentStock} article disponible`;
    }
    return text;
  }

  get compTextForDelivery(): string {
    let text = '';
    if (this.deliveryMethodId === 1) {
      text = 'Envoi par e-mail';
    } else if (this.deliveryMethodId === 3) {
      text = 'Envoi postal';
    } else {
      let date;
      if (this.collectionDateOption === 1) {
        date = moment(this.startedAt).format('D MMMM');
      } else if (this.collectionDateOption === 2) {
        date = moment(this.collectionDateUnique).format('D MMMM');
      } else {
        date = moment(this.collectionDateMin).format('D MMMM');
      }
      text = `Retrait sur place à partir du ${date}`;
    }

    return text;
  }

  get compIconForDelivery(): string {
    if (this.deliveryMethodId === 1) {
      return 'fas fa-paper-plane';
    }
    if (this.deliveryMethodId === 3) {
      return 'fas fa-envelope';
    }
    return 'fas fa-store';
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.typeId);
  }

  get isShowcaseOffer(): boolean {
    return OfferService.isShowcaseOffer(this.typeId);
  }

  get isCollectOffer(): boolean {
    return OfferService.isFundingOffer(this.typeId);
  }
}
