var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "30.129",
        height: "30.128",
        viewBox: "0 0 30.129 30.128"
      }
    },
    [
      _c("g", { attrs: { id: "vitrine", transform: "translate(0 0)" } }, [
        _c("path", {
          attrs: {
            id: "Tracé_3559",
            "data-name": "Tracé 3559",
            d: "M0,0H30.129V30.128H0Z",
            transform: "translate(0 0)",
            fill: "none"
          }
        }),
        _c("line", {
          staticClass: "st1",
          attrs: {
            id: "Ligne_10",
            "data-name": "Ligne 10",
            x2: "23.237",
            y2: "0.078",
            transform: "translate(3.263 26.422)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3560",
            "data-name": "Tracé 3560",
            d:
              "M3,8.021V9.277a3.766,3.766,0,0,0,7.532,0V8.021m0,1.255a3.766,3.766,0,0,0,7.532,0V8.021m0,1.255a3.766,3.766,0,0,0,7.532,0V8.021H3L5.511,3H23.086L25.6,8.021",
            transform: "translate(0.766 0.766)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("line", {
          staticClass: "st1",
          attrs: {
            id: "Ligne_11",
            "data-name": "Ligne 11",
            y1: "12.742",
            transform: "translate(6.277 13.62)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("line", {
          staticClass: "st1",
          attrs: {
            id: "Ligne_12",
            "data-name": "Ligne 12",
            y1: "12.742",
            transform: "translate(23.852 13.62)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3561",
            "data-name": "Tracé 3561",
            d:
              "M9,22.532V17.511A2.511,2.511,0,0,1,11.511,15h2.511a2.511,2.511,0,0,1,2.511,2.511v5.021",
            transform: "translate(2.298 3.83)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }