
























import { Component, Vue } from 'vue-property-decorator';
import * as Cookie from 'js-cookie';
import * as common from '@/services/common/common';
import { BannerInterface } from '@/services/merchantSpace/PlatformService';

@Component({})
export default class PendingValidationBanner extends Vue {
  get banner(): BannerInterface {
    return this.$store.getters['platform/getBannerInfos'];
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get showBanner(): boolean {
    const retrievedCookie = Cookie.get('PostBannerDismissed');

    if (retrievedCookie) {
      const retrievedBannerInfos = retrievedCookie.split(' | ');
      const retrievedBannerType = retrievedBannerInfos[0];
      const retrievedBannerText = retrievedBannerInfos[1];
      this.banner.postSignupBannerActivated = false;

      return (
        this.isConnected &&
        this.banner.postSignupBannerActivated &&
        (retrievedBannerType !== this.banner.postSignupBannerType || retrievedBannerText !== this.banner.postSignupBannerText)
      );
    }

    return this.isConnected && this.banner.postSignupBannerActivated && !retrievedCookie;
  }

  set showBanner(value: boolean) {
    const cookieValue = `${this.banner.postSignupBannerType} | ${this.banner.postSignupBannerText}`;
    Cookie.set('PostBannerDismissed', cookieValue, {
      expires: 31,
      sameSite: 'strict',
    });
  }
}
