var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "SignInButton",
      attrs: {
        "data-cy": "header-connection-button",
        text: _vm.text,
        plain: _vm.plain,
        block: _vm.block,
        ripple: _vm.ripple,
        color: _vm.color,
        large: _vm.large,
        rounded: _vm.rounded,
        elevation: _vm.elevation
      },
      on: { click: _vm.showSignIn }
    },
    [_vm._t("default", [_vm._v("Connexion")]), _vm._t("icon")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }