





















































import { Component, Vue } from 'vue-property-decorator';
import { isConnected } from '../../services/common/common';
import { getFooterLinks } from '@/api/resources/platformApi';

@Component({
  components: {},
})
export default class Footer extends Vue {
  footerLinks = [
    { order: 0, text: '', link: '' },
    { order: 1, text: '', link: '' },
    { order: 2, text: '', link: '' },
    { order: 3, text: '', link: '' },
  ];

  async mounted() {
    const platformFooterLinks = await getFooterLinks();
    this.footerLinks = platformFooterLinks.length > 0 ? platformFooterLinks : this.footerLinks;
  }

  get links() {
    return this.footerLinks.filter((link) => link.text && link.link);
  }

  get gcu() {
    return { name: 'gcu-legal-notice' };
  }

  get policies() {
    return { name: 'gcu-rgpd' };
  }

  get regulations() {
    return { name: 'gcu-user-route' };
  }

  get help() {
    if (isConnected()) {
      return { name: 'help' };
    }
    return { name: 'help-public' };
  }
}
