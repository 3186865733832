












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { startCodeFlow } from '@/services/authSpace/sso.service';
import { SSOBehavior } from '@/services/merchantSpace/types';
import { getTranslation } from '../../i18n';

@Component
export default class SSOSignUpDialog extends Vue {
  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  get ssoOptions() {
    return this.$store.getters['platform/ssoOptions'];
  }

  get signUpUrl() { 
    return this.$store.getters['platform/getExternalSignUpURL'];
  }

  get platformKey() {
    return this.$store.getters['platform/getPlatform'].key;
  }

  get siteName() {
    return new URL(this.signUpUrl).hostname.replace('www.', '');
  }

  get title() {
    return getTranslation(`${this.platformKey}.SSOSignUpDialog.title`) || 'Inscription et activation';
  }

  get content() {
    return getTranslation(`${this.platformKey}.SSOSignUpDialog.content`) || `Créez votre compte sur ${this.siteName} puis revenez sur le programme de fidélité pour activer le cumul de vos points.`;
  }

  get signUpButtonText() {
    return getTranslation(`${this.platformKey}.SSOSignUpDialog.signUpButton`) || `Créer un compte sur ${this.siteName}`;
  }

  get alreadySignedUpText() {
    return getTranslation(`${this.platformKey}.SSOSignUpDialog.alreadySignedUp`) || `J'active mon programme de fidélité`;
  }

  alreadySignUp() {
    if ([SSOBehavior.AUTO_SIGNIN_TO_EXTERNAL, SSOBehavior.SIGNIN_TO_EXTERNAL].includes(this.ssoBehavior)) {
      startCodeFlow();
    } else if (this.ssoBehavior === SSOBehavior.PASSWORD) {
      this.$router.push({ name: 'sso:signin' });
    }

    this.$emit('close');
  }
}
