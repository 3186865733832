import { v4 as uuidv4 } from 'uuid';

const DEFAULT_TIMEOUT = 3500;
const DELAY_BETWEEN_NOTIF = 200;

type NOTIFICATION_TYPE = 'info' | 'warning' | 'error' | 'success';

export type Notification = {
  id: string | number;
  message: string;
  type: NOTIFICATION_TYPE;
  timeout: number;
};

let timeoutId: number | undefined;

type State = {
  notifications: Notification[];
};

const initialState: State = {
  notifications: [],
};

export default {
  namespaced: true,
  state: initialState,

  mutations: {
    ADD_NOTIFICATION(
      state: State,
      {
        message,
        timeout,
        type,
      }: {
        message: string;
        type: NOTIFICATION_TYPE;
        timeout: number;
      },
    ) {
      state.notifications = [
        ...state.notifications,
        {
          id: uuidv4(),
          message,
          type,
          timeout,
        },
      ];
    },

    NEXT_NOTIFICATION(state: State) {
      const [, ...nextNotifications] = state.notifications;
      state.notifications = nextNotifications;
    },
  },

  actions: {
    startTimer({ dispatch, commit, getters }: any) {
      if (timeoutId) {
        return;
      }

      const currentNotif = getters.getCurrentNotification;
      if (!currentNotif) {
        return;
      }

      timeoutId = setTimeout(() => {
        timeoutId = undefined;
        clearTimeout(timeoutId);
        commit('NEXT_NOTIFICATION');
        dispatch('startTimer');
      }, currentNotif.timeout + DELAY_BETWEEN_NOTIF);
    },

    addFlash(
      { commit, dispatch, getters }: any,
      {
        message,
        type,
        timeout = DEFAULT_TIMEOUT,
      }: {
        message: string;
        type: NOTIFICATION_TYPE;
        timeout?: number;
      },
    ) {
      const notifications = getters.getNotifications;
      const isDuplicate = notifications.some((notification) => notification.message === message);

      if (!isDuplicate) {
        commit('ADD_NOTIFICATION', { message, timeout, type });
        dispatch('startTimer');
      }
    },

    addInfo({ dispatch }: any, { message, timeout }: { message: string; timeout?: number }) {
      dispatch('addFlash', { message, timeout, type: 'info' });
    },

    addDanger({ dispatch }: any, { message, timeout }: { message: string; timeout?: number }) {
      dispatch('addFlash', { message, timeout, type: 'error' });
    },
    // alias of addDanger
    flashDanger({ dispatch }: any, { message, timeout }: { message: string; timeout?: number }) {
      dispatch('addFlash', { message, timeout, type: 'error' });
    },

    addWarn({ dispatch }: any, { message, timeout }: { message: string; timeout?: number }) {
      dispatch('addFlash', { message, timeout, type: 'warning' });
    },
  },

  getters: {
    getNotifications(state: State) {
      return state.notifications;
    },

    getCurrentNotification(state: State) {
      return state.notifications[0];
    },
  },
};
