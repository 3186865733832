
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { OrderAddress } from '@/services/merchantSpace/types';

@Component({})
export default class AddressConfirmationCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly orderAddress!: OrderAddress;

  form = {
    address: '',
    zipCode: undefined,
    city: '',
  };

  suggestions = [];

  selectedRadio = '0';

  private getAddress() {
    return this.$store.getters['user/getUserAddress'];
  }

  get isAddressDefined() {
    const address = this.getAddress();
    this.form.address = address.deliveryAdress;
    this.form.zipCode = address.zipCode;
    this.form.city = address.city;
    return address && address.deliveryAdress && address.zipCode && address.city;
  }

  get formattedDefaultAddress() {
    const address = this.getAddress();
    if (address) {
      return `${address.deliveryAdress}, ${address.zipCode} ${address.city}`;
    }
    return '';
  }

  get customAddressLabel() {
    if (this.isAddressDefined) {
      return 'Recevoir à une autre adresse :';
    }
    return 'Veuillez entrer votre adresse :';
  }

  get isButtonEnabled() {
    return this.form.address !== '' && this.form.zipCode !== undefined && this.form.city !== '';
  }

  getAddressSuggestions() {
    const sessionToken = uuidv4();
    const endpoint = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${this.form.address}?language=fr&limit=5&session_token=${sessionToken}&country=FR&access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`;
    axios
      .get(endpoint)
      .then((response) => {
        const data = response.data;
        this.suggestions = data.suggestions.filter((suggestion) => suggestion.full_address);
      })
      .catch((error) => {
        console.error('Error fetching address suggestions:', error);
      });
  }

  selectSuggestion(suggestion) {
    this.form.address = suggestion.address;
    this.form.zipCode = suggestion.context.postcode.name;
    this.form.city = suggestion.context.place.name;
    this.suggestions = [];
  }

  handleRadioChange() {
    if (this.selectedRadio === '1') {
      this.resetFormData();
    } else {
      const address = this.getAddress();
      this.form.address = address.deliveryAdress;
      this.form.zipCode = address.zipCode;
      this.form.city = address.city;
    }
  }

  resetFormData() {
    this.form.address = '';
    this.form.zipCode = undefined;
    this.form.city = '';
    this.suggestions = [];
  }
}
