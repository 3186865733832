var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "banner",
      attrs: {
        "data-cy": "email-confirmation-banner-text-block",
        type: "warning",
        icon: _vm.icon,
        prominent: "",
        align: "center"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "banner-text",
              attrs: { cols: _vm.textCols, "align-self": "center" }
            },
            [
              _vm._v(
                " Veuillez procéder à la validation de votre email afin d’accéder à toutes les fonctionnalités du programme. "
              )
            ]
          ),
          !_vm.isMobile
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", text: "", elevation: "2" },
                      on: { click: _vm.goToProfile }
                    },
                    [_vm._v(" Voir mon profil ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isMobile
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", text: "", elevation: "2" },
                      on: { click: _vm.goToProfile }
                    },
                    [_vm._v(" Voir mon profil ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }