// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-f4011f5a] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-f4011f5a] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-f4011f5a] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.wrapper[data-v-f4011f5a] {\n  width: 100%;\n  min-height: 100%;\n}\n.shop-background[data-v-f4011f5a] {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  opacity: 0.5;\n}\n.dialog-background[data-v-f4011f5a] {\n  position: absolute;\n  width: 100%;\n  height: 60%;\n}\n.dialog-background svg[data-v-f4011f5a] {\n  position: absolute;\n  top: -25%;\n  left: -25%;\n  width: 150%;\n  height: 150%;\n}\n.dialog[data-v-f4011f5a] {\n  position: relative;\n  max-width: var(--max-page-width);\n  margin: 0 auto 0.5rem auto;\n}", ""]);
// Exports
module.exports = exports;
