var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "basicDialog",
        "max-width": _vm.maxWidth
      },
      on: {
        input: function($event) {
          return _vm.$emit("onClose")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "basicDialog__card" },
        [
          _c(
            "v-card-title",
            { staticClass: "basicDialog__title" },
            [
              _c("div", { staticClass: "basicDialog__title-title" }, [
                _vm.compShowStickers
                  ? _c(
                      "div",
                      { staticClass: "basicDialog__title-title-picto" },
                      [_c("SvgPopup")],
                      1
                    )
                  : _vm._e(),
                _c(
                  "h1",
                  { staticClass: "basicDialog__title-title-text ml-2" },
                  [_vm._v(_vm._s(_vm.title))]
                )
              ]),
              _c("v-icon", {
                staticClass: "basicDialog-close",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-times") },
                on: {
                  click: function($event) {
                    return _vm.$emit("onClose")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { staticClass: "basicDialog__content" },
                [_vm._t("default")],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mx-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-2 basicDialog__btn",
                  attrs: {
                    "data-cy": "basicDialog-btn",
                    rounded: "",
                    color: "popup_fond_bouton",
                    block: ""
                  },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }