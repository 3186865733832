var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "100",
        height: "100",
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { "fill-rule": "nonzero", fill: "none" } }, [
        _c("path", {
          attrs: {
            d:
              "M12.36 22.741c-5.092-.99-8.41-5.955-7.412-11.091.998-5.136 5.935-8.498 11.026-7.508 5.092.99 8.41 5.955 7.412 11.092-.999 5.136-5.936 8.497-11.027 7.507z",
            fill: "var(--v-stickers_primaire-base)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M.615 5.707c.094-.1.253-.106.353-.01l8.074 7.605.042.053c.058.093.048.217-.031.3-.095.101-.253.106-.354.011L.625 6.06l-.042-.052c-.057-.094-.047-.218.032-.301zM.98 3.609c.094-.1.253-.105.353-.01l9.757 9.192.043.052c.057.094.047.218-.032.302-.095.1-.253.105-.353.01L.99 3.962.95 3.91C.892 3.816.902 3.693.98 3.61zm.965-1.63c.095-.101.253-.106.354-.011l10.89 10.259.042.052c.057.094.047.217-.032.301-.095.1-.253.105-.353.01L1.957 2.333l-.043-.053c-.057-.093-.047-.217.032-.3zm1.737-.573c.095-.1.253-.105.354-.01l9.757 9.193.043.052c.057.094.047.218-.032.301-.095.1-.253.105-.354.01L3.694 1.76l-.042-.052c-.057-.094-.047-.218.031-.302zM5.764.95c.095-.1.253-.105.354-.01l8.074 7.606.042.052c.057.094.047.217-.032.301-.094.1-.253.105-.353.01L5.775 1.303l-.043-.052c-.057-.094-.047-.217.032-.301z",
            fill: "#ffffff"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }