
































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgTitle from '@/components/Svg/SvgTitle.vue';

const iban = require('iban');

@Component({
  components: {
    SvgTitle,
  },
})
export default class PaymentValidatedCard extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  readonly value!: boolean;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get show() {
    return this.value;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  async onClick(): Promise<void> {
    this.$emit('accept');
  }
}
