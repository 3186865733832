var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "footer",
      attrs: {
        color: "footer_fond",
        app: "",
        absolute: "",
        padless: "",
        elevation: "24",
        "data-cy": "footer-app"
      }
    },
    [
      _c("div", { staticClass: "footer__content px-md-12" }, [
        _c(
          "div",
          { staticClass: "footer__content-left" },
          [
            _c(
              "router-link",
              { staticClass: "footer__content-link", attrs: { to: _vm.gcu } },
              [_vm._v(" Mentions légales ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "footer__content-link",
                attrs: { to: _vm.policies }
              },
              [_vm._v(" Données personnelles - RGPD ")]
            ),
            _c(
              "router-link",
              {
                staticClass: "footer__content-link",
                attrs: { to: _vm.regulations }
              },
              [_vm._v(" Parcours utilisateurs ")]
            ),
            _c(
              "router-link",
              { staticClass: "footer__content-link", attrs: { to: _vm.help } },
              [_vm._v(" Besoin d'aide ? ")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer__content-right" },
          _vm._l(_vm.links, function(footerLink) {
            return _c(
              "a",
              {
                key: footerLink.order,
                staticClass: "footer__content-link footer__content-underline",
                attrs: {
                  href: footerLink.link,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }
              },
              [_vm._v(" " + _vm._s(footerLink.text) + " ")]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }