var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { attrs: { id: "Calque_1", x: "0px", y: "0px" } }, [
    _c(
      "pattern",
      {
        attrs: {
          id: "pattern",
          width: "1243",
          height: "415",
          patternUnits: "userSpaceOnUse",
          viewBox: "0 0 1243 415",
          preserveAspectRatio: "xMidYMid"
        }
      },
      [
        _c("g", [
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M626.1,278.4c-22.3,22.3-49.2,32.3-83.1,32.3c-68,0-123.1-55.1-123.1-123.1S475,64.6,543,64.6\n    c32.1,0,61.2,12.3,83.1,32.3L626.1,278.4z"
            }
          }),
          _c("circle", {
            staticClass: "st0",
            attrs: { cx: "157.4", cy: "229.2", r: "85" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M1.5,93.4c25.5,21.7,41.5,54.6,41.5,91.8c0,36.8-16,69.7-41.1,91.4"
            }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "28.1", y1: "309.9", x2: "15.7", y2: "310.2" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "23.7", y1: "274.3", x2: "15.7", y2: "274.7" }
          }),
          _c("line", {
            staticClass: "st3",
            attrs: { x1: "30.7", y1: "237.7", x2: "25.9", y2: "231.3" }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M178.6,413.7c-8.8-16.9-13.8-36.1-13.8-56.4c0-67.3,54.6-121.9,121.9-121.9S408.5,290,408.5,357.3\n    c0,21.6-4,38.8-13.8,56.4"
            }
          }),
          _c("circle", {
            staticClass: "st5",
            attrs: { cx: "128.1", cy: "88.4", r: "86.3" }
          }),
          _c("path", {
            staticClass: "st6",
            attrs: {
              d: "M364.7,414.9c0-36.2,29.4-62.7,65.6-62.7s65.6,26.5,65.6,62.7"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M626.1-1.2H523c0,0-22.3,23.6-8.9,69.2c0,0,69.1-16.3,112,28.9L626.1-1.2z"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M1.5,414.9l-0.3-68.6c0,0,58.5-63.1,129.9-4.2c30.9,28,26.3,72.7,26.3,72.7H1.5z"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M62.7-1.2L1.4-1l0,94.4c0,0,18.7,19.1,22.7,26.4C24.2,119.7,97.8,81,62.7-1.2z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d: "M351.4-2.3c0,68-55.1,123.1-123.1,123.1S105.2,65.7,105.2-2.3"
            }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "295.8", y1: "185", x2: "385.9", y2: "255.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "256.4", y1: "187.7", x2: "393.1", y2: "290.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "231.9", y1: "195.9", x2: "389.2", y2: "319.3" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "205.2", y1: "209.3", x2: "380.3", y2: "343.8" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "191.3", y1: "227", x2: "372.5", y2: "361.5" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "179.5", y1: "248", x2: "360.7", y2: "382.6" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "169.6", y1: "272.5", x2: "335.6", y2: "395.7" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "173.4", y1: "299.9", x2: "304.7", y2: "404.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "174.7", y1: "328.2", x2: "273.2", y2: "406.6" }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d: "M626.4,343.6v71.3h-28.2C598.2,414.9,596.1,370.4,626.4,343.6z"
            }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "472.3", y1: "325.6", x2: "460.5", y2: "321.8" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "514.9", y1: "325.8", x2: "503.6", y2: "330.9" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "514.9", y1: "356.7", x2: "511", y2: "368.5" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "553.7", y1: "372", x2: "541.5", y2: "369.9" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "568.8", y1: "342", x2: "560.3", y2: "332.9" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "596.1", y1: "328.9", x2: "605.3", y2: "320.6" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "603.2", y1: "355.6", x2: "612.3", y2: "356.2" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "547.5", y1: "254.4", x2: "556.2", y2: "255.5" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "587.7", y1: "288.4", x2: "594.5", y2: "293.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "538.1", y1: "305.7", x2: "546.9", y2: "305.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "505.9", y1: "257.2", x2: "519.1", y2: "263.5" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "487.2", y1: "276.4", x2: "486.6", y2: "290.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "616.5", y1: "248", x2: "605.6", y2: "250" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "623.4", y1: "207.8", x2: "615.6", y2: "215.6" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "607.6", y1: "180.3", x2: "596.6", y2: "180.6" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "577.7", y1: "222.2", x2: "570", y2: "219.8" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "559.8", y1: "183.9", x2: "560.8", y2: "175.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "527.2", y1: "161.4", x2: "535.2", y2: "161.3" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "521.9", y1: "212", x2: "528.5", y2: "207.3" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "494.2", y1: "186.4", x2: "501.8", y2: "183.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "483.1", y1: "226", x2: "491.1", y2: "225.5" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "447.5", y1: "224.1", x2: "453", y2: "230" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "442.5", y1: "270.3", x2: "450.3", y2: "268.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "458", y1: "52.1", x2: "458.4", y2: "39.7" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "431.3", y1: "63.2", x2: "423.9", y2: "53.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "410.7", y1: "80.7", x2: "398.4", y2: "82.5" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "393.3", y1: "36.8", x2: "395.3", y2: "49" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "428.4", y1: "9.3", x2: "421.3", y2: "19.5" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "460.6", y1: "5.4", x2: "472.1", y2: "9.9" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "368.9", y1: "63.2", x2: "368.2", y2: "75.6" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "365.8", y1: "17", x2: "355.7", y2: "24.2" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "334.9", y1: "58.4", x2: "334.2", y2: "70.8" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "323", y1: "22.6", x2: "332", y2: "31" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "301.2", y1: "20.8", x2: "298.1", y2: "32.8" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M1247.3,278.4c-22.3,22.3-49.2,32.3-83.1,32.3c-68,0-123.1-55.1-123.1-123.1s55.1-123.1,123.1-123.1\n    c32.1,0,61.2,12.3,83.1,32.3L1247.3,278.4z"
            }
          }),
          _c("circle", {
            staticClass: "st0",
            attrs: { cx: "778.6", cy: "229.2", r: "85" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M622.6,93.4c25.5,21.7,41.5,54.6,41.5,91.8c0,36.8-16,69.7-41.1,91.4"
            }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "649.3", y1: "310", x2: "636.9", y2: "310.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "644.9", y1: "274.3", x2: "636.9", y2: "274.7" }
          }),
          _c("line", {
            staticClass: "st3",
            attrs: { x1: "651.9", y1: "237.7", x2: "647.1", y2: "231.3" }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M799.8,413.8c-8.8-16.9-13.8-36.1-13.8-56.4c0-67.3,54.6-121.9,121.9-121.9s121.9,54.6,121.9,121.9\n    c0,21.6-4,38.8-13.8,56.4"
            }
          }),
          _c("circle", {
            staticClass: "st5",
            attrs: { cx: "749.3", cy: "88.5", r: "86.3" }
          }),
          _c("path", {
            staticClass: "st6",
            attrs: {
              d:
                "M985.9,414.9c0-36.2,29.4-62.7,65.6-62.7c36.2,0,65.6,26.5,65.6,62.7"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M1247.3-1.2h-103.1c0,0-22.3,23.6-8.9,69.2c0,0,69.1-16.3,112,28.9L1247.3-1.2z"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M622.6,414.9l-0.3-68.6c0,0,58.5-63.1,129.9-4.2c30.9,28,26.3,72.7,26.3,72.7H622.6z"
            }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M683.8-1.2L622.6-1l0,94.4c0,0,18.7,19.1,22.7,26.4C645.4,119.8,718.9,81,683.8-1.2z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d: "M972.5-2.2c0,68-55.1,123.1-123.1,123.1S726.4,65.7,726.4-2.2"
            }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "917", y1: "185", x2: "1007.1", y2: "255.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "877.5", y1: "187.7", x2: "1014.3", y2: "290.5" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "853.1", y1: "196", x2: "1010.4", y2: "319.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "826.4", y1: "209.3", x2: "1001.5", y2: "343.8" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "812.5", y1: "227", x2: "993.7", y2: "361.5" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "800.7", y1: "248.1", x2: "981.9", y2: "382.6" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "790.8", y1: "272.5", x2: "956.7", y2: "395.8" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "794.6", y1: "299.9", x2: "925.9", y2: "404.4" }
          }),
          _c("line", {
            staticClass: "st7",
            attrs: { x1: "795.9", y1: "328.3", x2: "894.3", y2: "406.6" }
          }),
          _c("path", {
            staticClass: "st4",
            attrs: {
              d:
                "M1247.6,343.6v71.3h-28.2C1219.4,414.9,1217.2,370.4,1247.6,343.6z"
            }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1093.4", y1: "325.7", x2: "1081.6", y2: "321.8" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1136.1", y1: "325.8", x2: "1124.8", y2: "330.9" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1136", y1: "356.7", x2: "1132.2", y2: "368.5" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1174.9", y1: "372", x2: "1162.7", y2: "370" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1190", y1: "342", x2: "1181.5", y2: "333" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1217.3", y1: "328.9", x2: "1226.5", y2: "320.6" }
          }),
          _c("line", {
            staticClass: "st8",
            attrs: { x1: "1224.4", y1: "355.6", x2: "1233.5", y2: "356.2" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1168.7", y1: "254.5", x2: "1177.4", y2: "255.5" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1208.9", y1: "288.4", x2: "1215.7", y2: "293.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1159.3", y1: "305.7", x2: "1168.1", y2: "305.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1127.1", y1: "257.3", x2: "1140.3", y2: "263.5" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1108.4", y1: "276.4", x2: "1107.7", y2: "291" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1237.6", y1: "248.1", x2: "1226.8", y2: "250" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1244.5", y1: "207.8", x2: "1236.8", y2: "215.7" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1228.8", y1: "180.3", x2: "1217.8", y2: "180.7" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1198.9", y1: "222.3", x2: "1191.2", y2: "219.8" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1180.9", y1: "183.9", x2: "1182", y2: "175.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1148.3", y1: "161.5", x2: "1156.4", y2: "161.4" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1143", y1: "212", x2: "1149.6", y2: "207.4" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1115.4", y1: "186.4", x2: "1123", y2: "183.9" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1104.2", y1: "226", x2: "1112.3", y2: "225.6" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1068.7", y1: "224.1", x2: "1074.2", y2: "230.1" }
          }),
          _c("line", {
            staticClass: "st9",
            attrs: { x1: "1063.7", y1: "270.3", x2: "1071.5", y2: "268.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1079.2", y1: "52.2", x2: "1079.5", y2: "39.7" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1052.5", y1: "63.3", x2: "1045", y2: "53.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1031.9", y1: "80.7", x2: "1019.6", y2: "82.6" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1014.5", y1: "36.8", x2: "1016.4", y2: "49.1" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1049.6", y1: "9.3", x2: "1042.4", y2: "19.5" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "1081.8", y1: "5.4", x2: "1093.3", y2: "10" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "990", y1: "63.2", x2: "989.3", y2: "75.6" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "986.9", y1: "17", x2: "976.9", y2: "24.3" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "956.1", y1: "58.4", x2: "955.4", y2: "70.8" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "944.1", y1: "22.6", x2: "953.2", y2: "31.1" }
          }),
          _c("line", {
            staticClass: "st2",
            attrs: { x1: "922.4", y1: "20.8", x2: "919.2", y2: "32.8" }
          })
        ])
      ]
    ),
    _c("rect", {
      attrs: {
        x: "0",
        y: "0",
        width: "100%",
        height: "100%",
        fill: "url(#pattern)"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }