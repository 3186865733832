


































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import ForgotPassword from '@/components/Dialogs/ForgotPassword.vue';

@Component({ components: { ForgotPassword, SvgPopup } })
export default class ExpiredToken extends Vue {
  showDialog: boolean = false;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
