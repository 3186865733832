































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

import * as UserService from '@/services/userSpace/UserService';
import { isConnected } from '@/services/common/common';

@Component({
  components: {
    SvgPopup,
  },
})
export default class EmailConfirmation extends Vue {
  @Prop({ type: String, required: true })
  readonly confirmationToken!: string;

  @Prop({ type: Boolean, required: true })
  readonly fromAdmin!: boolean;

  errorCode: string | null = null;

  loading: boolean = false;

  registerActionPoints: number | null = null;

  async mounted() {
    this.loading = true;

    try {
      await UserService.confirmEmail(this.confirmationToken, process.env.VUE_APP_PLATFORM_UUID, this.fromAdmin);
    } catch (e) {
      const axiosResponse = (e as any).response;
      if (axiosResponse) {
        this.errorCode = axiosResponse.data.errorName;
      }

      console.error('Email confirmation failed with', e);
    }

    if (isConnected()) {
      await Promise.all([UserService.refreshUser(), UserService.refreshUserPoints()]).catch((e) => {
        console.error('Cannot refresh user data after email confirmation', e);
      });

      this.registerActionPoints = await UserService.getEmailValidationPoints().catch((e) => {
        console.error('Cannot get email validation points', e);
      });
    }

    this.loading = false;
  }

  get inscriptionPoints() {
    return this.registerActionPoints || 0;
  }

  get activatedService() {
    const forSignupPlatformService = this.platformServices.find(({ forSignup }) => forSignup);
    if (forSignupPlatformService) {
      const forSignupService = forSignupPlatformService.service;
      // check if user forSignup service is validated
      const validatedSignupService = this.currentUserServices.find(({ serviceId, validated }) => serviceId === forSignupService.id && validated);
      if (validatedSignupService) {
        return true;
      }
    }

    return false;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get platformServices() {
    return this.platform.platformService;
  }

  get currentUser() {
    return this.$store.getters['user/getUser'];
  }

  get currentUserServices() {
    return this.currentUser && this.currentUser.userServices;
  }

  get showStickers() {
    return !this.platform.style.hideStickers;
  }
}
