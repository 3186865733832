var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "points" }, [
    _c(
      "div",
      { staticClass: "points__first" },
      [
        _c("h1", { class: _vm.titleClass }, [_vm._v(_vm._s(_vm.pointsTitle))]),
        _c("h2", { staticClass: "points__text--subtitle" }, [
          _vm._v(_vm._s(_vm.pointsSubtitle))
        ]),
        _vm.isMobile
          ? _c(
              "div",
              { staticClass: "points__second" },
              [
                _vm.secondBlockImage
                  ? _c("img", {
                      class: _vm.radiusClass,
                      attrs: {
                        src:
                          _vm.srcImg === null
                            ? _vm.compSecondBlockImage
                            : _vm.srcImg
                      }
                    })
                  : _c("SvgHomePointsPicto2", {
                      staticClass: "points__second--img"
                    })
              ],
              1
            )
          : _vm._e(),
        _c("p", {
          staticClass: "points__text--description",
          domProps: { innerHTML: _vm._s(_vm.pointsDescription) }
        }),
        !_vm.noBtnBarem
          ? _c(
              "v-btn",
              {
                class: _vm.buttonClass,
                attrs: {
                  to: _vm.navigateToPointsScale,
                  color: "homeglobal_fond_bouton",
                  large: "",
                  rounded: ""
                }
              },
              [_vm._v(" " + _vm._s(_vm.btnBaremText) + " ")]
            )
          : _vm._e()
      ],
      1
    ),
    !_vm.isMobile
      ? _c(
          "div",
          { class: _vm.secondClass },
          [
            _vm.secondBlockImage
              ? _c("img", {
                  class: _vm.radiusClass,
                  attrs: {
                    src:
                      _vm.srcImg === null
                        ? _vm.compSecondBlockImage
                        : _vm.srcImg
                  }
                })
              : _c("SvgHomePointsPicto2", {
                  staticClass: "points__second--img"
                })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }