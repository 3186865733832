

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { sendSSOSyncEmail } from '@/services/userSpace/UserService';

@Component({})
export default class SSOPartnerErrorDialog extends Vue {
  @Prop({ type: String, required: false }) customMessage?: string;

  @Prop({ type: String, required: false }) titleMessage?: string;

  @Prop({ type: String, required: true }) userInfo!: any;

  onVisibilityChange(isVisible) {
    if (!isVisible) {
      this.$emit('close');
    }
  }

  async onSynchronize() {
    sendSSOSyncEmail(this.userInfo).then(() => {
      this.$emit('close');
    });
  }
}
