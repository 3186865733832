























import { Vue, Component, Prop } from 'vue-property-decorator';

const moment = require('moment');

moment.locale('fr');

@Component
export default class OfferNotAvailableCard extends Vue {

}
