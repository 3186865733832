var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1920",
        height: "39",
        viewBox: "0 0 1920 39"
      }
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path" } }, [
          _c("path", {
            staticStyle: { stroke: "#707070", fill: "#fff" },
            attrs: {
              id: "Rectangle_1218",
              "data-name": "Rectangle 1218",
              transform: "translate(0 4311)",
              d: "M0 0h1920v39H0z"
            }
          })
        ]),
        _c("clipPath", { attrs: { id: "clip-path-2" } }, [
          _c("path", {
            attrs: {
              id: "Rectangle_1219",
              "data-name": "Rectangle 1219",
              transform: "rotate(90 960 960)",
              d: "M0 0h72.449v1920H0z"
            }
          })
        ])
      ]),
      _c(
        "g",
        {
          staticStyle: { "clip-path": "url(#clip-path)" },
          attrs: {
            id: "Groupe_de_masques_2",
            "data-name": "Groupe de masques 2",
            transform: "translate(0 -4311)"
          }
        },
        [
          _c(
            "g",
            { attrs: { id: "Illu_footer", transform: "translate(0 4311)" } },
            [
              _c(
                "g",
                {
                  staticStyle: { "clip-path": "url(#clip-path-2)" },
                  attrs: { id: "Calque_14" }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Groupe_664",
                        "data-name": "Groupe 664",
                        transform: "translate(0 -386.876)"
                      }
                    },
                    [
                      _c("path", {
                        staticClass: "cls-4",
                        attrs: {
                          id: "accent_3_",
                          d:
                            "M1325.38 86.2c-68.37 37.393-151.011 54.292-255.225 54.292-208.761 0-378.026-92.584-378.026-206.742s169.265-206.742 378.026-206.742c98.572 0 188.183 20.494 255.225 54.292z",
                          transform: "translate(593.624 385.172)"
                        }
                      }),
                      _c("ellipse", {
                        staticClass: "cls-4",
                        attrs: {
                          id: "accent_2_",
                          cx: "261.2",
                          cy: "142.742",
                          rx: "261.2",
                          ry: "142.742",
                          transform: "translate(218.385 246.112)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-4",
                        attrs: {
                          id: "accent_1_",
                          d:
                            "M.357-224.754C78.684-188.26 127.8-133.069 127.8-70.687 127.8-8.844 78.684 46.347 1.685 82.841",
                          transform: "translate(.306 385.473)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-5",
                        attrs: {
                          id: "Tracé_2435",
                          "data-name": "Tracé 2435",
                          d: "M.357-112.062v188.4",
                          transform: "translate(.306 385.5)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2436",
                          "data-name": "Tracé 2436",
                          d:
                            "M32.373 147.163c-9.957 0-17.922-4.315-18.254-9.708s7.634-10.067 17.922-10.067l38.168-.539c9.957 0 18.586 4.135 18.586 9.708.332 5.393-7.634 10.067-17.922 10.067l-38.168.539z",
                          transform: "translate(12.101 387.668)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2437",
                          "data-name": "Tracé 2437",
                          d:
                            "M32.386 87.849c-9.625 0-17.59-4.135-18.254-9.348-.664-5.393 7.3-10.067 17.258-10.427l24.56-.719C65.907 67 74.536 71.31 75.2 76.7s-7.3 10.071-17.258 10.43l-24.56.719z",
                          transform: "translate(12.088 387.297)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2438",
                          "data-name": "Tracé 2438",
                          d:
                            "M64.07 26.1c-5.642 0-10.952-1.438-14.6-3.955L34.531 11.354C28.557 7.039 30.217.747 38.182-2.489S57.764-4.826 63.738-.512l14.935 10.787c5.974 4.315 4.315 10.607-3.651 13.843A30.671 30.671 0 0 1 64.07 26.1z",
                          transform: "translate(26.537 386.849)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-7",
                        attrs: {
                          id: "secondary_3_",
                          d:
                            "M312.795 311.833c-27.215-28.4-42.482-60.584-42.482-94.742 0-113.079 167.606-204.764 374.375-204.764s374.375 91.685 374.375 204.764c0 36.315-12.28 65.258-42.482 94.742",
                          transform: "translate(231.841 386.953)"
                        }
                      }),
                      _c("path", {
                        staticStyle: { fill: "none" },
                        attrs: {
                          id: "Ligne_145",
                          "data-name": "Ligne 145",
                          transform: "translate(548.287 700.584)",
                          d: "M663.454 0H0"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2440",
                          "data-name": "Tracé 2440",
                          d:
                            "M335.857-82.653c-150.679 0-273.148-67.056-273.148-149.393s122.469-149.393 273.148-149.393 273.148 67.056 273.148 149.393S486.537-82.653 335.857-82.653zm0-289.8c-141.386 0-256.553 62.921-256.553 140.4s115.167 140.4 256.553 140.4 256.553-62.921 256.553-140.4S477.244-372.45 335.857-372.45z",
                          transform: "translate(53.785 384.495)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2442",
                          "data-name": "Tracé 2442",
                          d:
                            "M1015.88 312.443h-16.595c0-27.865-19.914-53.573-56.422-72.27-35.844-18.517-84.633-28.584-136.74-28.584s-100.9 10.247-136.74 28.584c-36.508 18.7-56.422 44.4-56.422 72.27h-16.594c0-30.382 21.905-58.247 61.732-78.742 39.163-20.135 91.6-31.1 148.024-31.1s109.193 10.966 148.024 31.1c39.828 20.499 61.733 48.36 61.733 78.742z",
                          transform: "translate(511.491 388.141)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-7",
                        attrs: {
                          id: "secondary_2_",
                          d:
                            "M1197.916-382.69H881.291s-68.37 39.73-27.547 116.315c0 0 212.411-27.506 344.173 48.539z",
                          transform: "translate(721.088 384.488)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-7",
                        attrs: {
                          id: "secondary_1_",
                          d:
                            "M.664 312.782 0 197.546s179.554-105.888 398.935-7.011c94.921 46.921 80.982 122.247 80.982 122.247z",
                          transform: "translate(0 387.802)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-7",
                        attrs: {
                          id: "secondary",
                          d:
                            "m188.209-382.69-187.852.36v158.562s57.417 32 69.7 44.225c-.002-.001 226.017-65.079 118.152-203.147z",
                          transform: "translate(.306 384.488)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-4",
                        attrs: {
                          id: "accent",
                          d:
                            "M927.923-384.476c0 114.157-169.265 206.742-378.026 206.742s-378.026-92.585-378.026-206.742",
                          transform: "translate(147.41 384.476)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2443",
                          "data-name": "Tracé 2443",
                          d:
                            "M768.141 51.9a17.973 17.973 0 0 1-6.306-1.079L485.368-67.474c-4.315-1.8-4.978-5.213-1.66-7.551s9.625-2.7 13.94-.9L774.115 42.369c4.315 1.8 4.978 5.213 1.66 7.551-1.66 1.258-4.647 1.98-7.634 1.98z",
                          transform: "translate(413.066 386.395)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2444",
                          "data-name": "Tracé 2444",
                          d:
                            "M846.25 110.656a16.158 16.158 0 0 1-5.974-1.079L420.432-63.007c-4.315-1.8-5.31-5.213-1.991-7.551s9.625-2.876 13.939-1.079l419.844 172.585c4.315 1.8 5.31 5.213 1.992 7.551a14.044 14.044 0 0 1-7.966 2.157z",
                          transform: "translate(357.193 386.422)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2445",
                          "data-name": "Tracé 2445",
                          d:
                            "M869.143 159.109a17.973 17.973 0 0 1-6.306-1.079L379.6-49.251c-4.315-1.8-4.978-5.213-1.659-7.551s9.625-2.7 13.94-.9L875.449 149.4c4.314 1.8 4.978 5.213 1.66 7.551a14.043 14.043 0 0 1-7.966 2.158z",
                          transform: "translate(322.352 386.509)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2446",
                          "data-name": "Tracé 2446",
                          d:
                            "M879.47 199.958a16.159 16.159 0 0 1-5.974-1.079L335.829-26.918c-4.315-1.8-4.978-5.213-1.659-7.551s9.625-2.7 13.94-.9l537.666 225.8c4.314 1.8 4.978 5.213 1.659 7.551a15.585 15.585 0 0 1-7.965 1.976z",
                          transform: "translate(284.81 386.648)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2447",
                          "data-name": "Tracé 2447",
                          d:
                            "M875.105 229.618a16.158 16.158 0 0 1-5.974-1.079L312.879 2.561c-4.315-1.8-5.31-5.213-1.991-7.551s9.625-2.876 13.939-1.079L881.079 219.91c4.315 1.8 5.31 5.213 1.992 7.551a14.043 14.043 0 0 1-7.966 2.157z",
                          transform: "translate(264.947 386.832)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2448",
                          "data-name": "Tracé 2448",
                          d:
                            "M855.631 264.634a16.158 16.158 0 0 1-5.974-1.079L293.4 37.757c-4.315-1.8-5.31-5.213-1.991-7.551s9.625-2.876 13.939-1.079l556.252 225.8c4.314 1.8 5.31 5.213 1.991 7.551a14.042 14.042 0 0 1-7.96 2.156z",
                          transform: "translate(248.244 387.051)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2449",
                          "data-name": "Tracé 2449",
                          d:
                            "M792.729 286.492a16.158 16.158 0 0 1-5.974-1.079L276.968 78.492c-4.315-1.8-5.31-5.213-1.991-7.551s9.625-2.876 13.939-1.079L798.7 276.784c4.315 1.8 5.31 5.214 1.991 7.551a14.043 14.043 0 0 1-7.962 2.157z",
                          transform: "translate(234.147 387.306)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2450",
                          "data-name": "Tracé 2450",
                          d:
                            "M692.5 300.769a14.735 14.735 0 0 1-6.306-1.258L282.946 124.05c-4.315-1.8-4.978-5.213-1.659-7.551s9.625-2.7 13.94-.9l403.25 175.461c4.315 1.8 4.978 5.213 1.66 7.551a12.951 12.951 0 0 1-7.637 2.158z",
                          transform: "translate(239.453 387.591)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2451",
                          "data-name": "Tracé 2451",
                          d:
                            "M593.75 304.248a14.736 14.736 0 0 1-6.306-1.258L285.09 171.394c-4.315-1.8-4.978-5.214-1.659-7.551s9.625-2.7 13.939-.9l302.354 131.6c4.315 1.8 4.978 5.213 1.659 7.551a12.951 12.951 0 0 1-7.633 2.154z",
                          transform: "translate(241.292 387.886)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-7",
                        attrs: {
                          id: "Tracé_2452",
                          "data-name": "Tracé 2452",
                          d:
                            "M1073.59 192.774V312.5h-86.624s-6.638-74.6 86.624-119.726z",
                          transform: "translate(846.41 388.08)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2453",
                          "data-name": "Tracé 2453",
                          d:
                            "M804.06 172.927a29.981 29.981 0 0 1-5.642-.539l-36.508-6.472c-9.625-1.618-14.935-7.371-11.616-12.4 2.987-5.214 13.276-8.09 22.9-6.292l36.508 6.472c9.625 1.618 14.935 7.371 11.616 12.4-2.318 4.134-9.624 6.831-17.258 6.831z",
                          transform: "translate(642.661 387.792)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2454",
                          "data-name": "Tracé 2454",
                          d:
                            "M841.378 184.409c-8.629 0-17.258-2.7-21.241-7.371-5.31-6.292-.332-13.843 11.284-16.719l34.517-8.629c11.616-2.876 25.556-.18 30.866 6.112s.332 13.843-11.284 16.719L851 183.15a33.779 33.779 0 0 1-9.622 1.259z",
                          transform: "translate(701.592 387.816)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2455",
                          "data-name": "Tracé 2455",
                          d:
                            "M853.539 247.186a49.9 49.9 0 0 1-7.3-.539c-12.28-2.157-18.918-9.348-14.935-15.82l11.948-19.775c3.983-6.652 17.258-10.247 29.206-8.09 12.28 2.157 18.918 9.348 14.935 15.82l-11.948 19.775c-2.988 5.214-12.281 8.629-21.906 8.629z",
                          transform: "translate(711.999 388.139)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2456",
                          "data-name": "Tracé 2456",
                          d:
                            "M941.373 252.981a29.532 29.532 0 0 1-3.983-.18l-37.5-3.416c-12.612-1.079-21.241-7.73-18.918-14.562 1.991-6.831 14.271-11.506 26.883-10.247l37.5 3.416c12.612 1.079 21.241 7.73 18.918 14.562-1.991 6.113-11.616 10.427-22.9 10.427z",
                          transform: "translate(755.263 388.277)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2457",
                          "data-name": "Tracé 2457",
                          d:
                            "M960.95 202.85c-6.306 0-12.612-1.438-16.927-3.955l-25.887-15.1c-8.629-5.034-8.3-13.124 1.328-17.8 9.293-4.674 24.228-4.494 32.857.719l25.888 15.1c8.629 5.034 8.3 13.124-1.328 17.8a38.507 38.507 0 0 1-15.931 3.236z",
                          transform: "translate(782.151 387.892)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2458",
                          "data-name": "Tracé 2458",
                          d:
                            "M994.05 181.046c-6.306 0-12.612-1.438-17.259-4.135-8.629-5.213-7.633-13.124 1.66-17.8l28.543-13.843c9.625-4.674 24.228-4.135 32.857.9 8.629 5.214 7.634 13.124-1.659 17.8l-28.543 13.843a36.963 36.963 0 0 1-15.599 3.235z",
                          transform: "translate(832.688 387.763)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2459",
                          "data-name": "Tracé 2459",
                          d:
                            "M1033.774 226.525h-1.659l-27.879-1.079c-12.944-.539-22.569-6.472-21.573-13.483s11.948-12.225 24.892-11.685l27.879 1.079c12.944.539 22.569 6.472 21.573 13.483-.996 6.652-11.284 11.685-23.233 11.685z",
                          transform: "translate(842.748 388.126)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2460",
                          "data-name": "Tracé 2460",
                          d:
                            "M940.493 58.411a16.669 16.669 0 0 1-2.987-.18l-26.551-1.8c-12.612-.9-21.905-7.191-20.246-14.022s13.276-11.863 25.891-10.964l26.551 1.8c12.612.9 21.9 7.191 20.245 14.022-1.33 6.47-11.287 11.144-22.903 11.144z",
                          transform: "translate(763.777 387.072)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2461",
                          "data-name": "Tracé 2461",
                          d:
                            "M1001.287 122.6a37.679 37.679 0 0 1-14.935-2.876l-20.909-9.348c-9.957-4.494-11.284-12.4-2.987-17.8s22.9-6.112 32.857-1.618l20.909 9.348c9.957 4.494 11.284 12.4 2.987 17.8-4.647 2.873-11.284 4.494-17.922 4.494z",
                          transform: "translate(820.805 387.426)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2462",
                          "data-name": "Tracé 2462",
                          d:
                            "M925.2 142.55h-.664l-26.883-.36c-12.944-.18-22.9-5.933-22.569-12.944s10.952-12.4 23.9-12.225l26.883.36c12.944.18 22.9 5.933 22.569 12.944-.336 6.832-10.628 12.225-23.236 12.225z",
                          transform: "translate(750.53 387.607)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2463",
                          "data-name": "Tracé 2463",
                          d:
                            "M885.562 71.864a37.812 37.812 0 0 1-9.957-1.258l-40.491-10.428c-11.614-3.056-16.594-10.427-10.952-16.719s19.25-8.989 30.866-5.933l40.491 10.427c11.616 3.056 16.595 10.427 10.952 16.719-3.982 4.495-12.28 7.192-20.909 7.192z",
                          transform: "translate(704.868 387.103)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2464",
                          "data-name": "Tracé 2464",
                          d:
                            "M813.1 117.688h-1c-12.944-.36-22.9-6.112-22.237-13.124l1.991-24.449c.664-7.011 11.284-12.4 24.228-12.045s22.9 6.112 22.237 13.124l-1.991 24.449c-.656 6.831-10.945 12.045-23.228 12.045z",
                          transform: "translate(677.428 387.301)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2465",
                          "data-name": "Tracé 2465",
                          d:
                            "M1009.815 49.308c-10.953 0-20.909-4.315-22.9-10.427-2.323-6.831 6.306-13.3 18.918-14.562l33.521-3.236c12.612-1.258 24.56 3.416 26.883 10.247s-6.306 13.3-18.918 14.562l-33.519 3.236a29.533 29.533 0 0 1-3.985.18z",
                          transform: "translate(846.13 387.007)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2466",
                          "data-name": "Tracé 2466",
                          d:
                            "M1026.428-7.981c-5.974 0-11.948-1.258-16.263-3.6-9.293-4.854-9.293-12.764-.332-17.8l23.9-13.124c8.961-5.034 23.564-5.034 32.858-.18s9.292 12.764.332 17.8l-23.9 13.124a35.6 35.6 0 0 1-16.595 3.78z",
                          transform: "translate(860.383 386.587)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2467",
                          "data-name": "Tracé 2467",
                          d:
                            "M994.968-66.481c-12.612 0-22.9-5.393-23.232-12.225-.332-7.011 9.625-12.764 22.569-12.944l33.853-.539h.664c12.612 0 22.9 5.393 23.232 12.225.332 7.011-9.625 12.764-22.568 12.944l-33.853.539z",
                          transform: "translate(833.429 386.301)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2468",
                          "data-name": "Tracé 2468",
                          d:
                            "M974.652 3.04a53.473 53.473 0 0 1-7.3-.539l-23.566-4.135c-12.28-2.157-18.918-9.169-14.935-15.82s16.926-10.247 29.206-8.09l23.564 4.135c12.28 2.157 18.918 9.169 14.935 15.82-2.986 5.213-11.947 8.629-21.904 8.629z",
                          transform: "translate(795.664 386.713)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2469",
                          "data-name": "Tracé 2469",
                          d:
                            "M933.98-61.041a16.669 16.669 0 0 1-2.987-.18c-12.612-.9-21.573-7.191-19.914-14.2l3.319-13.3c1.659-6.831 13.276-11.685 26.22-10.787 12.612.9 21.573 7.191 19.913 14.2l-3.319 13.3c-1.66 6.473-11.612 10.967-23.232 10.967z",
                          transform: "translate(781.242 386.254)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2470",
                          "data-name": "Tracé 2470",
                          d:
                            "M880.09-98.463c-12.612 0-22.9-5.573-23.233-12.4-.332-7.011 9.957-12.584 22.9-12.764l24.892-.18h.332c12.612 0 22.9 5.573 23.232 12.4.332 7.011-9.957 12.584-22.9 12.764l-25.224.18z",
                          transform: "translate(734.901 386.103)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2471",
                          "data-name": "Tracé 2471",
                          d:
                            "M871.4-14.089c-7.3 0-14.6-1.978-19.25-5.393-7.3-5.753-4.646-13.483 5.974-17.438l20.577-7.73c10.621-3.955 24.892-2.517 32.194 3.236s4.647 13.483-5.974 17.438l-20.578 7.73a36.507 36.507 0 0 1-12.943 2.157z",
                          transform: "translate(727.333 386.584)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2472",
                          "data-name": "Tracé 2472",
                          d:
                            "M825.531-56.809c-9.625 0-18.586-3.236-21.9-8.629-3.983-6.652 2.323-13.663 14.6-16l23.564-4.315c12.28-2.157 25.224 1.258 29.539 7.91 3.983 6.652-2.323 13.663-14.6 16l-23.564 4.315a38.784 38.784 0 0 1-7.639.719z",
                          transform: "translate(688.232 386.337)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2473",
                          "data-name": "Tracé 2473",
                          d:
                            "M807.207 9.272c-12.28 0-22.569-5.213-23.233-11.865-.664-7.011 9.293-12.944 21.9-13.3l24.892-.719c12.944-.36 23.9 5.034 24.56 11.865.664 7.011-9.293 12.944-21.9 13.3l-24.892.719z",
                          transform: "translate(672.371 386.773)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2474",
                          "data-name": "Tracé 2474",
                          d:
                            "M765.289 15.939c-6.306 0-12.612-1.438-16.927-3.955L731.436 2.1c-8.629-5.034-8.3-13.124 1.328-17.8 9.293-4.674 24.228-4.494 32.857.719l16.926 9.888c8.629 5.034 8.3 13.124-1.328 17.8a38.5 38.5 0 0 1-15.93 3.232z",
                          transform: "translate(622.023 386.757)"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-6",
                        attrs: {
                          id: "Tracé_2475",
                          "data-name": "Tracé 2475",
                          d:
                            "M740.248 83.254c-10.289 0-19.914-3.775-22.569-9.348-3.319-6.652 4.315-13.663 16.595-15.281l24.226-3.416c12.28-1.8 25.224 2.337 28.211 8.989 3.319 6.652-4.315 13.663-16.595 15.281l-24.226 3.415a53.078 53.078 0 0 1-5.642.36z",
                          transform: "translate(614.87 387.218)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2476",
                          "data-name": "Tracé 2476",
                          d:
                            "M763.624-283.46h-.332c-9.957-.18-17.922-4.674-17.922-10.067l1-20.854c.332-5.393 8.629-9.708 18.586-9.708 9.957.18 17.922 4.674 17.922 10.067l-1 20.854c-.332 5.393-8.297 9.708-18.254 9.708z",
                          transform: "translate(639.287 384.853)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2477",
                          "data-name": "Tracé 2477",
                          d:
                            "M730.04-265.084c-5.642 0-10.953-1.438-14.6-3.955l-22.9-16.719c-5.974-4.315-4.315-10.607 3.651-13.843s19.582-2.337 25.556 1.978l22.9 16.719c5.974 4.315 4.314 10.607-3.651 13.843a32.068 32.068 0 0 1-10.956 1.977z",
                          transform: "translate(590.893 384.994)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2478",
                          "data-name": "Tracé 2478",
                          d:
                            "M664.963-232.83c-8.961 0-16.595-3.416-17.922-8.27-1.659-5.393 5.31-10.427 15.267-11.326l37.836-3.236c9.957-.9 19.25 2.876 20.909 8.27s-5.31 10.427-15.267 11.326l-37.836 3.236z",
                          transform: "translate(554.743 385.28)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2479",
                          "data-name": "Tracé 2479",
                          d:
                            "M662.54-288.641c-8.961 0-16.595-3.416-17.922-8.449l-5.974-20.674c-1.659-5.393 5.31-10.427 15.267-11.326s19.25 2.876 20.909 8.27l5.974 20.674c1.659 5.393-5.31 10.427-15.267 11.326a16.667 16.667 0 0 1-2.987.179z",
                          transform: "translate(547.541 384.821)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2480",
                          "data-name": "Tracé 2480",
                          d:
                            "M702.824-337.974a27.087 27.087 0 0 1-10.289-1.8c-8.3-3.056-10.289-9.348-4.646-13.843l21.905-17.079c5.642-4.494 17.258-5.573 25.556-2.517s10.289 9.348 4.646 13.843l-21.9 17.079c-3.988 2.879-9.63 4.317-15.272 4.317z",
                          transform: "translate(587.244 384.536)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2481",
                          "data-name": "Tracé 2481",
                          d:
                            "M803.055-354.114a26.066 26.066 0 0 1-6.638-.719l-35.181-7.551c-9.293-1.978-13.939-7.73-10.289-12.764s14.271-7.551 23.564-5.573l35.181 7.551c9.293 1.978 13.939 7.73 10.289 12.764-2.655 3.955-9.624 6.292-16.926 6.292z",
                          transform: "translate(643.002 384.496)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2482",
                          "data-name": "Tracé 2482",
                          d:
                            "M614.983-244.333h-1c-9.957-.36-17.59-5.034-17.258-10.427l2.323-20.854c.664-5.393 9.293-9.528 19.25-9.348 9.957.36 17.59 5.034 17.258 10.427l-2.323 20.854c-.659 5.392-8.625 9.348-18.25 9.348z",
                          transform: "translate(511.793 385.098)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2483",
                          "data-name": "Tracé 2483",
                          d:
                            "M594.395-330.143c-5.642 0-11.284-1.438-14.6-4.135-5.974-4.494-4.315-10.607 3.983-13.843l30.866-12.225c8.3-3.236 19.582-2.337 25.556 2.157s4.314 10.607-3.983 13.843l-30.866 12.225a28.281 28.281 0 0 1-10.956 1.978z",
                          transform: "translate(494.214 384.615)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2484",
                          "data-name": "Tracé 2484",
                          d:
                            "M558.9-252.373h-1c-9.957-.36-17.922-4.854-17.258-10.427l1.991-20.854c.664-5.393 8.961-9.708 19.25-9.348 9.957.36 17.922 4.854 17.258 10.427l-1.991 20.854c-.662 5.213-8.628 9.348-18.25 9.348z",
                          transform: "translate(463.665 385.047)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2485",
                          "data-name": "Tracé 2485",
                          d:
                            "M568.2-318.7a28.534 28.534 0 0 1-12.612-2.7l-27.879-14.382c-7.3-3.775-7.633-10.067-.664-14.022s18.586-4.135 25.888-.36l27.879 14.382c7.3 3.775 7.633 10.067.664 14.022a28.163 28.163 0 0 1-13.276 3.06z",
                          transform: "translate(447.724 384.674)"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          id: "Tracé_2486",
                          "data-name": "Tracé 2486",
                          d:
                            "M499.192-315.8a40.414 40.414 0 0 1-4.646-.36c-9.625-1.438-15.6-6.831-12.944-12.045l9.625-20.135c2.655-5.213 12.612-8.449 22.237-7.011s15.6 6.831 12.944 12.045l-9.625 20.135c-1.992 4.492-9.625 7.371-17.591 7.371z",
                          transform: "translate(412.517 384.656)"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }