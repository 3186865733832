var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatar", class: _vm.size }, [
    _c(
      "div",
      { staticClass: "avatar-photo" },
      [
        _c(
          "div",
          { staticClass: "avatar-photo__background" },
          [_vm.compShowStickers ? _c("SvgAvatar") : _vm._e()],
          1
        ),
        _vm.price
          ? _c(
              "v-avatar",
              {
                attrs: {
                  size:
                    _vm.size === "large"
                      ? "200px"
                      : _vm.size === "medium"
                      ? "140px"
                      : "88px",
                  color: "homebloc_fond_bouton"
                }
              },
              [_vm._v(" " + _vm._s(_vm.price) + " ")]
            )
          : _vm._e(),
        _vm.initials
          ? _c(
              "v-avatar",
              {
                attrs: {
                  size:
                    _vm.size === "large"
                      ? "200px"
                      : _vm.size === "medium"
                      ? "140px"
                      : "88px",
                  color: "homebloc_fond_bouton"
                }
              },
              [_vm._v(" " + _vm._s(_vm.initials) + " ")]
            )
          : _vm._e(),
        !_vm.price && !_vm.initials
          ? _c(
              "v-avatar",
              {
                attrs: {
                  size:
                    _vm.size === "large"
                      ? "200px"
                      : _vm.size === "medium"
                      ? "140px"
                      : "88px",
                  color: "homebloc_fond_bouton"
                }
              },
              [
                _c("v-icon", {
                  domProps: { textContent: _vm._s("fas fa-user-circle") }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }