var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-question", class: { "is-mobile": _vm.isMobile } },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "dashboard-question__content" },
        [
          !_vm.isMobile
            ? _c("p", { staticClass: "dashboard-question__content-text" }, [
                _vm._v(
                  " En répondant à la question de la semaine vous gagnez des points supplémentaires dans votre compte point. "
                )
              ])
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "dashboard-question__content-btn",
              attrs: { color: "homeglobal_fond_bouton" },
              on: { click: _vm.openQuestion }
            },
            [_vm._v(" Répondre à la question ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-question__header" }, [
      _c("h3", { staticClass: "dashboard-question__header-title" }, [
        _vm._v("Question de la semaine")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }