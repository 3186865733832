export default {
  state: {
    loginVisible: false,
    ssoSignUpVisible: false,
    logoutMessageVisible: false,
    askSSOSynchroVisible: false,
    confirmSSOSynchroVisible: false,
  },

  getters: {
    loginVisible(state: any) {
      return state.loginVisible;
    },
    ssoSignUpVisible(state: any) {
      return state.ssoSignUpVisible;
    },
    logoutMessageVisible(state: any) {
      return state.logoutMessageVisible;
    },
    askSSOSynchroVisible(state: any) {
      return state.askSSOSynchroVisible;
    },
    confirmSSOSynchroVisible(state: any) {
      return state.confirmSSOSynchroVisible;
    },
    autoSynchroError(state: any) {
      return state.autoSynchroError;
    },
  },

  mutations: {
    SHOW_LOGIN(state: any, show: boolean) {
      state.loginVisible = show;
    },
    SHOW_SSO_SIGNUP(state: any, show: boolean) {
      state.ssoSignUpVisible = show;
    },
    SHOW_LOGOUT_MESSAGE(state: any, show: boolean) {
      state.logoutMessageVisible = show;
    },
    SHOW_ASK_SSO_SYNCHRO_MESSAGE(state: any, show: boolean) {
      state.askSSOSynchroVisible = show;
    },
    SHOW_CONFIRM_SSO_SYNCHRO_MESSAGE(state: any, show: boolean) {
      state.confirmSSOSynchroVisible = show;
    },
    AUTO_SYNCHRO_ERROR(state: any, status: boolean) {
      state.autoSynchroError = status;
    },
  },
  actions: {
    showLogin({ commit }: any, show: boolean) {
      commit('SHOW_LOGIN', show);
    },
    showSSOSignUp({ commit }: any, show: boolean) {
      commit('SHOW_SSO_SIGNUP', show);
    },
    showLogoutMessage({ commit }: any, show: boolean) {
      commit('SHOW_LOGOUT_MESSAGE', show);
    },
    showAskSSOSynchroMessage({ commit }: any, show: boolean) {
      commit('SHOW_ASK_SSO_SYNCHRO_MESSAGE', show);
    },
    showConfirmSSOSynchroMessage({ commit }: any, show: boolean) {
      commit('SHOW_CONFIRM_SSO_SYNCHRO_MESSAGE', show);
    },
    autoSynchroError({ commit }: any, status: boolean) {
      commit('AUTO_SYNCHRO_ERROR', status);
    },
  },
};
