var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Picto_file",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.size,
        height: _vm.size,
        viewBox: "0 0 34 34"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Tracé_3703",
          "data-name": "Tracé 3703",
          d: "M0,0H34V34H0Z",
          fill: "none"
        }
      }),
      _c("path", {
        staticClass: "st0",
        attrs: {
          id: "Tracé_3704",
          "data-name": "Tracé 3704",
          d: "M14,3V8.69a1.423,1.423,0,0,0,1.423,1.423h5.69",
          transform: "translate(5.812 1.268)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2"
        }
      }),
      _c("path", {
        staticClass: "st0",
        attrs: {
          id: "Tracé_3705",
          "data-name": "Tracé 3705",
          d:
            "M21.95,28.465H7.825A2.827,2.827,0,0,1,5,25.635V5.829A2.827,2.827,0,0,1,7.825,3h9.887l7.062,7.074V25.635A2.827,2.827,0,0,1,21.95,28.465Z",
          transform: "translate(2.113 1.268)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2"
        }
      }),
      _c("path", {
        staticClass: "st0",
        attrs: {
          id: "Tracé_3706",
          "data-name": "Tracé 3706",
          d: "M12,19.535V11",
          transform: "translate(5 4.562)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2"
        }
      }),
      _c("path", {
        staticClass: "st0",
        attrs: {
          id: "Tracé_3707",
          "data-name": "Tracé 3707",
          d: "M9.5,14.5l3.556,3.556L16.613,14.5",
          transform: "translate(3.944 6.032)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }