var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column tab" }, [
    _c(
      "div",
      {
        staticClass:
          "align-center text-capitalize text-center px-2 px-md-6 tab-header"
      },
      _vm._l(_vm.headers, function(item, $index) {
        return _c("span", { key: $index, style: _vm.compStyle(item.width) }, [
          _vm._v(_vm._s(item.name))
        ])
      }),
      0
    ),
    _c("div", { staticClass: "tab-content" }, [_vm._t("content")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }