var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "orders-number",
      attrs: { "data-cy": "luck-offer-orders-number" }
    },
    [
      _c("v-icon", { staticClass: "icon" }, [_vm._v("fas fa-users")]),
      _vm._v(" " + _vm._s(_vm.ordersNumber) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }