import Vue from 'vue';
import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';
import store from '@/store';
import { isConnected, logout } from '@/services/common/common';

// TODO: move in src/api

Vue.prototype.$axios = axios;
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}

export enum ErrorMessages {
  USER_NOT_CONNECTED = 'User not connected',
  ACCESS_TOKEN_REVOKED = 'Access token revoked',
  CONFIRMATION_TOKEN_NOT_FOUND = 'This token is not found',
}

const UNAUTHORIZED_CODE = 401;
const FORBIDDEN_CODE = 403;

export function isDevEnv() {
  const devLocation = /integ|preprod|localhost/;
  return devLocation.test(window.location.hostname);
}

export const apiPublicInstance = () => {
  const axiosInstance = axios.create({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message && error.message === 'Network Error') {
        error.response = {
          status: 404,
          message: 'API Not Online',
          config: { headers: { ignoreToast: false } },
        };
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};

export const apiAuthenticatedInstance = () => {
  if (!isConnected()) {
    throw new Error(ErrorMessages.USER_NOT_CONNECTED);
  }

  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${store.getters['user/getAccessToken']}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;

  /**
   * Inteceptor to handle refresh of the JWT token
   */
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message && error.message === 'Network Error') {
        error.response = {
          status: 404,
          message: 'API Not Online',
          config: { headers: { ignoreToast: false } },
        };
        return Promise.reject(error);
      }
      const errorResponse = error.response;
      if (isTokenRevokedError(errorResponse)) {
        // la gestion d'erreur devrait être géré au niveau de l'appli.
        // Le logout étant non bloquant, l'appli va continuer à traiter les erreurs
        // voir générer des requêtes dans le vent le temps d'un instant.
        logout();
        return Promise.reject(error);
      }

      if (isRefreshTokenExpiredError(errorResponse)) {
        store.dispatch('user/resetTokens');
        error.response.config.headers.ignoreToast = true;
        return Promise.reject(error);
      }

      if (isTokenExpiredError(errorResponse)) {
        return refreshTokenAndReattemptRequest(error);
      }

      // If the error is due to other reasons, we just throw it back to axios
      return Promise.reject(error);
    },
  );

  /**
   * Interceptor to display error message on server errors
   */
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // handle error
      if (error.response && !error.response.config.headers.ignoreToast) {
        store.dispatch('user/showError', error.response.message);
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );

  let isAlreadyFetchingAccessToken = false;

  // This is the list of waiting requests that will retry after the JWT refresh complete
  let subscribers: Array<any> = [];

  async function refreshTokenAndReattemptRequest(error: any) {
    try {
      const { response: errorResponse } = error;
      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        return Promise.reject(error);
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axiosInstance(errorResponse.config));
        });
      });

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        const response = await axiosInstance.post('/api/auth/refreshToken', { refreshToken });
        const tokens = response.data;
        saveRefreshToken(tokens);
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(tokens.accessToken);
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function onAccessTokenFetched(accessToken: string) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
  }

  function addSubscriber(callback: any) {
    subscribers.push(callback);
  }

  function getRefreshToken(): string | null {
    return store.getters['user/getRefreshToken'];
  }

  function saveRefreshToken(tokens: any) {
    store.dispatch('user/login', { tokens });
  }

  return axiosInstance;
};

function isTokenRevokedError(errorResponse: any): boolean {
  if (!errorResponse) {
    return false;
  }

  return errorResponse.status === UNAUTHORIZED_CODE && errorResponse.data.message === ErrorMessages.ACCESS_TOKEN_REVOKED;
}

function isTokenExpiredError(errorResponse: any): boolean {
  return errorResponse ? errorResponse.status === UNAUTHORIZED_CODE : false;
}

function isRefreshTokenExpiredError(errorResponse: any): boolean {
  if (!errorResponse) {
    return false;
  }

  return errorResponse.status === FORBIDDEN_CODE && errorResponse.data.type === 'refreshTokenExpired';
}

export const getEoiToken = async (username: string, password: string): Promise<string> => {
  const creds = JSON.stringify({ username, password });
  const config: AxiosRequestConfig = {
    method: 'post',
    url: `${process.env.VUE_APP_IREBY_EOI}api/user/auth`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: creds,
  };

  let res;
  await axios(config)
    .then((response) => {
      res = response.data.accessToken;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });

  return res;
};

export const eoiAuthenticatedInstance = (jwt: string) => {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.defaults.baseURL = process.env.VUE_APP_IREBY_EOI;
  return axiosInstance;
};
