



















































import { Vue, Component } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import ContactDialog from '@/components/Dialogs/ContactDialog.vue';
import * as common from '@/services/common/common';
import { getPlatformFAQ } from '@/api/resources/platformApi';

@Component({
  components: {
    PageTitle,
    ContactDialog,
  },
})
export default class Help extends Vue {
  search = '';

  platformFAQ: Array<any> = [];

  faq: Array<any> = [];

  faqCopy = common.deepCopy(this.faq);

  faqIsEmpty = true;

  faqBase = [
    { panel: [], subject: 'Mon compte', questions: [] },
    { panel: [], subject: 'Mes points', questions: [] },
    { panel: [], subject: 'Mes commandes & la boutique', questions: [] },
  ];

  async mounted() {
    this.platformFAQ = await getPlatformFAQ();
    this.faq = this.platformFAQ.reduce((acc, curr) => {
      if (curr.title && curr.description) {
        this.faqIsEmpty = false;
        acc[curr.categoryId].questions.push({ question: curr.title, answer: curr.description, index: curr.index });
        acc[curr.categoryId].questions.sort(this.sortAscendingOrder);
      }
      return acc;
    }, this.faqBase);
    this.faqCopy = common.deepCopy(this.faq);
  }

  sortAscendingOrder = (a, b) => a.index - b.index;

  searchInFAQ(pattern) {
    this.closePanelsAndRemoveHighlight();
    this.openPanelsWithPattern(pattern);
  }

  openPanelsWithPattern(pattern) {
    if (pattern) {
      this.faq.forEach((subject, i) => {
        const regex = new RegExp(pattern, 'i');
        subject.questions.forEach((question, index) => {
          if (regex.test(`${question.answer}`)) {
            this.faq[i].questions[index].answer = question.answer.replace(pattern, `<span class="highlight">${pattern}</span>`);
            this.faq[i].panel.push(index);
          }
        });
      });
    }
  }

  closePanelsAndRemoveHighlight() {
    this.faq = common.deepCopy(this.faqCopy);
  }
}
