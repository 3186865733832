











import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import BasicDialog from '@/components/Dialogs/BasicDialog.vue';

import * as UserService from '../../services/userSpace/UserService';

@Component({
  components: {
    SvgPopup,
    BasicDialog
  },
})
export default class ConfirmPassword extends Vue {

  title = 'Félicitations !';

  content = 'Votre mot de passe a été modifié avec succès.';

  buttonText= 'Retour profil';

  @Emit('input')
  onClose(): void {};


  goToProfile(): void {
    this.$router.push('/dashboard/profile');
    this.$emit('input');
  }
}
