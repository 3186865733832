var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cgu
    ? _c("div", { staticClass: "user-route" }, [
        _c(
          "div",
          { staticClass: "user-route__block" },
          [
            _vm.isMobile
              ? _c("div", { staticClass: "user-route__title" }, [
                  _vm._v(
                    " " + _vm._s(_vm._f("removeBreakTag")(_vm.title)) + " "
                  )
                ])
              : _vm._e(),
            _c("PageTitle", { attrs: { text: _vm.title } }),
            [
              _c("div", { staticClass: "user-route__update" }, [
                _vm._v("Mis à jour le " + _vm._s(_vm.dateFormat(_vm.key)))
              ]),
              _c("div", {
                staticClass: "user-route__content",
                domProps: { innerHTML: _vm._s(_vm.userRoute) }
              })
            ]
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }