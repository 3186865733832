var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.isMobile,
        transition: _vm.isMobile ? "dialog-bottom-transition" : "",
        "hide-overlay": _vm.isMobile,
        "content-class": "question",
        "max-width": "600px"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _vm.error
        ? _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "question-card-title" }, [
                _c(
                  "div",
                  { staticClass: "question-card-title-picto" },
                  [
                    _vm.compShowStickers
                      ? _c("SvgPopup", {
                          attrs: {
                            width: _vm.isMobile ? 60 : 100,
                            height: "100"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("h1", { staticClass: "question-card-title-text" }, [
                  _vm._v(" Question de "),
                  _c("br"),
                  _vm._v(" la semaine ")
                ])
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "question-card-subtitle center error--text" },
                  [_vm._v(_vm._s(_vm.error))]
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "question-card-button",
                  attrs: { large: "", rounded: "", color: "popup_fond_bouton" },
                  on: { click: _vm.onCancelClick }
                },
                [_vm._v(" Retour tableau de bord ")]
              ),
              _c("div", { staticClass: "question-card-bottom" })
            ],
            1
          )
        : _vm.stepper === 1
        ? _c(
            "v-card",
            { attrs: { "content-class": "question-card" } },
            [
              _c("v-card-title", { staticClass: "question-card-title" }, [
                _c(
                  "div",
                  { staticClass: "question-card-title-picto" },
                  [
                    _vm.compShowStickers
                      ? _c("SvgPopup", {
                          attrs: {
                            width: _vm.isMobile ? 60 : 100,
                            height: "100"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("h1", { staticClass: "question-card-title-text" }, [
                  _vm._v(" Question de "),
                  _c("br"),
                  _vm._v(" la semaine ")
                ])
              ]),
              _c("div", { staticClass: "question-card-subtitle" }, [
                _vm._v(_vm._s(_vm.question.name))
              ]),
              _vm.question.questionTypeId === 1
                ? _c(
                    "div",
                    { staticClass: "question-card-radios" },
                    _vm._l(_vm.sortedAnswers, function(answer) {
                      return _c(
                        "div",
                        { key: answer.id, staticClass: "question-card-item" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              model: {
                                value: _vm.choice,
                                callback: function($$v) {
                                  _vm.choice = $$v
                                },
                                expression: "choice"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { color: "actif", value: answer },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "question-card-label"
                                            },
                                            [_vm._v(_vm._s(answer.name))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.question.questionTypeId === 2
                ? _c("v-text-field", {
                    ref: "form",
                    staticClass: "question-card-textfield",
                    attrs: {
                      "single-line": "",
                      rules: _vm.openChoiceInputRule,
                      label: "Renseignez votre réponse ici",
                      required: ""
                    },
                    model: {
                      value: _vm.openChoice,
                      callback: function($$v) {
                        _vm.openChoice = $$v
                      },
                      expression: "openChoice"
                    }
                  })
                : _vm._e(),
              _vm.isMobile
                ? _c(
                    "v-btn",
                    {
                      staticClass: "question-card-button",
                      attrs: {
                        large: "",
                        rounded: "",
                        color: "popup_fond_bouton"
                      },
                      on: { click: _vm.onCancelClick }
                    },
                    [_vm._v(" Annuler ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "question-card-button",
                  attrs: {
                    disabled: _vm.isConfirmDisabled,
                    large: "",
                    rounded: "",
                    color: "popup_fond_bouton"
                  },
                  on: { click: _vm.onConfirmClick }
                },
                [_vm._v(" Valider ")]
              ),
              _c("div", { staticClass: "question-card-bottom" })
            ],
            1
          )
        : _vm.stepper === 2
        ? _c(
            "v-card",
            { attrs: { "content-class": "question-card" } },
            [
              _c(
                "v-card-title",
                { staticClass: "question-card-title" },
                [
                  _c(
                    "div",
                    { staticClass: "question-card-title-picto" },
                    [
                      _vm.compShowStickers
                        ? _c("SvgPopup", {
                            attrs: {
                              width: _vm.isMobile ? 60 : 100,
                              height: "100"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("h1", { staticClass: "question-card-title-text" }, [
                    _vm._v("Félicitations")
                  ]),
                  _c("v-icon", {
                    staticClass: "question-card-close",
                    attrs: { medium: "" },
                    domProps: { textContent: _vm._s("fas fa-times") },
                    on: { click: _vm.onCancelClick }
                  })
                ],
                1
              ),
              _vm.isMobile
                ? _c("div", { staticClass: "question-card-subtitle center" }, [
                    _vm._v(" En répondant à cette question ")
                  ])
                : _vm._e(),
              !_vm.isMobile
                ? _c("div", { staticClass: "question-card-subtitle center" }, [
                    _vm._v(
                      " En répondant à cette question vous venez de gagner " +
                        _vm._s(_vm.points) +
                        " points. "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "question-card-points" }, [
                _vm._v("+" + _vm._s(_vm.points) + " points")
              ]),
              _c("div", { staticClass: "question-card-question" }, [
                _vm._v(_vm._s(_vm.question.name))
              ]),
              _c("div", { staticClass: "question-card-answer" }, [
                _vm._v("Votre réponse :")
              ]),
              _c("div", { staticClass: "question-card-answer-result" }, [
                _vm._v(_vm._s(_vm.answer.name))
              ]),
              _vm.isMobile
                ? _c(
                    "v-btn",
                    {
                      staticClass: "question-card-button",
                      attrs: {
                        large: "",
                        rounded: "",
                        color: "popup_fond_bouton"
                      },
                      on: { click: _vm.onCancelClick }
                    },
                    [_vm._v(" Retour tableau de bord ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "question-card-bottom" })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }