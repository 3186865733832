





































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoOrders from '@/components/Account/NoOrders.vue';
import OrderLineWeb from '@/components/Account/OrderLineWeb.vue';
import Datatable from '@/components/Datatable.vue';

import * as OrderService from '@/services/merchantSpace/OrderService';

@Component({
  components: {
    Datatable,
    NoOrders,
    OrderLineWeb,
  },
})
export default class OrdersProfile extends Vue {
  page: number = 1;

  list: Array<any> = [];

  isLoading: boolean = true;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  readonly limit!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  readonly infinite!: boolean; // definite if infiniteHandler is use or not

  headers: Object[] = [
    { name: 'Marchand', width: '14%' },
    { name: 'Offre', width: '17%' },
    { name: 'Points', width: '16%' },
    { name: "Date d'achat", width: '16%' },
    { name: 'Etat', width: '16%' },
    { name: 'Bon de commande', width: '18%' },
  ];

  async mounted() {
    try {
      if (!this.infinite) {
        const response = await OrderService.getOrders(1, this.limit);
        this.list.push(...response.items);
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  async infiniteHandler($state: any) {
    try {
      const response = await OrderService.getOrders(this.page, 10);
      if (response.items.length && this.list.length < response.meta.totalItems) {
        this.page += 1;
        this.list.push(...response.items);
        $state.loaded();
      } else {
        $state.complete();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }
}
