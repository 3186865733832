var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SSOAccountBindingView text-secondary" }, [
    _c("div", { staticClass: "text-center" }, [
      _vm.logoUrl
        ? _c("img", {
            staticClass: "logo",
            attrs: { src: _vm.logoUrl, alt: "logo" }
          })
        : _vm._e()
    ]),
    _c(
      "div",
      { key: _vm.$route.name, staticClass: "scene" },
      [_c("router-view")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }