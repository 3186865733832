var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.conditionalClasses },
    [
      _c(
        "v-card",
        {
          staticClass: "applications-card",
          on: {
            click: function($event) {
              return _vm.$router.push(
                "/dashboard/profile/applications/" + _vm.name
              )
            }
          }
        },
        [
          _vm.synchronized
            ? _c(
                "v-icon",
                {
                  staticClass: "applications-card-check",
                  attrs: { size: "50" }
                },
                [_vm._v(" mdi-check-circle ")]
              )
            : _vm.waitingValidation
            ? _c(
                "v-icon",
                {
                  staticClass: "applications-card-loading",
                  attrs: { size: "50" }
                },
                [_vm._v(" mdi-clock ")]
              )
            : _vm._e(),
          _c("img", {
            staticClass: "applications-card-logo",
            attrs: { src: _vm.logo, alt: "" }
          })
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "applications-card-wrapper-sync" },
        [_vm._t("sync")],
        2
      ),
      _c(
        "p",
        { staticClass: "applications-card-wrapper-text" },
        [_vm._t("text")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }