



































import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class ApplicationCard extends Vue {
  @Prop({ type: String })
  name!: string;

  @Prop({ type: String })
  logo!: string;

  @Prop({ type: Boolean, default: false })
  synchronized!: boolean;

  @Prop({ type: Boolean, default: false })
  waitingValidation!: boolean;

  @Prop({ type: Boolean, default: false })
  solo!: boolean;

  get conditionalClasses() {
    return this.solo ? 'applications-card-wrapper-solo' : 'applications-card-wrapper';
  }
}
