import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import UserModule from '@/stores/UserStore';
import PlatformModule from '@/stores/PlatformStore';
import AppModule from '@/stores/AppStore';
import NotificationModule from '@/stores/NotificationStore';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: `${process.env.VUE_APP_NAME_APP}-${process.env.VUE_APP_PLATFORM_UUID}`,
  modules: ['user', 'platform', 'app'],
});

export default new Vuex.Store({
  modules: {
    user: UserModule,
    platform: PlatformModule,
    app: AppModule,
    notifications: NotificationModule,
  },
  plugins: [vuexLocal.plugin],
});
