// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/RiftSoft-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Europa-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Europa-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"PrimaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"SecondaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"TertiaryFallback\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n.text-tertiary[data-v-108e1dc4] {\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.text-secondary[data-v-108e1dc4] {\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}\n.text-primary[data-v-108e1dc4] {\n  font-family: Primary, PrimaryFallback, sans-serif;\n}\n.banner[data-v-108e1dc4] {\n  padding: 0 1rem;\n  margin-bottom: 0;\n}\n@media only screen and (min-width: 960px) {\n.banner[data-v-108e1dc4] {\n    padding: 1rem 3rem;\n}\n}\n.banner[data-v-108e1dc4] .v-icon::before {\n  color: black;\n}\n.banner .col[data-v-108e1dc4] {\n  padding: 0.5rem;\n}\n.banner-text[data-v-108e1dc4] {\n  font-weight: bold;\n  color: var(--v-homebloc_texte_bouton-base);\n  font-family: Tertiary, TertiaryFallback, sans-serif;\n}\n.banner-button[data-v-108e1dc4] {\n  color: var(--v-homebloc_texte_bouton-base);\n  background-color: var(--v-homebloc_fond_bouton-base) !important;\n  border: 3px solid white;\n  margin: 1.5rem;\n  letter-spacing: -0.5px;\n  font-family: Secondary, SecondaryFallback, sans-serif;\n}", ""]);
// Exports
module.exports = exports;
