














































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class EmailConfirmationBanner extends Vue {

  goToProfile() {
    this.$router.push('/dashboard/profile/update');
  }

  get textCols() {
    return this.isMobile ? 12 : 10;
  }

  get icon() {
    return this.isMobile ? false : 'mdi-alert';
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
