




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Payment } from '@/api/resources/orderApi';

const moment = require('moment');

@Component
export default class CashbackPayOrder extends Vue {
  displayPayments: boolean = false;

  @Prop({
    type: Array,
    required: true,
  })
  readonly payments!: Payment[];

  headers: any[] = [
    { value: 'date', text: 'Date de commande' },
    { value: 'detail', text: 'Statut' },
    { value: 'comment', text: 'Commentaires' },
  ];

  triggerPayments() {
    this.displayPayments = !this.displayPayments;
  }

  formatDate(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }
}
