




































import { Vue, Component } from 'vue-property-decorator';
import OrdersDatatable from '@/components/Account/OrdersDatatable.vue';
import OrdersMobile from '@/components/Account/OrdersMobile.vue';

@Component({
  components: {
    OrdersDatatable,
    OrdersMobile,
  },
})
export default class DashboardLastOrder extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  navigateToOrders(arg: string) {
    if (arg === 'btn') {
      this.$router.push({ name: 'orders' });
    }
    if (arg === 'card') {
      if (this.isMobile) {
        this.$router.push({ name: 'orders' });
      }
    }
  }
}
