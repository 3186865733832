
































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgSignin from '@/components/Svg/SvgSignin.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import ErrorDialog from '@/components/Card/OfferDetailCard/ErrorDialog.vue';
import * as UserService from '@/services/userSpace/UserService';

@Component({
  components: {
    SvgSignin,
    ErrorDialog,
  },
})
export default class AlmostDoneCollectConfirmationCard extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  readonly value!: boolean;

  closeDialog() {
    this.show = false;
  }

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }

  goTo(route): void {
    this.$router.push(route);
    this.$emit('input');
  }

  @Prop({ type: Number, required: true })
  readonly points!: number;

  get titleText(): string {
    return `Objectif atteint !`;
  }

  get message(): string {
    return `La cagnotte est presque pleine !`;
  }

  get subtitleMessage(): string {
    return `Seulement ${this.points} points suffisent pour atteindre l'objectif de la cagnotte.`;
  }

  get subtitleMessage2(): string {
    return `Confirmez-vous votre souhait de participer à hauteur de ${this.points} points ?`;
  }

  get buttonText(): string {
    return 'Confirmer ma contribution';
  }

  async onConfirmClick() {
    this.$emit('click', true);
  }
}
