var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-refer-friend",
      class: { "is-mobile": _vm.isMobile }
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "dashboard-refer-friend__content" }, [
        _c("p", { staticClass: "dashboard-refer-friend__content-text" }, [
          _vm._v(
            " Parrainer un ami va permettre à chacun de vous de gagner " +
              _vm._s(_vm.points) +
              " points. Vous pouvez parrainer jusqu’à " +
              _vm._s(_vm.limit) +
              " personnes maximum en leur partageant le code ci-dessous : "
          )
        ])
      ]),
      _c("div", { staticClass: "dashboard-refer-friend__referral" }, [
        _c("div", { staticClass: "dashboard-refer-friend__center" }, [
          _c("p", { staticClass: "dashboard-refer-friend__content-code" }, [
            _vm._v(_vm._s(_vm.referralCode))
          ]),
          _vm.referralCode
            ? _c(
                "div",
                {
                  staticClass: "dashboard-refer-friend__copy",
                  on: { click: _vm.copyToClipboard }
                },
                [
                  _c("SvgPictoCopy"),
                  _vm.displayCopy
                    ? _c("div", { staticStyle: { position: "absolute" } }, [
                        _c("p", { staticClass: "copy-confirm" }, [
                          _vm._v("Copié !")
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard-refer-friend__header" }, [
      _c("h3", { staticClass: "dashboard-refer-friend__header-title" }, [
        _vm._v("Parrainer un ami")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }