






import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgQuestion from '@/components/Svg/SvgQuestion.vue';

@Component({
  components: { SvgQuestion },
})
export default class DashboardQuestion extends Vue {}
