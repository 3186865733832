var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "30.129",
        height: "30.128",
        viewBox: "0 0 30.129 30.128"
      }
    },
    [
      _c("g", { attrs: { id: "chance", transform: "translate(0 0.001)" } }, [
        _c("path", {
          attrs: {
            id: "Tracé_3574",
            "data-name": "Tracé 3574",
            d: "M0,0H30.129V30.128H0Z",
            transform: "translate(0 -0.001)",
            fill: "none"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3575",
            "data-name": "Tracé 3575",
            d:
              "M14.552,21.771,6.8,25.844l1.48-8.628-6.277-6.11,8.662-1.255L14.543,2l3.874,7.85,8.662,1.255L20.8,17.217l1.48,8.628Z",
            transform: "translate(0.513 0.51)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }