import Vue from 'vue';
import Router from 'vue-router';
import ConnectedRoutes from '@/routes/ConnectedRoutes';
import DisconnectedRoutes from '@/routes/DisconnectedRoutes';
import { redirectOnErrorQuery } from '@/routes/guards';
import { authGuards, hideFromAuth, prepareBreadcrumb, synchronizationPageGuard, synchronizationSSOGuard } from './routes/utils';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [...DisconnectedRoutes, ...ConnectedRoutes],
});

router.beforeEach(redirectOnErrorQuery);
router.beforeEach(authGuards);
router.beforeEach(hideFromAuth);
router.beforeEach(prepareBreadcrumb);
router.beforeEach(synchronizationPageGuard);
router.beforeEach(synchronizationSSOGuard);

export default router;
