var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "30.129",
        height: "30.128",
        viewBox: "0 0 30.129 30.128"
      }
    },
    [
      _c("g", { attrs: { id: "cagnotte", transform: "translate(0 -0.001)" } }, [
        _c("path", {
          attrs: {
            id: "Tracé_3568",
            "data-name": "Tracé 3568",
            d: "M0,0H30.129V30.128H0Z",
            transform: "translate(0 0.001)",
            fill: "none"
          }
        }),
        _c("ellipse", {
          staticClass: "st1",
          attrs: {
            id: "Ellipse_296",
            "data-name": "Ellipse 296",
            cx: "6",
            cy: "4",
            rx: "6",
            ry: "4",
            transform: "translate(14.188 3)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3569",
            "data-name": "Tracé 3569",
            d: "M11,6v5.021c0,2.08,2.687,3.766,6,3.766s6-1.686,6-3.766V6",
            transform: "translate(3.189 1.533)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3570",
            "data-name": "Tracé 3570",
            d: "M11,10v5.021c0,2.08,2.687,3.766,6,3.766s6-1.686,6-3.766V10",
            transform: "translate(3.189 2.554)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3571",
            "data-name": "Tracé 3571",
            d: "M11,14v5.021c0,2.08,2.687,3.766,6,3.766s6-1.686,6-3.766V14",
            transform: "translate(3.189 3.576)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3572",
            "data-name": "Tracé 3572",
            d:
              "M8.021,9H4.883a1.883,1.883,0,1,0,0,3.766H6.138a1.883,1.883,0,1,1,0,3.766H3",
            transform: "translate(0.766 2.299)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            id: "Tracé_3573",
            "data-name": "Tracé 3573",
            d: "M5,16.787v1.255M5,8V9.255",
            transform: "translate(1.277 2.044)",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }