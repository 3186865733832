import { Route } from 'vue-router';
import { PageName } from '@/constants';

export default async function redirectOnErrorQuery(to: Route, from: Route, next: Function) {
  // Attention, ce guard est fragile.
  // laissez la query "error" dans l'url adin d'éviter un
  // loop de redirection causé par le SSO -
  // (provoqué par restoreStateAndRedirect dans le Header et ShopOfflineMessage)
  if (to.name !== PageName.ERROR && to.query.error) {
    next({
      name: PageName.ERROR,
      query: to.query && {
        ...to.query,
      },
    });
  } else {
    next();
  }
}
