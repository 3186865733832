


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import MiniProfile from '@/components/Account/MiniProfile.vue';
import SvgBackgroundProfile from '@/components/Svg/SvgBackgroundProfile.vue';

@Component({
  components: {
    SvgBackgroundProfile,
    MiniProfile,
  },
})
export default class ProfileBanner extends Vue {
  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly firstname!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly lastname!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get compInitials() {
    const firstNameInitial = this.firstname ? this.firstname[0] : '';
    const lastNameInitial = this.lastname ? this.lastname[0] : '';
    return `${firstNameInitial}${lastNameInitial}`;
  }

  get compDisplayedName() {
    const firstname = this.firstname ? this.firstname : '';
    const lastname = this.lastname ? this.lastname : '';
    const bothName = firstname.concat(' ').concat(lastname);

    if (firstname.length > 12) {
      return firstname.substring(0, 12).concat('...');
    }

    if (bothName.length > 10) {
      return firstname.concat(' ').concat(lastname[0]).concat('.');
    }

    return bothName;
  }

  get miniProfileOption() {
    return {
      fullName: this.compDisplayedName,
      initials: this.compInitials,
    };
  }

  navigateToUpdateProfile() {
    this.$router.push('/dashboard/profile/update');
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
