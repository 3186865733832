




















import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SSOAccountBindingView extends Vue {
  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get logoUrl() {
    const logoPath = this.platform.style.logoUrl;
    return logoPath && `${process.env.VUE_APP_API_URL}/api${logoPath}`;
  }
}
