






















































import { Component, Vue } from 'vue-property-decorator';
import * as UserService from '@/services/userSpace/UserService';
import Avatar from '@/components/Avatar.vue';

import ConfirmDeleteAccount from '@/components/Dialogs/ConfirmDeleteAccount.vue';
import Goodbye from '@/components/Dialogs/Goodbye.vue';
import PageTitle from '@/components/PageTitle.vue';

@Component({
  components: {
    ConfirmDeleteAccount,
    Goodbye,
    Avatar,
    PageTitle,
  },
})
export default class DeleteAccount extends Vue {
  maxContentLength: number = 1000;

  rules = {
    reason: [(v) => v.length <= this.maxContentLength || '', (v) => !!v || 'Ce champ est obligatoire'],
  };

  reason: string = '';

  showConfirmation: boolean = false;

  showGoodbye: boolean = false;

  platform: any;

  handleDeleteAccount() {
    if ((this.$refs.deleteAccountForm as any).validate()) {
      this.showConfirmation = true;
    }
  }

  closeConfirm() {
    this.showConfirmation = false;
  }

  get compPlatform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get deleteRGPDMessage() {
    return this.$store.getters['platform/getBannerInfos'].rgpdMessages.deleteRGPDMessage;
  }

  async closeGoodbye() {
    this.showGoodbye = false;
    await this.$router.push('/');
  }

  async doDelete() {
    const userId = await UserService.getUserId();
    await UserService.deactivateUser(userId, this.reason, 'fr');
    this.showConfirmation = false;
    // Logout
    await this.$store.dispatch('user/resetTokens');
    this.showGoodbye = true;
  }
}
