

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import OfferDetailCard from '@/components/Card/OfferDetailCard/OfferDetailCard.vue';
import OfferDetailsWeb from '@/components/Offer/OfferDetailsWeb.vue';
import RequestConfirmationCard from '@/components/Card/RequestConfirmationCard.vue';
import AddressConfirmationCard from '@/components/Card/OfferDetailCard/AddressConfirmationCard.vue';
import OfferNotAvailableCard from '@/components/Card/OfferNotAvailableCard.vue';
import OrderConfirmationCard from '@/components/Card/OrderConfirmationCard.vue';
import SvgHomeBackground from '@/components/Home/SvgHomeBackground.vue';
import ErrorDialog from '@/components/Card/OfferDetailCard/ErrorDialog.vue';
import PageTitle from '@/components/PageTitle.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import * as UserService from '@/services/userSpace/UserService';
import * as common from '@/services/common/common';
import SvgBackgroundShop from '@/components/Svg/SvgBackgroundShop.vue';
import { Offer, Order } from '@/services/merchantSpace/types';
import * as offerApi from '@/api/resources/offerApi';

@Component({
  components: {
    OfferDetailCard,
    RequestConfirmationCard,
    AddressConfirmationCard,
    OrderConfirmationCard,
    SvgHomeBackground,
    OfferDetailsWeb,
    ErrorDialog,
    PageTitle,
    SvgBackgroundShop,
    OfferNotAvailableCard,
  },
})
export default class OfferDetails extends Vue {
  @Prop({ type: Number, required: true, default: -1 })
  readonly offerId!: number;

  offer: Offer = {
    id: -1,
    title: '',
    description: '',
    discountDescription: '',
    currentStock: 0,
    points: 0,
    discountTypeId: 1,
    offerCategoryId: 1,
    deliveryMethodId: 1,
    companyId: 1,
    photoUrls: [],
    collectionMailAdressOption: 1,
    collectionMailAdress: '',
    collectionDateOption: 1,
    creatorId: -1,
    status: 'pending_moderation',
    offerTypeId: -1,
    targetAudienceId: -1,
    companyName: '',
    companyAvatar: '',
    websiteURL: '',
    maxPoints: 0,
  };

  orderAddress = {
    address: '',
    zipCode: undefined,
    city: '',
  };

  DETAIL_STEP: number = 1;

  REQUEST_CONFIRMATION_STEP_ADDRESS_VALIDATION: number = 2;

  REQUEST_CONFIRMATION_STEP: number = 2.1;

  REQUEST_CONFIRMATION_STEP_CHECK_COLLECT: number = 2.2;

  CONFIRMED_STEP: number = 3;

  NOT_AVAILABLE_STEP: number = 4;

  step: number = this.DETAIL_STEP;

  userPointsBefore: number = 0;

  userPointsAfter: number = 0;

  showDialog: boolean = false;

  dialogMessage: string = '';

  currentUserPoints: number = 0;

  contributionCheck: offerApi.ContributionChecked = { exceeded: false, remainingPoints: 0 };

  order: Order[] = [];

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get webViewIsActive() {
    return this.$store.getters['platform/webViewIsActive'];
  }

  async mounted() {
    await this.loadOffer();
    this.currentUserPoints = await UserService.getUserPoints(1);
    if (common.isConnected()) {
      this.userPointsBefore = await UserService.getUserPoints(1);
    }
  }

  async loadOffer() {
    try {
      this.offer = await OfferService.getOfferById(this.offerId);
    } catch (error) {
      if (error instanceof OfferService.OfferNotFound) {
        this.dialogMessage = 'Cette offre n’existe pas';
      }
      if (error instanceof OfferService.OfferNotAvailable) {
        this.dialogMessage = 'Cette offre n’est plus disponible';
      }
      this.showDialog = true;
      setTimeout(this.redirection, 2000);
    }
    return this.offer;
  }

  async redirection() {
    if (this.webViewIsActive) {
      await this.$router.push(`/shop-webview`);
    } else {
      await this.$router.push(`/shop`);
    }
  }

  async onBuyClick() {
    const isAlreadyOrder = await offerApi.isAlreadyOrder(this.offer.id);
    const isOfferOrderable = await offerApi.isOrderable(this.offer.id);
    if (this.isFundingOffer && this.currentUserPoints < 10) {
      this.dialogMessage = `Vous n'avez pas assez de points pour contribuer à cette cagnotte (le minimum requis est de 10 points).`;
      this.showDialog = true;
      return;
    }

    if (this.isFundingOffer) {
      this.step = this.REQUEST_CONFIRMATION_STEP;
      return;
    }

    if (this.currentUserPoints < this.offer.points) {
      this.dialogMessage = this.isStandardOffer
        ? `Vous n'avez pas assez de points pour acheter cette offre.`
        : `Vous n'avez pas assez de points pour participer à cette offre.`;
      this.showDialog = true;
    } else if (this.isLuckOffer && isAlreadyOrder) {
      this.dialogMessage = 'Vous avez déjà participé à cette offre.';
      this.showDialog = true;
    } else if (this.isCashbackOffer && isAlreadyOrder) {
      this.dialogMessage = "Les offres cashback ne sont achetables qu'une fois.";
      this.showDialog = true;
    } else if (!isOfferOrderable) {
      this.dialogMessage = 'Cette offre n’est plus disponible.';
      this.showDialog = true;
    } else if (this.offer.deliveryMethodId === 3) {
      this.step = this.REQUEST_CONFIRMATION_STEP_ADDRESS_VALIDATION;
    } else {
      this.step = this.REQUEST_CONFIRMATION_STEP;
    }
  }

  async onConfirmClick(hasBeenBuyed: boolean) {
    if (hasBeenBuyed) {
      this.step = this.CONFIRMED_STEP;
      this.userPointsAfter = await UserService.getUserPoints(this.offer.targetAudienceId);
    } else {
      this.step = this.NOT_AVAILABLE_STEP;
    }
  }

  async getOrder(getOrder: Order[]) {
    this.order = getOrder;
  }

  get pointsBefore(): number {
    return this.userPointsBefore;
  }

  get pointsAfter(): number {
    return this.userPointsAfter;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get compDialogText() {
    return this.dialogMessage;
  }

  closeDialog() {
    this.showDialog = false;
  }

  get isStandardOffer(): boolean {
    return OfferService.isStandardOffer(this.offer.offerTypeId);
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.offer.offerTypeId);
  }

  get isFundingOffer(): boolean {
    return OfferService.isFundingOffer(this.offer.offerTypeId);
  }

  get isCashbackOffer(): boolean {
    return OfferService.isCashbackOffer(this.offer.offerTypeId);
  }

  showSignin(): void {
    this.$store.dispatch('showLogin', true);
  }

  handleAddressConfirmed(selectedAddress) {
    this.orderAddress = selectedAddress;
    this.step = this.REQUEST_CONFIRMATION_STEP;
  }
}
