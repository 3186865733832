var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Composant_67_4",
        "data-name": "Composant 67 – 4",
        xmlns: "http://www.w3.org/2000/svg",
        width: "64",
        height: "56",
        viewBox: "0 0 64 56"
      }
    },
    [
      _c("title"),
      _c(
        "g",
        {
          staticClass: "cls-1",
          attrs: {
            id: "Ellipse_18",
            "data-name": "Ellipse 18",
            transform: "translate(32 8)"
          }
        },
        [
          _c("circle", {
            staticClass: "cls-4",
            attrs: { cx: "16", cy: "16", r: "16" }
          }),
          _c("circle", {
            staticClass: "cls-5",
            attrs: { cx: "16", cy: "16", r: "15" }
          })
        ]
      ),
      _c("circle", {
        staticClass: "cls-2",
        attrs: {
          id: "Ellipse_16",
          "data-name": "Ellipse 16",
          cx: "24",
          cy: "24",
          r: "24"
        }
      }),
      _c("circle", {
        staticClass: "cls-3",
        attrs: {
          id: "Ellipse_17",
          "data-name": "Ellipse 17",
          cx: "16",
          cy: "16",
          r: "16",
          transform: "translate(24 24)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }