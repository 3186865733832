


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Home/Footer.vue';
import Signin from '@/components/Dialogs/Signin.vue';
import SSOSignup from '@/components/Dialogs/SSOSignUpDialog.vue';
import ForgotPassword from '@/components/Dialogs/ForgotPassword.vue';
import ReactivateAccount from '@/components/Dialogs/ReactivateAccount.vue';
import * as userService from '@/services/userSpace/UserService';
import { prepareNavTabs } from '@/routes/utils';
import Error from '@/views/Error.vue';
import NewCguDialog from '@/components/Dialogs/NewCguDialog.vue';
import ExternalCguAgreement from '@/components/Dialogs/ExternalCguAgreement.vue';
import LogoutMessage from '@/components/Dialogs/LogoutMessage.vue';
import ShopOfflineMessage from '@/components/Dialogs/ShopOfflineMessage.vue';
import MigrationMessage from '@/components/Dialogs/MigrationMessage.vue';
import Banner from '@/components/Home/Banner.vue';
import NotificationApp from '@/components/NotificationApp.vue';
import QrcodeScanManager from '@/components/QrcodeScanManager.vue';
import { User } from '@/services/userSpace/types';
import PendingValidationBanner from './components/Home/PendingValidationBanner.vue';
import EmailConfirmationBanner from './components/Home/EmailConfirmationBanner.vue';
import { loadApp } from '@/services/load-app';
import { loadPlatformByUUID } from './services/merchantSpace/PlatformService';
import { PlatformKey } from './constants';
import AskSSOSynchroDialog from './components/Dialogs/AskSSOSynchroDialog.vue';
import SynchronizationProposalBanner from './components/Home/SynchronizationProposalBanner.vue';
import ConfirmSSOSynchroDialog from './components/Dialogs/ConfirmSSOSynchroDialog.vue';

const moment = require('moment');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vhUpdate = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vhUpdate}px`);
});

@Component({
  name: 'APP',
  components: {
    NotificationApp,
    QrcodeScanManager,
    EmailConfirmationBanner,
    PendingValidationBanner,
    SynchronizationProposalBanner,
    Header,
    Footer,
    Signin,
    SSOSignup,
    ForgotPassword,
    ReactivateAccount,
    Error,
    NewCguDialog,
    ExternalCguAgreement,
    LogoutMessage,
    ShopOfflineMessage,
    MigrationMessage,
    Banner,
    AskSSOSynchroDialog,
    ConfirmSSOSynchroDialog,
  },
  computed: { ...mapGetters('platform', ['webViewIsActive']) },
})
export default class App extends Vue {
  webViewIsActive!: boolean;

  overflow: boolean = true;

  loading: boolean = true;

  showShopOfflineMessage: boolean = false;

  showForgotPasswordDialog: boolean = false;

  showReactivateAccountDialog: boolean = false;

  showNewCguDialog: boolean = false;

  showFirstConnectSSO: boolean = false;

  showAskSSOSynchro: boolean = false;

  platform: any = null;

  numberOfDaysUntilDeletion: number = 0;

  error: any = null;

  showError: boolean = false;

  newCgu: any = null;

  showNewCguButton: boolean = false;

  showApiError: boolean = false;

  maintenance: boolean = false;

  pageTitle: string = '';

  isLogout: boolean = false;

  messages: any = {
    logoutMessageBlockActivated: false,
    logoutMessageBlockTitle: '',
    logoutMessageBlockDescription: '',
    shopOfflineMessageActivated: false,
    shopOfflineMessageTitle: '',
    shopOfflineMessageDescription: '',
  };

  displayMigrationMessage: boolean = false;

  authorizedLogoutPaths = ['/shop', '/'];

  get isOverflow(): string {
    return this.overflow ? 'overflow-content' : '';
  }

  get isExcludedRoute() {
    const excludedRoutes = ['gcu-legal-notice', 'gcu-rgpd', 'gcu-user-route'];
    return this.$route.name && excludedRoutes.includes(this.$route.name);
  }

  get isConnected(): boolean {
    return this.$store.getters['user/isConnected'];
  }

  get isErrorPage(): string {
    return this.showApiError ? 'error-page' : '';
  }

  get showAlertMail(): boolean {
    return this.isConnected && !this.$store.getters['user/getConfirmationEmail'];
  }

  get isHome(): boolean {
    return this.$route.matched.some((record) => record.meta.isHome);
  }

  get isSignup(): boolean {
    return this.$route.matched.some((record) => record.meta.isSignup);
  }

  get isShop(): boolean {
    return this.$route.matched.some((record) => record.meta.isShop);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get breadCrumb() {
    return this.$store.getters['user/getBreadCrumb'];
  }

  get displayLogoutMessage() {
    const loginIsVisible = this.$store.getters.loginVisible;
    return this.$store.getters.logoutMessageVisible && this.isHome && !this.isSignup && !this.isConnected && !loginIsVisible;
  }

  get logoutPath() {
    return this.$store.getters['platform/getPlatform'].config.logoutPath;
  }

  get showHeader() {
    const routeWithHeader = !this.$route.meta.hideHeader;
    return !this.maintenance && routeWithHeader;
  }

  get showFooter() {
    if (this.maintenance) {
      return false;
    }

    return !this.webViewIsActive;
  }

  get wantCguAgreementPopup() {
    // Ajoutez ici les plateformes qui souhaitent la popup.
    const platformCguAgreements = [PlatformKey.AVANTAG, PlatformKey.STAR];

    return platformCguAgreements.includes(this.platform.key);
  }

  get showSSOSynchroDialog() {
    return this.$store.getters.askSSOSynchroVisible;
  }

  get showConfirmSynchroDialog() {
    return this.$store.getters.confirmSSOSynchroVisible;
  }

  get shouldDisplayLogoutMessage() {
    return this.messages && this.messages.logoutMessageBlockActivated && !this.isConnected && !this.showSSOSynchroDialog;
  }

  @Watch('isConnected')
  async connectedChange(isConnected: any) {
    if (!isConnected && !this.isLogout) {
      await this.$router.push('/');
    }

    if (!isConnected && this.isLogout) {
      this.isLogout = false;
      // check if logoutPath is intern path
      const internPath = this.authorizedLogoutPaths.includes(this.logoutPath);
      if (internPath) {
        // if inter user router
        await this.$router.push(this.logoutPath);
      } else {
        // else redirect
        window.location.href = this.logoutPath;
      }
    }

    if (isConnected) {
      this.showShopOfflineMessage = false;
    }
  }

  hideError() {
    this.$store.dispatch('user/hideError');
  }

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange() {
    if (!this.showReactivateAccountDialog) {
      await this.handleDialogCgu();
    }

    this.platform = await loadPlatformByUUID(process.env.VUE_APP_PLATFORM_UUID);

    this.pageTitle = await prepareNavTabs(this.$route, this.breadCrumb);
    this.setMeta();
    this.checkMaintenance();
  }

  async created() {
    this.loading = true;
    this.showApiError = false;

    const { platform } = await loadApp({
      platformUUID: process.env.VUE_APP_PLATFORM_UUID,
    }).catch((error) => {
      // TODO: this is not good
      if (error.response && error.response.status) {
        this.showApiError = true;
      } else {
        this.error = this.$store.getters['user/getDisplayHttpError'];
        this.showError = true;
      }

      this.loading = false;

      throw error;
    });

    // TODO: This is not good. Use the computed store
    this.platform = platform;
    this.loading = false;

    // TODO: this should be put on a "platform.style.theme" watcher
    const customTheme = platform.style.theme;
    if (customTheme) {
      this.applyVuetifyTheme(customTheme);
    }

    this.checkMaintenance();
    this.$meta().addApp('App');

    this.messages = this.$store.getters['platform/getPlatform'].config.messageAndBanner;
    if (this.shouldDisplayLogoutMessage) {
      this.showLogoutMessage();
    }

    if (this.messages && this.messages.shopOfflineMessageActivated && !this.isConnected) {
      this.showShopOfflineMessage = true;
    }

    this.redirectToShop();
    this.displayWebView();
  }

  setMeta() {
    const { set } = this.$meta().addApp('App');
    const title = this.pageTitle && this.pageTitle.length < 150 ? `${this.pageTitle} - Programme de fidélité` : this.pageTitle;
    const description = `Programme de fidélité - ${this.platform.name} - Changez vos habitudes pour une mobilité plus durable et soyez récompensé !`;
    set({
      title,
      link: [{ rel: 'canonical', href: window.location.href }],
      meta: [
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:url',
          content: window.location.href,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: `${process.env.VUE_APP_API_URL}/api${this.platform.style.logoUrl}`,
        },
      ],
    });
  }

  checkMaintenance() {
    if (!this.platform) {
      return;
    }

    if (!this.$route.fullPath.includes('maintenance')) {
      if (this.platform.config.maintenance) {
        this.maintenance = true;
        this.$router.push({ name: 'maintenance' });
      } else {
        this.maintenance = false;
      }
    }
    if (this.$route.fullPath.includes('maintenance')) {
      if (this.platform.config.maintenance) {
        this.maintenance = true;
      } else {
        this.maintenance = false;
        this.$router.push({ name: 'home' });
      }
    }
  }

  redirectToShop() {
    // WARN: IT'S SUPER HACKY. The global redirection process must be reworked
    // On ne redirige pas vers le shop lorsque la route est une "page static",
    // exemple: une page d'erreur
    if (this.$route.meta.isStaticPage) {
      return;
    }

    if (this.$store.getters['platform/homeToShopIsActive'] && !this.isConnected && !this.$route.fullPath.includes('shop')) {
      const query = this.$route.query || {};
      this.$router.push({ name: 'shop', query });
    }
  }

  displayWebView() {
    // WARN: IT'S SUPER HACKY. The global redirection process must be reworked
    // On ne redirige pas vers le shop lorsque la route est une "page static",
    // exemple: une page d'erreur
    if (this.$route.meta.isStaticPage) {
      return;
    }

    if (this.$store.getters['platform/webViewIsActive'] && !this.isConnected && !this.$route.fullPath.includes('shop-webview')) {
      this.$router.push('/shop-webview');
    }
  }

  async handleDialogCgu() {
    this.showFirstConnectSSO = false;
    this.showNewCguButton = false;
    this.showNewCguDialog = false;

    if (this.isConnected) {
      this.newCgu = await userService.checkCguValidity();
      this.platform = await loadPlatformByUUID(process.env.VUE_APP_PLATFORM_UUID);
      if ((await userService.userFirstConnection()) && this.wantCguAgreementPopup) {
        this.showFirstConnectSSO = true;
      } else if (this.newCgu) {
        if (!this.$route.path.includes('gcu')) {
          this.showNewCguDialog = true;
        } else {
          this.showNewCguButton = true;
        }
      }
    }
  }

  async signNewCgu() {
    if (await userService.signNewCgu(this.newCgu.version)) {
      this.showNewCguButton = false;
      this.showNewCguDialog = false;
      this.showFirstConnectSSO = false;
    }
  }

  async acceptSSOSynchro() {
    const synchroURL = this.$store.getters['user/getUserSynchroURL'];
    this.$store.dispatch('showAskSSOSynchroMessage', false);
    window.location.href = window.location.origin + synchroURL;
  }

  async logout() {
    await this.$emit('is-logout');
    await this.$store.dispatch('user/resetTokens');
    this.closeSynchroMessage();
    this.$router.replace('/');
    this.redirectToLogout();
  }

  showSignup(): void {
    this.$store.dispatch('showSignup', true);
  }

  closeDialog(): void {
    this.handleDialogCgu();
    this.$store.dispatch('showLogin', false);
  }

  closeSSOSignupDialog(): void {
    this.$store.dispatch('showSSOSignUp', false);
  }

  showLogoutMessage(): void {
    this.$store.dispatch('showLogoutMessage', true);
  }

  closeLogoutMessage(): void {
    this.$store.dispatch('showLogoutMessage', false);
  }

  closeSynchroMessage(): void {
    this.$store.dispatch('showAskSSOSynchroMessage', false);
  }

  showMigrationMessage(): void {
    this.$store.dispatch('showLogin', false);
    this.displayMigrationMessage = true;
  }

  closeMigrationMessage(): void {
    this.displayMigrationMessage = false;
  }

  redirectToLogout() {
    this.isLogout = true;
  }

  showReactivation(data: User): void {
    const deactivationDate: Date = data.deactivatedAt as Date;

    this.numberOfDaysUntilDeletion = moment(deactivationDate).add(30, 'days').diff(moment(), 'days');

    this.$store.dispatch('showLogin', false);
    this.showReactivateAccountDialog = true;
  }

  closeReactivateAccountDialog(): void {
    this.showReactivateAccountDialog = false;
    userService.resetAccessToken();
  }

  async reactivateAccount(): Promise<void> {
    const userId = await userService.getUserId();
    await userService.reactivateUser(userId);
    this.showReactivateAccountDialog = false;
    await this.$router.push('/dashboard');
  }

  applyVuetifyTheme(customTheme) {
    const newVuetifyVariables = Object.entries(this.$vuetify.theme.themes.light).map(([key, value]) => {
      const data = customTheme[key];
      return [key, data || value];
    });
    this.$vuetify.theme.themes.light = Object.fromEntries(newVuetifyVariables);
  }
}
