






































import { Component, Prop, Vue } from 'vue-property-decorator';
import MiniProfileIcon from '@/components/Svg/MiniProfileIcon.vue';
import Avatar from '@/components/Avatar.vue';

@Component({
  components: { Avatar, MiniProfileIcon },
})
export default class DashboardProfileCard extends Vue {
  @Prop({ type: Boolean, default: false })
  private readonly avatar!: boolean;

  @Prop({ type: String, default: 'John Doe' })
  private readonly initials!: string;

  @Prop({ type: String, default: 'John Doe' })
  private readonly fullName!: string;

  @Prop({ type: String, default: 'Mes informations personnelles' })
  readonly buttonText!: string;

  @Prop({ type: String, default: 'update-profile' })
  readonly routeName!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  navigateToUpdateProfile(arg: string) {
    if (arg === 'button') {
      this.$router.push({ name: this.routeName });
    }
    if (arg === 'card') {
      if (!this.avatar) {
        this.$router.push({ name: this.routeName });
      }
    }
  }
}
