var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Luck",
        xmlns: "http://www.w3.org/2000/svg",
        width: "82.1",
        height: "82.3",
        viewBox: "0 0 82.1 82.3"
      }
    },
    [
      _c("title"),
      _c(
        "g",
        {
          attrs: {
            id: "Groupe_647",
            "data-name": "Groupe 647",
            transform: "translate(0 0.1)"
          }
        },
        [
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2240",
              "data-name": "Tracé 2240",
              d:
                "M20.9,35.3v1.3a4.339,4.339,0,0,1-3.3-1.3,4.2,4.2,0,0,1,.1-6.1,4.2,4.2,0,0,1,6.1-.1,4.379,4.379,0,0,1,1.3,3.1H23.7a3,3,0,0,0-.9-2.2,2.973,2.973,0,0,0-4.2,4.2A2.579,2.579,0,0,0,20.9,35.3Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2241",
              "data-name": "Tracé 2241",
              d:
                "M27,39.4,24.4,42l-1-1,2.3-2.3a.978.978,0,0,0,0-1.6,1.491,1.491,0,0,0-1.3-.3l-2.6,2.6-1-1L27.2,32l1,1-2.9,2.9a1.949,1.949,0,0,1,1.7.6A1.892,1.892,0,0,1,27,39.4Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2242",
              "data-name": "Tracé 2242",
              d:
                "M31.6,43.9,29,46.6l-1-1,.5-.5a2.235,2.235,0,0,1-1.9-.7,1.856,1.856,0,0,1-.1-2.8,2.067,2.067,0,0,1,2.9.2,2.743,2.743,0,0,1,.8,1.4l.2-.2a1.127,1.127,0,0,0-.2-1.6,4.388,4.388,0,0,0-1.5-.9l.5-1.2a7.012,7.012,0,0,1,2,1.2C32.4,41.8,32.5,43,31.6,43.9Zm-2.4.4.4-.4a3.151,3.151,0,0,0-.7-1.1c-.3-.3-.9-.6-1.3-.2s-.1.9.2,1.2A1.465,1.465,0,0,0,29.2,44.3Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2243",
              "data-name": "Tracé 2243",
              d:
                "M36.2,48.6l-2.6,2.6-1-1,2.3-2.3a.978.978,0,0,0,0-1.6,1.491,1.491,0,0,0-1.3-.3L31,48.6l-1-1,4-4,.9.9-.6.6a2.106,2.106,0,0,1,1.9.5A2,2,0,0,1,36.2,48.6Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2244",
              "data-name": "Tracé 2244",
              d:
                "M38.9,53.8v1.3a2.986,2.986,0,0,1-2.4-.9,3.113,3.113,0,0,1-.1-4.3,2.936,2.936,0,0,1,4.3.1,2.986,2.986,0,0,1,.9,2.4l-1.3-.1a1.816,1.816,0,0,0-.5-1.4,1.626,1.626,0,1,0-2.3,2.3A1.4,1.4,0,0,0,38.9,53.8Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              id: "Tracé_2245",
              "data-name": "Tracé 2245",
              d:
                "M44.7,58.2l-.4.4-3-3a1.579,1.579,0,0,0,.3,1.9,2.31,2.31,0,0,0,1.5.7l-.3,1.3a4.144,4.144,0,0,1-2.2-1,3.113,3.113,0,0,1-.1-4.3,2.9,2.9,0,0,1,4.2,4ZM44,56.8a1.45,1.45,0,0,0-2-2Z"
            }
          })
        ]
      ),
      _c(
        "g",
        { attrs: { id: "Composant_102_1", transform: "translate(0 0.1)" } },
        [
          _c("g", { attrs: { id: "Composant_101_3" } }, [
            _c("g", { attrs: { id: "Composant_49_1" } }, [
              _c("path", {
                staticClass: "cls-2",
                attrs: { id: "accent", d: "M0-.1,82.1,82.2H51.4L0,30.6Z" }
              }),
              _c(
                "g",
                { attrs: { id: "Groupe_648", "data-name": "Groupe 648" } },
                [
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2246",
                      "data-name": "Tracé 2246",
                      d:
                        "M22.1,41v1.7A5.215,5.215,0,0,1,18,41.1a5.285,5.285,0,0,1,.1-7.6,5.351,5.351,0,0,1,9.2,3.8H25.6a3.775,3.775,0,0,0-1.1-2.7,3.456,3.456,0,0,0-5.1.2,3.628,3.628,0,0,0-.2,5.1A3.746,3.746,0,0,0,22.1,41Z"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2247",
                      "data-name": "Tracé 2247",
                      d:
                        "M29.5,45.9l-3.3,3.3L25,47.9,27.9,45a1.266,1.266,0,0,0,0-2,1.667,1.667,0,0,0-1.7-.3L23,45.9l-1.3-1.3,8-8L31,37.9l-3.6,3.6a2.447,2.447,0,0,1,2.2.7A2.488,2.488,0,0,1,29.5,45.9Z"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2248",
                      "data-name": "Tracé 2248",
                      d:
                        "M35,51.3l-3.3,3.3-1.2-1.2.6-.6a2.986,2.986,0,0,1-2.4-.9,2.413,2.413,0,0,1-.1-3.5,2.473,2.473,0,0,1,3.6.3,3.336,3.336,0,0,1,1,1.8l.3-.3c.7-.7.2-1.5-.2-2a5.685,5.685,0,0,0-1.9-1.1l.6-1.5a7.354,7.354,0,0,1,2.4,1.5C36.1,48.6,36.2,50.2,35,51.3Zm-3,.5.5-.5a3.614,3.614,0,0,0-.9-1.4c-.4-.4-1.1-.8-1.6-.2-.5.5-.1,1.2.3,1.6A2.025,2.025,0,0,0,32,51.8Z"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2249",
                      "data-name": "Tracé 2249",
                      d:
                        "M40.6,57l-3.3,3.3L36.1,59,39,56a1.266,1.266,0,0,0,0-2,1.642,1.642,0,0,0-1.6-.4L34.1,57l-1.3-1.3,5-5L39,51.9l-.7.7A2.543,2.543,0,0,1,40.6,57Z"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2250",
                      "data-name": "Tracé 2250",
                      d:
                        "M43.7,63.2v1.7a3.923,3.923,0,0,1-3-1.1,3.775,3.775,0,0,1-.1-5.3,3.685,3.685,0,0,1,5.3.1,3.923,3.923,0,0,1,1.1,3l-1.7-.1a2.389,2.389,0,0,0-.6-1.7,2.051,2.051,0,0,0-2.9,0,1.967,1.967,0,0,0,0,2.9A2.106,2.106,0,0,0,43.7,63.2Z"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2251",
                      "data-name": "Tracé 2251",
                      d:
                        "M50.8,68.6l-.5.5-3.8-3.8a1.921,1.921,0,0,0,.4,2.4,2.927,2.927,0,0,0,1.9.9l-.3,1.6a4.625,4.625,0,0,1-2.7-1.3,3.775,3.775,0,0,1-.1-5.3,3.568,3.568,0,0,1,5.2-.1A3.623,3.623,0,0,1,50.8,68.6Zm-.9-1.8a1.718,1.718,0,0,0-.2-2.3,1.591,1.591,0,0,0-2.2-.2Z"
                    }
                  })
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }