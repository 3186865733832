import { SSOBehavior } from '@/services/merchantSpace/types';

enum FUNCTIONALITIES {
  SHOP_TO_HOME = 'SHOP_TO_HOME',
  SPONSORSHIP = 'SPONSORSHIP',
  WEBVIEW = 'WEBVIEW',
  EXPIRATION_POINTS = 'EXPIRATION_POINTS',
}

export default {
  namespaced: true,
  state: {
    platform: null,
    functionalities: [],
    bannerInfos: null,
    platformUrl: null,
  },
  mutations: {
    setPlatform(state: any, platform: any) {
      state.platform = platform;
    },
    setFunctionalities(state: any, functionalities: any) {
      state.functionalities = functionalities;
    },
    setBannerInfos(state: any, messageAndBanner: any) {
      state.bannerInfos = messageAndBanner;
    },
    setPlatformUrl(state: any, platformUrl: string) {
      state.platformUrl = platformUrl;
    },
  },
  actions: {
    async setPlatform({ commit, dispatch, state }: any, platform: any) {
      commit('setPlatform', platform);

      if (platform.config.messageAndBanner && platform.config.messageAndBanner !== state.bannerInfos) {
        commit('setBannerInfos', platform.config.messageAndBanner);
      }
      if (platform.config.functionalities && platform.config.functionalities !== state.functionalities) {
        commit('setFunctionalities', platform.config.functionalities);
      }
      if (platform.config.platformUrl && platform.config.platformUrl !== state.platformUrl) {
        commit('setPlatformUrl', platform.config.platformUrl);
      }
    },
  },
  getters: {
    getPlatform(state: any) {
      return state.platform;
    },
    getFunctionalities(state: any): string[] {
      return state.functionalities;
    },
    getBannerInfos(state: any) {
      return state.bannerInfos;
    },
    getPlatformUrl(state: any): string {
      return state.platformUrl;
    },
    getExternalSignInURL(state: any): string {
      return state.platform.config.sso.externalSignInURL;
    },
    getExternalSignUpURL(state: any): string {
      return state.platform.config.sso.externalSignUpURL;
    },
    signUpIsShow(state: any): boolean {
      return state.platform.config.sso.showSignUp;
    },
    isPkceAuthEnabled(state) {
      return ['OpenIdPKCEFlow', 'AstucePKCEFlow'].includes(state.platform.config.sso.signInScript);
    },
    homeToShopIsActive(state: any): boolean {
      if (state.functionalities) {
        return state.functionalities.some(({ functionality, isActive }) => functionality.slug === FUNCTIONALITIES.SHOP_TO_HOME && isActive);
      }
      return false;
    },
    webViewIsActive(state: any): boolean {
      if (state.functionalities) {
        return state.functionalities.some(({ functionality, isActive }) => functionality.slug === FUNCTIONALITIES.WEBVIEW && isActive);
      }
      return false;
    },
    sponsorshipIsActive(state: any): boolean {
      if (state.functionalities) {
        return state.functionalities.some(({ functionality, isActive }) => functionality.slug === FUNCTIONALITIES.SPONSORSHIP && isActive);
      }
      return false;
    },
    peremptionIsActive(state: any): boolean {
      if (state.functionalities) {
        return state.functionalities.some(({ functionality, isActive }) => functionality.slug === FUNCTIONALITIES.EXPIRATION_POINTS && isActive);
      }
      return false;
    },
    isSSOEnabled(state: any) {
      const { ssoBehavior } = state.platform.config.sso;
      return ssoBehavior && ssoBehavior !== SSOBehavior.NONE;
    },
    ssoPrompt(state: any) {
      return state.platform.config.sso.prompt;
    },
    ssoBehavior(state: any): SSOBehavior {
      return state.platform.config.sso.ssoBehavior;
    },
    ssoOptions(state: any): any {
      return state.platform.style.signup.ssoOptions;
    },
    externalSessionName(state) {
      return state.platform.config.sso.externalSessionName;
    },
  },
};
