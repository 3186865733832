var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Composant_69_1",
        "data-name": "Composant 69 – 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "45",
        height: "45",
        viewBox: "0 0 45 45"
      }
    },
    [
      _c("title"),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          id: "Tracé_2239",
          "data-name": "Tracé 2239",
          d:
            "M18,0A18.255,18.255,0,0,1,36,18.5,18.255,18.255,0,0,1,18,37,18.255,18.255,0,0,1,0,18.5,18.255,18.255,0,0,1,18,0Z",
          transform: "translate(9 8)"
        }
      }),
      _c("circle", {
        staticClass: "cls-2",
        attrs: {
          id: "Ellipse_10",
          "data-name": "Ellipse 10",
          cx: "12",
          cy: "12",
          r: "12"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_741",
          "data-name": "Rectangle 741",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "translate(20.646 5.559) rotate(41)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_742",
          "data-name": "Rectangle 742",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "matrix(-0.326, 0.946, -0.946, -0.326, 27.897, 17.089)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_743",
          "data-name": "Rectangle 743",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "translate(16.512 24.632) rotate(173)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_745",
          "data-name": "Rectangle 745",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "matrix(0.342, 0.94, -0.94, 0.342, 25.199, 22.452)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_746",
          "data-name": "Rectangle 746",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "translate(33.289 13.543) rotate(158)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_749",
          "data-name": "Rectangle 749",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "matrix(-0.857, 0.515, -0.515, -0.857, 9.996, 20.606)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_747",
          "data-name": "Rectangle 747",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "translate(15.264 0.368) rotate(61)"
        }
      }),
      _c("rect", {
        staticClass: "cls-3",
        attrs: {
          id: "Rectangle_744",
          "data-name": "Rectangle 744",
          width: "3.78",
          height: "6.615",
          rx: "1.89",
          transform: "translate(15.343 14.167) rotate(144)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }