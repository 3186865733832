var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BasicDialog",
    {
      attrs: {
        "max-width": "500px",
        title: _vm.title,
        "button-text": _vm.buttontText
      },
      on: { onClose: _vm.onClose, onSubmit: _vm.onClose }
    },
    [
      [
        _vm._v(" " + _vm._s(_vm.contentLine1) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.contentLine2) + " ")
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }