















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Datatable extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  headers!: Object[];

  compStyle(width) {
    return `width: ${width}`;
  }
}
