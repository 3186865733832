












































import { Vue, Component } from 'vue-property-decorator';

import HomeTitle from '@/components/Home/HomeTitle.vue';
import HomeIntro from '@/components/Home/HomeIntro.vue';
import HomePoints from '@/components/Home/HomePoints.vue';
import OfferShow from '@/components/Home/OfferShow.vue';
import PartnersIntro from '@/components/Home/PartnersIntro.vue';
import ServiceProviders from '@/components/Home/ServiceProviders.vue';
import Footer from '@/components/Home/Footer.vue';

import * as common from '@/services/common/common';

@Component({
  components: {
    HomeTitle,
    HomeIntro,
    HomePoints,
    OfferShow,
    PartnersIntro,
    ServiceProviders,
    Footer,
  },
})
export default class Home extends Vue {
  get isConnected(): boolean {
    return common.isConnected();
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get compHeaderImage() {
    const image = this.$store.getters['platform/getPlatform'].style.headerImage;
    if (!image) {
      return '';
    }
    const headerImage = `${process.env.VUE_APP_API_URL}/api${image}`;
    return `background-image: url(${headerImage});`;
  }
}
