var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Composant_81_8",
        "data-name": "Composant 81 – 8",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "103",
        height: "103",
        viewBox: "0 0 103 103"
      }
    },
    [
      _c("title"),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path" } }, [
          _c("circle", {
            staticClass: "cls-1",
            attrs: {
              id: "Ellipse_148",
              "data-name": "Ellipse 148",
              cx: "51.5",
              cy: "51.5",
              r: "51.5",
              transform: "translate(136 88)"
            }
          })
        ])
      ]),
      _c(
        "g",
        {
          staticClass: "cls-2",
          attrs: {
            id: "Groupe_de_masques_8",
            "data-name": "Groupe de masques 8",
            transform: "translate(-136 -88)"
          }
        },
        [
          _c(
            "g",
            {
              attrs: { id: "Calque_14", transform: "translate(132.768 78.047)" }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Groupe_651",
                    "data-name": "Groupe 651",
                    transform: "translate(-0.211 -0.053)"
                  }
                },
                [
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2309",
                      "data-name": "Tracé 2309",
                      d:
                        "M66.559,0c5.82,5.82,8.447,12.855,8.447,21.726A32.165,32.165,0,1,1,19.124,0Z",
                      transform: "translate(6.812 0.085)"
                    }
                  }),
                  _c("circle", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Ellipse_149",
                      "data-name": "Ellipse 149",
                      cx: "22.235",
                      cy: "22.235",
                      r: "22.235",
                      transform: "translate(38.282 100.38)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2310",
                      "data-name": "Tracé 2310",
                      d:
                        "M15.355,105.758A31.322,31.322,0,0,1,39.341,94.909a31.518,31.518,0,0,1,23.9,10.736",
                      transform: "translate(9.648 57.625)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2311",
                      "data-name": "Tracé 2311",
                      d: "M44.535,101.663",
                      transform: "translate(27.339 61.719)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2312",
                      "data-name": "Tracé 2312",
                      d: "M32.672,101.663h29.3",
                      transform: "translate(9.904 61.719)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_243",
                      "data-name": "Ligne 243",
                      x2: "0.085",
                      y2: "3.249",
                      transform: "translate(81.621 156.404)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_244",
                      "data-name": "Ligne 244",
                      x2: "0.085",
                      y2: "2.091",
                      transform: "translate(72.326 157.563)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-5",
                    attrs: {
                      id: "Ligne_245",
                      "data-name": "Ligne 245",
                      x1: "1.695",
                      y2: "1.271",
                      transform: "translate(61.053 155.726)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Tracé_2313",
                      "data-name": "Tracé 2313",
                      d:
                        "M85.1,95.527A31.869,31.869,0,1,1,70.353,35.406,28.494,28.494,0,0,1,85.1,39.023",
                      transform: "translate(23.671 21.55)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Ligne_246",
                      "data-name": "Ligne 246",
                      y2: "56.476",
                      transform: "translate(109.054 60.29)"
                    }
                  }),
                  _c("circle", {
                    staticClass: "cls-7",
                    attrs: {
                      id: "Ellipse_150",
                      "data-name": "Ellipse 150",
                      cx: "22.545",
                      cy: "22.545",
                      r: "22.545",
                      transform: "translate(1.187 107.726)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-8",
                    attrs: {
                      id: "Tracé_2314",
                      "data-name": "Tracé 2314",
                      d:
                        "M73.867,55.493c-9.464,0-16.386-7.685-16.386-17.149S64.4,21.195,73.867,21.195",
                      transform: "translate(35.187 12.934)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Tracé_2315",
                      "data-name": "Tracé 2315",
                      d:
                        "M-.035,0V26.953s6.159,5.82,18.081,2.345c0,0-4.266-18.081,7.572-29.3Z",
                      transform: "translate(0.318 0.085)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Tracé_2316",
                      "data-name": "Tracé 2316",
                      d:
                        "M77.348,117.084l-17.94.056s-16.5-15.284-1.1-33.959c7.317-8.08,19.014-6.894,19.014-6.894v40.8Z",
                      transform: "translate(31.705 46.298)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Tracé_2317",
                      "data-name": "Tracé 2317",
                      d:
                        "M-.035,92.645l.057,15.991H24.686s5-4.888,6.894-5.933C31.579,102.7,21.465,83.463-.035,92.645Z",
                      transform: "translate(0.318 54.747)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      id: "Tracé_2318",
                      "data-name": "Tracé 2318",
                      d:
                        "M-.211,44.711A32.181,32.181,0,0,1,31.968,76.89,32.181,32.181,0,0,1-.211,109.069",
                      transform: "translate(0.211 27.191)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_247",
                      "data-name": "Ligne 247",
                      y1: "23.534",
                      x2: "18.42",
                      transform: "translate(48.961 62.889)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_248",
                      "data-name": "Ligne 248",
                      y1: "35.739",
                      x2: "26.868",
                      transform: "translate(49.667 60.997)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_249",
                      "data-name": "Ligne 249",
                      y1: "41.135",
                      x2: "32.264",
                      transform: "translate(51.815 62.014)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_250",
                      "data-name": "Ligne 250",
                      y1: "45.769",
                      x2: "35.174",
                      transform: "translate(55.318 64.33)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_251",
                      "data-name": "Ligne 251",
                      y1: "47.351",
                      x2: "35.174",
                      transform: "translate(59.923 66.393)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_252",
                      "data-name": "Ligne 252",
                      y1: "47.351",
                      x2: "35.174",
                      transform: "translate(65.432 69.472)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_253",
                      "data-name": "Ligne 253",
                      y1: "43.395",
                      x2: "32.208",
                      transform: "translate(71.845 76.027)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_254",
                      "data-name": "Ligne 254",
                      y1: "34.326",
                      x2: "27.32",
                      transform: "translate(78.993 84.107)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-9",
                    attrs: {
                      id: "Ligne_255",
                      "data-name": "Ligne 255",
                      y1: "25.738",
                      x2: "20.455",
                      transform: "translate(86.424 92.357)"
                    }
                  }),
                  _c("path", {
                    staticClass: "cls-6",
                    attrs: {
                      id: "Tracé_2319",
                      "data-name": "Tracé 2319",
                      d: "M56.073-.053H74.72V7.321S63.108,7.886,56.073-.053Z",
                      transform: "translate(34.334 0.053)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_256",
                      "data-name": "Ligne 256",
                      x1: "1.017",
                      y2: "3.108",
                      transform: "translate(84.728 40.288)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_257",
                      "data-name": "Ligne 257",
                      x2: "1.328",
                      y2: "2.938",
                      transform: "translate(85.774 29.156)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_258",
                      "data-name": "Ligne 258",
                      x2: "3.079",
                      y2: "1.017",
                      transform: "translate(93.854 29.156)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_259",
                      "data-name": "Ligne 259",
                      x1: "0.509",
                      y2: "3.193",
                      transform: "translate(97.329 19.014)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_260",
                      "data-name": "Ligne 260",
                      x1: "2.373",
                      y2: "2.204",
                      transform: "translate(87.638 15.058)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_261",
                      "data-name": "Ligne 261",
                      x1: "2.175",
                      y1: "2.43",
                      transform: "translate(84.418 5.509)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-10",
                    attrs: {
                      id: "Ligne_262",
                      "data-name": "Ligne 262",
                      y1: "2.373",
                      x2: "0.17",
                      transform: "translate(93.543 3.701)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_263",
                      "data-name": "Ligne 263",
                      y1: "2.26",
                      x2: "0.254",
                      transform: "translate(67.127 18.364)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_264",
                      "data-name": "Ligne 264",
                      y1: "1.78",
                      x2: "1.441",
                      transform: "translate(75.998 8.334)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_265",
                      "data-name": "Ligne 265",
                      y1: "2.288",
                      x2: "0.057",
                      transform: "translate(80.519 20.794)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_266",
                      "data-name": "Ligne 266",
                      y1: "3.447",
                      x2: "1.61",
                      transform: "translate(67.862 28.054)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_267",
                      "data-name": "Ligne 267",
                      x2: "3.786",
                      y2: "0.17",
                      transform: "translate(72.863 36.389)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_268",
                      "data-name": "Ligne 268",
                      x2: "0.48",
                      y2: "2.853",
                      transform: "translate(65.46 2.599)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_269",
                      "data-name": "Ligne 269",
                      x2: "2.062",
                      y2: "2.034",
                      transform: "translate(54.922 0.791)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_270",
                      "data-name": "Ligne 270",
                      x2: "0.085",
                      y2: "2.882",
                      transform: "translate(47.746 4.916)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_271",
                      "data-name": "Ligne 271",
                      x1: "0.65",
                      y2: "2.006",
                      transform: "translate(58.058 12.742)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_272",
                      "data-name": "Ligne 272",
                      x1: "2.091",
                      y1: "0.283",
                      transform: "translate(46.588 17.149)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_273",
                      "data-name": "Ligne 273",
                      x1: "0.028",
                      y1: "2.119",
                      transform: "translate(42.774 23.845)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_274",
                      "data-name": "Ligne 274",
                      x1: "1.215",
                      y1: "1.752",
                      transform: "translate(54.809 25.597)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_275",
                      "data-name": "Ligne 275",
                      x1: "0.65",
                      y1: "2.006",
                      transform: "translate(48.679 32.575)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_276",
                      "data-name": "Ligne 276",
                      x1: "0.113",
                      y1: "2.119",
                      transform: "translate(59.556 35.372)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_277",
                      "data-name": "Ligne 277",
                      y1: "1.441",
                      x2: "1.554",
                      transform: "translate(59.188 45.345)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-11",
                    attrs: {
                      id: "Ligne_278",
                      "data-name": "Ligne 278",
                      x1: "0.509",
                      y1: "2.062",
                      transform: "translate(70.744 46.023)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_279",
                      "data-name": "Ligne 279",
                      x1: "3.249",
                      y1: "0.085",
                      transform: "translate(10.99 43.932)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_280",
                      "data-name": "Ligne 280",
                      x1: "2.599",
                      y2: "1.949",
                      transform: "translate(14.522 50.995)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_281",
                      "data-name": "Ligne 281",
                      x2: "0.48",
                      y2: "3.221",
                      transform: "translate(21.698 56.391)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_282",
                      "data-name": "Ligne 282",
                      y1: "0.509",
                      x2: "3.193",
                      transform: "translate(10.227 60.432)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_283",
                      "data-name": "Ligne 283",
                      x2: "2.656",
                      y2: "1.865",
                      transform: "translate(3.051 51.758)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_284",
                      "data-name": "Ligne 284",
                      y1: "2.995",
                      x2: "1.187",
                      transform: "translate(2.006 40.344)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_285",
                      "data-name": "Ligne 285",
                      x2: "3.249",
                      y2: "0.198",
                      transform: "translate(17.121 67.325)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_286",
                      "data-name": "Ligne 286",
                      x2: "1.921",
                      y2: "2.627",
                      transform: "translate(5.029 68.144)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_287",
                      "data-name": "Ligne 287",
                      x2: "3.221",
                      y2: "0.17",
                      transform: "translate(15.878 76.225)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_288",
                      "data-name": "Ligne 288",
                      y1: "2.373",
                      x2: "2.232",
                      transform: "translate(6.498 76.959)"
                    }
                  }),
                  _c("line", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Ligne_289",
                      "data-name": "Ligne 289",
                      x2: "3.136",
                      y2: "0.819",
                      transform: "translate(6.046 85.011)"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }