var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 81.7 82.1" },
      attrs: {
        id: "collect",
        "xmlns:x": "&ns_extend;",
        "xmlns:i": "&ns_ai;",
        "xmlns:graph": "&ns_graphs;",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 81.7 82.1",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M20.9,35.3v1.3c0,0-1.8,0.2-3.3-1.3c-1.7-1.7-1.7-4.3,0.1-6.1c1.8-1.8,4.4-1.8,6.1-0.1\n\t\tc1.4,1.4,1.3,3.1,1.3,3.1h-1.4c0,0,0.1-1.2-0.9-2.2c-1.2-1.2-3-1-4.1,0.1s-1.3,2.9-0.1,4.1C19.6,35.5,20.9,35.3,20.9,35.3z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M27,39.4L24.4,42l-1-1l2.3-2.3c0.6-0.6,0.5-1.2,0-1.6c-0.5-0.5-1.3-0.3-1.3-0.3l-2.6,2.6l-1-1l6.4-6.4l1,1\n\t\tl-2.9,2.9c0,0,0.9-0.2,1.7,0.6S28,38.4,27,39.4z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M31.6,43.9L29,46.6l-1-1l0.5-0.5c0,0-1,0.2-1.9-0.7c-0.8-0.8-1-1.9-0.1-2.8c0.8-0.8,2-0.6,2.9,0.2\n\t\tc0.7,0.7,0.8,1.4,0.8,1.4l0.2-0.2c0.5-0.5,0.2-1.2-0.2-1.6c-0.6-0.6-1.5-0.9-1.5-0.9l0.5-1.2c0,0,1.1,0.4,2,1.2\n\t\tC32.4,41.8,32.5,43,31.6,43.9z M29.2,44.3l0.4-0.4c0,0-0.2-0.6-0.7-1.1c-0.3-0.3-0.9-0.6-1.3-0.2c-0.4,0.4-0.1,0.9,0.2,1.2\n\t\tC28.4,44.5,29.2,44.3,29.2,44.3z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M36.2,48.6l-2.6,2.6l-1-1l2.3-2.3c0.6-0.6,0.5-1.2,0-1.6c-0.5-0.5-1.3-0.3-1.3-0.3L31,48.6l-1-1l4-4l0.9,0.9\n\t\tl-0.6,0.6c0,0,1-0.3,1.9,0.5C37,46.4,37.2,47.6,36.2,48.6z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M38.9,53.8v1.3c0,0-1.3,0.2-2.4-0.9c-1.2-1.2-1.2-3.1-0.1-4.3c1.2-1.2,3.1-1.2,4.3,0.1\n\t\tc1.1,1.1,0.9,2.4,0.9,2.4l-1.3-0.1c0,0,0.1-0.8-0.5-1.4c-0.7-0.7-1.7-0.6-2.3,0s-0.7,1.6,0,2.3C38.1,54,38.9,53.8,38.9,53.8z"
          }
        }),
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M44.7,58.2c-0.1,0.1-0.2,0.2-0.4,0.4l-3-3C41,56,41,56.9,41.6,57.5c0.7,0.7,1.5,0.7,1.5,0.7l-0.3,1.3\n\t\tc0,0-1.2-0.1-2.2-1c-1.2-1.2-1.2-3.1-0.1-4.3s3-1.3,4.1-0.1C45.8,55.2,45.9,57,44.7,58.2z M44,56.8c0.3-0.4,0.5-1.2-0.2-1.8\n\t\tc-0.7-0.7-1.4-0.5-1.8-0.2L44,56.8z"
          }
        })
      ]),
      _c(
        "g",
        { attrs: { id: "Composant_102_1", transform: "translate(0 0)" } },
        [
          _c("g", { attrs: { id: "Composant_101_3" } }, [
            _c("g", { attrs: { id: "Composant_49_1" } }, [
              _c("path", {
                staticClass: "st1",
                attrs: { id: "accent", d: "M0,0l81.7,82.1H51.1L0,30.6V0z" }
              })
            ])
          ])
        ]
      ),
      _c("g", [
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M16.6,33.3l0,1.6c0,0-2.1,0.3-3.9-1.5c-2-2.1-2-5.2,0.1-7.2c2.1-2.1,5.2-2.1,7.2,0c1.7,1.7,1.5,3.7,1.5,3.7\n\t\tl-1.6,0c0,0,0.1-1.4-1-2.6c-1.4-1.4-3.5-1.2-4.9,0.1c-1.4,1.4-1.6,3.5-0.2,4.9C15.1,33.5,16.6,33.3,16.6,33.3z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M23.6,37.9L20.5,41l-1.1-1.1l0.6-0.6c0,0-1.2,0.2-2.3-0.9c-0.9-0.9-1.1-2.3-0.1-3.3c0.9-0.9,2.4-0.7,3.5,0.3\n\t\tc0.9,0.9,1,1.7,1,1.7l0.3-0.3c0.6-0.6,0.2-1.4-0.2-1.9c-0.7-0.7-1.8-1.1-1.8-1.1l0.6-1.4c0,0,1.3,0.5,2.3,1.5\n\t\tC24.6,35.3,24.7,36.8,23.6,37.9z M20.8,38.3l0.5-0.5c0,0-0.2-0.7-0.8-1.3c-0.4-0.4-1-0.7-1.6-0.2c-0.5,0.5-0.1,1.1,0.2,1.5\n\t\tC19.8,38.5,20.8,38.3,20.8,38.3z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M23.6,44.4c-1.2-1.2-1.3-3.4,0.2-4.8c1.5-1.5,3.6-1.4,4.9-0.2c1,1,0.7,2.2,0.7,2.2l0.6-0.6l1.1,1.1l-4.9,4.8\n\t\tc-1.7,1.7-3.6,1.2-5-0.2c-1.4-1.4-1.3-3.2-1.3-3.2l1.6-0.2c0,0,0,1.4,0.9,2.3c0.9,0.9,1.9,0.9,2.6,0.1l0.6-0.6\n\t\tC25.2,45.1,24.4,45.1,23.6,44.4z M28.2,42.5c0,0,0.3-1-0.5-1.8c-0.8-0.8-1.9-0.7-2.7,0.1s-0.9,1.9-0.1,2.7c0.8,0.8,1.8,0.5,1.8,0.5\n\t\tL28.2,42.5z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M34.9,49.3l-3.1,3.1l-1.2-1.2l2.8-2.8c0.7-0.7,0.6-1.4,0-1.9c-0.6-0.6-1.6-0.3-1.6-0.3l-3.2,3.1L27.5,48\n\t\tl4.8-4.8l1.1,1.1l-0.7,0.7c0,0,1.2-0.4,2.3,0.6C35.9,46.7,36.1,48.2,34.9,49.3z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M40.2,56c-1.4,1.4-3.7,1.4-5.1-0.1c-1.4-1.4-1.4-3.7,0-5.1c1.4-1.4,3.7-1.4,5.1,0.1\n\t\tC41.6,52.4,41.6,54.6,40.2,56z M38.9,54.8c0.8-0.7,0.9-1.9,0.1-2.7c-0.8-0.8-2-0.7-2.7,0c-0.8,0.7-0.9,1.9-0.1,2.7\n\t\tC37,55.7,38.2,55.5,38.9,54.8z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M43.1,54.2l0.9,0.9l1.4-1.4l1.2,1.2l-1.4,1.4l1.4,1.5l-1.1,1.1l-1.4-1.5l-1.9,1.9c-0.6,0.6-0.5,1.1-0.1,1.4\n\t\tc0.3,0.3,0.7,0.3,0.7,0.3l-0.6,1.4c0,0-0.8-0.1-1.5-0.8c-0.9-0.9-0.9-2.3,0.1-3.3l2.1-2.1L42,55.3L43.1,54.2z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M47.3,58.4l0.9,0.9l1.4-1.4l1.2,1.2l-1.4,1.4l1.4,1.5l-1.1,1.1l-1.4-1.5l-1.9,1.9c-0.6,0.6-0.5,1.1-0.1,1.4\n\t\tc0.3,0.3,0.7,0.3,0.7,0.3l-0.6,1.4c0,0-0.8-0.1-1.5-0.8c-0.9-0.9-0.9-2.3,0.1-3.3l2.1-2.1l-0.9-0.9L47.3,58.4z"
          }
        }),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M53.9,69.9c-0.1,0.1-0.3,0.3-0.5,0.4l-3.6-3.6c-0.3,0.5-0.4,1.6,0.4,2.3c0.8,0.8,1.8,0.8,1.8,0.8l-0.3,1.5\n\t\tc0,0-1.4-0.1-2.6-1.2c-1.4-1.5-1.4-3.7,0-5.1c1.4-1.4,3.5-1.5,4.9-0.1C55.3,66.3,55.4,68.4,53.9,69.9z M53.1,68.2\n\t\tc0.4-0.4,0.6-1.4-0.2-2.2c-0.8-0.8-1.7-0.6-2.1-0.2L53.1,68.2z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }