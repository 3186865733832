




































































































































































































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';

import * as OfferService from '@/services/merchantSpace/OfferService';
import SvgCultureCategory from '@/components/Forms/Filter/svg/SvgCultureCategory.vue';
import SvgFoodCategory from '@/components/Forms/Filter/svg/SvgFoodCategory.vue';
import SvgSportCategory from '@/components/Forms/Filter/svg/SvgSportCategory.vue';
import SvgHobbiesCategory from '@/components/Forms/Filter/svg/SvgHobbiesCategory.vue';
import SvgServicesCategory from '@/components/Forms/Filter/svg/SvgServicesCategory.vue';
import SvgCommerceCategory from '@/components/Forms/Filter/svg/SvgCommerceCategory.vue';
import SvgOtherCategory from '@/components/Forms/Filter/svg/SvgOtherCategory.vue';
import SvgShowcaseOfferType from '@/components/Forms/Filter/svg/SvgShowcaseOfferType.vue';
import SvgStandardOfferType from '@/components/Forms/Filter/svg/SvgStandardOfferType.vue';
import SvgLuckOfferType from '@/components/Forms/Filter/svg/SvgLuckOfferType.vue';
import SvgCashbackOfferType from '@/components/Forms/Filter/svg/SvgCashbackOfferType.vue';
import SvgCollectOfferType from '@/components/Forms/Filter/svg/SvgCollectOfferType.vue';
import * as common from "@/services/common/common";

@Component({
  components: {
    SvgCultureCategory,
    SvgFoodCategory,
    SvgSportCategory,
    SvgHobbiesCategory,
    SvgServicesCategory,
    SvgCommerceCategory,
    SvgOtherCategory,
    SvgShowcaseOfferType,
    SvgStandardOfferType,
    SvgLuckOfferType,
    SvgCashbackOfferType,
    SvgCollectOfferType,
  },
})
export default class FilterOffers extends Vue {
  sortMode: string = OfferService.sortModesEnum.DEFAULT;

  defaultSortMode: string = '';

  filterPoints: number[] = [100, 10000];

  ticksAreaLabels: any = ['0 km', null, null, null, null, null, null, null, null, '200 km'];

  filterArea: any = [0, 5, 10, 15, 20, 30, 40, 50, 100, 200];

  selectedArea: number = 0;

  defaultFilterPoints: number[] = [];

  displayedAddresses: any[] = [];

  addressSearch: string = '';

  addressesItems: any[] = [];

  offerTypesToDisplay: any[] = [];

  offerTypeFilter: any[] = [
    { id: OfferService.offerTypeEnum.STANDARD.id, icon: SvgStandardOfferType, data: OfferService.offerTypeEnum.STANDARD.type },
    { id: OfferService.offerTypeEnum.LUCK.id, icon: SvgLuckOfferType, data: OfferService.offerTypeEnum.LUCK.type },
    { id: OfferService.offerTypeEnum.COLLECT.id, icon: SvgCollectOfferType, data: OfferService.offerTypeEnum.COLLECT.type },
    { id: OfferService.offerTypeEnum.SHOWCASE.id, icon: SvgShowcaseOfferType, data: OfferService.offerTypeEnum.SHOWCASE.type },
    { id: OfferService.offerTypeEnum.CASHBACK.id, icon: SvgCashbackOfferType, data: OfferService.offerTypeEnum.CASHBACK.type },
  ];

  offerTypeList: any[] = [
    { id: OfferService.offerTypeEnum.STANDARD.id, name: OfferService.offerTypeEnum.STANDARD.type, isSelected: false },
    { id: OfferService.offerTypeEnum.LUCK.id, name: OfferService.offerTypeEnum.LUCK.type, isSelected: false },
    { id: OfferService.offerTypeEnum.COLLECT.id, name: OfferService.offerTypeEnum.COLLECT.type, isSelected: false },
    { id: OfferService.offerTypeEnum.SHOWCASE.id, name: OfferService.offerTypeEnum.SHOWCASE.type, isSelected: false },
    { id: OfferService.offerTypeEnum.CASHBACK.id, name: OfferService.offerTypeEnum.CASHBACK.type, isSelected: false },
  ];

  @Prop({ type: Array, required: true, default: null })
  allMerchantsList!: OfferService.Merchant[];

  @Prop({ type: Array, required: true, default: null })
  allCategoriesList!: OfferService.Category[];

  @Prop({ type: Number, required: true })
  max!: number;

  @Prop({ type: Number, required: true })
  min!: number;

  @Prop({ type: Array, required: true, default: null })
  events!: OfferService.Event[];

  @Prop({ type: Array, required: false })
  searchEvent?: number[];

  internalMin: number = 100;

  internalMax: number = 10000;

  merchantsList: number[] = [];

  categoriesList: number[] = [];

  eventList: number[] = [];

  internalfilterParams: OfferService.FilterValues = {
    geo: [],
    area: 0,
    sortMode: OfferService.sortModesEnum.NEW_TO_OLD,
    filterPoints: [this.roundDownMin(this.min), this.roundUpMax(this.max)],
    categories: [],
    merchants: [],
    offerTypes: [],
    events: [],
  };

  eventSearch = '';

  displayMerchants = false;

  displayCategories = false;

  displayOfferTypes = false;

  displayGeoloc = false;

  async offersActiveCategories() {
    // on récupère toutes les offres de la plateforme
    let platformOffers;
    if (this.isConnected) {
      platformOffers = await OfferService.getOffers(0, 0, OfferService.targetAudienceEnum.PRO.id);
    } else {
      platformOffers = await OfferService.getOffers(0, 0, OfferService.targetAudienceEnum.PUBLIC.id);
    }
    // on récupère le type de chaque offre dans un tableau
    const offerTypes: Number[] = platformOffers.items.map(({offerTypeId})=>offerTypeId)
    // on utilise uniquement les offerTypeFilter qui matchent avec les types d'offres présentes pour la plateforme
    this.offerTypesToDisplay = this.offerTypeFilter.filter(({id}) => offerTypes.includes(id));
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get sortedMerchantsList(): OfferService.Merchant[] {
    return this.allMerchantsList.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  get sortedCategoriesList(): OfferService.Category[] {
    return this.allCategoriesList;
  }

  get geoItems(): OfferService.GeoData[] {
    return this.addressesItems;
  }

  removeFromAddresses(addr: any) {
    this.addressesItems = this.addressesItems.filter((v) => v.label !== addr.label);
    this.displayedAddresses = this.displayedAddresses.filter((v) => v.label !== addr.label);
  }

  @Watch('addressSearch')
  onSearchAddress(val: string) {
    if (val !== '' && val !== null && val.length >= 3) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?country=FR&language=fr&types=postcode,place&access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
      )
        .then((res) => res.json())
        .then((res) => {
          this.addressesItems = res.features.map((addr) => {
            let newLabel = '';
            if (addr.place_type.find((type) => type === 'postcode')) {
              newLabel = `${addr.context[0].text} (${addr.text})`;
            } else if (addr.place_type.find((type) => type === 'region' || type === 'place')) {
              newLabel = `${addr.text} (Toute la ville)`;
            } else {
              newLabel = `${addr.place_name}`;
            }
            return {
              value: {
                label: newLabel !== '' ? newLabel : addr.place_name,
                addr: addr.place_name,
                positionX: addr.geometry.coordinates[0],
                positionY: addr.geometry.coordinates[1],
              },
            };
          });
          this.displayedAddresses.forEach((v) => {
            if (!this.addressesItems.find((v1) => v1 === v)) {
              this.addressesItems.push(v);
            }
          });
        });
    } else {
      this.addressesItems = [];
      this.displayedAddresses.forEach((v) => {
        if (!this.addressesItems.find((v1) => v1 === v)) {
          this.addressesItems.push(v);
        }
      });
    }
  }

  @Watch('max', { immediate: true })
  onMaxChange(value) {
    const max = this.roundUpMax(value);
    this.internalMax = max;
    if (this.filterPoints[1] > max) {
      this.filterPoints = [this.filterPoints[0], max];
    }
  }

  @Watch('min', { immediate: true })
  onMinChange(value) {
    let min = this.roundDownMin(value);
    if (min < 100) {
      min = 100;
    }
    this.internalMin = min;
    if (this.filterPoints[0] < min) {
      this.filterPoints = [min, this.filterPoints[1]];
    }
  }

  mounted() {
    this.offersActiveCategories();
    this.defaultSortMode = this.sortMode;
    this.defaultFilterPoints = this.filterPoints;
    this.internalfilterParams.sortMode = '';
    this.internalfilterParams.filterPoints = [];
    this.internalfilterParams.categories = [];
    this.internalfilterParams.merchants = [];
    this.internalfilterParams.offerTypes = [];
    this.internalMin = this.min;
    this.internalMax = this.max;
    this.filterPoints = [this.roundDownMin(this.internalMin), this.roundUpMax(this.internalMax)];
    this.eventList = this.searchEvent ? this.searchEvent : [];
  }

  @Emit('onValidate')
  validate() {
    this.internalfilterParams.offerTypes = this.offerTypeList
      .filter((offerType) => {
        return offerType.isSelected ? offerType.id : null;
      })
      .map((offerType) => {
        return offerType.id;
      });

    if (this.displayedAddresses && this.displayedAddresses.length > 0) {
      this.internalfilterParams.geo = this.displayedAddresses;
    } else {
      this.internalfilterParams.geo = [];
    }

    if (this.selectedArea >= 0 && this.displayedAddresses.length === 1) {
      this.internalfilterParams.area = this.filterArea[this.selectedArea];
    } else {
      this.internalfilterParams.area = 0;
    }

    this.internalfilterParams.filterPoints = this.filterPoints;
    this.internalfilterParams.sortMode = this.sortMode;
    this.internalfilterParams.categories = [];
    this.internalfilterParams.merchants = this.merchantsList;
    this.internalfilterParams.categories = this.categoriesList;
    this.internalfilterParams.events = this.eventList;

    return this.internalfilterParams;
  }

  filterPointsChange(val: any) {
    if (
      this.offerTypeList.some((v) => {
        return v.id === OfferService.offerTypeEnum.SHOWCASE.id && v.isSelected;
      }) &&
      val[0] !== this.internalMin
    ) {
      this.offerTypeList.find((e) => e.id === OfferService.offerTypeEnum.SHOWCASE.id).isSelected = false;
    }
  }

  checkboxChange(eventId: any) {
    if (eventId !== this.eventSearch) {
      this.eventSearch = eventId;
      const page = 1;
      this.$router.replace({ query: { events: this.eventList.join(',') } });
    } else {
      this.eventSearch = '';
      this.$router.replace('/shop');
    }
  }

  reset(): void {
    this.sortMode = this.defaultSortMode;
    this.filterPoints = this.defaultFilterPoints;
    this.merchantsList = [];
    this.categoriesList = [];
    this.eventList = [];

    this.addressesItems = [];
    this.displayedAddresses = [];
    this.addressSearch = '';
    this.selectedArea = 0;
    this.eventSearch = '';

    for (let index = 0; index < this.offerTypeList.length; index++) {
      this.offerTypeList[index].isSelected = false;
    }

    this.$router.replace('/shop');

    this.validate();
  }

  triggerOfferTypes(): void {
    this.displayOfferTypes = !this.displayOfferTypes;
  }

  toggleOfferType(id: number): void {
    this.offerTypeList.find((e) => e.id === id).isSelected = !this.offerTypeList.find((e) => e.id === id).isSelected;
    if (this.offerTypeList.find((e) => e.id === id).id === OfferService.offerTypeEnum.SHOWCASE.id && this.offerTypeList.find((e) => e.id === id).isSelected) {
      this.filterPoints = [this.roundDownMin(0), this.roundUpMax(this.internalMax)];
    }
  }

  isSelected(isSelected: boolean): string {
    return isSelected ? 'selected' : '';
  }

  triggerMerchant(): void {
    this.displayMerchants = !this.displayMerchants;
  }

  triggerCategories(): void {
    this.displayCategories = !this.displayCategories;
  }

  triggerGeoloc(): void {
    this.displayGeoloc = !this.displayGeoloc;
  }

  roundDownMin(min: number): number {
    return Math.floor(min / 100) * 100;
  }

  roundUpMax(max: number): number {
    return Math.ceil(max / 100) * 100;
  }
}
