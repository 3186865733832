





















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SSOAccountOptionsTitle from './SSOAccountOptionsTitle.vue';
import { formRules } from '@/services/userSpace/UserService';
import { bindExistingAccount } from '@/api/resources/ssoApi';
import { loginAndRedirectToLanding } from '@/services/authSpace/sso.service';

const USER_NOT_FOUND = 'UserNotFound';
const errorMessages = {
  [USER_NOT_FOUND]: 'Compte fidélité introuvable',
};

@Component({
  components: {
    SSOAccountOptionsTitle,
  },
})
export default class SSOBindExistingAccountForm extends Vue {
  @Prop({ type: String, required: true }) userInfos!: string;

  submitting: boolean = false;

  errorCode: string = '';

  formRules = formRules;

  form = {
    currentEmail: '',
    dateOfBirth: '',
  };

  // ===== computed =======
  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get ssoName() {
    // TODO: Placer cette option en bdd lorsque
    // d'autres plateformes aura besoin de cette feature
    return 'Bibus';
  }

  get errorMessage() {
    if (!this.errorCode) {
      return '';
    }

    return errorMessages[this.errorCode] || `Oops. Une erreur est survenue ${this.errorCode}`;
  }

  // ===== methods =======
  async submit() {
    if (!(this.$refs.form as any).validate()) {
      return Promise.resolve();
    }

    this.submitting = true;
    this.errorCode = '';

    try {
      const response = await bindExistingAccount({
        userInfos: this.userInfos,
        dateOfBirth: this.form.dateOfBirth,
        platformUUID: this.platform.uuid,
        currentEmail: this.form.currentEmail,
      });

      return loginAndRedirectToLanding(response.tokens);
    } catch (e) {
      const axiosResponse = (e as any).response;
      if (axiosResponse && axiosResponse.data.name) {
        this.errorCode = axiosResponse.data.name;
      } else {
        throw e;
      }
    } finally {
      this.submitting = false;
    }

    return undefined;
  }
}
