import { apiAuthenticatedInstance, apiPublicInstance } from '@/services/API';
import { Service } from '@/services/merchantSpace/types';

export function findPlatformByUUID(uuid) {
  return apiPublicInstance()
    .get(`/api/platforms/${uuid}`)
    .then((res) => res.data);
}

export const getFooterLinks = async () => (await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}/footer`)).data;

export const getMerchantHome = async () => {
  return (await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}/merchantHome`)).data;
};

export const getPlatformFAQ = async () => {
  return (await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}/faq`)).data;
};

export const getShopSlider = async (uuid: string) => {
  return (await apiPublicInstance().get(`/api/platforms/${uuid}/shopSlider`)).data;
};

export const getPlatformServices = async (): Promise<Service[]> => (await apiAuthenticatedInstance().get(`/api/platforms/my/service`)).data;

export const getServiceDeskId = async () => {
  return (await apiPublicInstance().get(`/api/platforms/${process.env.VUE_APP_PLATFORM_UUID}/serviceDesk`)).data;
};
