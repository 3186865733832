






















import { Vue, Component, Prop } from 'vue-property-decorator';
import PageTitleIcon from '@/components/Svg/PageTitleIcon.vue';

@Component({ components: { PageTitleIcon } })
export default class PageTitle extends Vue {
  @Prop({ type: String, default: 'Title' })
  readonly text!: string;

  @Prop({ type: Boolean, default: false })
  readonly hideIcon!: boolean;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
