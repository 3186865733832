





























































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SvgPopup extends Vue {
  @Prop({
    type: [String, Number],
    required: false,
    default: '53.939',
  })
  readonly width!: string | number;

  @Prop({
    type: [String, Number],
    required: false,
    default: '43.353',
  })
  readonly height!: string | number;
}
