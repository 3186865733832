





















































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import OfferCard from '@/components/Card/OfferCard.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';

@Component({
  components: {
    OfferCard,
  },
})
export default class OfferShow extends Vue {
  @Prop({type: String, required: true})
  readonly title!: string;

  @Prop({type: String, required: true})
  readonly subtitle!: string;

  @Prop({type: String, required: false, default: 'Accéder à la boutique'})
  readonly catchphrase!: string;

  @Prop({type: Boolean, default: false})
  readonly bigLine!: boolean;

  @Prop({type: String, default: '100%'})
  readonly offersWidth!: string;

  list: any = [];

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get titleClass() {
    return this.bigLine ? 'offer-show__title-text offer-show__title-text--bigline' : 'offer-show__title-text';
  }

  get widthStyle() {
    return {
      width: this.offersWidth
    }
  }

  get chevronsStyle() {
    return {
      color : 'red'
    }
  }

  async mounted() {
    await OfferService.getOffers(1, 5, OfferService.targetAudienceEnum.PUBLIC.id).then((response: any) => {
      if (response.items.length && this.list.length < response.meta.totalItems) {
        this.list.push(...response.items);
      }
    });
  }

  onOfferCardClick(offerId: number): void {
    this.$router.push(`/shop/${offerId}`);
  }
}
