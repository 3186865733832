












import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import BasicDialog from './BasicDialog.vue';

@Component({
components: {
BasicDialog,
},
})
export default class ConfirmDeleteAccount extends Vue {

title = 'Oh non !';

content = 'Vous êtes sur le point de nous quitter. Êtes vous sûr(e) de vouloir supprimer votre compte ?';

buttontText= 'Valider la suppression';

@Emit('onClose')
onClose(): void { }

@Emit('confirmDelete')
confirmDelete(): void { }
}
