




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import ExternalCguAgreement from '@/components/Dialogs/ExternalCguAgreement.vue';
import SignInButton from '@/components/Button/SignInButton.vue';
import { SSOBehavior } from '@/services/merchantSpace/types';

@Component({
  components: {
    SvgPopup,
    ExternalCguAgreement,
    SignInButton,
  },
})
export default class ShopOfflineMessage extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  readonly title!: string;

  @Prop({
    type: String,
    required: false,
  })
  readonly description!: string;

  get isExternalConnectionActive() {
    return [SSOBehavior.SIGNIN_TO_EXTERNAL, SSOBehavior.AUTO_SIGNIN_TO_EXTERNAL, SSOBehavior.WITH_CLASSIC].includes(this.ssoBehavior);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get showStickers() {
    return !this.platform.style.hideStickers;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  goTo(route): void {
    this.$router.push(route);
    this.$emit('input', false);
  }
}
