































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

@Component({
  components: {
    SvgPopup,
  },
})
export default class RetailScalePoints extends Vue {
  @Prop({ required: false, default: '600px', type: String })
  maxWidth!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
