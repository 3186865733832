var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.internalSlider.length > 0
    ? _c(
        "div",
        { staticClass: "shop-slider", class: [_vm.isMobile ? "mobile" : ""] },
        [
          !_vm.isMobile
            ? _c(
                "div",
                { staticClass: "shop-slider__background" },
                [_vm.compShowStickers ? _c("ShopSliderBackground") : _vm._e()],
                1
              )
            : _vm._e(),
          _c(
            "v-carousel",
            {
              attrs: {
                "hide-delimiter-background": "",
                "show-arrows": !_vm.isMobile,
                "show-arrows-on-hover": !_vm.isMobile,
                cycle: ""
              },
              model: {
                value: _vm.carouselItem,
                callback: function($$v) {
                  _vm.carouselItem = $$v
                },
                expression: "carouselItem"
              }
            },
            _vm._l(_vm.internalSlider, function(slider) {
              return _c(
                "v-carousel-item",
                { key: slider.id, attrs: { eager: "" } },
                [
                  slider && slider.imageDesktop && slider.imageMobile
                    ? _c(
                        "div",
                        { staticClass: "shop-slider__img-wrapper" },
                        [
                          !_vm.isMobile
                            ? _c("v-img", {
                                key: slider.imageDesktop,
                                staticClass: "shop-slider__img rounded-lg",
                                attrs: {
                                  "lazy-src": slider.imageDesktop,
                                  src: slider.imageDesktop,
                                  contain: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.goToUrl(slider.url)
                                  }
                                }
                              })
                            : _c("v-img", {
                                key: slider.imageMobile,
                                staticClass:
                                  "shop-slider__img mobile rounded-lg",
                                attrs: {
                                  "lazy-src": slider.imageMobile,
                                  src: slider.imageMobile,
                                  contain: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.goToUrl(slider.url)
                                  }
                                }
                              }),
                          slider.title
                            ? _c(
                                "div",
                                {
                                  staticClass: "shop-slider__info",
                                  class: [_vm.isMobile ? "mobile" : ""]
                                },
                                [
                                  _c("div", {
                                    staticClass: "shop-slider__info-background"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "shop-slider__info-content"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "shop-slider__info-text"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(slider.title) + " "
                                          )
                                        ]
                                      ),
                                      slider.url
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "shop-slider__info-btn",
                                              attrs: {
                                                color: "shopoffre_fond_bouton"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.goToUrl(slider.url)
                                                }
                                              }
                                            },
                                            [_vm._v(" Découvrir ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }