var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapperClass },
    [
      _vm.status
        ? _c(
            "v-container",
            [
              _c("PageTitle", {
                staticClass: "d-md-flex",
                attrs: { text: "Vos applications synchronisées" }
              }),
              _c("p", {
                staticClass: "text-center applications-status",
                domProps: { innerHTML: _vm._s(_vm.statusText) }
              })
            ],
            1
          )
        : !_vm.appName
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("PageTitle", {
                staticClass: "d-md-flex",
                attrs: { text: "Vos applications synchronisées" }
              }),
              _c("p", { staticClass: "text-center pa-4 applications-text" }, [
                _vm._v(
                  "Synchroniser une de vos applications partenaires sur votre compte pour cumuler davantage de points."
                )
              ]),
              _c(
                "v-row",
                [
                  !_vm.showNewApps
                    ? [
                        _vm._l(_vm.synchronizedApps, function(app) {
                          return _c(
                            "v-col",
                            {
                              key: app.name,
                              staticClass: "applications-col",
                              attrs: {
                                xl: "4",
                                lg: "4",
                                md: "4",
                                sm: "6",
                                xs: "6"
                              }
                            },
                            [
                              _c(
                                "ApplicationCard",
                                {
                                  attrs: {
                                    name: app.name,
                                    logo: app.logo,
                                    synchronized: app.isSync,
                                    waitingValidation: !app.isSync
                                  }
                                },
                                [
                                  app.isSync
                                    ? _c("template", { slot: "sync" }, [
                                        _vm._v(" Synchronisée ")
                                      ])
                                    : _vm._e(),
                                  !app.isSync
                                    ? _c("template", { slot: "sync" }, [
                                        _vm._v(" En attente ")
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        _vm.synchronizableApps.length > 0
                          ? _c(
                              "v-col",
                              {
                                staticClass: "applications-col",
                                attrs: {
                                  xl: "4",
                                  lg: "4",
                                  md: "4",
                                  sm: "6",
                                  xs: "6"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "applications-card",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewApps = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "applications-card-icon",
                                            attrs: { size: "80" }
                                          },
                                          [_vm._v(" mdi-plus-circle ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.showNewApps
                    ? _vm._l(_vm.synchronizableApps, function(app) {
                        return _c(
                          "v-col",
                          {
                            key: app.name,
                            staticClass: "applications-col",
                            attrs: {
                              xl: "4",
                              lg: "4",
                              md: "4",
                              sm: "6",
                              xs: "6"
                            }
                          },
                          [
                            _c("ApplicationCard", {
                              attrs: { name: app.name, logo: app.logo }
                            })
                          ],
                          1
                        )
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _c(
            "v-container",
            [
              _vm.currentService
                ? _c("ApplicationSync", {
                    attrs: {
                      service: _vm.currentService,
                      appLogo: _vm.appLogo,
                      product: _vm.appProduct
                    }
                  })
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }