var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop" },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : [
            _c("div", { staticClass: "shop__title" }, [
              _vm.showStickers
                ? _c(
                    "div",
                    { staticClass: "shop__title-picto" },
                    [_c("SvgPopup")],
                    1
                  )
                : _vm._e(),
              !_vm.errorCode
                ? _c("h2", { staticClass: "shop__title-text" }, [
                    _vm._v(
                      " Bienvenue sur " + _vm._s(_vm.platform.name) + " ! "
                    )
                  ])
                : _c("h2", { staticClass: "shop__title-text" }, [
                    _vm._v(" Oups ! ")
                  ])
            ]),
            !_vm.errorCode
              ? _c("div", { staticClass: "shop__content my-4" }, [
                  _vm.registerActionPoints
                    ? _c("div", [
                        _vm.activatedService
                          ? _c("p", [
                              _vm._v(
                                "Votre email est maintenant validé et vous venez de gagner " +
                                  _vm._s(_vm.inscriptionPoints) +
                                  " points."
                              )
                            ])
                          : _c("p", [
                              _vm._v(
                                " Votre email est maintenant validé. Votre compte doit d’abord être validé par nos services avant de pouvoir vous créditer " +
                                  _vm._s(_vm.inscriptionPoints) +
                                  " points. "
                              )
                            ])
                      ])
                    : _c("p", [_vm._v("Votre email est maintenant validé.")]),
                  _c("p", [
                    _vm._v(
                      "Bénéficiez dès à présent des offres de notre boutique et des avantages du programme."
                    )
                  ])
                ])
              : _c("div", { staticClass: "shop__content my-4" }, [
                  _c("p", [_vm._v("Le lien de confirmation n'est pas valide.")])
                ]),
            _c(
              "div",
              { staticClass: "shop__actions" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "popup_fond_bouton",
                      rounded: "",
                      large: "",
                      width: "80%",
                      to: "/"
                    }
                  },
                  [_vm._v(" Découvrir la boutique ")]
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }