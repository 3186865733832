











































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Maintenance extends Vue {
  get isLaPoste(): boolean {
    const laPosteUUIDs = [
      '6a5283f2-3475-4b7a-b940-4a861608866a', // PREPROD
      '53da56f2-6384-4fbd-8e60-17a9edc10511', // PROD
    ];
    return laPosteUUIDs.some((e) => e === this.$store.getters['platform/getPlatform'].uuid);
  }

  get platformLogo() {
    return `${process.env.VUE_APP_API_URL}/api${this.$store.getters['platform/getPlatform'].style.logoUrl}`;
  }

  get backgroundImage() {
    return {
      backgroundImage: `url(${require('@/assets/maintenance/laposte-background.webp')})`,
    };
  }

  mounted() {
    document.body.style.overflow = 'hidden';
    (document.querySelector('html') as any).style.overflow = 'hidden';
  }
}
