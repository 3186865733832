var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cashback-transac" },
    [
      _c(
        "v-card-title",
        {
          staticClass: "cashback-transac__dropdown",
          on: { click: _vm.triggerTransactions }
        },
        [
          _c("div", { staticClass: "cashback-transac__dropdown-title" }, [
            _vm._v("Suivi des transactions")
          ]),
          _c("v-spacer"),
          _c("v-icon", {
            staticClass: "cashback-transac__dropdown-icon",
            attrs: { medium: "", color: "homeglobal_picto" },
            domProps: {
              textContent: _vm._s(
                "fas " +
                  (_vm.displayTransactions
                    ? "fa-chevron-down"
                    : "fa-chevron-up")
              )
            }
          })
        ],
        1
      ),
      _c("v-divider"),
      _vm.displayTransactions
        ? _c(
            "div",
            { staticClass: "cashback-transac-content" },
            [
              _vm.compTransactionsLength === 0
                ? _c(
                    "span",
                    { staticClass: "cashback-transac__content-empty" },
                    [_vm._v(" Aucune transaction effectuée ")]
                  )
                : _vm._e(),
              _vm._l(_vm.transactions, function(item, $index) {
                return _c(
                  "div",
                  {
                    key: $index,
                    staticClass: "orders-list-item",
                    attrs: { "data-num": $index + 1 }
                  },
                  [
                    _c("CashbackTransactionLine", {
                      attrs: {
                        date: item.date,
                        name: item.shopName,
                        gain: item.gain,
                        amountPayed: item.amount,
                        reduction: item.percentCashback,
                        unitReduction: item.unitCashback,
                        status: item.transacState.status
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }