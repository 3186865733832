var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "order-confirmed-card" },
    [
      !_vm.isFundingOffer
        ? _c("v-card-title", { staticClass: "order-confirmed-card-title" }, [
            _vm._v(" Félicitations ! ")
          ])
        : _c("v-card-title", { staticClass: "order-confirmed-card-title" }, [
            _vm._v(" Validé ! ")
          ]),
      _c("v-card-text", { staticClass: "order-confirmed-card-subtitle" }, [
        _vm.isStandardOffer
          ? _c("p", [
              _vm._v("Merci de votre achat."),
              _c("br"),
              _vm._v("Nous vous enverrons les instructions par "),
              _c("span", [_vm._v("email")]),
              _vm._v(".")
            ])
          : _vm._e(),
        _vm.isFundingOffer
          ? _c("p", [
              _vm._v(
                "Merci pour votre contribution. Nous vous enverrons votre validation par "
              ),
              _c("span", [_vm._v("email")]),
              _vm._v(".")
            ])
          : _vm._e(),
        _vm.isLuckOffer
          ? _c("p", [
              _vm._v(
                " Merci de votre participation. Le tirage au sort aura lieu le "
              ),
              _c("span", [_vm._v(_vm._s(_vm.drawDate) + " ")]),
              _vm._v(". ")
            ])
          : _vm._e()
      ]),
      _c("BalancePointsCard", {
        staticClass: "order-confirmed-card-points",
        attrs: { title: _vm.beforeTitleText, points: _vm.pointsBefore }
      }),
      _c("BalancePointsCard", {
        staticClass: "order-confirmed-card-points",
        attrs: { title: _vm.afterTitleText, points: _vm.pointsAfter }
      }),
      _vm.isFundingOffer
        ? _c(
            "router-link",
            {
              staticClass: "order-confirmed-card-subtitle-link",
              attrs: { to: { name: "orders" } }
            },
            [_vm._v(" Accéder au suivi de commande ")]
          )
        : _vm._e(),
      _vm.offer.offerTypeId === 5
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "order-confirmed-card-button",
                  attrs: {
                    large: "",
                    rounded: "",
                    color: "popup_fond_bouton",
                    target: "_blank",
                    href: _vm.trackingUrl
                  }
                },
                [_vm._v(" Activer le cashback ")]
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "order-confirmed-card-button",
                  attrs: {
                    large: "",
                    rounded: "",
                    color: "popup_fond_bouton",
                    href: "/shop",
                    "data-cy": "order-confirmed-shop-return"
                  }
                },
                [_vm._v(" Retour à la boutique ")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }