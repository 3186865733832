































import Vue from 'vue';
import Component from 'vue-class-component';
import SSOAccountOptionsTitle from './SSOAccountOptionsTitle.vue';

@Component({
  components: {
    SSOAccountOptionsTitle,
  },
})
export default class SSOPairingModeSelection extends Vue {
  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }
}
