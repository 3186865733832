




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import { getOffers, targetAudienceEnum } from '@/services/merchantSpace/OfferService';
import OfferCard from '@/components/Card/OfferCard.vue';
import { Offer } from '@/services/merchantSpace/types';

@Component({ components: { OfferCard, VueSlickCarousel } })
export default class OfferSlider extends Vue {
  settings: any = {
    infinite: false,
    dots: true,
    centerPadding: '20px',
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  loading: boolean = true;

  hasError: boolean = false;

  page: number = 1;

  resultCount: number = 5;

  offerList: Offer[] = [];

  @Prop({ type: Boolean, default: true })
  readonly light!: boolean;

  async mounted() {
    await this.loadOffers();
  }

  async loadOffers() {
    try {
      const response = await getOffers(this.page, this.resultCount, targetAudienceEnum.PUBLIC.id);
      this.offerList = [...response.items];
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
      this.hasError = true;
    }
  }

  onOfferCardClick(offerId: number): void {
    this.$router.push(`/shop/${offerId}`);
  }

  get showSlider() {
    return (!this.loading || !this.hasError) && this.offerList.length > 0;
  }
}
