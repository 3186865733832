














































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

@Component({
  components: {
    SvgPopup,
  },
})
export default class BasicDialog extends Vue {
  @Prop({ required: false, default: '600px', type: String })
  maxWidth!: string;

  @Prop({ required: true, type: String })
  title!: string;

  @Prop({ required: true, type: String })
  buttonText!: string;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  @Emit('onSubmit')
  onSubmit(): void {}
}
