var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop", style: _vm.christmasFideliteTcl },
    [
      !_vm.$vuetify.breakpoint.xsOnly
        ? _c("PageTitle", {
            staticClass: "shop-title",
            attrs: { text: "Boutique", hideIcon: "" }
          })
        : _vm._e(),
      !_vm.$vuetify.breakpoint.xsOnly ? _c("ShopSlider") : _vm._e(),
      _c("ShopOffers", { attrs: { searchEvent: _vm.searchEvent } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }