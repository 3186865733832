










































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as common from '@/services/common/common';
import SvgHomePointsPicto from '@/components/Home/SvgHomePointsPicto.vue';
import SvgHomeBackground from '@/components/Home/SvgHomeBackground.vue';
import SignInButton from '../Button/SignInButton.vue';
import SignUpButton from '../Button/SignUpButton.vue';
import { SSOBehavior } from '@/services/merchantSpace/types';

@Component({
  components: {
    SvgHomePointsPicto,
    SvgHomeBackground,
    SignInButton,
    SignUpButton,
  },
})
export default class HomeIntro extends Vue {
  @Prop({ type: String, required: true })
  readonly signinTitle!: string;

  @Prop({ type: String, required: true })
  readonly signinSubtitle!: string;

  @Prop({ type: String, required: true })
  readonly signinDescription!: string;

  @Prop({ type: String, required: false, default: 'right' })
  readonly signinDescriptionAlign!: string;

  @Prop({ type: String, required: false, default: 'Inscription' })
  readonly authTextBtn!: string;

  @Prop({ type: String, required: false, default: '1' })
  readonly opacity!: string;

  @Prop({ type: String, required: false, default: 'none' })
  readonly boxShadow!: string;

  @Prop({ type: String, required: false, default: '0' })
  readonly paddingBlock!: string;

  @Prop({ type: String, required: false, default: null })
  readonly redirectUrl!: string;

  @Prop({ type: Boolean, default: false })
  readonly squareImage!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly bigLine!: boolean;

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get showStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  get showSignUpBtn() {
    if (this.ssoBehavior === SSOBehavior.NONE) {
      return true;
    }

    return this.$store.getters['platform/signUpIsShow'];
  }

  get firstBlockImage() {
    return this.$store.getters['platform/getPlatform'].style.firstBlockImage;
  }

  get compFirstBlockImage() {
    return `${process.env.VUE_APP_API_URL}/api${this.firstBlockImage}`;
  }

  get introBlockClass () {
    return this.squareImage ? 'intro__block__narrow' : 'intro__block';
  }

  get blockIntroBlockStyle() {
    return {
      opacity: this.opacity,
      'box-shadow': this.boxShadow,
    };
  }

  get blockTextTitleClass() {
    let titleClass = 'intro__block__text--title';

    if (this.squareImage) {
      titleClass += ' intro__block__text--title--noline';
    }

    if (this.bigLine) {
      titleClass += ' intro__block__text--title--bigline';
    } 

    return titleClass;
  }

  get blockIntroStyle() {
    return {
      'padding-top': this.paddingBlock,
    };
  }
}
