

















import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { nextQrCodeReward, saveCurrentQrCode } from '@/services/merchantSpace/qrcode.service';
import { getApiErrorCode } from '@/api/utils';

const errorMessages = {
  InvalidQrcodeException: 'Qrcode invalide',
  BadQrcodeConfigurationException: 'Erreur de configuration plateforme',
  ActionLimitFetchedException: 'Limite de trajet atteinte',
  ActionIntervalFetchedException: 'Limite de trajet atteinte pour la période actuelle',
  fallback: 'La validation de trajet rencontre un soucis'
}

@Component({})
export default class QrcodeScanManager extends Vue {
  notification: any = null;

  created() {
    saveCurrentQrCode();
    this.nextScan();
  }

  @Watch('isConnected', { immediate: true })
  async onConnectionChange(isConnected){
    return isConnected && this.nextScan()
  }

  async nextScan() {
    if (!this.isConnected) return;

    const { state, job } = nextQrCodeReward();
    if (state === 'rewarding') {
      // TODO: Revoir feedback
      this.notification = {
        message: 'Validation de trajet en cours...',
        type: 'info',
        timeout: -1
      };

      await job()
        .then(() => {
          this.notification = {
            message: 'Trajet validé',
            type: 'success',
            timeout: 2000
          };
        })
        .catch(e => {
          console.error('Qrcode scan failed', e)
          const errorCode = getApiErrorCode(e);

          this.notification = {
            // TODO: message en fonction de l'erreur
            message: errorMessages[errorCode] || errorMessages.fallback,
            type: 'error',
            timeout: 4000
          };
        });

      setTimeout(() => {
        this.nextScan();
      }, 6000);
    }
  }


  get isConnected() {
    return this.$store.getters['user/isConnected'];
  }
}
