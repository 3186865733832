








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ShopSliderBackground from '@/components/Shop/ShopSliderBackground.vue';
import { getShopSlider } from '@/api/resources/platformApi';

@Component({
  components: {
    ShopSliderBackground,
  },
})
export default class ShopSlider extends Vue {
  internalSlider: any = [];

  loading: boolean = false;

  carouselItem: number = 0;

  async mounted() {
    this.loading = true;
    const platformUuid = this.$store.getters['platform/getPlatform'].uuid;
    const sliders = await getShopSlider(platformUuid);
    sliders.forEach((val, id) => {
      if (!val.bddId) {
        val.bddId = val.id;
        val.id = id + 1;
      }
      if (!val.imageMobileName && !val.imageDesktopName) {
        val.imageDesktopName = val.imageDesktop.uuid;
        val.imageDesktop = `${process.env.VUE_APP_API_URL}/api/files/shopSlider/${val.imageDesktop.filename}`;
        val.imageMobileName = val.imageMobile.uuid;
        val.imageMobile = `${process.env.VUE_APP_API_URL}/api/files/shopSlider/${val.imageMobile.filename}`;
      }
      this.internalSlider.push(val);
    });
    this.internalSlider.sort((a, b) => {
      return a.index - b.index;
    });
    this.loading = false;
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  goToUrl(url: any) {
    if (url) {
      if (url.includes(window.location.host)) {
        window.location.href = url;
      } else {
        window.open(url, '_blank');
      }
    }
  }
}
