












import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import BasicDialog from '@/components/Dialogs/BasicDialog.vue';

import * as UserService from '../../services/userSpace/UserService';

@Component({
  components: {
    SvgPopup,
    BasicDialog,
  },
})
export default class ConfirmProfileUpdate extends Vue {
  title = 'Confirmation';

  content = 'Vos modifications ont été prises en compte.';

  mustReconnectContent = `Vous allez être déconnecté puis redirigé vers l'accueil pour vous reconnecter avec votre nouvelle adresse email.`;

  buttonText = 'Retour';

  @Prop({ type: Boolean, required: false })
  readonly mustReconnect!: false;

  @Emit('input')
  onClose(): void {
    this.$router.push('/dashboard/profile');
    this.$emit('input');
  }

  goToProfile(): void {
    if (this.mustReconnect) {
      UserService.resetAccessToken();
    } else {
      this.$router.push('/dashboard/profile');
      this.$emit('input');
    }
  }
}
