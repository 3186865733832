var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "56",
        height: "53.6",
        viewBox: "0 0 56 53.6"
      }
    },
    [
      _c("g", { attrs: { id: "prefix__star_1_", "data-name": "star (1)" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "prefix__star_1_2",
              "data-name": "star (1)",
              transform: "translate(0 -10.971)"
            }
          },
          [
            _c("path", {
              staticClass: "prefix__cls-2",
              attrs: {
                id: "prefix__Tracé_2550",
                d:
                  "M93.644 31.25l-14.628-1.357L73.205 16.4a1.172 1.172 0 0 0-2.153 0l-5.811 13.493-14.628 1.357a1.172 1.172 0 0 0-.665 2.048l11.037 9.7-3.23 14.331A1.172 1.172 0 0 0 59.5 58.59l12.632-7.5 12.632 7.5a1.172 1.172 0 0 0 1.736-1.266l-3.23-14.331 11.037-9.7a1.172 1.172 0 0 0-.663-2.043z",
                "data-name": "Tracé 2550",
                transform: "translate(-44.128 -4.204)"
              }
            }),
            _c(
              "g",
              {
                attrs: {
                  id: "prefix__Groupe_733",
                  "data-name": "Groupe 733",
                  transform: "translate(0 10.971)"
                }
              },
              [
                _c("path", {
                  staticClass: "prefix__cls-3",
                  attrs: {
                    id: "prefix__Tracé_2551",
                    d:
                      "M92.3 11.732c.746 1.036 2.851 5.484 4.054 8.076a.407.407 0 0 1-.649.467c-2.077-1.964-5.628-5.371-6.373-6.407a1.829 1.829 0 1 1 2.968-2.136z",
                    "data-name": "Tracé 2551",
                    transform: "translate(-79.257 -10.971)"
                  }
                }),
                _c("path", {
                  staticClass: "prefix__cls-3",
                  attrs: {
                    id: "prefix__Tracé_2552",
                    d:
                      "M359.379 11.732c-.746 1.036-2.851 5.484-4.054 8.076a.407.407 0 0 0 .649.467c2.077-1.964 5.628-5.371 6.373-6.407a1.829 1.829 0 0 0-2.968-2.137z",
                    "data-name": "Tracé 2552",
                    transform: "translate(-316.425 -10.971)"
                  }
                }),
                _c("path", {
                  staticClass: "prefix__cls-2",
                  attrs: {
                    id: "prefix__Tracé_2553",
                    d:
                      "M424.032 284.132c-1.213-.4-6.091-1.049-8.926-1.408a.407.407 0 0 0-.248.76c2.5 1.382 6.827 3.73 8.04 4.125a1.829 1.829 0 0 0 1.134-3.477z",
                    "data-name": "Tracé 2553",
                    transform: "translate(-369.294 -252.997)"
                  }
                }),
                _c("path", {
                  staticClass: "prefix__cls-2",
                  attrs: {
                    id: "prefix__Tracé_2554",
                    d:
                      "M1.262 284.132c1.213-.4 6.091-1.049 8.926-1.408a.407.407 0 0 1 .248.76c-2.5 1.382-6.827 3.73-8.04 4.125a1.829 1.829 0 0 1-1.134-3.477z",
                    "data-name": "Tracé 2554",
                    transform: "translate(0 -252.997)"
                  }
                }),
                _c("path", {
                  staticClass: "prefix__cls-3",
                  attrs: {
                    id: "prefix__Tracé_2555",
                    d:
                      "M239.794 408.956c0-1.276.891-6.116 1.429-8.923a.407.407 0 0 1 .8 0c.538 2.807 1.429 7.647 1.429 8.923a1.829 1.829 0 0 1-3.657 0z",
                    "data-name": "Tracé 2555",
                    transform: "translate(-213.567 -357.185)"
                  }
                })
              ]
            ),
            _c("path", {
              staticClass: "prefix__cls-4",
              attrs: {
                id: "prefix__Tracé_2556",
                d:
                  "M75.318 21.307L73.205 16.4a1.172 1.172 0 0 0-2.153 0l-5.811 13.493-14.628 1.357a1.172 1.172 0 0 0-.665 2.048l11.037 9.7-3.23 14.331A1.172 1.172 0 0 0 59.5 58.59l1.681-1a91 91 0 0 1 14.137-36.283z",
                "data-name": "Tracé 2556",
                transform: "translate(-44.128 -4.204)"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }