








































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';

export enum Action {
  SHOW_LOGIN = 'showLogin',
}

@Component({
  components: {
    SvgPopup,
  },
})
export default class PasswordResetSuccess extends Vue {
  navigateToHome() {
    this.$router.replace('/');
    this.$store.dispatch(Action.SHOW_LOGIN, true);
  }

  @Emit('input')
  showDialog(bool: boolean = false) {
    return bool;
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }
}
