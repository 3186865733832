var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-message" },
    [
      _c("img", {
        staticClass: "signup-message__logo",
        attrs: { src: _vm.logo, alt: "logo" }
      }),
      _c("div", { staticClass: "signup-message__title" }, [
        _vm.compShowStickers
          ? _c(
              "div",
              { staticClass: "signup-message__title-picto" },
              [_c("SvgSignup")],
              1
            )
          : _vm._e(),
        _c("h1", {
          staticClass: "signup-message__title-text",
          domProps: { innerHTML: _vm._s(_vm.title) }
        })
      ]),
      _c("p", {
        staticClass: "signup-message__text",
        domProps: { innerHTML: _vm._s(_vm.desc) }
      }),
      _c(
        "v-btn",
        {
          staticClass: "signup-message__signup-btn",
          attrs: {
            color: "homeglobal_fond_bouton",
            "data-cy": "signup-popin-inscription-button",
            rounded: ""
          },
          on: { click: _vm.showSignup }
        },
        [_vm._v(" Inscription ")]
      ),
      _c(
        "a",
        {
          staticClass: "signup-message__link",
          attrs: { href: "#" },
          on: { click: _vm.showSignin }
        },
        [_vm._v(" Déjà inscrit(e) ? ")]
      ),
      _vm.compShowStickers
        ? _c("SvgSignupStrip", {
            staticClass: "signup-message__strip",
            attrs: { alt: "strip" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }