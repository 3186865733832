

































































import { Component, Vue } from 'vue-property-decorator';
import { UserInfosForPatch } from '@/services/userSpace/types';
import * as UserServices from '@/services/userSpace/UserService';
import { getTranslation } from '@/i18n';

@Component
export default class ExternalCguAgreement extends Vue {
  form: UserInfosForPatch = {
    externalAccountId: '',
  };

  errorContactingService: any = null;

  async submit() {
    this.errorContactingService = null;
    this.$emit('signNewCgu');
    try {
      await UserServices.updateUserFields(this.userId, this.form, 'fr');
    } catch (e) {
      this.errorContactingService = 'Une erreur est survenu lors de le mise à jour de la newsletter. Veuillez réessayer plus tard.';
    }
  }

  get userId() {
    return this.$store.getters['user/getUser'].id;
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get title() {
    return getTranslation(`${this.platform.key}.externalCGUAgreement.title`) || 'Bonjour';
  }

  get agreementContent() {
    return (
      getTranslation(`${this.platform.key}.externalCGUAgreement.agreementContent`) ||
      "Vos données personnelles seront utilisées pour le gain de vos points,<br />l'achat en boutique et à des fins statistiques."
    );
  }

  get agreementBtnText() {
    return getTranslation(`${this.platform.key}.externalCGUAgreement.agreeBtnText`) || 'Accepter les CGU';
  }
}
