import store from '@/store';
import { PlatformKey, ServiceKey } from '@/constants';
import { findPlatformByUUID } from '@/api/resources/platformApi';

export interface BannerInterface {
  bannerActivated: boolean;
  bannerText: string;
  bannerType: 'success' | 'info' | 'warning' | 'error';
  postSignupBannerActivated: boolean;
  postSignupBannerText: string;
  postSignupBannerType: 'success' | 'info' | 'warning' | 'error';
}

export enum SERVICE_TYPE_ENUM {
  platform = 1,
  transportationSystem,
  carPooling,
  bike,
  train,
  bus,
  multimodal,
  mobilityCreator,
}

export async function loadPlatformByUUID(platformUUID) {
  const platform = await findPlatformByUUID(platformUUID);
  await store.dispatch('platform/setPlatform', platform);
  return platform;
}

// TODO: use service key instead of service name
export const MOBILITY_CREATOR_APPS: { [key: string]: string } = {
  [ServiceKey.BIBUS_MTICKET]: require('@/assets/providers/bibus.png'),
  [ServiceKey.WEFLO]: require('@/assets/providers/weflo.png'),
  [ServiceKey.IRIGO_ATOUT]: require('@/assets/providers/irigo-atout.jpg'), // TODO: change image
  [ServiceKey.IRIGO_MTICKET]: require('@/assets/providers/irigo-mticket.png'), // TODO: change image
};

export const getServiceType = (id: number) => {
  return SERVICE_TYPE_ENUM[id];
};

export const isApplicationType = (id: number) => {
  const serviceType = getServiceType(id);
  return serviceType === SERVICE_TYPE_ENUM[8] || serviceType === SERVICE_TYPE_ENUM[2];
};

export const getMobilityCreatorData = (serviceKey: string) => {
  return MOBILITY_CREATOR_APPS[serviceKey];
};

// Everyone has to consent for cookies except Ecobonus.
// Ecobonus cookies consentment is handle at Wordline level.
// Not the greatest code so far.
// Set this configuration in database
// as soon as more platform ask to hide the popup consentment.
export function hasToConsentForCookies(platform) {
  return !platform || platform.key !== PlatformKey.ECOBONUS;
}
