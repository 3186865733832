export interface UserCreationRequest {
  lastname?: string;
  firstname?: string;
  dateOfBirth: Date;
  email: string;
  password: string;
  externalAccountId: string;
  referrerCode?: string;
}

export type UpdateProfileFormRules = {
  [key: string]: Array<(v: any) => boolean | string>;
};

export interface UserPersonalInfoRequest {
  lastname?: string;
  firstname?: string;
  dateOfBirth?: Date;
  phoneNumber?: string;
  deliveryAdress?: string;
  zipCode?: string;
  city?: string;
}

export interface User {
  id: number;
  email: string;
  password: string;
  externalAccountId: string;
  referralCode?: string;
  lastname?: string;
  firstname?: string;
  dateOfBirth?: Date;
  phoneNumber?: string;
  deliveryAdress?: string;
  zipCode?: string;
  city?: string;
  newsletter?: boolean;
  deactivated?: boolean;
  deactivatedAt?: Date;
  userServices: any[];
  emailConfirmation?: boolean;
}

export interface SignupStepValues {
  lastname: string;
  firstname: string;
  dateOfBirth: Date | null;
  email: string;
  password: string;
  externalAccountId: string;
  referrerCode: string;
  gcuAndConfidentiality: boolean;
  parentalAgreement: boolean;
  minorConsent: boolean;
  gamesRegulation: boolean;
  newsletter: boolean;
  // service utilisé pour la création de compte
  services: { id: number; name: string; value: string | null }[];
}

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  BAD_GATEWAY = 502,
}

export interface Question {
  id: number;
  name: string;
  questionTypeId: number;
  answers?: Answer[];
}

export interface Answer {
  id: number;
  name: string;
}

export interface AnswerToSend {
  questionId: number;
  id?: number;
  name?: string;
}

export interface UserInfosForPatch {
  email?: string;
  lastname?: string;
  firstname?: string;
  externalAccountId: string;
  dateOfBirth?: Date | string | null;
  phoneNumber?: string;
  deliveryAdress?: string;
  zipCode?: string | null;
  city?: string;
  newsletter?: boolean;
}

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export interface LoginResponse {
  tokens: AuthTokens;
}

export interface SynchroResponse extends LoginResponse {
  synchroURL: string;
}
