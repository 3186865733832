var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "192",
        height: "192",
        viewBox: "0 0 192 192"
      }
    },
    [
      _c("defs"),
      _c("circle", {
        staticClass: "cls-1",
        attrs: { id: "Ellipse_212", cx: "96", cy: "96", r: "96" }
      }),
      _c(
        "g",
        { attrs: { id: "Groupe_734", transform: "translate(20.418 13.102)" } },
        [
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2557",
              d:
                "M65.725 139.888l8.071 3.886a2.757 2.757 0 0 1 1.694 3.736l1.345.648a.5.5 0 0 1 .249.7l-.1.3a.548.548 0 0 1-.747.249l-1.345-.648c-.9 1.544-2.242 1.943-3.985 1.1l-8.12-3.836c-1.943-.947-2.341-2.441-1.445-4.384l.1-.149c.895-1.901 2.34-2.499 4.283-1.602zm-.3 1.495c-1.395-.7-2.192-.4-2.79.8s-.3 1.993 1.146 2.69l7.373 3.537c1.445.648 2.192.4 2.79-.8s.3-2.043-1.1-2.69z",
              transform: "translate(-60.885 -93.009)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2558",
              d:
                "M72.941 120.457l9.665 6.078c1.843 1.2 2.043 2.64.9 4.434v.1c-1.146 1.793-2.591 2.242-4.434 1.046l-9.672-6.078a.5.5 0 0 1-.2-.747l.2-.3a.5.5 0 0 1 .8-.149l9.316 5.879c1.3.8 2.092.7 2.79-.448s.5-1.893-.8-2.69l-9.362-5.882a.548.548 0 0 1-.149-.8l.2-.3a.448.448 0 0 1 .747-.149z",
              transform: "translate(-65.012 -83.392)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2559",
              d:
                "M95.376 114.876l-2.939 3.687a.548.548 0 0 1-.8.1l-10.811-8.619q-.448-.349-.149-.747l2.939-3.637c.2-.3.448-.3.747-.1l.2.2a.448.448 0 0 1 .1.747l-2.292 2.939 4.135 3.288 1.943-2.441a.448.448 0 0 1 .747-.05l.2.149a.448.448 0 0 1 .1.747l-1.896 2.442 4.284 3.437 2.391-2.989a.448.448 0 0 1 .747-.05l.2.149q.453.399.154.748z",
              transform: "translate(-70.758 -75.918)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2560",
              d:
                "M99.785 96.759l-.249.3a.5.5 0 0 1-.747 0l-1.046-1c-1-1-1.843-1.046-2.74-.149s-.8 1.744.2 2.74l.747.7c.747.747 1.2.7 2.242.4l2.242-.6a2.963 2.963 0 0 1 3.188.6l1.445 1.395c1.544 1.495 1.445 2.939-.05 4.484h-.05c-1.495 1.495-2.889 1.644-4.484.1l-1.345-1.345a.448.448 0 0 1-.05-.747l.249-.3a.548.548 0 0 1 .8.05l1.1 1.1c1.046 1 1.893 1.1 2.79.149s.8-1.793-.2-2.79l-.847-.8c-.747-.747-1.245-.747-2.341-.448l-2.242.6a2.765 2.765 0 0 1-3.139-.548l-1.3-1.245c-1.495-1.445-1.3-3.039.1-4.434a2.924 2.924 0 0 1 4.484-.149l1.245 1.245a.448.448 0 0 1 0 .7z",
              transform: "translate(-76.96 -70.106)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2561",
              d:
                "M109 80.673l.149.2a.5.5 0 0 1-.05.8l-1.544 1.3 8.32 10.013c.2.3.2.548-.05.747l-.349.3a.5.5 0 0 1-.747-.1l-8.32-10.013-1.544 1.3a.448.448 0 0 1-.747-.1l-.2-.2a.5.5 0 0 1 .05-.8l4.284-3.537a.5.5 0 0 1 .747.1z",
              transform: "translate(-82.394 -63.386)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2562",
              d:
                "M127.127 88.294l-.3.249a.5.5 0 0 1-.747-.149l-8.02-11.309c-.2-.3-.2-.548.1-.747l.3-.249a.548.548 0 0 1 .8.149l7.971 11.309a.448.448 0 0 1-.1.747z",
              transform: "translate(-89.507 -61.143)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2563",
              d:
                "M129.43 67.258h.149c1.843-1.1 3.338-.8 4.434 1.046l4.484 7.772c1.1 1.843.6 3.338-1.245 4.434h-.152c-1.843 1.1-3.388.8-4.434-1.1l-4.533-7.722c-1.095-1.888-.547-3.334 1.297-4.43zm7.024 12.006c1.2-.648 1.395-1.495.6-2.84l-4.135-7.074c-.747-1.345-1.544-1.594-2.74-.9s-1.345 1.495-.6 2.84l4.135 7.074c.797 1.349 1.594 1.598 2.74.901z",
              transform: "translate(-94.35 -56.448)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2564",
              d:
                "M151.181 72.174l-.3.149a.5.5 0 0 1-.7-.349l-5.331-12.753c-.1-.349-.05-.6.3-.747l.448-.2a.548.548 0 0 1 .7.2l7.871 8.569-4.135-9.964a.5.5 0 0 1 .3-.747l.249-.1c.3-.149.548-.05.7.3l5.331 12.8c.149.349.05.6-.3.7l-.349.2a.6.6 0 0 1-.747-.2l-8.018-8.718 4.235 10.163c.145.348.045.597-.254.697z",
              transform: "translate(-103 -51.201)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2565",
              d:
                "M179.549 52.065l1.843 8.419c.448 2.092-.5 3.388-2.541 3.836l-2.79.6a.548.548 0 0 1-.648-.448l-2.939-13.551a.5.5 0 0 1 .4-.648l2.79-.6c2.092-.447 3.436.3 3.885 2.392zm-3.687-1l-1.793.349 2.591 11.956 1.793-.4c1.345-.3 1.744-1 1.445-2.541l-1.698-7.666c-.295-1.545-.992-1.993-2.337-1.694z",
              transform: "translate(-116.87 -47.88)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2566",
              d:
                "M199.576 61.288l-4.733.448c-.3 0-.5-.149-.548-.548L193 47.439c0-.4.149-.6.5-.648l4.633-.4c.3-.05.5.149.548.5v.249c0 .4-.149.6-.5.648l-3.687.3.5 5.281 3.089-.249c.3-.05.5.149.548.5v.249a.548.548 0 0 1-.5.6l-3.089.3.5 5.48 3.786-.349c.349-.05.548.149.6.5v.3a.5.5 0 0 1-.352.588z",
              transform: "translate(-127.183 -46.293)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2567",
              d:
                "M221.918 60.6v.3a.5.5 0 0 1-.548.548l-4.384-.3a.448.448 0 0 1-.5-.548l.747-13.849c.05-.349.249-.548.548-.548h.4c.349 0 .548.2.5.6l-.747 12.953 3.487.2a.548.548 0 0 1 .5.648z",
              transform: "translate(-138.963 -46.2)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2568",
              d:
                "M235.239 63.819l-.4-.1c-.3-.05-.448-.2-.448-.6v-3.236l-3.437-.6-1.146 3.039c-.1.349-.3.5-.648.4h-.3c-.4-.05-.5-.3-.4-.7l5.14-13.148a.5.5 0 0 1 .648-.4l.747.149a.548.548 0 0 1 .5.6l.4 14.1a.52.52 0 0 1-.656.496zm-.947-5.281l-.1-7.921-2.792 7.423z",
              transform: "translate(-144.961 -47.329)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2569",
              d:
                "M260.186 60.709l-.349-.1a.448.448 0 0 1-.3-.7l.448-1.345c.5-1.345.2-2.092-1-2.541s-1.943 0-2.391 1.345l-.349 1c-.349.947-.149 1.345.548 2.142l1.544 1.793a3.093 3.093 0 0 1 .8 3.139l-.648 1.893c-.747 2.043-2.092 2.591-4.135 1.843-2.043-.7-2.79-1.943-2.043-4.035l.648-1.793a.5.5 0 0 1 .7-.349l.349.1a.5.5 0 0 1 .3.7l-.548 1.495c-.448 1.345-.15 2.192 1.046 2.591s1.943 0 2.441-1.345l.4-1.146c.349-.947.1-1.445-.6-2.292l-1.495-1.744c-.9-1.1-1.245-1.843-.8-3.089l.6-1.694a2.832 2.832 0 0 1 4.035-1.843h.05c1.843.648 2.74 1.993 2.043 3.985l-.6 1.644a.448.448 0 0 1-.7.349z",
              transform: "translate(-156.805 -50.347)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2570",
              d:
                "M271.1 76.233l-4.235-2.043a.5.5 0 0 1-.249-.747l6.078-12.455a.5.5 0 0 1 .7-.249l4.185 2.043a.5.5 0 0 1 .249.7l-.149.249c-.149.349-.4.448-.7.3l-3.338-1.644-2.341 4.779 2.79 1.345a.5.5 0 0 1 .249.747l-.1.2q-.3.5-.747.3l-2.79-1.395-2.391 4.982 3.388 1.644a.5.5 0 0 1 .249.747l-.149.249a.5.5 0 0 1-.7.249z",
              transform: "translate(-164.082 -53.466)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2571",
              d:
                "M285.32 81.8a.587.587 0 0 1-.947-.648l2.292-8.469-5.928 8.868a.5.5 0 0 1-.747.149l-.249-.149a.448.448 0 0 1-.1-.747l7.672-11.508c.249-.3.5-.4.747-.2l.349.249a.648.648 0 0 1 .3.7l-2.69 9.615 7.921-6.078a.6.6 0 0 1 .747-.05l.4.3a.448.448 0 0 1 .149.747l-7.722 11.508a.5.5 0 0 1-.747.149l-.3-.2a.5.5 0 0 1-.15-.747l5.928-8.868z",
              transform: "translate(-170.581 -57.637)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2572",
              d:
                "M302.262 101.425l-.3-.3a.5.5 0 0 1-.1-.747l1.694-2.79-2.589-2.288-2.591 1.943c-.3.249-.5.249-.747.05l-.249-.249c-.3-.249-.3-.548.05-.8l11.309-8.469a.548.548 0 0 1 .747.05l.6.5a.6.6 0 0 1 .1.747l-7.174 12.205c-.202.348-.501.398-.75.148zm1.993-4.982l4.085-6.775-6.277 4.783z",
              transform: "translate(-179.453 -67.001)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2573",
              d:
                "M310.952 108.76l-.3-.3a.5.5 0 0 1 .05-.747l10.114-9.513a.448.448 0 0 1 .747 0l.249.3a.5.5 0 0 1 0 .8l-10.112 9.46a.448.448 0 0 1-.747 0z",
              transform: "translate(-186.15 -72.193)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2574",
              d:
                "M315.973 115.186l-.2-.249a.5.5 0 0 1 .1-.747l10.91-8.569a.448.448 0 0 1 .747.05l.3.4a.6.6 0 0 1 .05.747l-6.128 9.864 8.469-6.676a.5.5 0 0 1 .8.05l.149.249a.448.448 0 0 1-.1.747l-10.86 8.569a.5.5 0 0 1-.8-.05l-.249-.349a.548.548 0 0 1 0-.747l6.227-10.063-8.668 6.825c-.299.199-.548.199-.747-.051z",
              transform: "translate(-188.729 -75.93)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2575",
              d:
                "M329.16 134.846l-2.491-4.035a.448.448 0 0 1 .2-.747l11.757-7.273a.5.5 0 0 1 .747.149l2.441 3.936c.2.3.1.548-.2.747l-.249.149a.5.5 0 0 1-.747-.149l-1.943-3.188-4.484 2.79 1.594 2.64a.448.448 0 0 1-.2.747l-.2.149a.5.5 0 0 1-.747-.149l-1.638-2.64-4.683 2.889 1.993 3.238a.448.448 0 0 1-.2.747l-.2.149a.566.566 0 0 1-.75-.149z",
              transform: "translate(-194.195 -84.58)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2576",
              d:
                "M338.376 150.5l-.7.349a.5.5 0 0 1-.747-.249l-.2-.4c-.149-.3-.05-.548.3-.7l.7-.349a.5.5 0 0 1 .747.249l.149.4a.448.448 0 0 1-.249.7zm11.807-5.63l-1.594.747-7.822 3.537a.448.448 0 0 1-.7-.249h-.05a.5.5 0 0 1 .249-.7l7.672-3.836 1.594-.747a.548.548 0 0 1 .747.2l.149.4a.5.5 0 0 1-.249.648z",
              transform: "translate(-199.276 -95.053)"
            }
          })
        ]
      ),
      _c(
        "g",
        { attrs: { id: "Groupe_735", transform: "translate(60.799 57.191)" } },
        [
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2577",
              d:
                "M207.772 282.4h-18.433a3.139 3.139 0 0 0-3.039 3.089v18.383a3.139 3.139 0 0 0 3.039 3.089h18.433a3.138 3.138 0 0 0 3.039-3.089v-18.383a3.139 3.139 0 0 0-3.039-3.089z",
              transform: "translate(-164.201 -208.818)"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              id: "Tracé_2578",
              d:
                "M209.624 153.232a31.735 31.735 0 0 0-8.12-9.814 40.253 40.253 0 0 0-11.159-6.327 34.224 34.224 0 0 0-12.355-2.391q-23.315 0-35.57 20.376a3.288 3.288 0 0 0-.448 2.142 3.089 3.089 0 0 0 1.2 1.943l12.6 9.565a3.637 3.637 0 0 0 1.893.6 3.039 3.039 0 0 0 2.391-1.2c3.687-4.633 6.427-7.523 8.17-8.818a15.016 15.016 0 0 1 8.32-2.242 14.5 14.5 0 0 1 8.22 2.441q3.587 2.491 3.587 5.679a9.612 9.612 0 0 1-1.943 5.829 18.586 18.586 0 0 1-6.427 4.284 32.382 32.382 0 0 0-10.96 8.32c-3.338 3.786-4.982 7.772-4.982 12.006v3.487a5.579 5.579 0 0 0 .8 2.84 2.359 2.359 0 0 0 1.943 1.445h18.433a2.74 2.74 0 0 0 2.142-1.2 4.135 4.135 0 0 0 .9-2.491c0-1.3.7-2.989 2.092-5.131a15.244 15.244 0 0 1 5.281-4.982l4.683-2.74a39.455 39.455 0 0 0 4.384-3.338 21.922 21.922 0 0 0 4.235-4.533 26.057 26.057 0 0 0 2.69-5.879 24.411 24.411 0 0 0 1.2-7.772 24.959 24.959 0 0 0-3.188-12.106z",
              transform: "translate(-141.942 -134.7)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }