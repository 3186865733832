var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$vuetify.breakpoint.smAndUp || _vm.displayFilter,
              expression: "$vuetify.breakpoint.smAndUp || displayFilter"
            }
          ],
          staticClass: "shop-container-filter"
        },
        [
          _c("FilterOffers", {
            attrs: {
              allMerchantsList: _vm.allMerchantsList,
              allCategoriesList: _vm.allCategoriesList,
              min: _vm.minPoints,
              max: _vm.maxPoints,
              events: _vm.events,
              searchEvent: _vm.searchEvent
            },
            on: { onValidate: _vm.onValidate }
          })
        ],
        1
      ),
      _vm.$vuetify.breakpoint.xsOnly && !_vm.displayFilter
        ? _c("ShopSlider")
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$vuetify.breakpoint.xsOnly && _vm.displayShop,
              expression: "$vuetify.breakpoint.xsOnly && displayShop"
            }
          ],
          staticClass: "shop-container-filter"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConnected,
                  expression: "isConnected"
                }
              ],
              staticClass: "shop-container-points"
            },
            [
              _c("span", { class: _vm.loading ? "invisible" : "" }, [
                _vm._v(_vm._s(_vm.balance) + " points")
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "shop-container-action-filter",
              on: { click: _vm.showFilter }
            },
            [_c("span", [_vm._v("TRIER / FILTRER")])]
          )
        ]
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayShop,
              expression: "displayShop"
            }
          ],
          staticClass: "shop-container-offer"
        },
        [
          _c(
            "div",
            {
              staticClass: "shop-container-list",
              attrs: { "data-cy": "shop-offer-container" }
            },
            _vm._l(_vm.list, function(item, $index) {
              return _c(
                "div",
                {
                  key: $index,
                  staticClass: "shop-container-list-item",
                  attrs: { "data-cy": "shop-offer-card-" + item.id },
                  on: {
                    click: function($event) {
                      return _vm.onOfferCardClick(item.id)
                    }
                  }
                },
                [
                  _c("OfferCard", {
                    attrs: {
                      imageUrl: item.previewPhotoUrl,
                      offerTitle: item.title,
                      filledPoints: item.filledPoints || 0,
                      points: item.points,
                      companyName: item.companyName,
                      offerTypeId: item.offerTypeId,
                      targetAudienceId: item.targetAudienceId,
                      companyLogoUrl: item.companyAvatar,
                      maxPoints: item.maxPoints,
                      drawAt: item.drawAt,
                      offerId: item.id
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            [
              _c(
                "infinite-loading",
                {
                  attrs: { identifier: _vm.infiniteId },
                  on: { infinite: _vm.infiniteHandler }
                },
                [
                  _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
                  _c(
                    "div",
                    { attrs: { slot: "no-results" }, slot: "no-results" },
                    [_vm._v("Aucune offre")]
                  ),
                  _c("div", { attrs: { slot: "error" }, slot: "error" })
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }