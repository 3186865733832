











































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';

import ApplicationCard from '@/components/Account/ApplicationCard.vue';
import ApplicationSync from '@/components/Account/ApplicationSync.vue';
import { getMobilityCreatorData, MOBILITY_CREATOR_APPS } from '@/services/merchantSpace/PlatformService';
import { getUserServices, validateToken, getAppSyncText } from '@/services/userSpace/UserService';
import { Service, UserServiceProduct } from '@/services/merchantSpace/types';
import { getPlatformServices } from '@/api/resources/platformApi';

@Component({
  components: {
    ApplicationSync,
    ApplicationCard,
    PageTitle,
  },
})
export default class Applications extends Vue {
  @Prop({ type: String, required: false })
  appName?: string;

  @Prop({ type: String, required: false })
  status?: string;

  @Prop({ type: String, required: false })
  token?: string;

  appSynchronized = false;

  showNewApps = false;

  platformServices: Service[] = [];

  userServicesWithProduct: Array<{ service: Service; actualProduct: UserServiceProduct }> = [];

  async mounted() {
    if (this.token) {
      await this.checkToken();
    } else {
      this.userServicesWithProduct = await getUserServices();
      this.platformServices = await getPlatformServices();

      if (this.appName) {
        this.appSynchronized = !!this.userServices.find((s) => s.name === this.appName);
      }
    }
  }

  @Watch('appName')
  watchAppName() {
    this.appSynchronized = !!this.userServices.find((s) => s.name === this.appName);
  }

  get appLogo() {
    return this.currentService && getMobilityCreatorData(this.currentService.key);
  }

  get statusText() {
    if (!this.currentService || !this.status || !this.appName) {
      return '';
    }

    return getAppSyncText(this.currentService.key).validationTexts[this.status];
  }

  get currentService() {
    return this.platformServices.find((ps) => ps.name === this.appName);
  }

  get appProduct(): UserServiceProduct | undefined {
    const res = this.userServicesWithProduct.find((s) => s.service.name === this.appName);
    return res ? res.actualProduct : undefined;
  }

  get userServices() {
    return this.userServicesWithProduct.map((el) => {
      return {
        ...el.service,
        actualProduct: el.actualProduct,
      };
    });
  }

  get synchronizableApps() {
    const userServiceKeys = this.userServices.map((service) => service.key);
    const synchronizableServices = this.platformServices.filter((service) => {
      return MOBILITY_CREATOR_APPS[service.key] && !userServiceKeys.includes(service.key);
    });

    return synchronizableServices.map((service) => {
      return {
        logo: MOBILITY_CREATOR_APPS[service.key],
        name: service.name,
      };
    });
  }

  get synchronizedApps() {
    return this.userServices
      .filter((userService) => MOBILITY_CREATOR_APPS[userService.key])
      .map((userService) => ({
        logo: MOBILITY_CREATOR_APPS[userService.key],
        name: userService.name,
        isSync: userService.actualProduct.synchronized,
      }));
  }

  get wrapperClass() {
    return this.appName ? 'applications-wrapper-large' : 'applications-wrapper-small';
  }

  async checkToken() {
    if (this.token) {
      const validationResult = await validateToken(this.token);
      if (validationResult.success) {
        await this.$router.push(`/dashboard/profile/applications/${validationResult.applicationName}/synchroSuccess`);
      } else if (validationResult.error) {
        if (validationResult.error.message.en === 'ApplicationAlreadySynchronized') {
          await this.$router.push(`/dashboard/profile/applications/alreadySynchronized`);
        } else if (validationResult.error.message.en === 'JsonWebTokenError') {
          await this.$router.push(`/dashboard/profile/applications/synchronisation/badToken`);
        } else if (validationResult.error.message.en === 'SynchronisationTokenExpiredError') {
          await this.$router.push(`/dashboard/profile/applications/synchronisation/tokenExpired`);
        } else {
          await this.$router.push(`/dashboard/profile/applications/synchronisation/unknownError`);
        }
      }
    }
  }
}
