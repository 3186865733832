var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updatePassword" },
    [
      _c("PageTitle", { attrs: { text: "Mot de passe" } }),
      _c("div", { staticClass: "updatePassword-avatar mt-4" }, [
        _c(
          "div",
          { staticClass: "mb-md-4" },
          [_c("Avatar", { attrs: { initials: _vm.compInitials() } })],
          1
        ),
        _vm.isMobile
          ? _c("div", { staticClass: "updatePassword-avatar-name" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.compFirstName() + " " + _vm.compLastName()) +
                  " "
              )
            ])
          : _vm._e()
      ]),
      _c(
        "v-form",
        {
          ref: "updatePasswordForm",
          staticClass: "updatePassword-content",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleUpdatePassword($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "updatePassword-content-fields" },
            [
              _c("v-text-field", {
                ref: "oldPassword",
                staticClass: "updatePassword-content-fields-label",
                attrs: {
                  "error-messages": _vm.error,
                  type: _vm.show.oldPassword ? "text" : "password",
                  label: "ANCIEN MOT DE PASSE",
                  required: "",
                  "validate-on-blur": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.show.oldPassword = !_vm.show.oldPassword
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.show.oldPassword
                                    ? "fas fa-eye"
                                    : "fas fa-eye-slash"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.form.oldPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "oldPassword", $$v)
                  },
                  expression: "form.oldPassword"
                }
              }),
              _c("v-text-field", {
                ref: "newPassword",
                staticClass: "updatePassword-content-fields-label",
                attrs: {
                  rules: _vm.formRules.password,
                  type: _vm.show.newPassword ? "text" : "password",
                  hint:
                    "Votre mot de passe doit être composé de 8 caractères minimum / une minuscule, une majuscule, un chiffre, un caractère spécial.",
                  label: "NOUVEAU MOT DE PASSE",
                  required: "",
                  "validate-on-blur": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.show.newPassword = !_vm.show.newPassword
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.show.newPassword
                                    ? "fas fa-eye"
                                    : "fas fa-eye-slash"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.form.newPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "newPassword", $$v)
                  },
                  expression: "form.newPassword"
                }
              }),
              _c("v-text-field", {
                ref: "confirmPassword",
                staticClass: "updatePassword-content-fields-label",
                attrs: {
                  rules: _vm.formRules.confirmPassword.concat([
                    _vm.matchingPassword
                  ]),
                  type: _vm.show.confirmPassword ? "text" : "password",
                  label: "VALIDER NOUVEAU MOT DE PASSE",
                  required: "",
                  "validate-on-blur": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.show.confirmPassword = !_vm.show
                                  .confirmPassword
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.show.confirmPassword
                                    ? "fas fa-eye"
                                    : "fas fa-eye-slash"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.form.confirmPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "confirmPassword", $$v)
                  },
                  expression: "form.confirmPassword"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "updatePassword-content-actions" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "updatePassword-content-actions-validate-button mt-12",
                  attrs: {
                    color: "homeglobal_fond_bouton",
                    large: "",
                    rounded: ""
                  },
                  on: { click: _vm.handleUpdatePassword }
                },
                [_vm._v(" Valider ")]
              )
            ],
            1
          )
        ]
      ),
      _vm.showConfirm
        ? _c("ConfirmPassword", { on: { input: _vm.closeConfirm } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }