var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "30",
        height: "30",
        viewBox: "0 0 40 40"
      }
    },
    [
      _c("path", {
        staticStyle: { fill: "none" },
        attrs: { "data-name": "Tracé 3580", d: "M0 0h40v40H0z" }
      }),
      _c("rect", {
        staticStyle: {
          stroke: "#272828",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2px",
          fill: "none"
        },
        attrs: {
          "data-name": "Rectangle 1635",
          width: "19",
          height: "19",
          rx: "3",
          transform: "translate(14.221 14.221)"
        }
      }),
      _c("path", {
        staticStyle: {
          stroke: "#272828",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2px",
          fill: "none"
        },
        attrs: {
          "data-name": "Tracé 3581",
          d:
            "M22.36 10.12V7.06A3.06 3.06 0 0 0 19.3 4H7.06A3.06 3.06 0 0 0 4 7.06V19.3a3.06 3.06 0 0 0 3.06 3.06h3.06",
          transform: "translate(3.213 3.213)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }