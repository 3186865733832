var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "history-container" },
    [
      _c("PageTitle", {
        staticClass: "d-md-flex",
        attrs: { text: "Historique des points" }
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("PointsHistory", { attrs: { limit: _vm.pageLimit, infinite: "" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }