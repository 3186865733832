var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSlider
    ? _c(
        "div",
        [
          _c(
            "VueSlickCarousel",
            _vm._b(
              {
                class: { "light-theme": _vm.light },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "nextArrow",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "slick-arrow__next" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M19.576 12.93L8.616 23.579c-.528.513-1.385.513-1.913 0l-1.279-1.242c-.527-.513-.528-1.344-.002-1.858L14.107 12 5.422 3.52c-.526-.513-.525-1.344.002-1.857L6.703.421c.528-.513 1.385-.513 1.913 0l10.96 10.65c.528.513.528 1.345 0 1.859z",
                                    fill: "currentColor"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "prevArrow",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "slick-arrow__prev" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M5.424 11.07L16.384.421c.528-.513 1.385-.513 1.913 0l1.279 1.242c.527.513.528 1.344.002 1.858L10.893 12l8.685 8.478c.526.514.525 1.345-.002 1.858l-1.279 1.242c-.528.513-1.385.513-1.914 0L5.424 12.929c-.528-.513-.528-1.345 0-1.859z",
                                    fill: "currentColor"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3109518192
                )
              },
              "VueSlickCarousel",
              _vm.settings,
              false
            ),
            _vm._l(_vm.offerList, function(offer) {
              return _c(
                "div",
                {
                  key: offer.id,
                  staticClass: "slick-slider-item",
                  on: {
                    click: function($event) {
                      return _vm.onOfferCardClick(offer.id)
                    }
                  }
                },
                [
                  _c("OfferCard", {
                    attrs: {
                      imageUrl: offer.previewPhotoUrl,
                      drawAt: offer.drawAt,
                      offerId: offer.id,
                      offerTitle: offer.title,
                      points: offer.points,
                      companyName: offer.companyName,
                      offerTypeId: offer.offerTypeId,
                      targetAudienceId: offer.targetAudienceId,
                      companyLogoUrl: offer.companyAvatar,
                      filledPoints: offer.filledPoints || 0,
                      maxPoints: offer.maxPoints
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }