var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "migration-message",
        "max-width": this.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("input")
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "content-class": "migration-message-card" } },
        [
          _c(
            "v-card-title",
            { staticClass: "migration-message-card-title" },
            [
              _vm.compShowStickers
                ? _c(
                    "div",
                    { staticClass: "migration-message-card-title-picto" },
                    [_c("SvgPopup", { attrs: { width: "80", height: "80" } })],
                    1
                  )
                : _vm._e(),
              _c("h1", { staticClass: "migration-message-card-title-text" }, [
                _vm._v("Mot de passe")
              ]),
              _c("v-icon", {
                staticClass: "migration-message-card-close",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-times") },
                on: {
                  click: function($event) {
                    return _vm.$emit("input")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "migration-message-card-subtitle" },
            [_vm._v(" Votre programme de fidélité a évolué ! ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "migration-message-card-subtitle" },
            [
              _vm._v(
                " La sécurité est primordiale, en conséquence, un mail vient de vous être envoyé pour créer votre nouveau mot de passe. "
              )
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "migration-message-card-subtitle last" },
            [
              _vm._v(
                " Une fois votre nouveau mot de passe créé, vous pourrez à nouveau vous connecter. "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }