


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SSOBehavior } from '@/services/merchantSpace/types';

@Component
export default class SignInButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  text!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  plain!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  block!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  ripple!: boolean;

  @Prop({
    required: false,
    default: undefined,
  })
  color: any;

  @Prop({
    required: false,
    default: undefined,
  })
  elevation: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  large: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  rounded: any;

  @Prop({ type: String, required: false, default: 'Inscription' })
  readonly authTextBtn!: string;

  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  click() {
    this.signUpBeahvior();
  }

  signUpBeahvior() {
    if (this.ssoBehavior === SSOBehavior.NONE) {
      this.$router.push({ name: 'signup' });
    } else {
      this.$store.dispatch('showSSOSignUp', true);
    }
  }
}
