
























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import * as UserService from '@/services/userSpace/UserService';
import * as JiraService from '@/services/userSpace/JiraService';
import * as PlatformService from '@/services/merchantSpace/PlatformService';
import { getServiceDeskId } from '@/api/resources/platformApi';

@Component({
  components: {
    SvgPopup,
  },
})
export default class ContactDialog extends Vue {
  dialog: boolean = false;

  contactRunning: boolean = false;

  sendValid: boolean = false;

  wasCreated: boolean = false;

  formRules = UserService.formRules;

  requestTypes: any = null;

  error = '';

  formInfos = {
    email: '',
    subject: '',
    description: '',
    requestTypeId: '',
  };

  async mounted() {
    if (this.$store.getters['user/getAccessToken']) this.formInfos.email = (await UserService.getUserEmail()) || '';
    this.requestTypes = await JiraService.getServiceDeskRequestTypes(await this.getServiceDeskId());
  }

  async validate() {
    if ((this.$refs.form as any).validate()) {
      this.contactRunning = true;
      const descriptionHeader = await JiraService.createHeaderSupportTicket(this.formInfos.email, this.$route.path);
      const ticketToCreate = {
        serviceDeskId: await this.getServiceDeskId(),
        requestTypeId: this.formInfos.requestTypeId,
        raiseOnBehalfOf: this.formInfos.email,
        requestFieldValues: {
          summary: this.formInfos.subject,
          description: `${descriptionHeader}\n${this.formInfos.description}`,
        },
      };

      try {
        this.wasCreated = await JiraService.createSupportTicket(ticketToCreate);
        this.dialog = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.contactRunning = false;
      }
    }
  }

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  goToProfile() {
    this.$router.push('/dashboard/profile');
  }

  async getServiceDeskId() {
    return (await getServiceDeskId()) || JiraService.serviceDeskId;
  }
}
