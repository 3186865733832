var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: true,
        "content-class": "basicDialog",
        "retain-focus": "",
        "max-width": _vm.$vuetify.breakpoint.xsOnly ? "350px" : "600px"
      },
      on: {
        input: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-5" },
        [
          _c("v-card-title", { staticClass: "pt-O" }, [
            _c("h1", [_vm._v(" " + _vm._s(_vm.title) + " ")])
          ]),
          _c("v-card-text", [
            _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.content))])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "sign-up-btn",
                  attrs: {
                    href: _vm.signUpUrl,
                    target: "_blank",
                    large: "",
                    rounded: "",
                    block: "",
                    color: "homeglobal_fond_bouton"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.signUpButtonText) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "sign-up-btn already ml-0",
                  attrs: {
                    large: "",
                    rounded: "",
                    block: "",
                    color: "homebloc_fond_bouton"
                  },
                  on: { click: _vm.alreadySignUp }
                },
                [_vm._v(" " + _vm._s(_vm.alreadySignedUpText) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }